angular
	.module('dcApp')
	.controller('deleteProjectConfirmationController', [
		'$scope',
		function ($scope, ProjectService) {},
	])
	.directive('deleteProjectConfirmation', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'GenericService',
				'gettextCatalog',
				function ($scope, GenericService, gettextCatalog) {
					$scope.isTextValid = false;
					$scope.isCheckboxValid = false;
					$scope.checkConfirmationText = function () {
						$scope.isTextValid =
							$scope.confirmationText ===
							'SUPPRIMER' + ' ' + $scope.projectName || 'DELETE' + ' ' + $scope.projectName;
						$scope.validateSecondStep();
					};

					$scope.init = function () {
						$scope.accepted = false;
					};

					$scope.validateSecondStep = function () {
						$scope.accepted = $scope.isCheckboxValid && $scope.isTextValid;
					};
				},
			];
			return {
				restrict: 'AECM',
				templateUrl:
					'./src/components/modules/project/deleteProject/deleteProjectConfirmation.html',
				replace: true,
				controller: controller,
				scope: {
					projectElements: '=',
					projectName: '=',
					accepted: '=',
				},
				link: function (scope) {
					scope.init();
				},
			};
		},
	]);
