(function () {
	'use strict';

	angular.module('dcApp').config([
		'$locationProvider',
		function ($locationProvider) {
			$locationProvider.html5Mode(false);
			$locationProvider.hashPrefix('');
		},
	]);
})();
