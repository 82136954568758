(function () {
	'use strict';

	angular.module('dcApp').directive('datablockUnion', [
		'$parse',
		function ($parse) {
			var controller = [
				'$rootScope',
				'$scope',
				'DataBlocksService',
				'toaster',
				'$sce',
				'CaracService',
				'TypeEntiteService',
				'gettextCatalog',
				'GrammarUtils',
				function (
					$rootScope,
					$scope,
					DataBlocksService,
					toaster,
					$sce,
					CaracService,
					TypeEntiteService,
					gettextCatalog,
					GrammarUtils
				) {
					var vm = this;

					vm.getColumnDisplayedType = function (type, isList) {
						if (type != undefined) {
							type = type.toLowerCase();
							return $sce.trustAsHtml(getHtmlIconByType(type, isList, GrammarUtils.getTypeLabel(type)));
						}
					};

					$scope.types = [
						{
							code: 'UNION ALL',
							label: 'Union',
						},
						{
							code: 'INTERSECT',
							label: 'Intersection',
						},
					];

					$scope.addNewUnion = function () {
						$scope.unionId++;
						var mappings = [];
						for (var c in vm.data.columns) {
							mappings.push({
								left_column_lib: vm.data.columns[c].lib,
								left_column_uuid: vm.data.columns[c].uuid,
								uuid: generateUuid('_'),
								left_column_type: vm.data.columns[c].type,
							});
						}
						$scope.selectedUnion = {
							type: $scope.types[0].code,
							mappings: mappings,
							id: $scope.unionId,
						};
						$scope.unions.push($scope.selectedUnion);
					};

					$scope.deleteUnion = function (union) {
						if ($scope.selectedUnion && union.id == $scope.selectedUnion.id) {
							delete $scope.selectedUnion;
						}
						$scope.unions.splice($scope.unions.indexOf(union), 1);
					};

					$scope.selectUnion = function (union) {
						$scope.selectedUnion = union;
						$scope.selectedUnion.allSelected = false;

						if (!union.db_id) {
							return;
						}

						for (var c in vm.data.columns) {
							var exists = false;
							for (var m in $scope.selectedUnion.mappings) {
								if (
									$scope.selectedUnion.mappings[m].left_column_uuid ==
									vm.data.columns[c].uuid
								) {
									exists = true;
									break;
								}
							}
							if (!exists) {
								$scope.selectedUnion.mappings.push({
									left_column_lib: vm.data.columns[c].lib,
									left_column_uuid: vm.data.columns[c].uuid,
									uuid: generateUuid('_'),
									left_column_type: vm.data.columns[c].type,
								});
							}
						}

						DataBlocksService.isDataBlock(union.db_id).then(function (
							response
						) {
							if (response.data) {
								$scope.setSelectedDataBlock(union.db_id);
							} else {
								TypeEntiteService.getTypeWithLibs(union.db_id).then(function (
									response_r
								) {
									$scope.setSelectedEntite(response_r.data);
								});
							}
						});
					};

					$scope.addNewUnionMapping = function () {
						$scope.selectedUnion.mappings.push({});
					};

					$scope.showWidgetList = function () {
						vm.widgetData.hdhElementRestrictedList = [];
						$scope.showWidgetSelectionList();
					};

					$scope.showWidgetSelectionList = function () {
						vm.widgetData.elementSelectionType = 'DATABLOCK_ENTITE';
						if (vm.datablock.id) {
							DataBlocksService.getReferencedDatablocks(vm.datablock.id).then(
								function (response) {
									openElementSelection(response.data);
								}
							);
						} else {
							openElementSelection([]);
						}
					};

					var openElementSelection = function (elementSelectionExclude) {
						vm.widgetData.elementSelectionExclude = elementSelectionExclude;
						vm.widgetData.showHdhElementSelection = true;
						vm.widgetData.elementSelectionSaveMethod = $scope.selectDataBlock;
					};

					$scope.selectDataBlock = function (element, type) {
						for (var m in $scope.selectedUnion.mappings) {
							delete $scope.selectedUnion.mappings[m].right_column_uuid;
							delete $scope.selectedUnion.mappings[m].right_carac_code;
						}
						if (type == 'DATABLOCK') {
							$scope.setSelectedDataBlock(element.id);
						} else if (type == 'ENTITE') {
							$scope.setSelectedEntite(element);
						}
					};

					$scope.setSelectedDataBlock = function (datablockId) {
						$scope.selectedUnion.db_id = datablockId;
						$scope.selectedUnion.fromEntite = false;
						DataBlocksService.getDataBlock(datablockId).then(function (
							response
						) {
							$scope.selectedUnion.db_lib = response.data.metadata.label;
							setColumns(response.data, $scope.selectedUnion);
							if (
								!$scope.selectedUnion.lib ||
								$scope.selectedUnion.lib.length == 0
							) {
								$scope.selectedUnion.lib = $scope.selectedUnion.db_lib;
							}
						});
					};

					$scope.setSelectedEntite = function (entite) {
						$scope.selectedUnion.fromEntite = true;
						$scope.selectedUnion.db_id = entite.id;
						$scope.selectedUnion.datablock = entite;
						var lib = entite.label;
						if (
							!$scope.selectedUnion.lib ||
							$scope.selectedUnion.lib.length == 0
						) {
							$scope.selectedUnion.lib = lib;
						}
						$scope.selectedUnion.db_lib = lib;
						setColumnsFromEntite(entite, $scope.selectedUnion);
					};

					$scope.filteredDataColumns = {};
					let filterDataColumns = function (union) {
						for (let ma in union.mappings) {
							$scope.filteredDataColumns[union.mappings[ma].left_column_uuid] =
								union.data_columns;
							let leftType =
								vm.columnsTypes[union.mappings[ma].left_column_uuid];
							if (leftType) {
								$scope.filteredDataColumns[
									union.mappings[ma].left_column_uuid
								] = _.filter(union.data_columns, function (elm) {
									return (
										elm.type === leftType[0] && elm.is_list === leftType[1]
									);
								});
							}
						}
					};
					var setColumns = function (datablock, union) {
						var columns = $scope.withoutColumns
							? []
							: [{ alias: gettextCatalog.getString('Aucun Mapping') }];
						DataBlocksService.getDBBuilderColumns(datablock.grammar).then(
							function (response) {
								for (var c in response.data) {
									columns.push({
										alias: response.data[c].lib,
										column_uuid: response.data[c].uuid,
										type: response.data[c].type,
										right_displayed_type: vm.getColumnDisplayedType(
											response.data[c].type,
											response.data[c].is_list
										),
										is_list: response.data[c].is_list
											? response.data[c].is_list
											: false,
									});
								}
								union.data_columns = columns;
								if (union.mappings && union.mappings[0]) {
									let firstMappedElement = _.find(
										union.mappings,
										function (elm) {
											return elm.right_column_uuid;
										}
									);
									for (var m in union.mappings) {
										if (union.mappings[m].right_column_uuid) {
											let rightColumn = _.find(response.data, function (item) {
												return item.uuid == union.mappings[m].right_column_uuid;
											});

											if (!rightColumn) {
												delete union.mappings[m].right_column_uuid;
											}
										}
										// auto detect only if not element is mapped
										if (
											!union.mappings[m].right_column_uuid &&
											!firstMappedElement
										) {
											for (let o in response.data) {
												if (
													response.data[o].lib ==
													union.mappings[m].left_column_lib
												) {
													union.mappings[m].right_column_uuid =
														response.data[o].uuid;
												}
											}
										}
									}
								} else {
									union.mappings = [];
									for (let c in union.data_columns) {
										union.mappings.push({
											right_column_uuid: union.data_columns[c].column_uuid,
											uuid: $scope.withoutColumns
												? generateUuid('_')
												: undefined,
											right_displayed_type: vm.getColumnDisplayedType(
												union.data_columns[c].type,
												union.data_columns[c].is_list
											),
										});
									}
								}
								filterDataColumns(union);
							}
						);
					};
					var setColumnsFromEntite = function (datablock, union) {
						var columns = [];
						CaracService.getSimpleCaracsByEntiteId(datablock.id).then(function (
							response
						) {
							for (let c in response.data) {
								columns.push({
									alias: response.data[c].lib,
									carac_code: response.data[c].code,
									type: getTypeFromEntiteTypeCode(
										response.data[c].entite_type.code
									),
									is_list: response.data[c].list
										? response.data[c].list
										: false,
									right_displayed_type: vm.getColumnDisplayedType(
										getTypeFromEntiteTypeCode(
											response.data[c].entite_type.code
										),
										response.data[c].list
									),
								});
							}
							union.data_columns = columns;
							if (union.mappings && union.mappings[0]) {
								let firstMappedElement = _.find(union.mappings, function (elm) {
									return elm.right_carac_code;
								});
								for (let m in union.mappings) {
									if (union.mappings[m].right_carac_code) {
										let rightColumn = _.find(columns, function (item) {
											return (
												item.carac_code == union.mappings[m].right_carac_code
											);
										});

										if (!rightColumn) {
											delete union.mappings[m].right_carac_code;
										}
									}
									// auto detect only if not element is mapped
									if (
										!union.mappings[m].right_carac_code &&
										!firstMappedElement
									) {
										for (let c in columns) {
											if (
												columns[c].alias &&
												columns[c].alias == union.mappings[m].left_column_lib
											) {
												union.mappings[m].right_carac_code =
													columns[c].carac_code;
											}
										}
									}
								}
							} else {
								union.mappings = [];
								for (let c in union.data_columns) {
									union.mappings.push({
										right_carac_code: union.data_columns[c].carac_code,
										uuid: $scope.withoutColumns ? generateUuid('_') : undefined,
										right_displayed_type: vm.getColumnDisplayedType(
											union.data_columns[c].type,
											union.data_columns[c].is_list
										),
									});
								}
							}
							filterDataColumns(union);
						});
					};

					$scope.closeConfirmation = function () {
						vm.closeModal();
					};

					$scope.deleteUnions = function () {
						vm.widgetData.showConfirmationDialog = true;
						vm.widgetData.confirmationDialogTitle = gettextCatalog.getString(
							'Confirmer la suppression'
						);
						vm.widgetData.confirmationDialogMessage = gettextCatalog.getString(
							'Tous les paramètres ici définis seront perdus. Confirmer cette action ?'
						);
						vm.widgetData.confirmationDialogActionName =
							gettextCatalog.getString('Oui');
						vm.widgetData.enableConfirmDialogAction = true;
						vm.widgetData.confirmDialogAction = function () {
							confirmDeleteUnions();
						};
					};

					var confirmDeleteUnions = function () {
						vm.data.saveUnionsMethod([], $scope.saveAndClose);
					};

					$scope.save = function () {
						var notValid = false;
						if (!$scope.unions || !$scope.unions[0]) {
							toaster.pop(
								'error',
								gettextCatalog.getString('Erreur'),
								gettextCatalog.getString('Aucune union définie')
							);
							return;
						}
						var unions = [];
						for (var l in $scope.unions) {
							checkUnion($scope.unions[l]);
							if ($scope.unions[l].not_valid) {
								notValid = true;
							}
							var union = {
								type: $scope.unions[l].type,
								db_id: $scope.unions[l].db_id,
								lib: $scope.unions[l].lib,
								mappings: $scope.unions[l].mappings,
							};

							unions.push(union);
						}
						if (notValid) {
							return;
						}
						vm.data.saveUnionsMethod(unions, $scope.saveAndClose);
					};

					$scope.saveAndClose = function () {
						vm.closeModal();
						toaster.pop(
							'success',
							gettextCatalog.getString('Succès'),
							gettextCatalog.getString('Enregistrement effectué')
						);
					};

					vm.closeModal = function () {
						$(vm.element).modal('hide');
						vm.data.showDbUnions = false;
					};

					var checkUnion = function (union) {
						union.not_valid = false;
						if (!union.lib) {
							union.errorMessage = gettextCatalog.getString(
								'Le champ libellé est obligatoire'
							);
							union.not_valid = true;
						} else if (!union.db_id) {
							union.errorMessage = gettextCatalog.getString(
								'Le champ DataBlock est obligatoire'
							);
							union.not_valid = true;
						} else if (vm.data.datablock.id == union.db_id) {
							union.errorMessage = gettextCatalog.getString('Lien cyclique');
							union.not_valid = true;
						} else {
							for (var m in union.mappings) {
								if (union.mappings[m].left_column_uuid == undefined) {
									//union.errorMessage = "La colonne source dans définition de l'union est obligatoire" ;
									//union.not_valid = true;
								} else if (union.mappings[m].right_column_uuid == undefined) {
									//union.errorMessage = "La colonne destination dans définition de l'union est obligatoire" ;
									//union.not_valid = true;
								}
							}
						}
					};
				},
			];

			return {
				restrict: 'E',

				scope: {
					data: '=',
					datablock: '=',
					widgetData: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/dataOperationTools/datablockUnion/datablockUnion.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {
					$(element).modal({
						show: false,
						keyboard: attrs.keyboard,
						backdrop: attrs.backdrop,
					});
					$(element).on('hidden.bs.modal', function (event) {
						if (event.target == element[0] && scope.vm.data.showDbUnions) {
							scope.vm.closeModal();
						}
					});

					scope.$watch(
						function () {
							return scope.vm.data.showDbUnions;
						},
						function (value) {
							scope.vm.element = element;
							if (value == true) {
								delete scope.selectedUnion;
								delete scope.withoutColumns;
								if (!scope.vm.data.columns[0]) {
									scope.withoutColumns = true;
								}
								scope.vm.columnsTypes = {};
								for (var c in scope.vm.data.columns) {
									scope.vm.data.columns[c].index = c;
									scope.vm.data.columns[c].left_displayed_type =
										scope.vm.getColumnDisplayedType(
											scope.vm.data.columns[c].type,
											scope.vm.data.columns[c].is_list
										);
									scope.vm.data.columns[c].left_column_type =
										scope.vm.data.columns[c].type;
									scope.vm.columnsTypes[scope.vm.data.columns[c].uuid] = [
										scope.vm.data.columns[c].type,
										scope.vm.data.columns[c].is_list
											? scope.vm.data.columns[c].is_list
											: false,
									];
								}
								scope.unions = angular.copy(scope.vm.data.step.unions);
								scope.unionId = 0;

								scope.errorMessage = '';

								$(element).modal(
									{ backdrop: 'static', keyboard: false },
									'show'
								);
							} else {
								$(element).modal('hide');
							}
						}
					);
				},
			};
		},
	]);
})();
