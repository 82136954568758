(function () {
	'use strict';

	angular.module('dcApp').factory('InitializationService', [
		'$q',
		'$http',
		'APP_BUILD',
		'gettextCatalog',
		'API_BASE_URL_BACKEND',
		'APP_VERSION',
		'CommonInitializationService',
		'LoggerFactory',
		function (
			$q,
			$http,
			APP_BUILD,
			gettextCatalog,
			API_BASE_URL_BACKEND,
			APP_VERSION,
			CommonInitializationService,
			LoggerFactory
		) {
			const logger = LoggerFactory.getLogger('AngularJs-InitializationService');
			let _availablePaths = {};
			let _datePatternMappings = {};
			let _jobTypes = [];
			let _timezones = [];
			let _dateLanguages = [];
			let _defaultTimezone = [];

			return {
				initAvailablePaths: initAvailablePaths,
				initCommonTranslations: initCommonTranslations,
				initDevExtremeConfigs: initDevExtremeConfigs,
				initJobTypes: initJobTypes,
				initAvailableTimezones: initAvailableTimezones,
				initAvailableDateLanguages: initAvailableDateLanguages,
				initDatePatternMappings: initDatePatternMappings,
				initDefaultTimezone: initDefaultTimezone,
				getAvailablePaths: getAvailablePaths,
				getJobTypes: getJobTypes,
				setCurrentLanguage: setCurrentLanguage,
				getAvailableTimezones: getAvailableTimezones,
				getAvailableDateLanguages: getAvailableDateLanguages,
				getDatePatternMappings: getDatePatternMappings,
				getDefaultTimezone: getDefaultTimezone,
			};

			/**
			 * Binded functions
			 */

			function initCommonTranslations() {
				gettextCatalog.debug = CommonInitializationService.isDebugEnabled();
				return {
					dataGirdCaptions: initDataGridCaptionLabelsTranslation(),
					permissionsActionLabels: initPermissionsActionLabelsTranslation(),
					simpleTypes: initSimpleTypesLabelsTranslation(),
					various: initVariousLabelsTranslation(),
				};
			}

			function initDataGridCaptionLabelsTranslation() {
				const dataGirdCaptionsTra = {};
				dataGirdCaptionsTra['Code'] = gettextCatalog.getString('Code');
				dataGirdCaptionsTra['Libellé'] = gettextCatalog.getString('Libellé');
				dataGirdCaptionsTra['Tags'] = gettextCatalog.getString('Tags');
				dataGirdCaptionsTra['Description'] =
					gettextCatalog.getString('Description');
				dataGirdCaptionsTra['Date de création'] =
					gettextCatalog.getString('Date de création');
				dataGirdCaptionsTra['Créateur'] = gettextCatalog.getString('Créateur');
				dataGirdCaptionsTra['Date de mise à jour'] = gettextCatalog.getString(
					'Date de mise à jour'
				);
				dataGirdCaptionsTra['Dernier modificateur'] = gettextCatalog.getString(
					'Dernier modificateur'
				);
				dataGirdCaptionsTra['Actions'] = gettextCatalog.getString('Actions');
				dataGirdCaptionsTra['Type'] = gettextCatalog.getString('Type');
				dataGirdCaptionsTra['Connecteur'] =
					gettextCatalog.getString('Connecteur');
				dataGirdCaptionsTra['Type de Dépôt'] =
					gettextCatalog.getString('Type de Dépôt');
				dataGirdCaptionsTra['Nb entités métier'] =
					gettextCatalog.getString('Nb entités métier');
				dataGirdCaptionsTra['Importé'] = gettextCatalog.getString('Importé');
				dataGirdCaptionsTra['Actif'] = gettextCatalog.getString('Actif');
				dataGirdCaptionsTra['Oui'] = gettextCatalog.getString('Oui');
				dataGirdCaptionsTra['Non'] = gettextCatalog.getString('Non');
				dataGirdCaptionsTra['Cache et persistance'] = gettextCatalog.getString(
					'Cache et persistance'
				);
				dataGirdCaptionsTra['frame.status.persistence.date'] =
					gettextCatalog.getString('frame.status.persistence.date');
				dataGirdCaptionsTra['frame.status.cache.date'] =
					gettextCatalog.getString('frame.status.cache.date');
				dataGirdCaptionsTra['Mode'] = gettextCatalog.getString('Mode');
				dataGirdCaptionsTra['Nb de dépôts'] =
					gettextCatalog.getString('Nb de dépôts');
				dataGirdCaptionsTra['Projet'] = gettextCatalog.getString('Projet');
				dataGirdCaptionsTra['Création'] = gettextCatalog.getString('Création');
				dataGirdCaptionsTra['Mise à jour'] =
					gettextCatalog.getString('Mise à jour');
				dataGirdCaptionsTra['Par'] = gettextCatalog.getString('Par');
				dataGirdCaptionsTra['pager'] = gettextCatalog.getString(
					'Page {0} sur {1} ({2} éléments)'
				);
				dataGirdCaptionsTra['grid.header.filter.blank'] =
					gettextCatalog.getString('grid.header.filter.blank');

				return dataGirdCaptionsTra;
			}

			function initPermissionsActionLabelsTranslation() {
				return {
					create: gettextCatalog.getString('Création'),
					view: gettextCatalog.getString('Lecture'),
					edit: gettextCatalog.getString('Modification'),
					delete: gettextCatalog.getString('Suppression'),
					duplicate: gettextCatalog.getString('Duplication'),
					setting: gettextCatalog.getString('Paramètres'),
					stop: gettextCatalog.getString('Arrêt'),
					start: gettextCatalog.getString('Démarrage'),
					load: gettextCatalog.getString('Chargement des données'),
					unload: gettextCatalog.getString('Déchargement des données'),
					requesting: gettextCatalog.getString('Interrogation'),
					sourcesetting: gettextCatalog.getString('Gestion des sources'),
					list: gettextCatalog.getString('Liste'),
					execute: gettextCatalog.getString('Exécution'),
					historic: gettextCatalog.getString('Historique'),
					graph: gettextCatalog.getString('Graphe'),
					export: gettextCatalog.getString('Export'),
					export_dc: gettextCatalog.getString('Export DC'),
					column_stat: gettextCatalog.getString(
						'Statistiques sur les colonnes'
					),
					persist: gettextCatalog.getString('Persister/Cacher'),
					cache: gettextCatalog.getString('Cacher'),
					synchronize: gettextCatalog.getString('Synchroniser'),
					list_carac: gettextCatalog.getString('Liste des caractéristiques'),
					stat: gettextCatalog.getString('Statistiques'),
					permissions: gettextCatalog.getString('Droits'),
					link: gettextCatalog.getString('Lien'),
					carac: gettextCatalog.getString('Caractéristiques'),
					data: gettextCatalog.getString('Données'),
					extract: gettextCatalog.getString('Extraction'),
					data_catalog: gettextCatalog.getString('DataCatalogue'),
					publication: gettextCatalog.getString('Publication'),
					share: gettextCatalog.getString('share'),
				};
			}

			function initSimpleTypesLabelsTranslation() {
				return {
					'Chaîne de caractères': gettextCatalog.getString(
						'Chaîne de caractères'
					),
					'Nombre entier (<2 Md)': gettextCatalog.getString(
						'Nombre entier (<2 Md)'
					),
					'Grand nombre entier': gettextCatalog.getString(
						'Grand nombre entier'
					),
					Booléen: gettextCatalog.getString('Booléen'),
					'Nombre décimal': gettextCatalog.getString('Nombre décimal'),
					'Horodatage (Timestamp)': gettextCatalog.getString(
						'Horodatage (Timestamp)'
					),
					Mots: gettextCatalog.getString('Mots'),
					Fichier: gettextCatalog.getString('Fichier'),
					'Fichier Binaire': gettextCatalog.getString('Fichier Binaire'),
					Géométrie: gettextCatalog.getString('Géométrie'),
				};
			}

			function initVariousLabelsTranslation() {
				const various = {};
				various.helpTextTra = gettextCatalog.getString('Aide');
				various.cachedPasswordTra =
					gettextCatalog.getString('Mot de passe caché');
				various.cachedSshTra = gettextCatalog.getString('Clé SSH cachée');
				various.cachedAwsKeyTra = gettextCatalog.getString(
					'Clef secrète AWS cachée'
				);
				various.dxGridTextInfo = gettextCatalog.getString(
					'Page {0} sur {1} ({2} éléments)'
				);
				various.errorMessage = gettextCatalog.getString('Erreur');
				various.controlPermissionErrorMsg = gettextCatalog.getString(
					'error.control.permission'
				);
				return various;
			}

			function initDevExtremeConfigs() {
				return {
					sessionDelayAlertPopupOptions: {
						width: 600,
						height: 160,
						contentTemplate: 'info',
						showTitle: true,
						position: { my: 'top', at: 'top', of: window, offset: '0 30' },
						dragEnabled: false,
						closeOnOutsideClick: false,
						bindingOptions: {
							visible: 'visibleSessionDelayAlertPopup',
						},
						title: 'Session',
					},
					sessionDelayLastAlertPopupOptions: {
						width: 600,
						height: 160,
						contentTemplate: 'info',
						showTitle: true,
						position: { my: 'top', at: 'top', of: window, offset: '0 30' },
						dragEnabled: false,
						closeOnOutsideClick: false,
						bindingOptions: {
							visible: 'visibleSessionDelayLastAlertPopup',
						},
						title: 'Session',
					},
					inactivityAlertPopupOptions: {
						width: 600,
						height: 160,
						contentTemplate: 'info',
						showTitle: true,
						position: { my: 'top', at: 'top', of: window, offset: '0 30' },
						dragEnabled: false,
						closeOnOutsideClick: false,
						bindingOptions: {
							visible: 'visibleInactivityAlertPopup',
						},
						title: 'Déconnexion imminente !',
					},
				};
			}

			function initAvailablePaths() {
				logger.info('fetching paths');
				const deferred = $q.defer();
				_availablePaths = CommonInitializationService.getAvailablePaths();
				deferred.resolve();
				return deferred.promise;
			}

			function initDatePatternMappings() {
				const deferred = $q.defer();
				$.getJSON(
					'./src/components/base/constants/date-mapping.json?v=' + APP_BUILD,
					function (mappings) {
						_datePatternMappings = mappings;
						deferred.resolve();
					}
				);
				return deferred.promise;
			}

			function getAvailablePaths() {
				return _availablePaths;
			}

			function getDatePatternMappings() {
				return _datePatternMappings;
			}

			function setCurrentLanguage(lang) {
				DevExpress.localization.locale(lang);
				moment.locale(lang);
				gettextCatalog.setCurrentLanguage(lang);
			}

			/**
			 * Private functions
			 */

			function initJobTypes() {
				var deferred = $q.defer();
				var url = API_BASE_URL_BACKEND + 'job_types';
				$http.get(url).then(
					function (response) {
						_jobTypes = response.data;
						deferred.resolve();
					},
					function (errorMessage) {
						deferred.reject();
					}
				);
				return deferred.promise;
			}

			function getJobTypes() {
				return _jobTypes;
			}

			function initAvailableTimezones() {
				var deferred = $q.defer();
				var url = API_BASE_URL_BACKEND + 'dates/timezones';
				$http.get(url).then(
					function (response) {
						_timezones = response.data;
						deferred.resolve();
					},
					function (errorMessage) {
						deferred.reject();
					}
				);
				return deferred.promise;
			}

			function initDefaultTimezone() {
				var deferred = $q.defer();
				var url = API_BASE_URL_BACKEND + 'dates/timezone/default';
				$http.get(url).then(
					function (response) {
						_defaultTimezone = response.data;
						deferred.resolve();
					},
					function (errorMessage) {
						deferred.reject();
					}
				);
				return deferred.promise;
			}

			function initAvailableDateLanguages() {
				var deferred = $q.defer();
				var url = API_BASE_URL_BACKEND + 'dates/languages';
				$http.get(url).then(
					function (response) {
						_dateLanguages = response.data.map((obj) => ({
							label: obj.label.charAt(0).toUpperCase() + obj.label.slice(1),
							value: obj.value,
						}));
						deferred.resolve();
					},
					function (errorMessage) {
						deferred.reject();
					}
				);
				return deferred.promise;
			}

			function getAvailableTimezones() {
				return _timezones;
			}

			function getDefaultTimezone() {
				return _defaultTimezone;
			}

			function getAvailableDateLanguages() {
				return _dateLanguages;
			}
		},
	]);
})();
