(function () {
	'use strict';

	angular.module('dcApp').directive('dataoperation', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'NUMBER_PATTERNS',
				'COUNTING_FUNCTION_OPERATORS',
				'toaster',
				'$sce',
				'$timeout',
				'gettextCatalog',
				'GrammarUtils',
				function (
					$scope,
					NUMBER_PATTERNS,
					COUNTING_FUNCTION_OPERATORS,
					toaster,
					$sce,
					$timeout,
					gettextCatalog,
					GrammarUtils
				) {
					var vm = this;
					$scope.errorDialogData = {};
					$scope.operationType = 0;

					$scope.data = {};

					$scope.attributs = [];

					$scope.getHtmlIconByType = function (type, isList) {
						if (type !== undefined) {
							type = type.toLowerCase();
							return $sce.trustAsHtml(getHtmlIconByType(type, isList, GrammarUtils.getTypeLabel(type)));
						}
					};

					$scope.init = function () {
						$scope.numberOfRows = 1;
						$scope.columns = [];
						$scope.numberOfColumnsPerGroup = 1;
						$scope.startColumnIndex = 1;
						delete $scope.endColumnIndex;
						$scope.groupColumnLabel = '';
						$scope.valueColumnLabel = '';
						$scope.groupFieldsColumnUuid = [];
						$scope.operationType = 0;
						var columns;
						if (!vm.data.step.data_operation) {
							vm.data.step.data_operation = {};
						}
						if (
							vm.data.step.data_operation.explode &&
							vm.data.step.data_operation.explode.columns &&
							vm.data.step.data_operation.explode.columns[0]
						) {
							columns = vm.data.step.data_operation.explode.columns;
							$scope.operationType = '0';
						} else if (
							vm.data.step.data_operation.stack &&
							vm.data.step.data_operation.stack.columns &&
							vm.data.step.data_operation.stack.columns[0]
						) {
							columns = vm.data.step.data_operation.stack.columns;
							$scope.operationType = '1';
							$scope.numberOfRows =
								vm.data.step.data_operation.stack.number_of_rows;
						} else if (
							vm.data.step.data_operation.pivot &&
							vm.data.step.data_operation.pivot.number_of_columns_per_group
						) {
							columns = vm.data.step.data_operation.pivot.columns;
							$scope.operationType = '2';
							$scope.numberOfColumnsPerGroup =
								vm.data.step.data_operation.pivot.number_of_columns_per_group;
							$scope.startColumnIndex =
								vm.data.step.data_operation.pivot.start_column_index;
							$scope.endColumnIndex =
								vm.data.step.data_operation.pivot.end_column_index;
							$scope.groupColumnLabel =
								vm.data.step.data_operation.pivot.group_column_label;
							$scope.valueColumnLabel =
								vm.data.step.data_operation.pivot.value_column_label;
							$scope.valueColumnUuid =
								vm.data.step.data_operation.pivot.value_column_uuid;
							$scope.groupColumnUuid =
								vm.data.step.data_operation.pivot.group_column_uuid;
							$scope.groupFieldsColumnUuid =
								vm.data.step.data_operation.pivot.group_fields_column_uuid;
						}

						for (var c in columns) {
							var s = angular.copy(
								findElement(
									$scope.attributs,
									'column_uuid',
									columns[c].column_uuid
								)
							);
							s.keep_in = columns[c].keep_in;
							s.uuid = columns[c].uuid;
							$scope.columns.push(s);
						}

						$scope.searchCaracByText();
					};

					$scope.dropAllColumns = function () {
						$scope.columns = [];
						updateNumberOfRows();
					};

					$scope.dropCallbackColumns = function (event, index, item) {
						if (
							($scope.operationType == 0 || $scope.operationType == 1) &&
							_.find($scope.columns, function (columnItem) {
								return item.column_uuid == columnItem.column_uuid;
							})
						) {
							$scope.errorDialogData.visiblePopup = true;
							$scope.errorDialogData.popupTitle =
								gettextCatalog.getString('Ajout impossible');
							$scope.errorDialogData.popupMsg = gettextCatalog.getString(
								"Il n'est pas possible d'ajouter une colonne plus d'une fois."
							);
							return;
						}
						//updateNumberOfRows();
						return item;
					};

					$scope.changeOperationType = function () {
						if ($scope.operationType == 0) {
							$scope.columns = [];
						}
						if ($scope.operationType == 2) {
							$scope.valueColumnUuid = generateUuid('_');
							$scope.groupColumnUuid = generateUuid('_');
						}
					};

					$scope.moveCallbackColumns = function (index) {
						$scope.columns.splice(index, 1);
						//updateNumberOfRows();
					};

					var updateNumberOfRows = function () {
						if (!$scope.columns[0]) {
							$scope.numberOfRows = 1;
						} else {
							$scope.numberOfRows = $scope.columns.length;
						}
					};

					var watchColumns = function () {
						$scope.$watch(
							'columns',
							function (newVal) {
								if ($scope.operationType != 0) {
									if (!newVal) {
										$scope.numberOfRows = 1;
									} else {
										$scope.numberOfRows = newVal.length;
									}
								}
							},
							true
						);
					};

					$scope.setAttributes = function () {
						$scope.attributs = [];
						for (var i = 0; i < vm.data.columns.length; i++) {
							var obj = { id: i };
							obj.column_uuid = vm.data.columns[i].uuid;
							obj.name = vm.data.columns[i].lib;
							obj.is_list = vm.data.columns[i].is_list;
							obj.unauthorized = vm.data.columns[i].unauthorized;
							obj.type = vm.data.columns[i].type;
							$scope.attributs.push(obj);
						}
						$scope.searchCaracByText();
					};

					vm.closeModal = function () {
						$(vm.element).modal('hide');
						vm.data.showBDDataOperation = false;
					};

					$scope.deleteOperations = function () {
						vm.data.widgetData.showConfirmationDialog = true;
						vm.data.widgetData.confirmationDialogTitle =
							gettextCatalog.getString('Confirmer la suppression');
						vm.data.widgetData.confirmationDialogMessage =
							gettextCatalog.getString(
								'Tous les paramètres ici définis seront perdus. Confirmer cette action ?'
							);
						vm.data.widgetData.confirmationDialogActionName =
							gettextCatalog.getString('Oui');
						vm.data.widgetData.enableConfirmDialogAction = true;
						vm.data.widgetData.confirmDialogAction = function () {
							confirmDeleteOperations();
						};
					};

					var confirmDeleteOperations = function () {
						vm.data.saveDataOperationMethod(
							[],
							undefined,
							undefined,
							undefined,
							undefined,
							undefined,
							undefined,
							undefined,
							undefined,
							undefined,
							undefined,
							undefined,
							$scope.saveAndClose
						);
					};

					let invalidConfTra = gettextCatalog.getString(
						'Configuration invalide'
					);
					$scope.save = function () {
						if ($scope.operationType === '1' && !$scope.numberOfRows) {
							$scope.numberOfRows = 1;
						}

						if (
							(!$scope.columns || !$scope.columns[0]) &&
							$scope.operationType != 2
						) {
							toaster.pop(
								'error',
								invalidConfTra,
								gettextCatalog.getString(
									'Configurer une opération avant de poursuivre.'
								)
							);
							return;
						}

						if (
							($scope.operationType == 0 || $scope.operationType == 1) &&
							_.uniq($scope.columns, function (item) {
								return item.column_uuid;
							}).length < $scope.columns.length
						) {
							toaster.pop(
								'error',
								invalidConfTra,
								gettextCatalog.getString(
									"Il n'est pas possible d'ajouter une colonne plus d'une fois."
								)
							);
							return;
						}

						if ($scope.operationType == 2) {
							if (!checkPivotOperation()) {
								return;
							}
						}

						if (!checkColumns()) {
							toaster.pop(
								'error',
								invalidConfTra,
								gettextCatalog.getString(
									"Elle conduira à la formation d'au moins une colonne contenant des valeurs de type différent."
								)
							);
							return;
						}
						let stackedColumnNames = undefined;
						if ($scope.numberOfColumnsPerGroup == 1) {
							stackedColumnNames = [];
							for (
								let i = $scope.startColumnIndex - 1;
								i < vm.data.columns.length;
								i++
							) {
								stackedColumnNames.push(vm.data.columns[i].lib);
							}
						}
						let groupFieldsColumnUuid = [];
						for (let i = 0; i < $scope.numberOfColumnsPerGroup; i++) {
							if ($scope.groupFieldsColumnUuid[i]) {
								groupFieldsColumnUuid.push($scope.groupFieldsColumnUuid[i]);
							} else {
								groupFieldsColumnUuid.push(generateUuid('_'));
							}
						}
						vm.data.saveDataOperationMethod(
							$scope.columns,
							$scope.operationType,
							$scope.numberOfRows,
							$scope.numberOfColumnsPerGroup,
							$scope.startColumnIndex,
							groupFieldsColumnUuid,
							$scope.endColumnIndex,
							$scope.groupColumnLabel,
							$scope.valueColumnLabel,
							$scope.valueColumnUuid,
							$scope.groupColumnUuid,
							stackedColumnNames,
							$scope.saveAndClose
						);
					};

					$scope.saveAndClose = function () {
						vm.closeModal();
						toaster.pop(
							'success',
							gettextCatalog.getString('Succès'),
							gettextCatalog.getString('Enregistrement effectué')
						);
					};

					var checkPivotOperation = function () {
						if ($scope.startColumnIndex == undefined) {
							toaster.pop(
								'error',
								invalidConfTra,
								gettextCatalog.getString(
									'La saisie de la valeur "A partir de la colonne" est obligatoire'
								)
							);
							return;
						}

						if ($scope.startColumnIndex < 1) {
							toaster.pop(
								'error',
								invalidConfTra,
								gettextCatalog.getString(
									'La saisie de la valeur "A partir de la colonne" doit être >=0'
								)
							);
							return;
						}

						if ($scope.numberOfColumnsPerGroup == undefined) {
							toaster.pop(
								'error',
								invalidConfTra,
								gettextCatalog.getString(
									'La saisie de la valeur "Par groupe de" est obligatoire'
								)
							);
							return;
						}

						if ($scope.numberOfColumnsPerGroup < 1) {
							toaster.pop(
								'error',
								invalidConfTra,
								gettextCatalog.getString(
									'La saisie de la valeur "A partir de la colonne" doit être >=0'
								)
							);
							return;
						}

						if (
							$scope.groupColumnLabel == undefined ||
							!$scope.groupColumnLabel[0]
						) {
							toaster.pop(
								'error',
								invalidConfTra,
								gettextCatalog.getString(
									'La saisie de la valeur "Label Colonne Groupe" est obligatoire'
								)
							);
							return;
						}

						if ($scope.startColumnIndex >= $scope.vm.data.columns.length) {
							toaster.pop(
								'error',
								invalidConfTra,
								gettextCatalog.getString(
									'La saisie de la valeur "A partir de la colonne" doit être inférieur au Nb colonne Total'
								)
							);
							return;
						}

						if ($scope.endColumnIndex > $scope.vm.data.columns.length) {
							toaster.pop(
								'error',
								invalidConfTra,
								gettextCatalog.getString(
									'La saisie de la "valeur Jusqu\'à la colonne" doit être inférieur au Nb colonne Total'
								)
							);
							return;
						}

						if (
							($scope.valueColumnLabel == undefined ||
								!$scope.valueColumnLabel[0]) &&
							$scope.numberOfColumnsPerGroup == 1
						) {
							toaster.pop(
								'error',
								invalidConfTra,
								gettextCatalog.getString(
									'La saisie de la valeur "Label Colonne Groupe" est obligatoire'
								)
							);
							return;
						}

						let endColumnIndex = $scope.endColumnIndex
							? $scope.endColumnIndex
							: $scope.vm.data.columns.length;
						if (
							(endColumnIndex - $scope.startColumnIndex + 1) %
								$scope.numberOfColumnsPerGroup !=
							0
						) {
							toaster.pop(
								'error',
								invalidConfTra,
								gettextCatalog.getString(
									'Les paramètres fournis ne sont pas compatibles selon le nombre de colonnes à traiter. Vérifier les valeurs  A partir de la colonne ET Par groupe'
								)
							);
							return;
						}

						for (
							let i = $scope.startColumnIndex - 1;
							i < $scope.numberOfColumnsPerGroup + $scope.startColumnIndex - 1;
							i++
						) {
							let dataType = $scope.vm.data.columns[i].type;
							for (
								let j = i;
								j < endColumnIndex - $scope.numberOfColumnsPerGroup + 1;
								j = j + $scope.numberOfColumnsPerGroup
							) {
								if ($scope.vm.data.columns[j].type != dataType) {
									toaster.pop(
										'error',
										invalidConfTra,
										gettextCatalog.getString(
											'Certaines colonnes à unir  ne sont pas du même type. Mettre en conformité les colonnes à traiter.'
										)
									);
									return;
								}
							}
						}
						return true;
					};

					var checkColumns = function () {
						if ($scope.operationType == '1' && $scope.numberOfRows > 1) {
							var colNumber = Math.round(
								$scope.columns.length / $scope.numberOfRows
							);
							for (var i = 0; i < colNumber; i++) {
								if ($scope.columns[i]) {
									for (
										var j = i;
										j < $scope.columns.length;
										j = j + colNumber
									) {
										if ($scope.columns[j]) {
											if ($scope.columns[i].type !== $scope.columns[j].type) {
												return false;
											}
										}
									}
								}
							}
						}
						return true;
					};

					$scope.cancel = function () {
						vm.closeModal();
					};

					$scope.searchCaracByText = function () {
						if (
							$scope.caracSearchText == undefined ||
							$scope.caracSearchText.length == 0
						) {
							$scope.attributs_ = $scope.attributs;
						} else {
							$scope.attributs_ = _.filter($scope.attributs, function (item) {
								return (
									item.name
										.toLowerCase()
										.indexOf($scope.caracSearchText.toLowerCase()) !== -1
								);
							});
						}
					};
				},
			];

			return {
				restrict: 'E',

				scope: {
					data: '=',
					datablock: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/dataOperationTools/dataOperation/dataOperation.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {
					$(element).modal({
						show: false,
						keyboard: attrs.keyboard,
						backdrop: attrs.backdrop,
					});
					$(element).on('hidden.bs.modal', function (event) {
						if (event.target == element[0]) {
							scope.vm.closeModal();
						}
					});

					scope.$watch(
						function () {
							return scope.vm.data.showBDDataOperation;
						},
						function (value) {
							scope.vm.element = element;
							if (value == true) {
								scope.setAttributes();
								scope.init();
								$(element).modal(
									{ backdrop: 'static', keyboard: false },
									'show'
								);
							} else {
								$(element).modal('hide');
							}
						}
					);
				},
			};
		},
	]);
})();
