(function () {
	'use strict';

	angular.module('dcApp').factory('dataBlockControlerGenerator', [
		function () {
			var dataBlockControlerGenerator = {};

			dataBlockControlerGenerator.getController = function () {
				var controller = [
					'$state',
					'toaster',
					'$stateParams',
					'$sce',
					'$rootScope',
					'$scope',
					'$timeout',
					'PAGINATIONS_SIZES',
					'$q',
					'GenericService',
					'DataBlocksService',
					'PAGINATIONS_SIZE',
					'CommonServices',
					'API_BASE_URL_BACKEND',
					'$http',
					'$location',
					'$window',
					'SearchService',
					'ALIGNEMENTS',
					'TypeEntiteService',
					'DEFAULT_CELL_TEMPLATE',
					'SparkJobsServices',
					'$filter',
					'ExportHistoryService',
					'ConnectorService',
					'ExportService',
					'ParametersServices',
					'gettextCatalog',
					'UPDATE_EVENT',
					'DateService',
					'GrammarUtils',
					function (
						$state,
						toaster,
						$stateParams,
						$sce,
						$rootScope,
						$scope,
						$timeout,
						PAGINATIONS_SIZES,
						$q,
						GenericService,
						DataBlocksService,
						PAGINATIONS_SIZE,
						CommonServices,
						API_BASE_URL_BACKEND,
						$http,
						$location,
						$window,
						SearchService,
						ALIGNEMENTS,
						TypeEntiteService,
						DEFAULT_CELL_TEMPLATE,
						SparkJobsServices,
						$filter,
						ExportHistoryService,
						ConnectorService,
						ExportService,
						ParametersServices,
						gettextCatalog,
						UPDATE_EVENT,
						DateService,
						GrammarUtils
					) {
						var vm = this;
						$scope.datablockError = {};

						$scope.execWithoutCache = function () {
							$scope.jobDetails.init();
							$scope.reload(undefined, true);
						};

						$scope.$on('job_private', function (event, message) {
							handleSocketMessageJobRunning(message);
						});

						function handleSocketMessageJobRunning(notif) {
							if (
								!notif.data.client_id ||
								!$scope.widget ||
								(notif.data.client_id !== $scope.widget.clientId &&
									notif.data.client_id !== $scope.widget.summaryJobClientId) ||
								(notif.data.status !== 'SUCCESS' &&
									notif.data.status !== 'CACHED' &&
									notif.data.status !== 'ERROR' &&
									notif.data.status !== 'CANCELLED')
							) {
								return;
							}
							switch (notif.data.status) {
								case 'SUCCESS':
								case 'CACHED':
									getDataAndFillTableSimple(
										notif,
										notif.data.client_id == $scope.widget.summaryJobClientId
									);
									break;
								case 'CANCELLED':
									$scope.widget.isKilled = true;
									$scope.endLoading();
									break;
								default:
								case 'ERROR':
									$scope.widget.isError = true;
									$scope.datablockError.error = true;
									$scope.datablockError.errorMessage = notif.data.error_message;
									$scope.endLoading();
									break;
							}
						}

						function fillTableSimpleWithData(data, status, forSummary) {
							if (status == 'SUCCESS' || status == 'CACHED') {
								if (forSummary) {
									$scope.fillDataSummary(data);
								} else {
									$scope.fillDataDetails(data);
								}
								$scope.endLoading();
							} else if (status == 'CANCELLED') {
								$scope.widget.isKilled = true;
								$scope.endLoading();
							} else if (status == 'ERROR') {
								$scope.widget.isError = true;
								$scope.datablockError.error = true;
								$scope.endLoading();
							}
						}

						function getDataAndFillTableSimple(notif, forSummary) {
							$rootScope.disableAjaxLoading = true;
							$scope.datablockError.errorMessage = notif.data.error_message;
							DataBlocksService.getDataByJobId(notif.data.job_id).then(
								function (response) {
									$rootScope.disableAjaxLoading = false;
									if (response != undefined && response.data) {
										fillTableSimpleWithData(
											response.data,
											response.data.response_status,
											forSummary
										);
									}
								}
							);
						}

						vm.init = function () {
							$scope.widget = vm.widget;
							$scope.widget.grammar.take_max_row = $scope.widget.grammar
								.take_max_row
								? $scope.widget.grammar.take_max_row
								: 1500;
							$scope.widget.dataViewerId = _.uniqueId();
							$scope.grammar = $scope.widget.grammar;
							$scope.setGridColumns();
							// Get take max row
							$scope.getTakeMaxRow();
						};

						// Get take max row
						$scope.getTakeMaxRow = function () {
							ParametersServices.getTakeMaxRow().then(function (response) {
								$rootScope.defaultTakeMaxRow =
									response.data !== undefined ? response.data : 2500;
								$scope.UIGRID_DEFAULT_LIMIT =
									vm.widget &&
									vm.widget.grammar &&
									vm.widget.grammar.take_max_row != undefined
										? vm.widget.grammar.take_max_row
										: $rootScope.defaultTakeMaxRow;
							});
						};

						$scope.isUrl = function (str) {
							return isUrl(str);
						};

						$scope.toUrl = function (url) {
							var newUrl = url;
							if (url != null && $scope.isUrl(url)) {
								newUrl = url;
							}
							return newUrl;
						};

						// Model to JSON for demo purpose
						$scope.$watch(
							'lists',
							function (lists) {
								$scope.modelAsJson = angular.toJson(lists, true);
							},
							true
						);

						let stepsTOExec;
						$scope.reloadDataBlock = function (steps) {
							if (vm.preExec) {
								vm.preExec(true);
								stepsTOExec = steps;
								return;
							}
							$scope.reload(steps);
						};

						// TODO remove when entity params realtime exec is correctly handled
						$scope.$on(UPDATE_EVENT['ENTITETYPE_SAVE'], function (event) {
							$scope.reload(stepsTOExec);
							stepsTOExec = undefined;
						});

						$scope.isNewDataBlock =
							$stateParams == undefined || $stateParams.id == undefined;

						$scope.shitEmptyId = function () {
							if ($scope.exportOperation != 'index') {
								if ($scope.exportIds[0] == '') {
									$scope.exportIds.splice(0, 1);
									$scope.exportId = $scope.exportIds[0];
								}
							} else if ($scope.exportIds[0] != '') {
								$scope.exportIds.unshift('');
								$scope.exportId = $scope.exportIds[0];
							}
						};

						$scope.setSelectedConnector = function (connector) {
							$scope.selectedConnector = connector;
						};

						$scope.killJob = function () {
							$scope.endLoading();
							if ($scope.widget.jobId) {
								SparkJobsServices.killJob($scope.widget.jobId).then(function (
									response
								) {});
							}
						};

						$scope.startLoading = function () {
							$rootScope.disableAjaxLoading = true;
							$scope.widget.isLoading = true;
							$('#' + $scope.widget.id + '_widget').addClass('disabledDiv');
							addSpinner($('#' + $scope.widget.id + '_loading'));
						};

						$scope.endLoading = function () {
							$scope.widget.isLoading = false;
							$rootScope.disableAjaxLoading = false;
							$('#' + $scope.widget.id + '_widget').removeClass('disabledDiv');
							removeSpinner($('#' + $scope.widget.id + '_loading'));
						};

						var resetState = function () {
							delete $scope.widget.isKilled;
							delete $scope.widget.isError;
							delete $scope.widget.columnDefs;
							delete $scope.widget.cols;
							delete $scope.widget.gridOptions;
							$scope.showEmptyGrid = true;
							$scope.datablockError = { error: false };
						};

						$scope.reload = function (steps, withoutCache) {
							$scope.jobDetails.rerunMethod = function () {
								$scope.jobDetails.init();
								$scope.reload(steps, true);
							};
							for (var s in $scope.widget.steps) {
								delete $scope.widget.steps[s].selected;
							}
							if (!steps && $scope.widget.steps && $scope.widget.steps[0]) {
								$scope.widget.steps[
									$scope.widget.steps.length - 1
								].selected = true;
							} else if (steps && steps[0]) {
								steps[steps.length - 1].selected = true;
							}

							resetState();
							$scope.widget.executed = true;
							$scope.startLoading();
							$scope.exec(steps, withoutCache);
						};

						$scope.reboundFromRootGrid = function (data, cols) {
							vm.widgetData.multipleRebound = false;
							vm.widgetData.renoundedRow = sortRenoundedRowColumns(data, cols);
							vm.widgetData.columnsRebound = sortReboundedColumns(cols);
							vm.widgetData.disableHdhElements = true;
							vm.widgetData.showRebound = true;
						};

						var sortRenoundedRowColumns = function (row, sortColumns) {
							var newRow = {};
							for (var c in sortColumns) {
								for (var r in row) {
									if (r.toString() == sortColumns[c].col_index) {
										newRow[c] = row[r];
										break;
									}
								}
							}
							return newRow;
						};

						var sortReboundedColumns = function (columns) {
							var newColumns = [];
							for (var r in columns) {
								newColumns.push({
									column_alias: columns[r].column_alias,
									uca_id: columns[r].uca_id,
									parent_entite_type_id: columns[r].parent_entite_type_id,
								});
							}
							return newColumns;
						};

						$scope.executeJobs = function (steps, withoutCache) {
							if ($scope.jobDetails.init) {
								$scope.jobDetails.init();
							}
							if ($scope.jobDetails.setFromCache) {
								$scope.jobDetails.setFromCache(false);
							}
							$scope.getTableSimpleData(undefined, steps, withoutCache);
						};

						$scope.doGlobalSearch = function () {
							if (!$scope.currentExecution) {
								$scope.reload();
								return;
							}
							$scope.startLoading();
							$scope.getTableSimpleData(undefined, undefined);
						};

						$scope.setNestedFilter = function (procGrammar, wid) {
							if ($scope.widget.nestedFilterGetMethod) {
								var nestedRule = $scope.widget.nestedFilterGetMethod(
									$scope.widget[
										$scope.widgetData.updateWidgetColumnsAndRulesParam
									]
								);
								var rules = angular.copy(procGrammar.base_grammar.rules);
								procGrammar.base_grammar = angular.copy(
									procGrammar.base_grammar
								);
								procGrammar.base_grammar.rules = {
									condition: 'AND',
									rules: [],
								};
								if (rules && rules.rules[0]) {
									procGrammar.base_grammar.rules.rules.push(rules);
								}
								if (nestedRule) {
									procGrammar.base_grammar.rules.rules.push(nestedRule);
								}
							} else if ($scope.widget.nestedFilterSetMethod) {
								$scope.widget.nestedFilterSetMethod(
									$scope.widget[
										$scope.widgetData.updateWidgetColumnsAndRulesParam
									],
									procGrammar.base_grammar
								);
							}
						};

						$scope.getTableSimpleData = function (
							processDataDetails,
							steps,
							withoutCache
						) {
							$scope.widget.dataCountLoaded = false;
							$scope.widget.dataLoaded = false;
							$scope.gridContentIsReady = false;
							delete $scope.widget.dataCount;
							delete $scope.columnSummary;
							var procGrammar = buildDataBlockGrammar(
								$scope.widget.grammar,
								$scope.widget.globalSearchText,
								vm.widget.hidenGlobalSearchText,
								$scope.widget.globalSearchTextIgnoreCase,
								$scope.widget.innerJoin,
								$scope.widget.limit_max_row
							);

							$scope.currentExecution = 'getTableSimpleData';
							$scope.widget.procGrammar = procGrammar;
							if ($scope.widget.globalSearchTextMode) {
								$scope.widget.procGrammar.global_filter_mode =
									$scope.widget.globalSearchTextMode;
							}
							$scope.setNestedFilter(procGrammar);
							$rootScope.disableAjaxLoading = true;

							let finalSteps = steps ? steps : $scope.widget.steps;
							$scope.executedStep =
								finalSteps && finalSteps[0]
									? finalSteps[finalSteps.length - 1]
									: null;

							if ($scope.executedStep) {
								if (!$scope.executedStep.summary_config) {
									$scope.executedStep.summary_config = {
										data: true,
										summary: true,
										total_count: true,
										count_columns: [],
										summary_columns: [],
									};
								}
								$scope.selectedSummaryConfig =
									$scope.executedStep.summary_config;
							} else {
								if (!$scope.widget.grammar.summary_config) {
									$scope.widget.grammar.summary_config = {
										data: true,
										summary: true,
										total_count: true,
										count_columns: [],
										summary_columns: [],
									};
								}
								$scope.selectedSummaryConfig =
									$scope.widget.grammar.summary_config;
								procGrammar.summary_config =
									$scope.widget.grammar.summary_config;
							}

							for (var s in finalSteps) {
								finalSteps[s].executed = false;
							}
							if ($scope.executedStep) {
								$scope.executedStep.executed = true;
							}

							/** if no steps activate total count*/
							if ($scope.selectedSummaryConfig.summary && !processDataDetails) {
								$scope.execDataSummary(
									angular.copy(procGrammar),
									steps,
									withoutCache
								);
							}

							if ($scope.selectedSummaryConfig.data || processDataDetails) {
								$timeout(function () {
									$scope.execDataDetails(
										angular.copy(procGrammar),
										steps,
										withoutCache
									);
								}, 5);
							}
						};

						$scope.execDataDetails = function (
							procGrammar,
							steps,
							withoutCache
						) {
							$scope.widget.clientId = generateClientId(
								$rootScope.account.login
							);
							$scope.widget.jobLoading = true;
							$rootScope.disableAjaxLoading = true;
							var dbGrammar = {
								columns: procGrammar.base_grammar.columns,
								steps: steps ? steps : $scope.widget.steps,
							};

							$scope.executedGrammar = dbGrammar;
							dbGrammar.take_max_row =
								$scope.widget.grammar.take_max_row != undefined
									? $scope.widget.grammar.take_max_row
									: 1500;
							dbGrammar.global_filter = procGrammar.global_filter;
							dbGrammar.global_filter_ignore_case =
								procGrammar.global_filter_ignore_case;
							dbGrammar.global_filter_mode = procGrammar.global_filter_mode;

							DataBlocksService.execData(
								dbGrammar,
								$scope.widget.id,
								$scope.widget.parentId,
								$scope.widget.clientId,
								withoutCache
							)
								.then(function (response_) {
									delete $scope.widget.jobId;
									$scope.widget.countJobLoading = false;
									$scope.widget.resultColumns = response_.data.columns;
									$scope.numberOfCols = $scope.widget.resultColumns.length;
									if (!response_.data || !response_.data.value) {
										$scope.widget.isError = true;
										$scope.datablockError.error = true;
										$scope.endLoading();
										return;
									}
									if (response_.data.exec_data_result) {
										delete $scope.widget.clientId;
										fillTableSimpleWithData(
											response_.data.exec_data_result,
											response_.data.job_status,
											false
										);
										$scope.endLoading();
										return;
									}
									$rootScope.disableAjaxLoading = true;
								})
								.catch(function (error) {
									$scope.endLoading();
									$scope.widget.isError = true;
									$scope.datablockError.error = true;
								});
						};

						$scope.execDataSummary = function (
							procGrammar,
							steps,
							withoutCache
						) {
							$scope.widget.summaryJobClientId = generateClientId(
								$rootScope.account.login
							);
							$scope.widget.countJobLoading = true;
							$rootScope.disableAjaxLoading = true;
							var dbGrammar = {
								columns: procGrammar.base_grammar.columns,
								summary_config: procGrammar.base_grammar.summary_config,
								steps: steps ? steps : $scope.widget.steps,
								process_summary: true,
							};
							dbGrammar.take_max_row =
								$scope.widget.grammar.take_max_row != undefined
									? $scope.widget.grammar.take_max_row
									: 1500;
							dbGrammar.global_filter = procGrammar.global_filter;
							dbGrammar.global_filter_ignore_case =
								procGrammar.global_filter_ignore_case;
							dbGrammar.global_filter_mode = procGrammar.global_filter_mode;
							delete $scope.columnSummary;
							DataBlocksService.execData(
								dbGrammar,
								$scope.widget.id,
								$scope.widget.parentId,
								$scope.widget.summaryJobClientId,
								withoutCache
							)
								.then(function (response_) {
									delete $scope.widget.summaryJobId;
									$scope.widget.countJobLoading = false;
									if (!response_.data || !response_.data.value) {
										$scope.widget.isError = true;
										$scope.datablockError.error = true;
										$scope.endLoading();
										return;
									}
									$scope.widget.statResultColumns = response_.data.columns;
									if (response_.data.exec_data_result) {
										delete $scope.widget.summaryJobClientId;
										fillTableSimpleWithData(
											response_.data.exec_data_result,
											response_.data.job_status,
											true
										);
										$scope.endLoading();
										return;
									}
									$rootScope.disableAjaxLoading = true;
								})
								.catch(function (error) {
									if (
										error &&
										(error.code == '37' ||
											error.code == '38' ||
											error.code == '39')
									) {
										$scope.unavailableCtx = true;
										$scope.datablockError.errorMessage = translateErrorMessage;
									}
									$scope.endLoading();
									$scope.widget.isError = true;
									$scope.datablockError.error = true;
								});
						};

						$scope.showEmptyGrid = true;
						$scope.emptyGird = {
							noDataText: '',
						};

						$scope.tooltipOptions = {
							position: "right",
							onInitialized: function (e) {
								$scope.tooltipInstance = e.component;
							},
							wrapperAttr: {
								 class: "iso-date-tooltip"
						 }
						};

						var fillDataForASimpleTable = function (data) {
							// Create colDefs and columns defs
							$scope.showEmptyGrid = false;
							$scope.setGridColumns();
							$scope.widget.dataCached = data.is_cached;
							$scope.filteredOrSorted = false;
							var resultData = data.data;
							var cols = $scope.widget.cols;
							var columnDefs = $scope.widget.columnDefs;
							var formattedData = [];
							for (var d in resultData) {
								var o = {};
								var sizeExceededTab = resultData[d][resultData[d].length - 1];
								var colsLength = cols.length;
								for (var e = 0; e < colsLength; e++) {
									o[cols[e].nb] = {};
									o[cols[e].nb].sizeExceeded =
										sizeExceededTab.indexOf(Number(cols[e].nb)) > -1;
									o[cols[e].nb].val = resultData[d][cols[e].nb];
									o[cols[e].nb].isDoc = isDoc(o[cols[e].nb].val);
									o[cols[e].nb].path = cols[e].path;
									o[cols[e].nb].column_id = cols[e].column_id;

									if (cols[e].type == 'date' && !CommonServices.isEmpty(o[cols[e].nb].val)) {
										if (cols[e].is_list || Array.isArray(o[cols[e].nb].val)) {
											let dateValue = angular.copy(o[cols[e].nb].val);
											let timezone = cols[e].date_timezone ? cols[e].date_timezone : $rootScope.getDefaultTimezone();
											o[cols[e].nb].val = DateService.datesListToStringWithPatternAndTZ(
												dateValue,
												$rootScope.getCorrespondentDatePattern(cols[e].date_pattern),
												timezone
											);
											o[cols[e].nb].rawDate = DateService.datesListToISOString(dateValue);
										} else {
											let dateValue = angular.copy(o[cols[e].nb].val);
											let timezone = cols[e].date_timezone ? cols[e].date_timezone : $rootScope.getDefaultTimezone();
											o[cols[e].nb].val = DateService.dateToStringWithPatternAndTZ(
												dateValue,
												$rootScope.getCorrespondentDatePattern(cols[e].date_pattern),
												timezone);
											o[cols[e].nb].rawDate = DateService.dateToISOString(dateValue);
										}
									}
								}
								formattedData.push(o);
							}

							columnDefs.push({
								allowEditing: false,
								allowFiltering: false,
								allowFixing: false,
								allowHiding: false,
								allowReordering: false,
								allowSorting: true,
								allowSearch: false,
								allowExporting: true,
								cellTemplate: 'hdhExplorer',
							});

							$scope.widget.gridOptions = {
								dataSource: formattedData,
								allowColumnReordering: true,
								allowColumnResizing: true,
								allowExporting: true,
								columnAutoWidth: true,
								showBorders: true,
								rowAlternationEnabled: true,
								groupPanel: {
									visible: true,
								},
								export: {
									enabled: true,
									fileName:
										$scope.widget.name != undefined
											? $scope.widget.name
											: 'DB_' + $rootScope.getDateWithPattern(new Date()),
									allowExportSelectedData: true,
								},
								paging: {
									enabled: true,
									pageSize: PAGINATIONS_SIZE,
								},
								pager: {
									showPageSizeSelector: true,
									allowedPageSizes: PAGINATIONS_SIZES,
									infoText: $rootScope.dxGridTextInfo,
									showInfo: true,
									visible: true,
								},
								headerFilter: {
									visible: true,
									allowSearch: true,
								},
								filterRow: {
									visible: true,
									applyFilter: 'auto',
								},
								noDataText: '',
								columns: columnDefs,
								onInitialized: function (eInit) {
									$scope.gridInstance = eInit.component;
								},
								onContentReady: function (e) {
									$scope.gridContentIsReady = true;
									limitColumnWithWhenLargerThan400(e);
									getFilteredOrSorted(e.component, $scope);
									let parents = $(e.element).find('.dx-datagrid-text-content');
									$(parents).each(function (index, elm) {
										$(elm).addClass('dx-header-customize');
										$(elm).removeClass('dx-header-filter-indicator');
										$(elm).removeClass('dx-sort-indicator');
									});
								},
								onCellClick: function (eC) {
									showValuePreviewOnCellDoubleClick(
										eC,
										$scope.getShowValuePopup
									);
								},
								onCellPrepared: function (e) {
									if (e.rowType = "data" && e.column.col_type == 'date' && e.data && e.data[e.columnIndex]&& e.data[e.columnIndex].rawDate) {
										e.cellElement.mouseover(function (arg) {
											$scope.currentDateValue = e.data[e.columnIndex].rawDate;
											$scope.tooltipInstance.show(arg.target);
										});
											e.cellElement.mouseout(function (arg) {
											$scope.tooltipInstance.hide();
										});
									}
								},
								onToolbarPreparing: warningSortFilterGenerator,
							};

							$scope.widget.timeExec = data.time_exec / 1000;
							$scope.widget.dataCount = formattedData.length;
							restoreWidgetState();
						};

						$scope.popupWarningFilterOptions = popupWarningFilterOptions;
						$scope.visibleWarningSortPopup = false;
						$scope.showPopupWarningFilter = function () {
							$scope.visibleWarningSortPopup = true;
						};

						$scope.fillDataSummary = function (data) {
							delete $scope.widget.dataTotalCount;
							delete $scope.widget.displayedTotalCount;
							$scope.jobDetails.update({
								name: 'Synthèse',
								pushDate: data.push_date,
								endExecutionDate: data.exec_end_date,
								duration: data.time_exec,
							});
							$scope.widget.dataCached = data.is_cached;
							$scope.widget.gridOptions =
								$scope.widget.gridOptions != undefined
									? $scope.widget.gridOptions
									: {};
							$scope.dataSummary = data.data[0];
							$scope.widget.dataTotalCount =
								$scope.selectedSummaryConfig.total_count ||
								$scope.selectedSummaryConfig.count_columns[0]
									? $scope.dataSummary[0]
									: null;
							if ($scope.selectedSummaryConfig.total_count) {
								$scope.widget.gridOptions.totalItems =
									$scope.widget.dataTotalCount;
								$scope.widget.displayedTotalCount =
									$scope.widget.dataTotalCount;
							}

							$scope.widget.countJobDateExec = data.date_exec;
							$scope.widget.countJobTimeExec = data.time_exec / 1000;
							$scope.widget.dataCountLoaded = true;

							if ($scope.selectedSummaryConfig) {
								fillColumnSummary();
							}
						};

						var fillColumnSummary = function () {
							let dataColIndex =
								$scope.selectedSummaryConfig.total_count ||
								$scope.selectedSummaryConfig.count_columns[0]
									? 1
									: 0;
							$scope.columnSummary = {};

							for (var col in $scope.widget.statResultColumns) {
								let column = $scope.widget.statResultColumns[col];
								if (
									$scope.selectedSummaryConfig.count_columns.indexOf(
										column.uuid
									) > -1
								) {
									$scope.columnSummary[column.uuid] = { showCount: true };
									$scope.columnSummary[column.uuid].nonNullCount =
										$scope.dataSummary[dataColIndex++];
									if ($scope.widget.dataTotalCount) {
										$scope.columnSummary[column.uuid].nullCount =
											$scope.widget.dataTotalCount -
											$scope.columnSummary[column.uuid].nonNullCount;
									}
									$scope.columnSummary[column.uuid].headerChart =
										$scope.getHeaderChart(column);
								}
								if (
									$scope.selectedSummaryConfig.summary_columns.indexOf(
										column.uuid
									) > -1
								) {
									if (!$scope.columnSummary[column.uuid]) {
										$scope.columnSummary[column.uuid] = {};
									}
									$scope.columnSummary[column.uuid].showSummary = true;
									$scope.columnSummary[column.uuid].min =
										$scope.dataSummary[dataColIndex++];
									$scope.columnSummary[column.uuid].max =
										$scope.dataSummary[dataColIndex++];

									if (['string', 'date', 'boolean'].indexOf(column.type) < 0) {
										$scope.columnSummary[column.uuid].sum =
											$scope.dataSummary[dataColIndex++];
										$scope.columnSummary[column.uuid].avg =
											$scope.dataSummary[dataColIndex++];
										$scope.columnSummary[column.uuid].median =
											$scope.dataSummary[dataColIndex++];
										$scope.columnSummary[column.uuid].ecartType =
											$scope.dataSummary[dataColIndex++];
									}
								}
							}
						};

						$scope.fillDataDetails = function (data) {
							$scope.jobDetails.update({
								name: 'Données',
								pushDate: data.push_date,
								endExecutionDate: data.exec_end_date,
								duration: data.time_exec,
							});
							$scope.jobDetails.setFromCache(data.is_cached);
							$scope.widget.dateExec = data.date_exec;
							$scope.widget.timeExec = data.time_exec / 1000;
							fillDataForASimpleTable(data);
							$scope.widget.dataLoaded = true;
						};

						$scope.processDataDetails = function () {
							$rootScope.disableAjaxLoading = true;
							$scope.startLoading();
							$scope.getTableSimpleData(true, undefined);
						};

						$scope.exec = function (steps, withoutCache) {
							$scope.executeJobs(steps, withoutCache);
						};

						$scope.getDateWithPattern = function (date) {
							return $rootScope.getDateWithPattern(date);
						};

						$scope.showAddColumn = function () {
							vm.widgetData.widget =
								$scope.widget != undefined ? $scope.widget : $scope.data.widget;
							vm.widgetData.enableDeletePivotColumn = false;
							vm.widgetData.showAddColumn = true;
							vm.widgetData.enableAddColumnSummaryConfig = true;
							vm.widgetData.updateaction = $scope.saveAddColumn;
						};

						$scope.saveAddColumn = function (columns) {
							$scope.widget.grammar.columns = columns;
							$scope.updateColumns_();
							if (vm.widgetData.updateWidgetColumnsAndRules) {
								vm.widgetData.updateWidgetColumnsAndRules(
									$scope.widget[vm.widgetData.updateWidgetColumnsAndRulesParam],
									$scope.widget.grammar.columns,
									$scope.widget.grammar.rules
								);
							}
							if (!$scope.widget.grammar.pivot) {
								$scope.widget.grammar.pivot =
									$scope.widget.grammar.columns[0].uca_id;
								$scope.widget.grammar.pivot_type = 'c';
							}
							$rootScope.disableAjaxLoading = true;
						};

						$scope.updateColumns_ = function () {
							for (var c in $scope.widget.grammar.columns) {
								if (
									$scope.widget.grammar.columns[c].id >
									vm.widgetData.lastColumnId
								) {
									var newCol = $scope.widget.grammar.columns[c];
									newCol.visible = true;
									newCol.col = c;
									if ($scope.widget.grammar.columns_) {
										$scope.widget.grammar.columns_.push(newCol);
									}
								}
							}
						};

						$scope.getTypeHtml = function (type, isList) {
							if (type != undefined) {
								type = type.toLowerCase();
								return $sce.trustAsHtml(getHtmlIconByType(type, isList, GrammarUtils.getTypeLabel(type)));
							} else {
								return '';
							}
						};

						$scope.stop = function (ruleJobId) {
							if (ruleJobId) {
								SparkJobsServices.killJob(ruleJobId).then(function (
									response
								) {});
							}
						};

						$scope.previewModal = { showValuePreview: false, value: '' };
						$scope.getShowValuePopup = function (val) {
							$scope.previewModal.value = val;
							$scope.previewModal.showValuePreview = true;
						};

						function getFormulasColumns() {
							let formulas = [];
							for (let s in $scope.widget.steps) {
								formulas = _.union(formulas, $scope.widget.steps[s].formulas);
							}

							return formulas;
						}

						$scope.setGridColumns = function () {
							var cols = [];
							var columnsDxGrid = [];
							var formulas = getFormulasColumns();
							for (var c in $scope.widget.resultColumns) {
								var col = _.find($scope.widget.grammar.columns, function (elm) {
									return elm.uuid === $scope.widget.resultColumns[c].uuid;
								});
								var visible = true;
								var colAlias = undefined;
								var isMedia = false;
								var parentId = undefined;
								var ucaId = undefined;
								var list =
									$scope.widget.resultColumns[c].is_list != undefined
										? $scope.widget.resultColumns[c].is_list
										: false;
								var isFormula = false;
								var formula = undefined;
								if (col) {
									if (col.active === false) {
										visible = false;
									}
									colAlias = col.column_alias;
									isMedia = col.type == 'file';
									parentId = col.parent_entite_type_id;
									ucaId = col.uca_id;
									list = col.list || list;
								} else {
									let colF = _.find(formulas, function (elm) {
										return elm.uuid === $scope.widget.resultColumns[c].uuid;
									});
									if (colF) {
										colF.list = colF.list || colF.is_list;
										list = colF.list || list;
										isFormula = true;
										formula = colF;
									}
								}
								var colDx = {
									uuid: $scope.widget.resultColumns[c].uuid,
									allowEditing: false,
									allowFiltering: true,
									allowHeaderFiltering: true,
									allowFixing: false,
									allowHiding: false,
									allowReordering: false,
									allowSorting: true,
									allowSearch: false,
									allowExporting: true,
									dataField: '' + c + '',
									col_index: c,
									visible: visible,
									col_type: $scope.widget.resultColumns[c].type,
									caption: $scope.widget.resultColumns[c].lib,
									column_alias: colAlias,
									is_media: isMedia,
									is_list: list,
									parent_entite_type_id: parentId,
									uca_id: ucaId,
									cellTemplate: 'cellTemplate',
									alignment: 'right',
									cssClass: 'dx-grid-header-parent',
									calculateDisplayValue: function (rowData) {
										return rowData[this.col_index].val;
									},
									calculateCellValue: function (rowData) {
										return rowData[this.col_index].val;
									},
									summary: {},
									headerCellTemplate: 'headerCellTemplate',
									is_formula: isFormula,
									formula: formula,
								};
								columnsDxGrid.push(colDx);
								cols.push({
									nb: c,
									type: $scope.widget.resultColumns[c].type,
									date_pattern: $scope.widget.resultColumns[c].date_pattern,
									date_timezone: $scope.widget.resultColumns[c].date_timezone,
									is_media: isMedia,
									is_list: list,
								});
							}
							$scope.widget.cols = cols;
							$scope.widget.columnDefs = columnsDxGrid;
						};

						$scope.checkIfValueIsNullAndReturnIt = function (val) {
							if (val === null) {
								return 'Null';
							}

							return val;
						};

						$scope.checkIfValueIsNull = function (val) {
							return val === null;
						};

						$scope.getHeaderChart = function (column) {
							if (!$scope.columnSummary[column.uuid]) {
								return;
							}
							let total = $scope.widget.dataTotalCount;
							let notNullCount = $scope.columnSummary[column.uuid].nonNullCount;
							return {
								bindingOptions: { value: notNullCount },
								geometry: { orientation: 'horizontal' },
								scale: {
									label: { visible: false },
									startValue: 0,
									endValue: total,
									customTicks: [notNullCount, total],
								},
								rangeContainer: {
									ranges: [
										{ startValue: 0, endValue: notNullCount, color: 'green' },
										{ startValue: notNullCount, endValue: total, color: 'red' },
									],
								},
								valueIndicator: { type: 'none' },
							};
						};

						$scope.showColumnHeaderTooltip = function (column) {
							return (
								$scope.columnSummary &&
								$scope.columnSummary[column.uuid] &&
								$scope.columnSummary[column.uuid].showSummary
							);
						};

						let ruleTra = gettextCatalog.getString('Règle');
						let formulaTra = gettextCatalog.getString('Formule:');
						let activeTra = gettextCatalog.getString('Actif: ');
						let descTra = gettextCatalog.getString('Description:');
						$scope.getFormulaToolTip = function (column) {
							let traYesOrNo = column.formula.active
								? gettextCatalog.getString('Oui')
								: gettextCatalog.getString('Non');
							let tooltipText =
								'<p><div class="pull-left"><strong>' +
								activeTra +
								'</strong></div> ' +
								traYesOrNo +
								'</p>';
							tooltipText =
								tooltipText +
								'<p><div class="pull-left"><strong>' +
								descTra +
								'</strong></div> ' +
								(column.formula.description
									? column.formula.description
									: '-') +
								'</p>';
							tooltipText =
								tooltipText +
								'<p><div class="pull-left"><strong>' +
								formulaTra +
								'</strong></div><br>' +
								buildFormulaDisplayed(
									column.formula,
									false,
									ruleTra,
									_.union(
										$scope.widget.grammar.columns,
										getFormulasColumns(),
										$scope.widget.resultColumns,
										$scope.widget.statResultColumns
									),
									[],
									true
								) +
								' </p>';
							tooltipText = "<div class='formula-tooltip'>" + tooltipText + "</div>";
							return {
								target:
									'#header-formula-tooltip-' +
									$scope.uuid +
									'-' +
									column.col_index,
								showEvent: 'mouseenter',
								hideEvent: 'mouseleave',
								maxWidth: '50%',
								closeOnOutsideClick: false,
								contentTemplate: function (data) {
									data.html(tooltipText);
								},
							};
						};

						$scope.getHeaderStatTooltip = function (column) {
							let summary = $scope.columnSummary[column.uuid];
							if (!summary) {
								return;
							}

							let dataType = _.filter(
								$scope.widget.statResultColumns,
								function (item) {
									return item.uuid == column.uuid;
								}
							)[0].type;

							let tooltipText =
								'<table style="width : 500px" class="dont-break-out">';
							tooltipText = tooltipText + '<tr>';
							tooltipText = tooltipText + '<td class="width-50">';

							tooltipText =
								tooltipText +
								'<table style="width : 250px" class="dont-break-out">';

							let nullHeaderLabel =
								dataType === 'decimal'
									? gettextCatalog.getString('Nb de Null et NaN')
									: gettextCatalog.getString('Nb valeurs nulles');

							tooltipText =
								tooltipText +
								'<tr> <td class="width-50 height-25-px"> <span class="pull-left"> <b> ' +
								gettextCatalog.getString('Nb Total Ligne') +
								'</b> </span> </td> <td class="width-50 dont-break-out"> <span class="pull-right"> ' +
								($scope.widget.dataTotalCount !== undefined
									? $scope.widget.dataTotalCount
									: '') +
								'  </span></td> </tr>';
							tooltipText =
								tooltipText +
								'<tr> <td class="width-50 height-25-px"> <span class="pull-left"> <b> ' +
								gettextCatalog.getString(nullHeaderLabel) +
								'</b> </span> </td> <td class="width-50 dont-break-out"> <span class="pull-right"> ' +
								(summary.nullCount !== undefined ? summary.nullCount : '') +
								' </span> </td> </tr>';
							tooltipText =
								tooltipText +
								'<tr> <td class="width-50 height-25-px"> <span class="pull-left"> <b> ' +
								gettextCatalog.getString('Nb Valeurs non nulles') +
								'</b> </span> </td> <td class="width-50 dont-break-out"> <span class="pull-right"> ' +
								(summary.nonNullCount !== undefined
									? summary.nonNullCount
									: '') +
								'  </span></td> </tr>';

							if (summary.sum != null) {
								tooltipText =
									tooltipText +
									'<tr> <td class="width-50 height-25-px"> <span class="pull-left"> <b> ' +
									gettextCatalog.getString('Médiane') +
									'</b> </span> </td> <td class="width-50 dont-break-out"> <span class="pull-right"> ' +
									(summary.median !== undefined
										? formatStatValue(summary.median)
										: '') +
									' </span> </td> </tr>';
								tooltipText =
									tooltipText +
									'<tr> <td class="width-50 height-25-px"> <span class="pull-left"> <b> ' +
									gettextCatalog.getString('Moyenne') +
									'</b> </span> </td> <td class="width-50 dont-break-out"> <span class="pull-right"> ' +
									(summary.avg !== undefined
										? formatStatValue(summary.avg)
										: '') +
									' </span> </td> </tr>';
							}

							tooltipText = tooltipText + '</table>';
							tooltipText = tooltipText + '</td>';

							tooltipText =
								tooltipText +
								'<td class="width-50" style="padding-left : 10px" valign="top">';

							tooltipText =
								tooltipText +
								'<table style="width : 250px" class="dont-break-out">';
							let min = summary.min;
							if (summary.min != undefined && dataType == 'date') {
								min = $rootScope.getDateTimeWithPattern(summary.min);
							} else if (summary.min != undefined && dataType == 'string') {
								min = $scope.setMax25Length(summary.min);
							} else if (summary.min != undefined) {
								min = formatStatValue(summary.min);
							}
							let max = summary.max;
							if (summary.max != undefined && dataType == 'date') {
								max = $rootScope.getDateTimeWithPattern(summary.max);
							} else if (summary.max != undefined && dataType == 'string') {
								max = $scope.setMax25Length(summary.max);
							} else if (summary.max != undefined) {
								max = formatStatValue(summary.max);
							}
							tooltipText =
								tooltipText +
								'<tr> <td class="width-50 height-25-px"> <span class="pull-left"> <b> ' +
								gettextCatalog.getString('Minimum') +
								'</b> </span> </td> <td class="width-50 dont-break-out"> <span class="pull-right"> ' +
								min +
								' </span> </td> </tr>';
							tooltipText =
								tooltipText +
								'<tr> <td class="width-50 height-25-px"> <span class="pull-left"> <b> ' +
								gettextCatalog.getString('Maximum') +
								'</b> </span> </td> <td class="width-50 dont-break-out ellipsis-text"> <span class="pull-right"> ' +
								max +
								' </span> </td> </tr>';
							if (summary.sum != null) {
								tooltipText =
									tooltipText +
									'<tr> <td class="width-50 height-25-px"> <span class="pull-left"> <b> ' +
									gettextCatalog.getString('Ecart Type') +
									'</b> </span> </td> <td class="width-50 dont-break-out"> <span class="pull-right"> ' +
									(summary.ecartType !== undefined
										? formatStatValue(summary.ecartType)
										: '') +
									' </span> </td> </tr>';
								tooltipText =
									tooltipText +
									'<tr> <td class="width-50 height-25-px"> <span class="pull-left"> <b> ' +
									gettextCatalog.getString('Somme') +
									'</b> </span> </td> <td class="width-50 dont-break-out"> <span class="pull-right"> ' +
									(summary.sum !== undefined
										? formatStatValue(summary.sum)
										: '') +
									' </span> </td> </tr>';
							}
							tooltipText = tooltipText + '</table>';

							tooltipText = tooltipText + '</td>';
							tooltipText = tooltipText + '</tr>';
							tooltipText = tooltipText + '</table>';

							return {
								target:
									'#header-stat-tooltip-' +
									$scope.uuid +
									'-' +
									column.col_index,
								showEvent: 'mouseenter',
								hideEvent: 'mouseleave',
								closeOnOutsideClick: false,
								position: 'right',
								contentTemplate: function (data) {
									data.html(tooltipText);
								},
							};
						};
						$scope.getHeaderCountTooltip = function (column) {
							let summary = $scope.columnSummary[column.uuid];
							if (!summary) {
								return;
							}
							let count = summary.nullCount;
							let percent = (count / $scope.widget.dataTotalCount) * 100;
							let tooltipText =
								count +
								' ' +
								gettextCatalog.getString('valeurs nulles') +
								' ( ' +
								percent.toFixed(2) +
								' %) ';
							return {
								target:
									'#header-count-tooltip-' +
									$scope.uuid +
									'-' +
									column.col_index,
								showEvent: 'mouseenter',
								hideEvent: 'mouseleave',
								closeOnOutsideClick: false,
								position: 'right',
								contentTemplate: function (data) {
									data.html(tooltipText);
								},
							};
						};

						$scope.showColumnStat = function (column, e) {
							// start prevent sorting action after click
							e.stopPropagation();
							// end prevent sorting action after click
							vm.widgetData.columnInfoId = column.uuid;
							vm.widgetData.columnInfoType = column.col_type;
							vm.widgetData.columnInfoIsList = column.is_list;
							vm.widgetData.columnInfoLabel = column.caption;
							vm.widgetData.columnInfoOrigin = $scope.widget.name;
							vm.widgetData.columnInfoGrammar = $scope.executedGrammar;
							vm.widgetData.showColumnStat = true;
						};

						$scope.setMax25Length = function (str) {
							return setMaxStringLength(str, 25);
						};
					},
				];

				return controller;
			};
			return dataBlockControlerGenerator;
		},
	]);
})();
