'use strict';

angular
	.module('dcApp')
	.controller('DataBlockEditController', [
		'$scope',
		'$rootScope',
		'toaster',
		'DataBlocksService',
		'PAGINATIONS_SIZE',
		'PAGINATIONS_SIZES',
		'$stateParams',
		'$state',
		'$timeout',
		'CommonServices',
		'TypeEntiteService',
		'TimeLineService',
		'MapService',
		'GridService',
		'ChartService',
		'$window',
		'MediaService',
		'gettextCatalog',
		'MetadataService',
		'$sce',
		'$location',
		'GenericService',
		'UserService',
		'PermissionService',
		'$http',
		'API_BASE_URL_BACKEND',
		'NgExportTemplate',
		'DateService',
		function (
			$scope,
			$rootScope,
			toaster,
			DataBlocksService,
			PAGINATIONS_SIZE,
			PAGINATIONS_SIZES,
			$stateParams,
			$state,
			$timeout,
			CommonServices,
			TypeEntiteService,
			TimeLineService,
			MapService,
			GridService,
			ChartService,
			$window,
			MediaService,
			gettextCatalog,
			MetadataService,
			$sce,
			$location,
			GenericService,
			UserService,
			PermissionService,
			$http,
			API_BASE_URL_BACKEND,
			NgExportTemplate,
			DateService
		) {
			var saveInSessionStorage = function () {
				if (
					$stateParams.id &&
					$rootScope.isLoggedIn() &&
					$scope.datablock &&
					$scope.datablock.grammar
				) {
					let datablock = {
						id: $scope.datablock.id,
						metadata: $scope.metadata,
					};
					datablock.grammar = {
						summary_config: $scope.datablock.grammar.summary_config,
						columns: $scope.datablock.grammar.columns,
						steps: $scope.datablock.steps,
						from_datablock: $scope.datablock.grammar.from_datablock,
					};
					$window.sessionStorage.setItem(
						'datablock_' + $stateParams.id,
						JSON.stringify(datablock)
					);
				}
			};

			$scope.$on('$destroy', saveInSessionStorage);
			$window.onbeforeunload = saveInSessionStorage;

			$scope.confirmReloadFromServer = function () {
				$window.sessionStorage.removeItem('datablock_' + $stateParams.id);
				delete $scope.fromSessionStorage;
				init();
			};

			let showRefreshConfirmationMessage = function (username, updateDate) {
				$scope.datablockData.confirmationDialogTitle =
					gettextCatalog.getString('Rafraîchir');
				let msg = gettextCatalog.getString(
					'Attention. Le rafraîchissement du DataBlock chargera sa dernière version (enregistrée le '
				);
				msg = msg + $rootScope.getDateTimeWithPatternMn(updateDate);
				msg = msg + ' ';
				msg = msg + gettextCatalog.getString('par');
				msg = msg + ' ';
				msg = msg + username;
				msg =
					msg +
					gettextCatalog.getString(
						'). Cela peut entraîner la perte des actions non enregistrées de cet écran. Voulez-vous poursuivre ?'
					);
				$scope.datablockData.confirmationDialogMessage = msg;
				$scope.datablockData.confirmationDialogActionName =
					gettextCatalog.getString('Oui');
				$scope.datablockData.enableConfirmDialogAction = true;
				$scope.datablockData.confirmationGreenAction = true;
				$scope.datablockData.confirmDialogAction =
					$scope.confirmReloadFromServer;
				$scope.datablockData.showConfirmationDialog = true;
			};

			$scope.reloadFromServer = function () {
				MetadataService.getMetadataTrace($scope.datablock.metadata.id).then(
					function (response) {
						let userId = $scope.datablock.metadata.updated_by
							? $scope.datablock.metadata.updated_by
							: $scope.datablock.metadata.created_by;
						let username = '-';
						if (userId) {
							UserService.getUserById(userId).then(function (responseUser) {
								showRefreshConfirmationMessage(
									responseUser.data.name,
									response.data.update_date
								);
							});
						} else {
							showRefreshConfirmationMessage(
								username,
								response.data.update_date
							);
						}
					}
				);
			};

			// Static elements data
			$scope.static_elements_data = $rootScope.static_elements_data;

			// HELP
			$scope.helpTitleTra = gettextCatalog.getString('Aide DataBlock');

			let toasterPopSuccess = gettextCatalog.getString('Succès');
			let toasterPopDeleteDatablock = gettextCatalog.getString(
				'Suppression du  Datablock effectuée'
			);
			let toasterPopCreationDatablock = gettextCatalog.getString(
				'Création du Datablock effectuée'
			);
			let toasterPopEditDatablock = gettextCatalog.getString(
				'Modification du Datablock effectuée'
			);

			let toasterPopEditDatablockModalTitle = gettextCatalog.getString(
				'Modifications détectées'
			);
			let toasterPopEditDatablockModalMessage1 = gettextCatalog.getString(
				'Les modifications apportées seront répercutées sur'
			);
			let toasterPopDatablockModalMessage2 = gettextCatalog.getString(
				'Eléments Handle Data (Voir onglet Eléments Handle Data). Des suppressions de colonnes ont été détectées. Confirmer l’impact de ces suppressions sur l’ensemble des eléments qui consomment le DataBlock'
			);
			let toasterPopDatablockModalMessage3 = gettextCatalog.getString(
				'Eléments Handle Data (Voir onglet Eléments Handle Data). Confirmer les modifications apportées au DataBlock'
			);

			$scope.metadata = {};
			$scope.metadata.actif = true;
			$scope.metadata.referenced = false;
			$scope.metadata.tags = [];
			$scope.persistanceParams = {};
			$scope.checkPermissionData = {};
			$scope.datablockData = {};
			$scope.datablockData.datablockMenuOpenAction = {};
			$scope.datablockData.datablockMenuData = {};
			$scope.datablockData.hideWidgetMenu = false;
			$scope.elementGraphData = {};
			$scope.grammarData = {};
			$scope.active = 'active';

			$scope.dbBuilder = { widgetData: $scope.datablockData };
			$scope.permissionData = {};

			$scope.showPermissions = function () {
				$scope.checkPermissionData.elementId = $scope.datablock.id;
				$scope.checkPermissionData.type = 'datablock';
				$scope.checkPermissionData.action = 'permissions';
				$scope.checkPermissionData.actionIfPermitted = $scope.doShowPermissions;
				$scope.checkPermissionData.showPermissionCheck = true;
			};

			$scope.doShowPermissions = function () {
				$scope.permissionData.metadataId = $scope.datablock.metadata.id;
				$scope.permissionData.type = 'datablock';
				$scope.permissionData.elementId = $scope.datablock.id;
				$scope.permissionData.lib = $scope.datablock.metadata.label;
				$scope.permissionData.showElementPermissions = true;
			};

			$scope.showHdhGraph = function () {
				$scope.elementGraphData.centeredElementLabel =
					$scope.datablock.metadata.label;
				$scope.elementGraphData.centeredElementType = 'DATABLOCK';
				$scope.elementGraphData.centeredElementId = $scope.datablock.id;
				$scope.elementGraphData.axe = 'db';
				$scope.elementGraphData.showHdhElementGraph = true;
			};

			/*export*/
			$scope.showExportModal = function (obj) {
				$state.go('from-datablock-edit', {
					datablockId: obj.id,
					datablockLabel: obj.name,
				});
			};

			$scope.showGrammar = function () {
				$scope.currentGrammar = $scope.datablock.grammar;
				$scope.currentGrammar.steps = $scope.datablock.steps;
				$scope.currentGrammar.showGrammarDialog = true;
			};

			/*export*/

			$scope.createNewHdElement = function () {
				$scope.datablockData.confirmationDialogTitle = gettextCatalog.getString(
					'Enregistrement du DataBlock'
				);
				$scope.datablockData.confirmationDialogMessage =
					gettextCatalog.getString(
						"Avant de réaliser la création d'une visualisation, vous devez enregistrer votre DataBlock. Confirmer cette action ?"
					);
				$scope.datablockData.confirmationDialogActionName =
					gettextCatalog.getString('Oui');
				$scope.datablockData.enableConfirmDialogAction = true;
				$scope.datablockData.confirmationGreenAction = true;
				$scope.datablockData.confirmDialogAction =
					$scope.showCreateHdElementModal;
				$scope.datablockData.showConfirmationDialog = true;
			};

			$scope.disableDeleteTypeEntite = true;
			$scope.showDeleteModal = function () {
				$scope.elementToDelete = angular.copy($scope.datablock);
				$scope.checkPermissionData.elementId = $scope.elementToDelete.id;
				$scope.checkPermissionData.type = 'datablock';
				$scope.checkPermissionData.action = 'delete';
				$scope.checkPermissionData.actionIfPermitted = $scope.doShowDeleteModal;
				$scope.checkPermissionData.showPermissionCheck = true;
			};

			$scope.showCreateHdElementModal = function () {
				$scope.afterSaveAction = function () {
					$('#createHdElementModal').modal('show');
				};
				$scope.saveDataBlock(false);
			};

			$scope.createTimeline = function () {
				TimeLineService.createFromDataBlock($scope.datablock.id).then(function (
					response
				) {
					$('#createHdElementModal').modal('hide');
					const url = $state.href('timelines-edit', {
						id: response.data,
					});
					$window.open(url, '_blank');
					return;
				});
			};

			$scope.createMap = function () {
				MapService.createFromDataBlock($scope.datablock.id).then(function (
					response
				) {
					$('#createHdElementModal').modal('hide');
					const url = $state.href('maps-edit', {
						id: response.data,
					});
					$window.open(url, '_blank');
					return;
				});
			};

			$scope.createChart = function (type, subType) {
				ChartService.createChartFromDataBlock(type, {
					datablock_id: $scope.datablock.id,
					sub_type: subType,
				}).then(function (response) {
					$('#createHdElementModal').modal('hide');
					const url = $state.href('charts-edit', {
						type: type,
						id: response.data,
					});
					$window.open(url, '_blank');
					return;
				});
			};

			$scope.createMedia = function (type) {
				MediaService.createMediaFromDataBlock(type, {
					datablock_id: $scope.datablock.id,
				}).then(function (response) {
					$('#createHdElementModal').modal('hide');
					const url = $state.href('medias-edit', {
						type: type,
						id: response.data,
					});
					$window.open(url, '_blank');
					return;
				});
			};

			$scope.createGrid = function (type) {
				GridService.createGridFromDataBlock(type, $scope.datablock.id).then(
					function (response) {
						$('#createHdElementModal').modal('hide');
						const url = $state.href('grids-edit', {
							type: type,
							id: response.data,
						});
						$window.open(url, '_blank');
						return;
					}
				);
			};

			$scope.deleteElementData = {};
			$scope.doShowDeleteModal = function () {
				$scope.deleteElementData = {
					label:
						$scope.elementToDelete && $scope.elementToDelete.metadata
							? $scope.elementToDelete.metadata.label
							: undefined,
					doDelete: $scope.deleteDataBlock,
					showConfirmDeleteElement: true,
					bulk: false,
				};
			};

			$scope.deleteDataBlock = function () {
				DataBlocksService.deleteDataBlock($scope.datablock.id).then(function (
					response
				) {
					toaster.pop('success', toasterPopSuccess, toasterPopDeleteDatablock);
					$timeout(function () {
						$state.go('datablocks');
					}, 300);
				});
			};
			$scope.showLinkedList = false;
			$scope.getUsedEntitesAndDataBlocks = function () {
				$scope.showLinkedList = true;
			};
			$scope.showEntityList = function () {
				$scope.showLinkedList = true;
			};
			$scope.getLinkedElements = function () {
				if ($scope.linkedElements) {
					return;
				}
				if ($scope.datablock) {
					DataBlocksService.getDataBlockUsage($scope.datablock.id).then(
						function (response) {
							$scope.linkedElements = response.data;
							$scope.linkedElements.forEach(function (el) {
								mapElementToData(el);
							});
						}
					);
				}
			};

			$scope.changeColumnStatActivation = function () {
				if ($scope.datablockView[0].columnStat) {
					$scope.datablockView[0].steps[0].summary_config.count_columns = [];
					for (var c in $scope.datablockView[0].grammar.columns) {
						$scope.datablockView[0].steps[0].summary_config.count_columns.push(
							$scope.datablockView[0].grammar.columns[c].uuid
						);
						if (!$scope.datablockView[0].grammar.columns[c].list) {
							$scope.datablockView[0].steps[0].summary_config.summary_columns.push(
								$scope.datablockView[0].grammar.columns[c].uuid
							);
						}
					}
				} else {
					$scope.datablockView[0].steps[0].summary_config.count_columns = [];
					$scope.datablockView[0].steps[0].summary_config.summary_columns =
						$scope.datablockView[0].steps[0].summary_config.count_columns;
				}
			};

			$scope.getFrameData = function () {
				$scope.datablockView = [];
				TypeEntiteService.getEntiteTypeGrammar($scope.datablock.id).then(
					function (response) {
						if (response.data) {
							var dbView = buildDataBlock(
								{ grammar: response.data },
								'',
								undefined
							);
							dbView.steps = [
								{
									formulas: [],
									data_operation: {},
									agregation: {},
									filter: { condition: 'AND' },
									sort: {},
									summary_config: {
										summary: true,
										data: true,
										total_count: true,
										count_columns: [],
										summary_columns: [],
									},
								},
							];
							$scope.datablockView.push(dbView);
						}
					}
				);
			};

			$scope.redirectionToList = function () {
				$('#confirmationModal').modal('hide');
				$timeout(function () {
					$state.go('datablocks');
				}, 300);
			};

			$scope.saveDataBlockAndReturnToList = function () {
				if (!$scope.isNew) {
					$scope.checkPermissionData.elementId = $scope.datablock.id;
					$scope.checkPermissionData.type = 'datablock';
					$scope.checkPermissionData.action = 'edit';
					$scope.checkPermissionData.actionIfPermitted = function () {
						$scope.saveDataBlock(true);
					};
					$scope.checkPermissionData.showPermissionCheck = true;
				} else {
					$scope.saveDataBlock(true);
				}
			};

			$scope.dataModel = {};
			$scope.dataModel.save = $scope.saveDataBlockAndReturnToList;
			$scope.dataModel.redirectionToList = $scope.redirectionToList;

			var upperCaseColumnsUuids = function (columns, ignoreColumnUuid) {
				for (var c in columns) {
					if (columns[c].uuid) {
						columns[c].uuid = columns[c].uuid.toUpperCase();
					}
					if (columns[c].column_uuid && !ignoreColumnUuid) {
						columns[c].column_uuid = columns[c].column_uuid.toUpperCase();
					}
				}
			};

			var upperCaseUuids = function (grammar) {
				upperCaseColumnsUuids(grammar.columns);

				if (grammar.steps) {
					for (var s in grammar.steps) {
						var step = grammar.steps[s];

						if (step.filter) {
							upperCaseRuleUuids(step.filter);
						}

						if (step.output) {
							upperCaseColumnsUuids(step.output);
						}

						if (step.formulas) {
							for (var f in step.formulas) {
								upperCaseFormulaUuids(step.formulas[f]);
							}
							upperCaseColumnsUuids(step.formulas);
						}

						if (step.sort && step.sort.sorts) {
							upperCaseColumnsUuids(step.sort.sorts);
						}

						if (step.agregation) {
							if (step.agregation.groups) {
								upperCaseColumnsUuids(step.agregation.groups);
							}
							if (step.agregation.functions) {
								upperCaseColumnsUuids(step.agregation.functions);
							}
							if (step.agregation.sorts) {
								upperCaseColumnsUuids(step.agregation.sorts);
							}
							if (step.agregation.partitions) {
								for (var p in step.agregation.partitions) {
									if (step.agregation.partitions[p].groups) {
										upperCaseColumnsUuids(step.agregation.partitions[p].groups);
									}
									if (step.agregation.partitions[p].functions) {
										upperCaseColumnsUuids(
											step.agregation.partitions[p].functions
										);
									}
									if (step.agregation.partitions[p].sorts) {
										upperCaseColumnsUuids(step.agregation.partitions[p].sorts);
									}
								}
							}
						}

						if (step.data_operation && step.data_operation.explode) {
							upperCaseColumnsUuids(step.data_operation.explode.columns);
						}

						if (step.data_operation && step.data_operation.stack) {
							upperCaseColumnsUuids(step.data_operation.stack.columns);
						}

						if (step.links) {
							for (var l in step.links) {
								upperCaseColumnsUuids(step.links[l].columns, true);
								for (var m in step.links[l].mappings) {
									if (step.links[l].mappings[m].left_column_uuid) {
										step.links[l].mappings[m].left_column_uuid =
											step.links[l].mappings[m].left_column_uuid.toUpperCase();
									}
								}
							}
						}
					}
				}
			};

			var upperCaseFormulaUuids = function (formula) {
				if (formula.type == 'function' && formula.params) {
					for (var p in formula.params) {
						upperCaseFormulaUuids(formula.params[p]);
					}
				} else {
					if (formula.type == 'column' && formula.value) {
						formula.value = formula.value.toUpperCase();
					}
				}
			};

			var upperCaseRuleUuids = function (rule) {
				if (rule.condition && rule.rules && !rule.rules[0]) {
					for (var r in rule.rules) {
						upperCaseRuleUuids(rule.rules[r]);
					}
				} else {
					if (rule.column_alias) {
						rule.column_alias = rule.column_alias.toUpperCase();
					}
				}
			};
			$scope.dbBuilder.referencedElements = [];
			var getUsedEntitiesAndDataBlocksIds = function () {
				$scope.dbBuilder.referencedElements = [];
				if ($scope.datablock) {
					for (let i = 0; i < $scope.datablock.grammar.columns.length; i++) {
						if (
							$scope.datablock.grammar.columns[i].parent_entite_type_id &&
							$scope.dbBuilder.referencedElements.indexOf(
								$scope.datablock.grammar.columns[i].parent_entite_type_id
							) < 0
						) {
							$scope.dbBuilder.referencedElements.push(
								$scope.datablock.grammar.columns[i].parent_entite_type_id
							);
						}
					}
				}
				for (let i in $scope.datablock.steps) {
					if ($scope.datablock.steps[i].links) {
						for (let j in $scope.datablock.steps[i].links) {
							if (
								$scope.datablock.steps[i].links[j].db_id &&
								$scope.dbBuilder.referencedElements.indexOf(
									$scope.datablock.steps[i].links[j].db_id
								) < 0
							) {
								$scope.dbBuilder.referencedElements.push(
									$scope.datablock.steps[i].links[j].db_id
								);
							}
						}
					}
					if ($scope.datablock.steps[i].unions) {
						for (let j in $scope.datablock.steps[i].unions) {
							if (
								$scope.datablock.steps[i].unions[j].db_id &&
								$scope.dbBuilder.referencedElements.indexOf(
									$scope.datablock.steps[i].unions[j].db_id
								) < 0
							) {
								$scope.dbBuilder.referencedElements.push(
									$scope.datablock.steps[i].unions[j].db_id
								);
							}
						}
					}
				}
			};
			var initFromDataBlock = function () {
				if ($scope.datablock.grammar) {
					$scope.datablock.steps = $scope.datablock.grammar.steps;
					getUsedEntitiesAndDataBlocksIds();
					$scope.getLinkedElements();
				}
				$scope.datablock.source_id = $scope.datablock.id;
				$scope.metadata = $scope.datablock.metadata;
				$scope.datablock.name = $scope.metadata.label;
				$scope.datablock.type = 'TABLE';
				$scope.datablock.code = $scope.datablock.metadata.code;
				if ($scope.datablock.grammar) {
					$scope.datablock.grammar.base_grammar = {
						from_datablock: $scope.datablock.grammar.from_datablock,
						columns: $scope.datablock.grammar.columns,
						summary_config: $scope.datablock.grammar.summary_config,
					};
					loadDataBlockFromGrammar($scope.datablock, $scope.datablock.grammar);
				}

				PermissionService.havePermission(
					$scope.datablock.id,
					'data_catalog',
					'datablock'
				).then(function (response) {
					$scope.dataCatalogueDisabled = !response.data;
					$scope.metadataLoaded = true;
				});
			};

			var getUsedEntitiesAndDataBlocksCount = function () {
				DataBlocksService.getUsedEntitiesAndDataBlocksCount(
					$scope.datablock.id
				).then(function (response) {
					$scope.countReferencedElements = response.data;
					$scope.fetchDone = true;
				});
			};

			var init = function () {
				$scope.isNew = true;
				$scope.element_id = 0;
				$scope.savingInProgress = false;
				if ($stateParams.id) {
					// edit
					$scope.isNew = false;
					$scope.persistanceParams = { id: $stateParams.id };
					delete $scope.datablock;
					let datablockId = $stateParams.id;
					let stored = JSON.parse(
						$window.sessionStorage.getItem('datablock_' + datablockId)
					);
					if (stored) {
						DataBlocksService.isChanged(stored).then(function (response) {
							$scope.fromSessionStorage = response.data;
							$scope.datablock = stored;
							initFromDataBlock();
						});
					} else {
						DataBlocksService.getDataBlock(datablockId).then(function (
							response
						) {
							$scope.datablock = response.data;
							initFromDataBlock();
						});
					}
				} else if ($stateParams.duplicatedId) {
					// duplicate
					$scope.isNew = true;
					let datablockId = $stateParams.duplicatedId;
					DataBlocksService.getDataBlock(datablockId).then(function (response) {
						var grammar = response.data.grammar;
						upperCaseUuids(grammar);
						grammar.base_grammar = { columns: grammar.columns };
						$scope.metadataLoaded = true;
						$scope.datablock = { grammar: grammar, type: 'TABLE', isNew: true };
						$scope.datablock.steps = grammar.steps;
						loadDataBlockFromGrammar(
							$scope.datablock,
							$scope.datablock.grammar
						);
					});
					$scope.getLinkedElements();
				} else if ($stateParams.entiteId || $stateParams.datablockId) {
					let id = $stateParams.entiteId
						? $stateParams.entiteId
						: $stateParams.datablockId;
					TypeEntiteService.getEntiteTypeGrammar(id).then(function (response) {
						if (response.data) {
							TypeEntiteService.generateMetadataForDbOrDv(id).then(function (
								responseLib
							) {
								if (responseLib) {
									$scope.metadata = responseLib.data;
								}
								$scope.metadataLoaded = true;

								for (var c in response.data.columns) {
									response.data.columns[c].uuid = generateUuid('_');
								}

								$scope.datablock = {
									grammar: { base_grammar: response.data },
									type: 'TABLE',
									isNew: true,
								};
								$scope.datablock.grammar.base_grammar.from_datablock =
									$stateParams.datablockId != undefined;
								setDataBlockFromGrammar(
									$scope.datablock,
									$scope.datablock.grammar
								);
							});
						}
					});
				} else {
					$scope.metadataLoaded = true;
					$scope.datablock = {
						grammar: { base_grammar: { columns: [] } },
						type: 'TABLE',
						isNew: true,
					};
					setDataBlockFromGrammar($scope.datablock, $scope.datablock.grammar);
				}
			};

			init();

			$scope.saveDataBlockIgnoringDataViews = function () {
				$scope.saveDataBlock($scope.returnToList, true);
			};

			$scope.saveDB = function () {
				if ($scope.datablock.steps) {
					for (let s in $scope.datablock.steps) {
						if (
							$scope.datablock.steps[s].description &&
							$scope.datablock.steps[s].description.length > 5000
						) {
							toaster.pop(
								'error',
								'la taille de la description de l’étape ' +
									$scope.datablock.steps[s].alias +
									' ne doit pas dépasser 5000 caractères'
							);
							return;
						}
					}
				}
				if (!$scope.isNew) {
					$scope.checkPermissionData.elementId = $scope.datablock.id;
					$scope.checkPermissionData.type = 'datablock';
					$scope.checkPermissionData.action = 'edit';
					$scope.checkPermissionData.actionIfPermitted = $scope.saveDataBlock;
					$scope.checkPermissionData.showPermissionCheck = true;
				} else {
					$scope.saveDataBlock();
				}
			};

			$scope.savingInProgress = false;
			let catchDBSaveErrors = function (error) {
				$scope.savingInProgress = false;
			};

			$scope.saveDataBlock = function (returnToList, ignoreLinkedDataViews) {
				$('#confirmationModal').modal('hide');
				$scope.returnToList = returnToList;

				if (!ignoreLinkedDataViews && $scope.datablock.id) {
					var dbGrammar = $scope.datablock.grammar
						? {
								summary_config: angular.copy(
									$scope.datablock.grammar.summary_config
								),
								columns: angular.copy($scope.datablock.grammar.columns),
								steps: angular.copy($scope.datablock.steps),
								from_datablock: angular.copy(
									$scope.datablock.grammar.from_datablock
								),
						  }
						: {};
					// TODO remove when ISO date is handled correctly in the query builder
					filterDatesToIsoDefaultTz(dbGrammar);
					DataBlocksService.getUpdates($scope.datablock.id, dbGrammar).then(
						function (response1) {
							if (response1.data[0]) {
								DataBlocksService.getLinkedHdElements($scope.datablock.id).then(
									function (response) {
										if (response.data[0]) {
											$scope.datablockData.confirmationDialogTitle =
												toasterPopEditDatablockModalTitle;
											$scope.datablockData.confirmationDialogMessageDetails =
												'';
											$scope.datablockData.confirmationDialogActionName = 'Oui';
											$scope.datablockData.enableConfirmDialogAction = true;
											$scope.datablockData.confirmDialogAction =
												$scope.saveDataBlockIgnoringDataViews;
											for (var u in response1.data) {
												if (response1.data[u].action == 'DELETE') {
													$scope.datablockData.confirmationDialogMessage =
														toasterPopEditDatablockModalMessage1 +
														' ' +
														response.data.length +
														' ' +
														toasterPopDatablockModalMessage2;
													$scope.datablockData.showConfirmationDialog = true;
													return;
												}
											}
											$scope.datablockData.confirmationDialogMessage =
												toasterPopEditDatablockModalMessage1 +
												' ' +
												response.data.length +
												' ' +
												toasterPopDatablockModalMessage3;
											$scope.datablockData.showConfirmationDialog = true;
											return;
										} else {
											$scope.updateDataBlock(returnToList);
										}
									}
								);
							} else {
								$scope.updateDataBlock(returnToList);
							}
						}
					);
				} else {
					$scope.updateDataBlock(returnToList);
				}
			};

			$scope.updateDataBlock = function (returnToList) {
				// set metadata
				for (var t in $scope.metadata.tags) {
					$scope.metadata.tags[t].color =
						$scope.metadata.tags[t].color !== undefined
							? $scope.metadata.tags[t].color
							: '#dbf5d1';
				}
				$scope.savingInProgress = true;
				var afterSaveAction = $scope.afterSaveAction;
				delete $scope.afterSaveAction;
				if ($scope.isNew) {
					let datablock = { metadata: $scope.metadata };

					var dbGrammar = {
						summary_config: angular.copy(
							$scope.datablock.grammar.summary_config
						),
						from_datablock: angular.copy(
							$scope.datablock.grammar.from_datablock
						),
						columns: angular.copy($scope.datablock.grammar.columns),
						steps: angular.copy($scope.datablock.steps),
					};
					if (dbGrammar.columns && dbGrammar.columns[0]) {
						datablock.grammar = dbGrammar;
					}
					// TODO remove when ISO date is handled correctly in the query builder
					filterDatesToIsoDefaultTz(dbGrammar);
					DataBlocksService.createDataBlock(datablock)
						.then(function (response) {
							toaster.pop(
								'success',
								toasterPopSuccess,
								toasterPopCreationDatablock
							);
							if (returnToList) {
								$timeout(function () {
									$scope.savingInProgress = false;
									$state.go('datablocks');
								}, 300);
							} else {
								$timeout(function () {
									$scope.savingInProgress = false;
									$state.go('datablocks-edit', { id: response.data });
								}, 300);
							}
							if (afterSaveAction) {
								afterSaveAction();
							}
						})
						.catch(catchDBSaveErrors);
				} else {
					let datablock = {
						id: $scope.datablock.id,
						metadata: $scope.metadata,
					};
					datablock.grammar = {
						summary_config: angular.copy(
							$scope.datablock.grammar.summary_config
						),
						columns: angular.copy($scope.datablock.grammar.columns),
						steps: angular.copy($scope.datablock.steps),
						from_datablock: angular.copy(
							$scope.datablock.grammar.from_datablock
						),
					};
					if (!datablock.grammar.columns[0]) {
						delete datablock.grammar;
					}
					// TODO remove when ISO date is handled correctly in the query builder
					filterDatesToIsoDefaultTz(dbGrammar);
					DataBlocksService.editDataBlock(datablock)
						.then(function (response) {
							toaster.pop(
								'success',
								toasterPopSuccess,
								toasterPopEditDatablock
							);
							$scope.fromSessionStorage = false;
							$window.sessionStorage.removeItem('datablock_' + $stateParams.id);
							$scope.persistanceParams.updatePersistanceParams();
							$scope.savingInProgress = false;
							$scope.savingInProgress = false;
							if (returnToList) {
								$timeout(function () {
									$state.go('datablocks');
								}, 300);
							}
							if (afterSaveAction) {
								afterSaveAction();
							}
						})
						.catch(catchDBSaveErrors);
				}
			};

			$scope.showDataExposeParams = function () {
				$scope.checkPermissionData.elementId = $stateParams.id;
				$scope.checkPermissionData.type = 'datablock';
				$scope.checkPermissionData.action = 'view';
				$scope.checkPermissionData.actionIfPermitted = showDataExposeParams;
				$scope.checkPermissionData.showPermissionCheck = true;
			};

			var showDataExposeParams = function () {
				$scope.datablockToExposeID = $stateParams.id;
				$scope.datablockToExposeLabel = $scope.metadata.label;
				$scope.showExposeDataBlock = true;
			};

			$scope.showConfirmationModal = function () {
				$('#confirmationModal').modal('show');
			};

			var mapElementToData = function (el) {
				const type = el.type.toLowerCase();
				el.state = "exposition" === type ? "exposition-view({expositionId:hdEle.id})" : `${type}s-edit({id:hdEle.id, type: hdEle.sub_type})`;
				if (type === 'datablock') {
					el.icon = $rootScope.static_elements_data.GD.icons[type];
					el.title = gettextCatalog.getString(
						$rootScope.static_elements_data.GD.labels[type]
					);
				} else {
					el.icon = $rootScope.static_elements_data.HD.icons[type];
					el.title = gettextCatalog.getString(
						$rootScope.static_elements_data.HD.labels[type]
					);
				}
			};

			$scope.showElementComment = false;
			$scope.showComments = function () {
				$scope.showElementComment = true;
			};

			$scope.getAutoDoc = function () {
				let url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('DATABLOCK_ENTITY_AUTO_DOC');
				url = url.replace('{elementId}', $stateParams.id);
				$http
					.get(url, { responseType: 'arraybuffer' })
					.then(function (response) {
						var headers = response.headers();
						var fileName = $scope.datablock.metadata.code + '.pdf';
						var a = document.createElement('a');
						document.body.appendChild(a);
						var file = new Blob([response.data], {
							type: headers['content-type'],
						});
						var fileURL = window.URL.createObjectURL(file);
						a.href = fileURL;
						a.download = fileName;
						a.click();
					});
			};

			$scope.checkColumnRemoval = function (datablockId, columnUUID) {
				return DataBlocksService.columnIsUsedInDataBlockLinks(
					datablockId,
					columnUUID
				);
			};

			/**
				Transform the filter dates to iso
			**/
			function filterDatesToIsoDefaultTz(grammar) {
				if (!grammar || !grammar.steps) {
					return;
				}
				for (let i in grammar.steps) {
					if (grammar.steps[i].filter && grammar.steps[i].filter.rules) {
						DataBlocksService.filterDatesToIsoDefaultTz(
							grammar.steps[i].filter.rules
						);
					}
				}
			}
		},
	])
	.controller('DataBlockListController', [
		'$timeout',
		'$scope',
		'$state',
		'$rootScope',
		'$window',
		'toaster',
		'DataBlocksService',
		'PAGINATIONS_SIZES',
		'PAGINATIONS_SIZE',
		'$q',
		'$http',
		'API_BASE_URL_BACKEND',
		'$interval',
		'DEFAULT_CELL_TEMPLATE',
		'TypeEntiteService',
		'ConnectorService',
		'ExportHistoryService',
		'PermissionService',
		'ExportService',
		function (
			$timeout,
			$scope,
			$state,
			$rootScope,
			$window,
			toaster,
			DataBlocksService,
			PAGINATIONS_SIZES,
			PAGINATIONS_SIZE,
			$q,
			$http,
			API_BASE_URL_BACKEND,
			$interval,
			DEFAULT_CELL_TEMPLATE,
			TypeEntiteService,
			ConnectorService,
			ExportHistoryService,
			PermissionService,
			ExportService
		) {
			PermissionService.haveGlobalPermission('datablock-create').then(function (
				response
			) {
				$scope.create_authorised = response.data;
			});

			$scope.shitEmptyId = function () {
				if ($scope.exportOperation != 'index') {
					if ($scope.exportIds[0] == '') {
						$scope.exportIds.splice(0, 1);
						$scope.exportId = $scope.exportIds[0].alias;
					}
				} else if ($scope.exportIds[0] != '') {
					$scope.exportIds.unshift('');
					$scope.exportId = $scope.exportIds[0].alias;
				}
			};
		},
	]);
