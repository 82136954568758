(function () {
	'use strict';

	angular.module('dcApp').run(startupRun);

	startupRun.$inject = [
		'$trace',
		'$transitions',
		'$log',
		'$q',
		'$rootScope',
		'$state',
		'$filter',
		'$http',
		'$injector',
		'$stateParams',
		'$window',
		'$location',
		'$timeout',
		'CommonServices',
		'UserService',
		'ParametersServices',
		'gettextCatalog',
		'Idle',
		'ProjectStorage',
		'ProjectService',
		'toaster',
		'InitializationService',
		'AppSelectorService',
		'API_BASE_URL_BACKEND',
		'DATE_PATTERN',
		'DATE_TIME_PATTERN',
		'CURRENT_LOCATION_NAME',
		'DATE_TIME_PATTERN_MN',
		'STORAGE_SUFFIX',
		'LOGIN_NAME',
		'LANG_NAME',
		'APP_BUILD',
		'PROJECT_LABEL_VAR_NAME',
		'PROJECT_VAR_NAME',
		'USER_ROLE_NAME',
		'MEMBER_ROLE_NAME',
		'PROJECT_ROLE_NAME',
		'BACKEND_CLIENT_ID',
		'ADMIN_ROLE_NAME',
		'SELECTED_MENU',
		'WEB_SOCKET_TOPICS',
		'JOBS_CACHE_STATUS',
		'CTX_VAR_NAME',
	];

	function startupRun(
		$trace,
		$transitions,
		$log,
		$q,
		$rootScope,
		$state,
		$filter,
		$http,
		$injector,
		$stateParams,
		$window,
		$location,
		$timeout,
		CommonServices,
		UserService,
		ParametersServices,
		gettextCatalog,
		Idle,
		ProjectStorage,
		ProjectService,
		toaster,
		InitializationService,
		AppSelectorService,
		API_BASE_URL_BACKEND,
		DATE_PATTERN,
		DATE_TIME_PATTERN,
		CURRENT_LOCATION_NAME,
		DATE_TIME_PATTERN_MN,
		STORAGE_SUFFIX,
		LOGIN_NAME,
		LANG_NAME,
		APP_BUILD,
		PROJECT_LABEL_VAR_NAME,
		PROJECT_VAR_NAME,
		USER_ROLE_NAME,
		MEMBER_ROLE_NAME,
		PROJECT_ROLE_NAME,
		BACKEND_CLIENT_ID,
		ADMIN_ROLE_NAME,
		SELECTED_MENU,
		WEB_SOCKET_TOPICS,
		JOBS_CACHE_STATUS,
		CTX_VAR_NAME
	) {
		// $trace.enable('TRANSITION');
		// retry every 5 seconds
		let retryWebSocketConnectDelay = 5000;
		let datePatternMappings = undefined;
		let defaultTZ = undefined;

		$rootScope.API_BASE_URL_BACKEND = API_BASE_URL_BACKEND;
		$rootScope.jobCacheActive = true;
		$rootScope.filtersParam = {};
		$rootScope.sessionExpirationFirstAlertDelay = 600;
		$rootScope.sessionExpirationLastAlertDelay = 60;
		// VARIABLES
		$rootScope.hasDefaultUserRoleActive = false;
		$rootScope.keycloak = undefined;
		$rootScope.apiPaths = undefined;
		$rootScope.dataGirdCaptionsTra = undefined;
		$rootScope.sessionDelayAlertPopupOptions = undefined;
		$rootScope.sessionDelayLastAlertPopupOptions = undefined;
		$rootScope.inactivityAlertPopupOptions = undefined;
		$rootScope.currentLangCode = undefined;
		$rootScope.currentProject = undefined;
		$rootScope.currentProjectId = undefined;
		$rootScope.stompClient = undefined;
		$rootScope.jobTypes = undefined;
		$rootScope.availableTimezones = undefined;
		$rootScope.availableDateLanguages = undefined;
		$rootScope.selectedMenu = undefined;

		// ROLES
		$rootScope.hasUserRole = undefined;
		$rootScope.hasMemberRole = undefined;
		$rootScope.hasProjectRole = undefined;
		$rootScope.hasAdminRole = undefined;
		$rootScope.userIsAdmin = undefined;

		$rootScope.goTo = goTo;
		$rootScope.logout = executeOnLogout;
		$rootScope.getCenteringTooltip = getCenteringTooltip;
		$rootScope.handleInvalidToken = handleInvalidToken;
		$rootScope.checkError = checkError;
		$rootScope.checkErrorBoolean = checkErrorBoolean;
		$rootScope.getDateWithPattern = getDateWithPattern;
		$rootScope.getDateTimeWithPattern = getDateTimeWithPattern;
		$rootScope.getDateTimeWithPatternMn = getDateTimeWithPatternMn;
		$rootScope.getFormattedDateWithPattern = getFormattedDateWithPattern;
		$rootScope.isLoggedIn = isLoggedIn;
		$rootScope.getLastLoginDate = getLastLoginDate;
		$rootScope.getUrl = getUrl;
		$rootScope.translateSimpleTypes = translateSimpleTypes;
		$rootScope.havePermission = havePermission;
		$rootScope.setDefaultLanguage = setDefaultLanguage;
		$rootScope.getRunningJob = getRunningJob;
		$rootScope.mapTags = mapTags;
		$rootScope.getCorrespondentDatePattern = getCorrespondentDatePattern;
		$rootScope.getDefaultTimezone = getDefaultTimezone;

		$rootScope.static_elements_data = {
			GD: {
				icons: {
					datablock: 'fal fa-table',
					entity: 'far fa-credit-card-front',
					repository: 'far fa-boxes',
					connector: 'far fa-plug',
				},
				links: {
					datablock: '/datablocks',
					entity: '/entites',
					repository: '/depots',
					connector: '/connectors',
				},
				labels: {
					datablock: 'DataBlocks',
					entity: 'Entités Métier',
					repository: 'Dépôts',
					connector: 'Connecteurs',
				},
			},
			HD: {
				icons: {
					map: 'fas fa-map-marked-alt',
					chart: 'far fa-chart-line',
					grid: 'fas fa-table',
					media: 'far fa-photo-video',
					timeline: 'far fa-line-height',
					dashboard: 'fas fa-file-medical-alt',
					exposition: 'fas fa-share-alt'
				},
				links: {
					map: '/maps',
					chart: '/charts',
					grid: '/grids',
					media: '/medias',
					timeline: '/timelines',
					dashboard: '/dashboards',
					exposition: '/expositions',
				},
				labels: {
					map: 'Cartes',
					chart: 'Graphiques',
					grid: 'Tableaux',
					media: 'Médias',
					timeline: 'TimeLine',
					dashboard: 'Tableaux de Bord',
					exposition: 'exposition.name',
				},
			},
		};

		init();

		function init() {
			if (!window._keycloak || !window._keycloak.token) {
				$log.warn('No token in cookies');
				InitializationService.initAvailablePaths();
				$rootScope.apiPaths = InitializationService.getAvailablePaths();
				// load date pattern mapping and default timezone
				initDatePatternMappings();
				initDefaultTimezone();
				return;
			}
			initRoles();
			initEvents();
			initVariables();

			InitializationService.initAvailablePaths();
			connect();
		}

		function getUrl(code) {
			if ($rootScope.apiPaths === null || $rootScope.apiPaths === undefined) {
				$rootScope.apiPaths = InitializationService.getAvailablePaths();
			}
			return $rootScope.apiPaths[code];
		}

		function translateSimpleTypes(types) {
			let translatedTypes = [];
			for (let i in types) {
				translatedTypes[i] = types[i];
				translatedTypes[i].lib = $rootScope.simpleTypesTra[types[i].lib]
					? $rootScope.simpleTypesTra[types[i].lib]
					: types[i].lib;
			}
			return translatedTypes;
		}

		function loadCacheActiveStatus() {
			let status = $window.localStorage.getItem(JOBS_CACHE_STATUS);
			if (status != undefined) {
				$rootScope.jobCacheActive = status === 'true';
				return;
			}
			ParametersServices.getCacheStatus().then(
				function (response) {
					$rootScope.jobCacheActive = response.data;
					$window.localStorage.setItem(
						JOBS_CACHE_STATUS,
						$rootScope.jobCacheActive
					);
				},
				function (e) {
					$log.error(e);
					$rootScope.jobCacheActive = true;
					$window.localStorage.setItem(
						JOBS_CACHE_STATUS,
						$rootScope.jobCacheActive
					);
				}
			);
		}

		function setDefaultLanguage(currentLangCode) {
			$rootScope.currentLangCode = currentLangCode;
			InitializationService.setCurrentLanguage(currentLangCode);
			$http.defaults.headers.common['Accept-Language'] = currentLangCode;
		}

		function havePermission(permission) {
			// FIXME: moves condition on element ex: ng-if= $rootScope.userIsAdmin || havePermission('permission')
			//  permission string should be a constant
			if ($rootScope.userIsAdmin) {
				return true;
			}

			if ($rootScope.globalPermissions) {
				return $rootScope.globalPermissions.indexOf(permission) > -1;
			}

			return false;
		}

		function downInactivityCount() {
			$timeout(function () {
				$rootScope.inactivityCountdown--;
				if ($rootScope.visibleInactivityAlertPopup) {
					downInactivityCount();
				}
			}, 1000);
		}

		function closeInactivityAlert() {
			$rootScope.visibleInactivityAlertPopup = false;
		}

		function startIdleWatch() {
			if (window._keycloak.token) {
				$http.defaults.headers.common.Authorization =
					'Bearer ' + window._keycloak.token;
			}
			UserService.getInactivityMaxDelay().then(
				function (response) {
					if (!response.data) {
						$log.error('Invalid inactivity Max Delay parameters ==> logout');
						executeOnLogout();
						return;
					}

					let inactivityDelay = (Number(response.data) - 1) * 60;
					let inactivityTimeout = 60;
					Idle.setIdle(inactivityDelay); // in seconds
					Idle.setTimeout(inactivityTimeout); // in seconds

					Idle.watch();
				},
				function (response) {
					$log.error('cannot get inactivity Ma xDelay parameters ==> logout');
					executeOnLogout();
				}
			);
		}

		function executeOnLogout(onlyLocally) {
			$log.debug('logout');
			$rootScope.account = null;
			Idle.unwatch();
			$rootScope.isLoggedOut = true;
			delete $rootScope.lastLoginDate;
			_logout();
		}

		function goTo(state, hasTargetProject, selectedMenu) {
			$rootScope.selectedMenu =
				selectedMenu !== undefined ? selectedMenu : state;
			$window.sessionStorage.setItem(SELECTED_MENU, $rootScope.selectedMenu);
			if (hasTargetProject !== undefined && hasTargetProject) {
				$state.go(state, {
					projectId: AppSelectorService.getSelectedProjectId(),
				});
			} else {
				$state.go(state);
			}
		}

		function isLoggedIn() {
			return window._keycloak && window._keycloak.token;
		}

		function connect() {
			if (!$rootScope.hasUserRole) {
				$state.go('accessDenied');
				return;
			}

			$rootScope.$watch('keycloak.token', function (newValue, oldValue) {
				$http.defaults.headers.common.Authorization =
					'Bearer ' + window._keycloak.token;
			});

			$rootScope.selectedMenu = $window.sessionStorage.getItem(SELECTED_MENU)
				? $window.sessionStorage.getItem(SELECTED_MENU)
				: 'gd-home';
			$rootScope.currentProject = AppSelectorService.getSelectedProjectLabel();
			$rootScope.currentProjectId = AppSelectorService.getSelectedProjectId();

			//put the token in Authorization header
			$http.defaults.headers.common.Authorization =
				'Bearer ' + window._keycloak.token;
			if ($rootScope.hasMemberRole) {
				initProject();
			} else if ($rootScope.hasProjectRole && !$rootScope.hasAdminRole) {
				$state.go('projects');
			} else if (!$rootScope.hasProjectRole && !$rootScope.hasAdminRole) {
				$state.go('main');
			}

			const langCode =
				window._keycloak.profile &&
				window._keycloak.profile.attributes &&
				window._keycloak.profile.attributes['locale'] &&
				window._keycloak.profile.attributes['locale'][0]
					? window._keycloak.profile.attributes['locale'][0]
					: 'fr';

			let data = {
				login: window._keycloak.idTokenParsed.name,
				preferredUsername: window._keycloak.idTokenParsed.preferred_username,
				lang: {
					code: langCode,
				},
			};
			$rootScope.account = {
				login: data.login,
				lang: data.lang,
				userId: window._keycloak.idTokenParsed.sub,
				preferredUsername: data.preferredUsername,
			};
			$window.localStorage.setItem(LOGIN_NAME, data.login);
			$window.localStorage.setItem(LANG_NAME, langCode);

			setDefaultLanguage(langCode);

			// set last login date
			setLastLoginDate();

			//start session expiration counter
			getSessionExpirationDelay();

			// start inactivity watcher
			// startIdleWatch();

			//connect on web socket
			connectOnWebSocket();

			//load user permissions
			// loadUserPermissions();

			//load cache active status
			loadCacheActiveStatus();

			// load common translate
			loadCommonTra();

			//load job types
			initJobTypes();

			// load timezones
			initAvailableTimezones();

			// load date languages
			initAvailableDateLanguages();

			// load date pattern mapping
			initDatePatternMappings();

			// load default timezone
			initDefaultTimezone();
		}

		function loadCommonTra() {
			const translations = InitializationService.initCommonTranslations();
			$rootScope.dataGirdCaptionsTra = translations.dataGirdCaptions;
			$rootScope.errorTra = translations.errorTra;
			$rootScope.permissionsActionLabelsTrans =
				translations.permissionsActionLabels;
			$rootScope.simpleTypesTra = translations.simpleTypes;
			for (let key in translations.various) {
				$rootScope[key] = translations.various[key];
			}
		}

		function initProject() {
			$rootScope.currentProject = AppSelectorService.getSelectedProjectLabel();
			$rootScope.currentProjectId = AppSelectorService.getSelectedProjectId();

			if (!$rootScope.currentProjectId) {
				ProjectService.getDefaultProject().then(function (response) {
					if (response.data === '') {
						AppSelectorService.clearProjectSelection();
					} else {
						AppSelectorService.setSelectedProject(
							response.data.id,
							response.data.metadata.label
						).then(function () {
							$state.go('projects');
						});
					}
				});
			}
		}

		function connectOnWebSocket() {
			$rootScope.$broadcast('loader_show');

			var socket = new SockJS(
				`${API_BASE_URL_BACKEND}${getUrl('STOMP_END_POINT')}`,
				null,
				{ timeout: 15000 }
			);

			$rootScope.stompClient = Stomp.over(socket);
			$rootScope.stompClient.debug = null;
			$rootScope.stompClient.connect(
				{ Authorization: `Bearer ${window._keycloak.token}` },
				function (frame) {
					$rootScope.$broadcast('socketInit', undefined);

					$rootScope.stompClient.subscribe(
						WEB_SOCKET_TOPICS.user_replay,
						function (message) {
							$log.debug(message);
						}
					);

					//for all users and all contexts :
					$rootScope.stompClient.subscribe(
						WEB_SOCKET_TOPICS.global_running_jobs,
						function (message) {
							$rootScope.$broadcast('running', JSON.parse(message.body));
						}
					);
					//for all users and by context : update frame status after (un)persist/(un)cache
					$rootScope.stompClient.subscribe(
						WEB_SOCKET_TOPICS.global_jobs,
						function (message) {
							broadcastMessage(message, 'job_global');
						}
					);
					//for all users update context status
					$rootScope.stompClient.subscribe(
						WEB_SOCKET_TOPICS.global_context,
						function (message) {
							broadcastMessage(message, 'context_status_update');
						}
					);
					//only for private user
					$rootScope.stompClient.subscribe(
						WEB_SOCKET_TOPICS.user_private_job,
						function (message) {
							broadcastMessage(message, 'job_private');
						}
					);

					//only for private user
					$rootScope.stompClient.subscribe(
						WEB_SOCKET_TOPICS.user_import_from_database_progress,
						function (message) {
							$rootScope.$broadcast(
								'import_db_progress',
								JSON.parse(message.body)
							);
						}
					);

					$rootScope.stompClient.subscribe(
						WEB_SOCKET_TOPICS.global_datablock,
						function (message) {
							broadcastMessage(message, 'datablocks');
						}
					);

					$rootScope.stompClient.subscribe(
						WEB_SOCKET_TOPICS.global_export_datablock,
						function (message) {
							$rootScope.$broadcast(
								'exportJobFinished',
								JSON.parse(message.body)
							);
						}
					);

					$rootScope.stompClient.subscribe(
						WEB_SOCKET_TOPICS.global_expose_datablock,
						function (message) {
							$rootScope.$broadcast(
								'exposeJobFinished',
								JSON.parse(message.body)
							);
						}
					);

					$rootScope.stompClient.subscribe(
						WEB_SOCKET_TOPICS.global_running_jobs,
						function (message) {
							$rootScope.$broadcast(
								'globalRunningJobs',
								JSON.parse(message.body)
							);
						}
					);

					$rootScope.stompClient.subscribe(
						WEB_SOCKET_TOPICS.project_bulk_execution,
						function (message) {
							$rootScope.$broadcast('bulk_status', JSON.parse(message.body));
						}
					);
					$rootScope.$broadcast('loader_hide');
				},
				function () {
					$log.debug('Websocket connection failed');
					retryConnectToWebSocket();
				}
			);

			$rootScope.stompClient.ws.onclose = function () {
				$log.debug('Websocket connection closed');
				retryConnectToWebSocket();
			};
		}

		function disconnectFromSocket() {
			$log.debug('disconnecting from socket');
			if ($rootScope.stompClient) {
				$rootScope.stompClient.disconnect();
			}
		}

		function retryConnectToWebSocket() {
			$log.debug('Retry Web socket connection');
			if (!isLoggedIn()) {
				$log.debug('Not logged in => stop connection retry');
				executeOnLogout(true);
				return;
			}

			$log.debug(
				' Reconnect to WebSocket ' + ' after ' + retryWebSocketConnectDelay
			);

			setTimeout(function () {
				connectOnWebSocket();
			}, retryWebSocketConnectDelay);
		}

		function setLastLoginDate() {
			UserService.getBeforeLastLoginDate().then(function (response) {
				if (response.data) {
					let date = moment(response.data);
					$rootScope.lastLoginDate = date.format('LLLL');
				}
			});
		}

		function getSessionExpirationDelay() {
			$rootScope.visibleSessionDelayAlertPopup = false;
			$rootScope.visibleSessionDelayLastAlertPopup = false;
			UserService.getSessionExpirationDelay().then(function (response) {
				if (response.data) {
					$rootScope.sessionExpirationDelay = response.data;
					if (
						$rootScope.sessionExpirationDelay <=
						$rootScope.sessionExpirationLastAlertDelay
					) {
						showSessionExpirationLastAlert();
					} else if (
						$rootScope.sessionExpirationDelay <=
						$rootScope.sessionExpirationFirstAlertDelay
					) {
						showSessionExpirationFirstAlert();
					} else {
						$timeout(function () {
							showSessionExpirationFirstAlert();
						}, ($rootScope.sessionExpirationDelay -
							$rootScope.sessionExpirationFirstAlertDelay) *
							1000);
					}
				}
			});
		}

		function showSessionExpirationFirstAlert() {
			$rootScope.visibleSessionDelayAlertPopup = true;
			$timeout(function () {
				showSessionExpirationLastAlert();
			}, ($rootScope.sessionExpirationFirstAlertDelay -
				$rootScope.sessionExpirationLastAlertDelay) *
				1000);
		}

		function showSessionExpirationLastAlert() {
			$rootScope.visibleSessionDelayAlertPopup = false;
			$rootScope.visibleSessionDelayLastAlertPopup = true;
		}

		function getLastLoginDate() {
			return $rootScope.lastLoginDate;
		}

		function broadcastMessage(message, topic) {
			var messageData = JSON.parse(message.body);
			if (
				messageData.action === 'NEW_AUTHENTIFACTION_DETECTED' &&
				messageData.data.token == window._keycloak.token
			) {
				$rootScope.newAuthenticationDetected = true;
				executeOnLogout(true);
			} else if (messageData.action === 'LOGOUT_BY_ADMIN') {
				$rootScope.logoutByAdmin = true;
				executeOnLogout(true);
			}
			// FIXME: attempting to change to a triple equal will break the fetch from job
			//  queue mechanism; need to store context as a number in the session storage
			//  instead of a string, before switching to a triple equal comparison
			else if (
				$window.sessionStorage.getItem(CTX_VAR_NAME) ==
					messageData.context_id ||
				(messageData.shared_files_system &&
					(messageData.type === 'PersistFrame' ||
						messageData.type === 'UnPersistFrame'))
			) {
				$rootScope.$broadcast(topic, messageData);
			}
		}

		// FIXME: probablement plus de sens, d'une epoqque ou datachain gerait les permission aux niveaux des pages du front
		function permitAccess(access) {
			if (access === '*') {
				return true;
			}
			if (access.indexOf('member') > -1 && $rootScope.hasMemberRole) {
				return true;
			}
			if (access.indexOf('admin') > -1 && $rootScope.hasAdminRole) {
				return true;
			}
			if (access.indexOf('project') > -1 && $rootScope.hasProjectRole) {
				return true;
			}
			return false;
		}

		function handleInvalidToken() {
			$state.go('logged');
		}

		function checkError(fieldName) {
			return checkErrorBoolean(fieldName) ? 'has-error' : '';
		}

		function checkErrorBoolean(fieldName) {
			var isError = false;
			if ($rootScope.fields && $rootScope.fields.length > 0) {
				isError = contains($rootScope.fields, fieldName);
			}
			return isError;
		}

		function getDateWithPattern(date) {
			if (!date) {
				return '';
			}
			return $filter('date')(date, DATE_PATTERN);
		}

		function getDateTimeWithPattern(date) {
			if (!date) {
				return '';
			}
			return $filter('date')(date, DATE_TIME_PATTERN);
		}

		function getDateTimeWithPatternMn(date) {
			if (!date) {
				return '';
			}
			return $filter('date')(date, DATE_TIME_PATTERN_MN);
		}

		function getFormattedDateWithPattern(date, pattern) {
			if (!date) {
				return '';
			}
			return $filter('date')(date, pattern ? pattern : DATE_TIME_PATTERN);
		}

		function _logout() {
			window._keycloak.logout();
			deleteLocalStorage();
		}

		function deleteLocalStorage(e) {
			$window.localStorage.clear();
			$window.sessionStorage.clear();
		}

		function initEvents() {
			$rootScope.$on('IdleTimeout', function () {
				$log.error('Inactivity Max Delay ==> logout');
				$rootScope.visibleInactivityAlertPopup = false;
				angular.element('.inmodal').hide();
				executeOnLogout();
			});

			$transitions.onSuccess({}, function (transition) {
				$rootScope.$broadcast(
					'state:transition:success',
					transition.to().data['title']
				);
			});

			$transitions.onStart({}, function (transition) {
				if (!permitAccess(transition.to().data.access)) {
					event.preventDefault();
					$state.go('projects');
				}
			});

			// FIXME: infinite loop
			// $rootScope.$on('IdleStart', function() {
			//     $rootScope.inactivityCountdown = 60;
			//     $rootScope.visibleInactivityAlertPopup = true;
			//     downInactivityCount();
			//     $log.error('Inactivity Max Delay ==> alert')
			// });

			$rootScope.$on('IdleEnd', function () {
				closeInactivityAlert();
			});
		}

		function initVariables() {
			const devExtremeConfigs = InitializationService.initDevExtremeConfigs();
			$rootScope.sessionDelayAlertPopupOptions =
				devExtremeConfigs.sessionDelayAlertPopupOptions;
			$rootScope.sessionDelayLastAlertPopupOptions =
				devExtremeConfigs.sessionDelayLastAlertPopupOptions;
			$rootScope.inactivityAlertPopupOptions =
				devExtremeConfigs.inactivityAlertPopupOptions;
		}

		function initRoles() {
			$rootScope.keycloak = window._keycloak;
			$rootScope.hasUserRole = $rootScope.keycloak.hasResourceRole(
				USER_ROLE_NAME,
				BACKEND_CLIENT_ID
			);
			$rootScope.hasMemberRole = $rootScope.keycloak.hasResourceRole(
				MEMBER_ROLE_NAME,
				BACKEND_CLIENT_ID
			);
			$rootScope.hasProjectRole = $rootScope.keycloak.hasResourceRole(
				PROJECT_ROLE_NAME,
				BACKEND_CLIENT_ID
			);
			$rootScope.hasAdminRole = $rootScope.keycloak.hasResourceRole(
				ADMIN_ROLE_NAME,
				BACKEND_CLIENT_ID
			);
		}

		function initJobTypes() {
			// get JOb paths
			InitializationService.initJobTypes().then(function () {
				$rootScope.jobTypes = InitializationService.getJobTypes();
			});
		}

		function initAvailableTimezones() {
			// get timezones
			InitializationService.initAvailableTimezones().then(function () {
				$rootScope.availableTimezones =
					InitializationService.getAvailableTimezones();
			});
		}

		function initAvailableDateLanguages() {
			// get timezones
			InitializationService.initAvailableDateLanguages().then(function () {
				$rootScope.availableDateLanguages =
					InitializationService.getAvailableDateLanguages();
			});
		}

		function initDatePatternMappings() {
			// get timezones
			InitializationService.initDatePatternMappings().then(function () {
				datePatternMappings = InitializationService.getDatePatternMappings();
			});
		}

		function initDefaultTimezone() {
			InitializationService.initDefaultTimezone().then(function () {
				defaultTZ = InitializationService.getDefaultTimezone();
			});
		}

		function getDefaultTimezone() {
			return defaultTZ ? defaultTZ : moment.tz.guess();
		}

		/**
					Map AngularJS date pattern to MomentJs Patterns
		 **/
		function getCorrespondentDatePattern(angularPattern) {
			return datePatternMappings.hasOwnProperty(angularPattern)
				? datePatternMappings[angularPattern]
				: angularPattern;
		}

		function getRunningJob(type) {
			if (!type) {
				return;
			}
			let jobType = _.find($rootScope.jobTypes, function (elm) {
				return elm.code === type;
			});
			return jobType ? jobType.value : type;
		}

		function mapTags(tags) {
			if (tags && tags.length <= 0) {
				return null;
			}

			return _.map(tags, function (item) {
				return item.code;
			});
		}
	}
})();
