(function () {
	'use strict';

	ImportProjectCmpController.$inject = [
		'$scope',
		'ProjectService',
		'DataBlocksService',
		'$rootScope',
		'$stateParams',
		'$state',
		'validateLength',
		'ContextServices',
		'AppSelectorService',
		'elem',
		'API_BASE_URL_BACKEND',
		'gettextCatalog',
		'toaster',
		'importStatus',
		'APP_VERSION',
		'UserService',
		'PROJECT_PROGRESS_OPERATION',
		'PROGRESS_STATUS',
		'PROJECT_OPERATION_DONE',
	];
	function ImportProjectCmpController(
		$scope,
		ProjectService,
		DataBlocksService,
		$rootScope,
		$stateParams,
		$state,
		validateLength,
		ContextServices,
		AppSelectorService,
		elem,
		API_BASE_URL_BACKEND,
		gettextCatalog,
		toaster,
		importStatus,
		APP_VERSION,
		UserService,
		PROJECT_PROGRESS_OPERATION,
		PROGRESS_STATUS,
		PROJECT_OPERATION_DONE
	) {
		const vm = this;
		onInit();

		let execId;
		vm.descriptionTruncateThresh = 200;
		vm.descriptionTitleTruncateThresh = 400;
		vm.MAXLENGTH = validateLength.maxLength;
		vm.MINLENGTH = validateLength.minLength;
		vm.elem = elem;
		vm.closeModal = closeModal;
		vm.projectInfos = {};
		vm.exportProjectInfos = {};
		vm.duplicatedProjectInfos = {};
		vm.confirmationData = {};
		vm.progress = {};
		vm.from = 'import';
		vm.projectStats = {};
		vm.local = true;
		vm.userExist = false;
		vm.users = [];
		vm.running = importStatus.running;
		vm.APP_VERSION = APP_VERSION;
		vm.importInProgress = false;
		vm.workDone = false;
		vm.files = {};
		vm.uploadDone = false;
		vm.importAnalysisOperation = PROJECT_PROGRESS_OPERATION.import_analysis;
		vm.importOperation = PROJECT_PROGRESS_OPERATION.import;
		vm.importProject = importProject;
		vm.cancelImport = cancelImport;
		vm.submitFlow = submitFlow;
		vm.uploadError = uploadError;
		vm.gotToNewProject = gotToNewProject;
		vm.handleUploadResponse = handleUploadResponse;

		function onInit() {
			getContexts();
			UserService.getProjectMembers().then(function (response) {
				vm.users = response;
			});
		}

		function importProject() {
			let options = {
				folder_path: vm.projectToImportInfos.projectFolerPath,
				import_permission_template: vm.importedProjectInfos.templateRightValue,
				metadata: {
					label: vm.importedProjectInfos.importedProjectLabel,
					description: vm.projectToImportInfos.description,
					expiration_date: Date.parse(
						vm.importedProjectInfos.importExpirationDate
					),
					effect_date: Date.parse(vm.importedProjectInfos.importedEffectDate),
					contexts: vm.importedProjectInfos.contexts,
				},
				membership:
					vm.projectToImportInfos.members.length === 0
						? { new_members: vm.members }
						: { member_mappings: vm.members },
				elements: {
					connector: vm.elementTypes.connector.checked,
					export_template: vm.elementTypes.exportTemplate.checked,
					depot: vm.elementTypes.repository.checked,
					entity: vm.elementTypes.entity.checked,
					datablock: vm.elementTypes.datablock.checked,
					hd_element: vm.elementTypes.hdElements.checked,
					dashboard: vm.elementTypes.dashboard.checked,
					extraction: vm.elementTypes.extractRepository.checked,
					local_data: vm.elementTypes.localData.checked,
				},
				data_exposition: {
					datablocks: vm.projectToImportInfos.data_exposition
						? vm.projectToImportInfos.data_exposition
						: [],
				},
			};

			vm.progress = {
				countOps: 12,
				projectLabel: fileName,
				operation: vm.importOperation,
				progressBarInitValue: null,
				to: vm.importedProjectInfos.importedProjectLabel,
				running: true,
			};
			$rootScope.$broadcast('bulk_status_progress', vm.progress);
			vm.importInProgress = true;

			ProjectService.importProject(options)
				.then(function (responseExp) {
					execId = responseExp.data;
					vm.progress.progressBarInitValue = 0;
					vm.progress.execId = execId;
					$rootScope.$broadcast('bulk_status_progress', vm.progress);
				})
				.catch(function (error) {
					vm.importInProgress = false;
				});
		}

		function getContexts() {
			ContextServices.getContextList(true).then(function (response) {
				delete $scope.currentContext;
				if (response.data) {
					$scope.contextList = response.data;
					delete $scope.currentContext;
					if (AppSelectorService.getSelectedCtxId()) {
						for (var ctx in $scope.contextList) {
							// FIXME: possible string to number comparison
							if (
								$scope.contextList[ctx].id ==
								AppSelectorService.getSelectedCtxId()
							) {
								$scope.currentContext = $scope.contextList[ctx];
								break;
							}
						}
					}
					if (!$scope.currentContext) {
						$scope.currentContext = $scope.contextList[0];
					}

					if ($scope.currentContext) {
						setCurrentContextInLocalStorage();
					}
					if ($scope.currentContext) {
						setCurrentContextInLocalStorage();
					}
				}
			});
		}

		function setCurrentContextInLocalStorage() {
			AppSelectorService.setSelectedContext($scope.currentContext.id);
		}

		let fileName;
		function submitFlow(files, event, flow) {
			flow.opts.headers = {
				Authorization: 'Bearer ' + window._keycloak.token,
				"Accept-Language": $rootScope.currentLangCode,
			};
			flow.opts.target =
				API_BASE_URL_BACKEND + $rootScope.getUrl('PROJECTS_IMPORT_UPLOAD');
			flow.upload();
			fileName = files && files[0] ? files[0].name : '-';
			vm.progress = {
				countOps: 1,
				projectLabel: fileName,
				operation: vm.importAnalysisOperation,
				progressBarInitValue: null,
			};
			$rootScope.$broadcast('bulk_status_progress', vm.progress);
			vm.importInProgress = true;
		}

		function uploadError(file, response, flow) {
			let data = JSON.parse(response);
			let error =
				data.field_errors != undefined && data.field_errors.length > 0
					? data.field_errors[0]
					: data;
			let msgHeader = gettextCatalog.getString('Erreur');
			if (error.field != undefined) {
				angular.forEach(vm.files.flow.files, function (fl) {
					fl.cancel();
				});
			}
			$rootScope.$broadcast('bulk_status', {
				status: PROGRESS_STATUS.failed,
			});
			vm.uploadDone = false;
		}

		let responseImportId;
		function handleUploadResponse(flow, file, response) {
			let data = JSON.parse(response);
			if (!data.valid || !data.valid_version) {
				$rootScope.$broadcast('bulk_status', {
					status: PROGRESS_STATUS.failed_version,
					version: data.version,
				});
			} else {
				vm.projectToImportInfos = {
					label: data.source_metadata.label,
					description: data.source_metadata.description,
					code: data.source_metadata.code,
					iconId: data.source_metadata.icon_id,
					tags: data.source_metadata.tags,
					version: data.version,
					exportDate: moment(data.export_date).format(
						'DD/MM/YYYY [à] HH:MM:SS'
					),
					members: data.members.users.concat(data.members.groups),
					containsDepot: data.contains_depot_data,
					containsPermission: data.contains_permission_template,
					projectFolerPath: data.project_folder_path,
					validVersion: data.valid_version,
					validFileExtension: data.valid_file_extension,
					depotFilesFolderPath: data.depot_files_folder_path,
					data_exposition: {
						datablocks: data.data_block_expositions
							? data.data_block_expositions
							: [],
					},
				};

				vm.expositions = vm.projectToImportInfos.data_exposition.datablocks;
				vm.isExposed = checkExpositionStatus(vm.expositions);
				vm.projectStats = {
					connector: { count: data.connector_count || 0 },
					dashboard: { count: data.dashboard_count || 0 },
					datablock: { count: data.data_block_count || 0 },
					repository: { count: data.depot_count || 0 },
					exportTemplate: { count: data.export_template_count || 0 },
					entity: { count: data.entity_count || 0 },
					hdElements: { count: data.hd_element_count || 0 },
				};
				vm.uploadDone = true;
				vm.importInProgress = false;
			}
		}

		function closeModal(doNotRefresh) {
			vm.projectInfos = {};
			vm.duplicatedProjectInfos = {};
			vm.importedProjectInfos = {};
			vm.elementTypes = {};
			vm.projectStats = {};
			vm.local = true;
			vm.userExist = false;
			vm.users = [];
			vm.data = false;
			vm.progress = {
				countOps: 1,
				projectLabel: fileName,
				operation: vm.importAnalysisOperation,
				progressBarInitValue: null,
			};
			$rootScope.$broadcast('bulk_status_progress', vm.progress);
			vm.importInProgress = false;
			vm.uploadDone = false;
			if (vm.files && vm.files.flow.files) {
				angular.forEach(vm.files.flow.files, function (fl) {
					fl.cancel();
				});
			}
			if (!doNotRefresh && vm.workDone) {
				$rootScope.$broadcast('refresh_projects_list');
			}
			vm.workDone = false;
			$(vm.element).modal('hide');
		}

		vm.doNotDuplicateExposures = function () {
			vm.duplicateExpose = vm.duplicateStatus.status.doNotduplicate;
		};

		vm.duplicateExposures = function () {
			vm.duplicateExpose = vm.duplicateStatus.status.duplicate;
		};

		vm.showCancelConfirmation = function () {
			vm.confirmationData.confirmationDialogTitle =
				gettextCatalog.getString('cancel.import');
			vm.confirmationData.confirmationDialogMessage = gettextCatalog.getString(
				'import.cancel.message'
			);
			vm.confirmationData.confirmationDialogActionName =
				gettextCatalog.getString('yes.cancel.action');
			vm.confirmationData.cancelDialogActionName =
				gettextCatalog.getString('no.cancel.action');
			vm.confirmationData.confirmationGreenAction = false;
			vm.confirmationData.enableConfirmDialogAction = true;
			vm.confirmationData.showConfirmationDialog = true;
			vm.confirmationData.enableConfirmDialogAction = true;
			vm.confirmationData.confirmDialogAction = vm.cancelImport;
			vm.confirmationData.showConfirmationDialog = true;
		};

		/**
		 *Private functions
		 */
		function cancelImport() {
			if (!vm.workDone && vm.projectId && execId) {
				ProjectService.cancelImportProject(vm.projectId, execId).then(
					function () {
						closeModal();
					}
				);
			} else {
				closeModal();
			}
		}

		function checkExpositionStatus(expositions) {
			return expositions.length > 0;
		}

		function gotToNewProject() {
			if (vm.workDone && vm.newProjectId) {
				closeModal(true);
				$state.go('projects-edit', { projectId: vm.newProjectId });
			}
		}

		$scope.$on(PROJECT_OPERATION_DONE.import, function (event, data) {
			vm.workDone = true;
			vm.newProjectId = data;
		});
	}

	// const cmpConfig = {
	// 	restrict: 'E',
	// 	bindings: {
	// 		data: '<',
	// 	},
	// 	controller: ImportProjectCmpController,
	// 	templateUrl:
	// 		'./src/components/directives/importProject/importProjectModal.html',
	// 	controllerAs: 'vm',
	// };

	angular
		.module('ProjectModule')
		.controller('ImportProjectController', ImportProjectCmpController);
})();
