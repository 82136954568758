(function () {
	'use strict';

	angular.module('dcApp').controller('LinkGraphController', [
		'$scope',
		'$state',
		'$stateParams',
		'GenericService',
		'TypeEntiteService',
		'CaracService',
		'$rootScope',
		'$http',
		'$window',
		'toaster',
		'TagService',
		'API_BASE_URL_BACKEND',
		'DataBlocksService',
		'gettextCatalog',
		function (
			$scope,
			$state,
			$stateParams,
			GenericService,
			TypeEntiteService,
			CaracService,
			$rootScope,
			$http,
			$window,
			toaster,
			TagService,
			API_BASE_URL_BACKEND,
			DataBlocksService,
			gettextCatalog
		) {
			let toasterFilterTag = {};
			toasterFilterTag.all = gettextCatalog.getString('Tout');
			toasterFilterTag.yes = gettextCatalog.getString('Oui');
			toasterFilterTag.no = gettextCatalog.getString('Non');

			let network;

			$scope.execEntiteCountWithoutCache = function () {
				execEntiteStats(true);
			};

			$scope.showHdhGraph = function (node) {
				$scope.elementGraphData.centeredElementLabel = node.lib_court;
				$scope.elementGraphData.centeredElementType = 'ENTITETYPE';
				$scope.elementGraphData.centeredElementId = node.id;
				$scope.elementGraphData.showHdhElementGraph = true;
			};

			$scope.caracIsSelected = function (caracId) {
				if ($scope.selectedCarac && caracId == $scope.selectedCarac.id) {
					return true;
				}
			};

			$scope.unselectCarac = function () {
				delete $scope.selectedCarac;
			};

			$scope.selectCarac = function (carac) {
				$scope.selectedCarac = carac;
				if ($scope.selectedCarac.complex) {
					TypeEntiteService.getCaracs($scope.selectedCarac.id_type).then(
						function (response) {
							let map = [];
							for (let carac in response.data) {
								map.push({ v1: response.data[carac].id, v2: 'c' });
							}
							GenericService.getElementaryData(map).then(function (response) {
								$scope.simpleCaracs = [];
								for (let carac in response.data) {
									if (!response.data[carac].complex) {
										$scope.simpleCaracs.push(response.data[carac]);
									}
								}
							});
						}
					);
				} else {
					changeSelectedCarac($scope.selectedCarac.id);
				}
			};

			$scope.activatePanel = function () {
				$scope.showPanel = true;
				if ($scope.showPanel && $scope.selectNodeP) {
					$scope.selectNode($scope.selectNodeP);
				}
			};

			$scope.desactivatePanel = function () {
				$scope.showPanel = false;
			};

			$scope.setSimpleAndComplexCaracs = function () {
				$scope.simpleCaracs = _.filter($scope.caracs, function (item) {
					return item.complex == false;
				});
				$scope.complexCaracs = _.filter($scope.caracs, function (item) {
					return item.complex == true;
				});
			};

			$scope.selectNode = function (p) {
				$scope.datablocksLoaded = false;
				$scope.selectedNodeUpdate = p;
				delete $scope.selectedEdge;
				delete $scope.selectedCarac;

				if (p.nodes[0] === undefined && p.edges[0] !== undefined) {
					$scope.selectedEdge = p.edges[0];
					delete $scope.selectedNode;
					delete $scope.simpleCaracs;
					CaracService.getCaracsByEdge(p.edges[0]).then(function (response) {
						$scope.edgeCaracs = response.data;
						$scope.showPanel = true;
						$state.go('link-graph');
					});
				} else if (p.nodes[0] !== undefined) {
					$scope.selectedNode = undefined;
					$scope.selectedNode = p.nodes[0];

					let node = p.nodes[0];
					$state.go(
						'link-graph',
						{
							nodeId: node,
						},
						{
							location: true,
							inherit: true,
							relative: $state.$current,
							notify: false,
						}
					);

					let map = [];
					map.push({ v1: node, v2: 't' });

					GenericService.getElementaryData(map, true).then(function (response) {
						delete $scope.simpleCaracs;
						$scope.selectedNode = response.data[0];
						execEntiteStats();
					});
				}
			};

			$scope.loadLinkedDataBlocks = function () {
				if (!$scope.datablocksLoaded) {
					TypeEntiteService.getDataBlocksUsing($scope.selectedNode.id).then(
						function (response) {
							$scope.datablocksUsing = response.data;
							$scope.datablocksLoaded = true;
						}
					);
				}
			};

			$scope.editCaracLink = function (carac) {
				carac.lanCode = $rootScope.account.lang.code;
				$scope.linkCreatorData.entiteTypeSourceId = carac.parent_entite_type_id;
				$scope.linkCreatorData.entiteTypeSourceCode =
					carac.parent_entite_type_code;
				$scope.linkCreatorData.entiteTypeSourceLib =
					carac.parent_entite_type_lib;
				$scope.linkCreatorData.node = carac;
				$scope.linkCreatorData.node.entite_type = {};
				$scope.linkCreatorData.node.entite_type.id = carac.id_type;
				$scope.linkCreatorData.node.entite_type.code = carac.code_type;
				$scope.linkCreatorData.showLinkCreator = true;
			};

			$scope.editLink = function (carac) {
				carac.lanCode = $rootScope.account.lang.code;
				CaracService.getLibellesLang(carac.id).then(function (response) {
					$scope.linkCreatorData.entiteTypeSourceId = $scope.selectedNode.id;
					$scope.linkCreatorData.entiteTypeSourceCode =
						$scope.selectedNode.code;
					$scope.linkCreatorData.entiteTypeSourceLib =
						$scope.selectedNode.lib_court;
					$scope.linkCreatorData.node = carac;
					$scope.linkCreatorData.node.entite_type = {};

					if ($scope.selectedNode.id == carac.id_type) {
						$scope.linkCreatorData.node.entite_type.id =
							carac.parent_entite_type_id;
						$scope.linkCreatorData.node.entite_type.code =
							carac.parent_entite_type_code;
					} else {
						$scope.linkCreatorData.node.entite_type.id = carac.id_type;
						$scope.linkCreatorData.node.entite_type.code = carac.code_type;
					}

					$scope.linkCreatorData.showLinkCreator = true;
				});
			};

			$scope.showDeleteModal = function (modalId, entiteElement) {
				$scope.caracDataBlocks = [];
				DataBlocksService.getCaracUsage(
					$scope.selectedNode.id,
					entiteElement.id
				).then(function (response) {
					$scope.caracDataBlocks = response.data;
					$scope.elementToDelete = entiteElement;
					$('#' + modalId).modal('show');
				});
			};

			$scope.updateDataAfterLinkCreate = function () {
				$scope.getGraphe('', true);
			};

			$scope.getGraphe = function (searchText, selecteNode) {
				if ($scope.searchText && $scope.searchText.length == 1) {
					return;
				}
				let text;
				if (searchText) {
					text = searchText;
				} else {
					text = '';
				}
				const nodeId =
					$scope.entiteTypeDest.selected != undefined
						? $scope.entiteTypeDest.selected.id
						: undefined;
				let tagsList = _.map($scope.selectedTags, function (elm) {
					return elm.id;
				});
				let queryData = {
					label: true,
					desc: true,
					tag: true,
					text: text,
					tags: tagsList,
					entity_id: nodeId,
				};
				TypeEntiteService.getGraphe(
					queryData,
					$scope.showCurrentProjectOnly
				).then(function (response) {
					$scope.nodes = response.data;
					redrawAll($scope.nodes.nodes, $scope.nodes.edges);
					if (nodeId) {
						var selectedNodeExist = _.find($scope.nodes.nodes, function (elm) {
							return elm.id == nodeId;
						});
						if (selectedNodeExist !== undefined) {
							let node = {};
							node.nodes = [nodeId];
							$scope.selectNodeP = node;
							$scope.activatePanel();
							$scope.showPanel = true;
							$scope.selectNode(node);
							let pos = network.getPositions(node.nodes);
							let movetoObj = {
								position: pos[0],
								scale: network.getScale() * 2,
							};
							network.focus(String(nodeId), movetoObj);
							network.setSelection(node);
						}
					}

					if (selecteNode) {
						$scope.selectNode($scope.selectedNodeUpdate);
					}
				});
			};

			$scope.createLink = function () {
				$scope.modes = {
					caracMode: true,
					qualityMode: false,
				};
				$scope.newLinkType = 'tech';

				$scope.entiteTypeSourceId = $scope.selectedNode.id;
				$scope.entiteTypeSourceLib = $scope.selectedNode.lib_court;
				$scope.entiteTypeDestId = '';
				$scope.showLinkCreator = true;

				$scope.linkCreatorData.entiteTypeSourceId = $scope.entiteTypeSourceId;
				$scope.linkCreatorData.entiteTypeSourceLib = $scope.entiteTypeSourceLib;
				$scope.linkCreatorData.entiteTypeDestinationId =
					$scope.entiteTypeDestId;
				$scope.linkCreatorData.showLinkCreator = true;
			};

			$scope.getFiltredNodes = function (nodes) {
				return nodes;
			};

			// Refresh graph after addin new uet
			$scope.addNewUetAndRefreshGraph = function (id, lib) {
				if (_.find($scope.nodes.nodes, { id: id }) === undefined) {
					let uet = { id: id, label: lib, title: lib, group: id, value: id };
					$scope.nodes.nodes.push(uet);
					redrawAll($scope.nodes.nodes, $scope.nodes.edges);
					TypeEntiteService.getAllTypes().then(function (response) {
						$scope.types = response.data;
					});
				}
			};

			$scope.showMapping = function () {
				let entite = $scope.selectedNode;
				let entiteTypeId = entite.id;
				$scope.mappingScope.mappingEnititeTypeId = entiteTypeId;
				$scope.mappingScope.showMappingFlag = true;
				$scope.mappingScope.disableEntiteType =
					entiteTypeId != null ? true : false;
			};

			$scope.goToNewEntiteType = function () {
				$state.go('entites-new', {
					fromGraphe: true,
				});
			};

			$scope.goToNewFileSource = function () {
				$state.go('files-new', {
					fromGraphe: true,
				});
			};

			$scope.showData = function (entitetypeId) {
				$scope.dataPreviewConf.elementId = entitetypeId;
				$scope.dataPreviewConf.elementLabel = $scope.selectedNode.lib_court;
				$scope.dataPreviewConf.show = true;
			};

			$scope.exportHdh = function (row) {
				$scope.exportData = {};
				$scope.exportData.entiteTypeId = row.id;
				$scope.exportData.entiteTypeLib = row.lib_court;
				$scope.exportData.entiteTypeCode = row.code;
				$scope.exportData.showExportHdh = true;
			};

			$scope.clearTagsFilter = function () {
				$scope.selectedTags = [];
				$scope.getGraphe($scope.searchText);
				$window.sessionStorage.setItem(
					'tags',
					JSON.stringify($scope.selectedTags)
				);
			};

			$scope.showTagFiltersModal = function (modalId) {
				$scope.initialTags = $scope.tags;
				for (let i in $scope.initialTags) {
					$scope.initialTags[i].selected =
						_.find($scope.selectedTags, function (elm) {
							return elm.id == $scope.initialTags[i].id;
						}) != undefined;
				}

				$scope.filteredTags = $scope.initialTags;
				let selectedElms = _.filter($scope.initialTags, function (el) {
					return el.selected === true;
				});
				$scope.allTagsSelected =
					selectedElms.length == $scope.initialTags.length;
				$('#' + modalId).modal('show');
			};

			$scope.checkIfAllSelected = function (tag) {
				for (let i in $scope.initialTags) {
					if (tag.id == $scope.initialTags[i].id) {
						$scope.initialTags[i].selected = tag.selected;
						break;
					}
				}
				let selectedElms = _.filter($scope.initialTags, function (el) {
					return el.selected === true;
				});
				$scope.allTagsSelected =
					selectedElms.length == $scope.initialTags.length;
				$scope.filterTagsList();
			};

			$scope.selectAllTags = function () {
				for (let i in $scope.initialTags) {
					$scope.initialTags[i].selected = $scope.allTagsSelected;
				}

				$scope.filterTagsList();
			};

			$scope.filterTagsList = function () {
				let filteredList = $scope.initialTags;
				let selected = $scope.selectedFilters == 'true' ? true : false;
				if (
					$scope.selectedFilters != undefined &&
					$scope.selectedFilters != ''
				) {
					filteredList = _.filter($scope.initialTags, function (el) {
						return el.selected == selected;
					});
				}

				if (
					$scope.searchTagsFilter != undefined &&
					$scope.searchTagsFilter != ''
				) {
					filteredList = _.filter(filteredList, function (el) {
						return (
							el.code &&
							_.contains(
								el.code.toLowerCase(),
								$scope.searchTagsFilter.toLowerCase()
							)
						);
					});
				}

				$scope.filteredTags = filteredList;
			};

			$scope.saveTagsFilter = function (modalId) {
				$scope.selectedTags = _.filter($scope.filteredTags, function (el) {
					return el.selected == true;
				});
				$window.sessionStorage.setItem(
					'tags',
					JSON.stringify($scope.selectedTags)
				);
				$('#' + modalId).modal('hide');
			};

			$scope.reloadGraph = function () {
				$scope.getGraphe($scope.searchText);
			};

			init();

			function init() {
				$scope.searchText = '';
				$scope.searchPoids = 0;
				$scope.reloadDataConfirmation = {};
				$scope.selectedTags = [];
				$scope.tags = [];
				$scope.elementGraphData = {};
				$scope.showCurrentProjectOnly = true;
				$scope.showPanel = false;
				$scope.deleteElementData = {};
				$scope.deleteCaracFromElementData = {};
				$scope.disableDeleteCarac = true;
				$scope.linkCreatorData = {};
				$scope.mappingScope = {
					showMappingFlag: false,
					mappingEnititeTypeId: null,
					mappingSourceId: null,
					disableDataSource: false,
					disableEntiteType: false,
					saveAction: $scope.addNewUetAndRefreshGraph,
				};
				$scope.widgetData = {};
				$scope.dataPreviewConf = {};
				$scope.filterTagChoices = [
					{ label: toasterFilterTag.all, value: '' },
					{ label: toasterFilterTag.yes, value: true },
					{ label: toasterFilterTag.no, value: false },
				];
				$scope.entiteTypeDest = {};

				getTags();
				$scope.getGraphe();

				TypeEntiteService.getAllTypes().then(function (response) {
					$scope.types = response.data;
				});

				$scope.jobDetails = { rerunMethod: $scope.execEntiteCountWithoutCache };
				$scope.jobDetailsId = generateUuid('_');
			}

			function getTags() {
				TagService.getTags('').then(function (response) {
					$scope.tags = response.data;
					$scope.selectedTags = JSON.parse(
						$window.sessionStorage.getItem('tags')
					);
					$scope.selectedTags =
						$scope.selectedTags != undefined && $scope.selectedTags != null
							? $scope.selectedTags
							: [];
				});
			}

			function redrawAll(nod, edg) {
				// remove positoins
				for (let i = 0; i < nod.length; i++) {
					delete nod[i].x;
					delete nod[i].y;
				}
				// create a network
				let container = document.getElementById('entites');
				for (let n in edg) {
					edg[n]['shape'] = 'dot';
				}

				let data = {
					nodes: nod,
					edges: edg,
				};
				let options = {
					groups: {
						1: {
							color: {
								background: '#7AC2DF',
								border: '#7AC2DF',
							},
						},
						0: {
							color: {
								background: '#C4CACC',
								border: '#C4CACC',
							},
						},
					},
					nodes: {
						shape: 'dot',
						size: 200,
						scaling: {
							min: 10,
							max: 30,
						},
						font: {
							size: 18,
							face: 'Arial',
						},
					},
					edges: {
						width: 0.15,
						color: {
							inherit: 'from',
						},
						smooth: {
							type: 'continuous',
						},
					},
					physics: {
						enabled: true,
						stabilization: true,
						barnesHut: {
							avoidOverlap: 1,
							gravitationalConstant: -5000,
							springConstant: 0.001,
							springLength: 90,
						},
					},

					interaction: {
						navigationButtons: false,
						tooltipDelay: 400,
						hideEdgesOnDrag: true,
						keyboard: {
							enabled: false,
							speed: {
								zoom: 0.002,
							},
							bindToWindow: true,
						},
					},
				};

				network = new vis.Network(container, data, options);

				network.on('select', function (p) {
					if (!p || (!p.edges[0] && !p.nodes[0])) {
						delete $scope.selectNodeP;
						delete $scope.showPanel;
						return;
					}
					$scope.selectNodeP = p;
					if (!$scope.showPanel) {
						return;
					}
					$scope.selectNode(p);
				});
			}

			function changeSelectedCarac(id) {
				$scope.widgetData.caracInfoId = id;
				$scope.widgetData.caracInfoEntiteId = $scope.selectedNode.id;
				$scope.widgetData.caracInfoEntiteLabel = $scope.selectedNode.lib_court;
				$scope.widgetData.showColumnStat = true;
			}

			function execEntiteStats(noCache) {
				$scope.jobDetails.init();
				let node = $scope.selectedNode.id;
				TypeEntiteService.getNbrEntities(node, noCache).then(function (
					response
				) {
					$scope.entitiesCount = response.data.data[0][0];
					$scope.jobDetails.update({
						name: gettextCatalog.getString('Compte entité'),
						pushDate: response.data.push_date,
						endExecutionDate: response.data.exec_end_date,
						duration: response.data.time_exec,
					});
					$scope.jobDetails.setFromCache(response.data.is_cached);

					TypeEntiteService.getNbrValuesPerCarac(node, noCache).then(function (
						responseCarac
					) {
						$scope.jobDetails.update({
							name: gettextCatalog.getString('Compte Caractéristique'),
							pushDate: responseCarac.data.push_date,
							endExecutionDate: responseCarac.data.exec_end_date,
							duration: responseCarac.data.time_exec,
						});
						$scope.caracs_ids = responseCarac.data.data;
						let caracs = [];
						CaracService.getCaracElementaryDataByUetId(node).then(function (
							response
						) {
							$scope.caracs = response.data;
							for (let carac in $scope.caracs) {
								let c = {};
								angular.copy($scope.caracs[carac], c);
								c.nbr = $scope.caracs_ids[$scope.caracs[carac].id];

								if (c.nbr == undefined) {
									c.nbr = 0;
								}
								caracs.push(c);
							}
							$scope.caracs = caracs;
							$scope.setSimpleAndComplexCaracs();
						});
					});
					$scope.showPanel = true;
				});
			}
		},
	]);
})();
