(function () {
	'use strict';

	angular.module('dcApp').directive('eniteFromDepot', [
		'$parse',
		function ($parse) {
			let controller = [
				'$scope',
				'$rootScope',
				'$window',
				'EntityFromDepotService',
				'TypeEntiteService',
				'ParametersServices',
				'MetadataService',
				'toaster',
				'DATE_PATTERN',
				'DepotService',
				'CommonServices',
				'gettextCatalog',
				function (
					$scope,
					$rootScope,
					$window,
					EntityFromDepotService,
					TypeEntiteService,
					ParametersServices,
					MetadataService,
					toaster,
					DATE_PATTERN,
					DepotService,
					CommonServices,
					gettextCatalog
				) {
					// init EntiteType
					$scope.libs_lang = {};
					$scope.metadataLoaded = true;
					$scope.metadata = {};
					$scope.hide_preview = true;
					$scope.getDateWithPattern = $rootScope.getDateWithPattern;
					$scope.charsets = [];
					$scope.caracIsList = {};
					$scope.entiteTypeCaracs = [];
					$scope.selectedFileHeader = {};
					$scope.selectedEntiteTypeCarac = {};
					$scope.selectedHeaderType = {};
					$scope.createRepDetails = false;
					$scope.creationLoading = false;

					$scope.setSelectedEntiteTypeCarac = function (carac, index) {
						setSelectedEntiteTypeCarac(
							carac,
							index,
							$scope.caracIsList,
							$scope.selectedFileHeader,
							$scope.mappings,
							$scope.selectedEntiteTypeCarac
						);
					};

					$scope.saveAll = function () {
						let entiteTypeCaracsCopy = angular.copy($scope.entiteTypeCaracs);
						let data = {};
						let caracs = [];
						let mapping = [];
						angular.forEach(
							$scope.selectedEntiteTypeCarac,
							function (carac, key) {
								if (!carac.depotDetails) {
									updateGeneratedCarac(carac);
									let caracData = carac;
									let caracCode =
										caracData.tempCode != undefined
											? caracData.tempCode
											: caracData.code;
									let headerCarac = caracData && caracData.header ? { id: angular.copy(caracData.header.id) } : null;
									delete caracData.header;
									if (
										headerCarac != undefined &&
										headerCarac != '' &&
										headerCarac != null
									) {
										let caracMapping = {};
										caracMapping.is_formula = false;
										caracMapping.header = headerCarac;
										caracMapping.pattern =
											caracData.date_pattern &&
											caracData.entite_type.code == 'SIMPLE_TYPES_DATE_ITEM'
												? caracData.date_pattern
												: undefined;
										caracMapping.uca_code = caracData.origin_column_id;
										caracMapping.linkId = $scope.linkId;
										mapping.push(caracMapping);
										caracs.push(caracData);
									}
								}
							}
						);
						data.entity = $scope.metadata;
						// set tags default colors
						for (let t in data.entity.tags) {
							data.entity.tags[t].color =
								data.entity.tags[t].color !== undefined
									? data.entity.tags[t].color
									: '#dbf5d1';
						}
						data.caracs = caracs;
						data.mappings = mapping;
						let entiteTypeFileHeaderCopy = angular.copy(
							$scope.entiteTypeFileHeader
						);
						$scope.creationLoading = true;
						EntityFromDepotService.createEntityFromDepot(
							$scope.depotId,
							$scope.createRepDetails,
							data
						)
							.then(function (response) {
								$scope.entiteTypeCaracs = entiteTypeCaracsCopy;
								$scope.entiteTypeFileHeader = entiteTypeFileHeaderCopy;
								angular.forEach($scope.entiteTypeCaracs, function (element) {
									$scope.setSelectedEntiteTypeCarac(element, element.code);
								});
								actionsAfterEntityExpress(response.data, vm);
								closeModal(true);
								toaster.pop(
									'success',
									gettextCatalog.getString('Succès'),
									gettextCatalog.getString("Création de l'entité effectuée")
								);
							})
							.catch(function () {
								// used to keep mapping filled even if an error occurred
								$scope.entiteTypeCaracs = entiteTypeCaracsCopy;
								$scope.entiteTypeFileHeader = entiteTypeFileHeaderCopy;
								$scope.creationLoading = false;
								angular.forEach($scope.entiteTypeCaracs, function (element) {
									$scope.setSelectedEntiteTypeCarac(element, element.code);
									$scope.setSelectedType(element);
								});
							});
					};

					function createCarac(carac, header) {
						let caracData = {};
						let caracCode = carac.metaCodeValidator();

						caracData.code = '_' + caracCode;
						caracData.code = caracData.code.toUpperCase();
						caracData.tempCode = caracData.code;
						caracData.oldCode = carac;
						caracData.entite_type = {};
						caracData.entite_type.id = $scope.typeMapper[header.data_type];
						caracData.entite_type =
							$scope.simpleTypes[caracData.entite_type.id];
						caracData.poids = 100;
						caracData.lib = carac;
						caracData.label = carac;
						caracData.complex = false;
						caracData.header = header;
						caracData.depotDetails = header.depot_details;
						if (caracData.date_pattern == undefined) {
							for (let t in $scope.datePatterns) {
								if ($scope.datePatterns[t].value == 'ISO 8601') {
									caracData.date_pattern = $scope.datePatterns[t].value;
									break;
								}
							}
						}

						return caracData;
					}

					$scope.metadataLoaded = false;
					$scope.generateMetadata = function () {
						$scope.metadataLoaded = true;
						$scope.metadata.actif = true;
						$scope.metadataLoaded = true;
					};

					$scope.setSelectedType = function (carac) {
						setSelectedType(carac, $scope.defaultTypes);
					};

					$scope.setSelectedConnector = function (connector) {
						setSelectedConnector(connector, $scope.connexions, $scope.depot);
					};

					$scope.updateCaracLib = function (carac, lib) {
						carac.label = lib;
					};

					$scope.getHeaderReadableType = function (type, code) {
						if ($scope.caracIsList[code]) {
							return 'Liste(' + getHeaderRealReadableType(type, gettextCatalog) + ')';
						} else {
							return getHeaderRealReadableType(type, gettextCatalog);
						}
					};

					function createHeadersAndCarac(data) {
						$scope.headers = data;
						$scope.entiteTypeCaracs = _.map($scope.headers, function (element) {
							return createCarac(element.column, element);
						});
						incrementAndUpdateDuplicatedValues($scope.entiteTypeCaracs, 'code');
						angular.forEach($scope.entiteTypeCaracs, function (carac) {
							$scope.caracIsList[carac.code] =
								$scope.caracIsList[carac.oldCode];
							carac.list =
								$scope.caracIsList[carac.code] != undefined
									? $scope.caracIsList[carac.code]
									: false;
							delete $scope.caracIsList[carac.oldCode];
						});
					}

					$scope.itemTracker = function (item) {
						return item.code + '-' + _.uniqueId();
					};

					let vm = this;

					$scope.reset = function () {
						// init File
						$scope.metadata = {};
						$scope.metadata.code = '';
						$scope.metadata.actif = true;
						$scope.metadata.tags = [];
						$scope.disableMetaDataCode = false;
						$scope.metadataLoaded = false;
						$scope.entiteTypeFileHeader = [];
						$scope.selectedFileHeader = {};
						$scope.entiteTypeFileDataPreview = [];
						$scope.entiteTypeFileHeader = [];
						$scope.entiteTypeCaracs = [];
					};

					$scope.cancel = function () {
						closeModal();
					};

					function closeModal(reload) {
						vm.showEntiteFromDepot = false;
						$scope.selectedFileHeader = {};
						$scope.selectedEntiteTypeCarac = {};
						$scope.selectedFileHeaderFormula = {};
						$scope.depot = {};
						delete $scope.gridData;
						$scope.entiteTypeFileHeader = [];
						$scope.disableMetaDataCode = false;
						$scope.reset();
						$scope.libs_lang = {};
						$scope.createRepDetails = false;
						$scope.creationLoading = false;
					}

					// Get necessary data and show the modal
					vm.initData = function (element) {
						// Get simpleTypes
						$scope.entiteTypeFileHeaderTypeMap = {};
						TypeEntiteService.getSimpleTypes().then(function (response) {
							let simpleTypes = $rootScope.translateSimpleTypes(response.data);
							$scope.defaultTypes = simpleTypes;
							$scope.simpleTypes = {};
							$scope.typeMapper = {};
							mapSimpleTypes(
								simpleTypes,
								$scope.typeMapper,
								$scope.simpleTypes
							);
							DepotService.getHeadersByDepotId(
								$scope.depotId,
								$scope.depotType
							).then(function (responseHeaders) {
								let headers = responseHeaders.data;
								CommonServices.getDateReadingMasks().then(function (
									responseMasks
								) {
									$scope.datePatterns = responseMasks.data;
									$scope.generateMetadata();
									createHeadersAndCarac(headers);
									$(element).modal(
										{ backdrop: 'static', keyboard: false },
										'show'
									);
								});
							});
						});
					};

					$scope.metadataConfig = {
						htmlId: 'uet-express',
					};
				},
			];

			return {
				restrict: 'E',
				scope: {
					showEntiteFromDepot: '=',
					depotId: '=',
					depotType: '=',
					depotLib: '=',
					reloadDataConfirmation: '=',
					refreshGraphAction: '=',
					updateAction: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				transclude: true,
				replace: true,
				templateUrl:
					'./src/components/directives/entityFromDepot/entityFromDepot.html',
				link: function postLink(scope, element, attrs) {
					$(element).modal({
						show: false,
						keyboard: attrs.keyboard,
						backdrop: attrs.backdrop,
					});
					element.bind('keydown keypress', function (event) {
						if (event.which == 27) {
							if (event.target.id == element[0].id) {
								event.stopPropagation();
								event.stopImmediatePropagation();
							} else {
								$(event.target).modal('hide');
							}
						}
					});

					scope.$watch(
						function () {
							return scope.vm.showEntiteFromDepot;
						},
						function (value) {
							scope.vm.element = element;
							if (value == true) {
								scope.depotId = scope.vm.depotId;
								scope.depotType = scope.vm.depotType;
								scope.depotLib = scope.vm.depotLib;
								scope.vm.initData(element);
							} else {
								$(element).modal('hide');
							}
						}
					);
				},
			};
		},
	]);
})();
