(function () {
	'use strict';

	angular.module('ProjectModule').config(stateConfig);

	stateConfig.$inject = ['$stateProvider'];

	function stateConfig($stateProvider) {
		$stateProvider.state('projects-root', {
			url: '/project',
			parent: 'logged',
			abstract: true,
			views: {
				content: {
					template: '<ui-view/>',
				},
			},
			resolve: {
				check: [
					'$q',
					'$window',
					'$log',
					'PROJECT_VAR_NAME',
					function ($q, $window, $log, PROJECT_VAR_NAME) {
						if (!$window.sessionStorage.getItem(PROJECT_VAR_NAME)) {
							$log.warn('No project found in session storage');
						}
						if (!$window.localStorage.getItem(PROJECT_VAR_NAME)) {
							$log.warn('No project found in local storage');
						}
					},
				],
			},
		});

		$stateProvider.state('target-project', {
			url: '/:projectId',
			parent: 'projects-root',
			abstract: true,
			resolve: {
				selectedProject: [
					'$q',
					'$rootScope',
					'$stateParams',
					'$transition$',
					'ProjectService',
					'PermissionService',
					'AppSelectorService',
					function (
						$q,
						$rootScope,
						$stateParams,
						$transition$,
						ProjectService,
						PermissionService,
						AppSelectorService
					) {
						const deferred = $q.defer();
						if (!AppSelectorService.currentProjectId) {
							// Get project data and check project admin permissions
							const query =
								$transition$.to().name === 'projects-edit'
									? ProjectService.getProject($stateParams.projectId)
									: ProjectService.getProjectMetada($stateParams.projectId);

							query.then(function (response) {
								AppSelectorService.setSelectedProject(
									$stateParams.projectId,
									response.data.metadata.label
								).then(function () {
									// FIXME: projectId est obligatoire pour la recuperation des permissions inclus globaux
									PermissionService.getCurrentUserGlobalPermissions().then(
										function (globalPermResponse) {
											$rootScope.globalPermissions = globalPermResponse.data;
											deferred.resolve($stateParams.projectId);
										}
									);
								});
							});
						} else {
							deferred.resolve($stateParams.projectId);
						}
						return deferred.promise;
					},
				],
			},
		});

		$stateProvider.state('projects', {
			url: '/',
			parent: 'projects-root',
			data: {
				admin: true,
				access: 'project,member',
				title: 'Projects',
			},
			views: {
				'@projects-root': {
					templateUrl: './src/components/modules/project/projects.html',
					controller: 'ProjectListController',
				},
			},
		});

		$stateProvider.state('project-import', {
			url: '/import',
			parent: 'projects-root',
			data: {
				admin: true,
				access: 'project,member',
				title: 'Import Projet',
			},
			views: {
				'@projects-root': {
					template: ``,
					controller: [
						'$state',
						'ModalService',
						function ($state, ModalService) {
							ModalService.open({
								controllerAs: 'vm',
								bindToController: true,
								templateUrl:
									'./src/components/modules/project/components/import-project-modal.component.html',
								controller: 'ImportProjectController',
								disableBackdrop: true,
								locals: {},
							})
								.then(function (path) {
									$state.go('projects');
								})
								.finally(function (path) {
									$state.go('projects');
								});
						},
					],
				},
			},
		});

		$stateProvider.state('projects-edit', {
			url: '/edit',
			parent: 'target-project',
			data: {
				admin: true,
				access: 'project',
				title: 'Edition du Projet',
			},
			views: {
				'@target-project': {
					templateUrl: './src/components/modules/project/projectForm.html',
					controller: 'ProjectController',
				},
			},
		});

		$stateProvider.state('gd-home', {
			url: '/generics-data/home',
			parent: 'target-project',
			data: {
				admin: true,
				access: 'member',
				title: 'GD-Accueil',
			},
			views: {
				'@target-project': {
					templateUrl: './src/components/modules/home/gd-home.html',
					controller: 'HomeController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('home', {
			url: '/home',
			parent: 'target-project',
			data: {
				admin: false,
				access: '*',
				title: 'HD-Accueil',
			},
			views: {
				'@target-project': {
					templateUrl: './src/components/modules/home/hd-home.html',
					controller: 'HomeController',
					controllerAs: '$ctrl',
				},
			},
		});

		$stateProvider.state('projects-new', {
			url: '/new',
			parent: 'projects-root',
			data: {
				admin: true,
				access: 'project',
				title: 'Nouveau Projet',
			},
			views: {
				'@projects-root': {
					templateUrl: './src/components/modules/project/projectForm.html',
					controller: 'ProjectController',
					controllerAs: '$ctrl',
				},
			},
		});
	}
})();
