(function () {
	'use strict';

	angular.module('dcApp').directive('hdhGraph', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$state',
				'TypeEntiteService',
				'$window',
				'HdhElementInfoService',
				'gettextCatalog',
				'$rootScope',
				function (
					$scope,
					$state,
					TypeEntiteService,
					$window,
					HdhElementInfoService,
					gettextCatalog,
					$rootScope
				) {
					let connectorGroupIcon = './src/img/icons/dc-plug.svg';
					let publicConnectorGroupIcon = './src/img/icons/dc-plug-public.svg';
					let depotGroupIcon = './src/img/icons/dc-depot.svg';
					let publicDepotGroupIcon = './src/img/icons/dc-depot-public.svg';

					let entityGroupIcon = './src/img/icons/dc-entity.svg';
					let persistedEntityGroupIcon =
						'./src/img/icons/dc-entity-persisted.svg';
					let cachedEntityGroupIcon = './src/img/icons/dc-entity-cached.svg';
					let persistedCachedEntityGroupIcon =
						'./src/img/icons/dc-entity-persisted-cached.svg';
					let publicEntityGroupIcon = './src/img/icons/dc-entity-public.svg';
					let publicPersistedEntityGroupIcon =
						'./src/img/icons/dc-entity-persisted-public.svg';
					let publicCachedEntityGroupIcon =
						'./src/img/icons/dc-entity-cached-public.svg';
					let publicPersistedCachedEntityGroupIcon =
						'./src/img/icons/dc-entity-persisted-cached-public.svg';

					let datablockGroupIcon = './src/img/icons/dc-datablock.svg';
					let publicDatablockGroupIcon =
						'./src/img/icons/dc-datablock-public.svg';
					let publicPersistedDatablockGroupIcon =
						'./src/img/icons/dc-datablock-persisted-public.svg';
					let publicCachedDatablockGroupIcon =
						'./src/img/icons/dc-datablock-cached-public.svg';
					let publicPersistedCachedDatablockGroupIcon =
						'./src/img/icons/dc-datablock-persisted-cached-public.svg';
					let persistedDatablockGroupIcon =
						'./src/img/icons/dc-datablock-persisted.svg';
					let cachedDatablockGroupIcon =
						'./src/img/icons/dc-datablock-cached.svg';
					let persistedCachedDatablockGroupIcon =
						'./src/img/icons/dc-datablock-persisted-cached.svg';

					let selectedPersistedGroupIcon =
						'./src/img/icons/dc-selected-persisted.svg';
					let selectedCachedGroupIcon =
						'./src/img/icons/dc-selected-cached.svg';
					let selectedPersistedCachedGroupIcon =
						'./src/img/icons/dc-selected-persisted-cached.svg';

					let hdSourceGroupIcon = './src/img/icons/dc-source.svg';
					let publicHdSourceGroupIcon = './src/img/icons/dc-source-public.svg';

					let hdElementGroupIcon = './src/img/icons/dc-element.svg';
					let publicHdElementGroupIcon =
						'./src/img/icons/dc-element-public.svg';

					let hdExpositionGroupIcon = './src/img/icons/dc-exposition.svg';
					let publicHdExpositionGroupIcon =
						'./src/img/icons/dc-exposition-public.svg';

					let dashboardGroupIcon = './src/img/icons/dc-dashboard.svg';
					let publicDashboardGroupIcon =
						'./src/img/icons/dc-dashboard-public.svg';

					let axesTypeDash = gettextCatalog.getString('Tableaux de Bord');
					let axesTypeEle = gettextCatalog.getString('Eléments HD');
					let axesTypeSource = gettextCatalog.getString('Sources');
					let axesTypedb = gettextCatalog.getString('DataBlocks');
					let axesTypeEt = gettextCatalog.getString('Entités Métier');
					let axesTypedp = gettextCatalog.getString('Dépôts et Connecteurs');

					let translateMessage = gettextCatalog.getString(
						"Résultat vide. Veuillez changer l'axe de recherche"
					);
					let elementTypeMap = {
						DATABLOCK: 'datablock',
						DEPOT: 'depot',
						ENTITETYPE: 'entity',
						CONNECTOR: 'connector',
						DASHBOARD: 'dashboard',
						TIMELINE: 'timeline',
						MAP: 'map',
						CHART: 'chart',
						GRID: 'grid',
						MEDIA: 'media',
						EXPOSITION: 'exposition',
					};
					let blackBorder = { border: '#000000' };
					let blueBorder = { border: '#00B0B0' };

					$scope.axes = [
						{ value: 'dash', lib: axesTypeDash },
						{ value: 'ele', lib: axesTypeEle },
						{ value: 'source', lib: axesTypeSource },
						{ value: 'db', lib: axesTypedb },
						{ value: 'et', lib: axesTypeEt },
						{ value: 'dp', lib: axesTypedp },
					];
					$scope.frameDataConfig = {};
					$scope.currentProjectId = $rootScope.currentProjectId;
					$scope.withPersistenceInfo = false;
					$scope.isHiearchie = true;
					$scope.isVertical = false;
					$scope.hideElementInfo = hideElementInfo;

					var network;
					var getNextAxe = function (value) {
						for (var a in $scope.axes) {
							if ($scope.axes[a].value == value) {
								return $scope.axes[Number(a) + 1].value;
							}
						}
					};

					$scope.getAxes = function () {
						switch ($scope.filtredType) {
							case 'DASHBOARD':
								return $scope.axes.slice(0, 1);
							case 'GRID':
								return $scope.axes.slice(0, 2);
							case 'CHART':
								return $scope.axes.slice(0, 2);
							case 'MEDIA':
								return $scope.axes.slice(0, 2);
							case 'TIMELINE':
								return $scope.axes.slice(0, 2);
							case 'MAP':
								return $scope.axes.slice(0, 2);
							case 'DATAVIEW':
								return $scope.axes.slice(0, 3);
							case 'DATABLOCK':
								return $scope.axes.slice(0, 4);
							case 'ENTITETYPE':
								return $scope.axes.slice(0, 5);
							case 'DEPOT':
								return $scope.axes.slice(0, 6);
							case 'CONNECTOR':
								return $scope.axes;

							default:
								return $scope.axes;
						}
					};

					$scope.init = function () {
						$scope.selectedAxe = $scope.vm.graphData.axe;
						if (!$scope.selectedAxe) {
							$scope.selectedAxe = $scope.axes[0].value;
						}
						$scope.filtredLabel = $scope.vm.graphData.centeredElementLabel;
						$scope.filtredType = $scope.vm.graphData.centeredElementType;
						$scope.filtredId = $scope.vm.graphData.centeredElementId;
						$scope.getGraphe(true);
					};

					$scope.selectNode = function (p) {
						$scope.selectedNode = _.find($scope.nodes.nodes, {
							id: p.nodes[0],
						});
						if ($scope.selectedNode) {
							$scope.selectedType = getTranslatedTypes(
								$scope.selectedNode.type
							);
							if ($scope.elementInfoShown) {
								showElementInfo();
							}
						} else {
							hideElementInfo();
						}
						$scope.$apply();
					};

					$scope.editElement = function (element) {
						var url = '';
						switch (element.type) {
							case 'EXPOSITION':
								url = $state.href('exposition-view', {
									expositionId: element.real_id,
								});
								$window.open(url, '_blank');
								return;
							case 'DASHBOARD':
								url = $state.href('dashboards-edit', {
									id: element.real_id,
								});
								$window.open(url, '_blank');
								return;
							case 'CHART':
								url = $state.href('charts-edit', {
									type: element.real_type,
									id: element.real_id,
								});
								$window.open(url, '_blank');
								return;
							case 'GRID':
								url = $state.href('grids-edit', {
									type: element.real_type,
									id: element.real_id,
								});
								$window.open(url, '_blank');
								return;
							case 'MEDIA':
								url = $state.href('medias-edit', {
									type: element.real_type,
									id: element.real_id,
								});
								$window.open(url, '_blank');
								return;
							case 'TIMELINE':
								url = $state.href('timelines-edit', {
									id: element.real_id,
								});
								$window.open(url, '_blank');
								return;
							case 'MAP':
								url = $state.href('maps-edit', {
									id: element.real_id,
								});
								$window.open(url, '_blank');
								return;
							case 'CONNECTOR':
								url = $state.href('connectors-edit', {
									type: element.real_type,
									id: element.real_id,
								});
								$window.open(url, '_blank');
								return;
							case 'DEPOT':
								url = $state.href('depots-edit', {
									type: element.real_type,
									id: element.real_id,
								});
								$window.open(url, '_blank');
								return;
							case 'DATABLOCK':
								url = $state.href('datablocks-edit', { id: element.real_id });
								$window.open(url, '_blank');
								return;
							case 'ENTITETYPE':
								url = $state.href('entites-edit', {
									entiteId: element.real_id,
								});
								$window.open(url, '_blank');
								return;
							default:
								break;
						}
					};

					$scope.toggleElementInfo = function () {
						if ($scope.elementInfoShown) {
							$scope.elementInfoShown = false;
							hideElementInfo();
						} else {
							showElementInfo();
						}
					};

					function showElementInfo() {
						$scope.selectedNode.normalizedType =
							elementTypeMap[$scope.selectedNode.type];
						$scope.elementInfoShown = true;
					}

					function hideElementInfo() {
						$scope.elementInfoShown = false;
					}

					var groups = {
						//Connectors
						CONNECTOR: {
							color: blackBorder,
							shape: 'image',
							image: connectorGroupIcon,
						},
						//Public connectors
						'PUBLIC:CONNECTOR': {
							color: blueBorder,
							shape: 'image',
							image: publicConnectorGroupIcon,
						},
						//Dashboards
						DASHBOARD: {
							color: blackBorder,
							shape: 'image',
							image: dashboardGroupIcon,
						},
						//Public dashboards
						'PUBLIC:DASHBOARD': {
							color: blueBorder,
							shape: 'image',
							image: publicDashboardGroupIcon,
						},
						//Depots
						DEPOT: {
							color: blackBorder,
							shape: 'image',
							image: depotGroupIcon,
						},
						//Public depots
						'PUBLIC:DEPOT': {
							color: blueBorder,
							shape: 'image',
							image: publicDepotGroupIcon,
						},
						//Entities
						ENTITY: {
							color: blackBorder,
							shape: 'image',
							image: entityGroupIcon,
						},
						//Persisted entities
						'ENTITY:PERSISTED': {
							color: blackBorder,
							shape: 'image',
							image: persistedEntityGroupIcon,
						},
						//Cached entities
						'ENTITY:CACHED': {
							color: blackBorder,
							shape: 'image',
							image: cachedEntityGroupIcon,
						},
						//Persisted and Cached entities
						'ENTITY:PERSISTED:CACHED': {
							color: blackBorder,
							shape: 'image',
							image: persistedCachedEntityGroupIcon,
						},
						//Public entities
						'PUBLIC:ENTITY': {
							color: blueBorder,
							shape: 'image',
							image: publicEntityGroupIcon,
						},
						//Public persisted entities
						'PUBLIC:ENTITY:PERSISTED': {
							color: blueBorder,
							shape: 'image',
							image: publicPersistedEntityGroupIcon,
						},
						//Public cached entities
						'PUBLIC:ENTITY:CACHED': {
							color: blueBorder,
							shape: 'image',
							image: publicCachedEntityGroupIcon,
						},
						//Public persisted and cached entities
						'PUBLIC:ENTITY:PERSISTED:CACHED': {
							color: blueBorder,
							shape: 'image',
							image: publicPersistedCachedEntityGroupIcon,
						},
						//Datablocks
						DATABLOCK: {
							color: blackBorder,
							shape: 'image',
							image: datablockGroupIcon,
						},
						'DATABLOCK:PERSISTED': {
							color: blackBorder,
							shape: 'image',
							image: persistedDatablockGroupIcon,
						},
						'DATABLOCK:CACHED': {
							color: blackBorder,
							shape: 'image',
							image: cachedDatablockGroupIcon,
						},
						'DATABLOCK:PERSISTED:CACHED': {
							color: blackBorder,
							shape: 'image',
							image: persistedCachedDatablockGroupIcon,
						},
						//Public datablocks
						'PUBLIC:DATABLOCK': {
							color: blueBorder,
							shape: 'image',
							image: publicDatablockGroupIcon,
						},
						'PUBLIC:DATABLOCK:PERSISTED': {
							color: blueBorder,
							shape: 'image',
							image: publicPersistedDatablockGroupIcon,
						},
						'PUBLIC:DATABLOCK:CACHED': {
							color: blueBorder,
							shape: 'image',
							image: publicCachedDatablockGroupIcon,
						},
						'PUBLIC:DATABLOCK:PERSISTED:CACHED': {
							color: blueBorder,
							shape: 'image',
							image: publicPersistedCachedDatablockGroupIcon,
						},
						//Hd Sources
						HDSOURCE: {
							color: blackBorder,
							shape: 'image',
							image: hdSourceGroupIcon,
						},
						//Public Hd Sources
						'PUBLIC:HDSOURCE': {
							color: blueBorder,
							shape: 'image',
							image: publicHdElementGroupIcon,
						},
						//Hd Elements
						HDELEMENT: {
							color: blackBorder,
							shape: 'image',
							image: hdElementGroupIcon,
						},
						//Public Hd Elements
						'PUBLIC:HDELEMENT': {
							color: blueBorder,
							shape: 'image',
							image: publicHdElementGroupIcon,
						},
						// Exposition
						EXPOSITION: {
							color: blackBorder,
							shape: 'image',
							image: hdExpositionGroupIcon,
						},
						//Public Hd Elements
						'PUBLIC:EXPOSITION': {
							color: blueBorder,
							shape: 'image',
							image: publicHdExpositionGroupIcon,
						},
						SELECTED: { color: { background: '#f5f56e', border: '#000000' } },
						'SELECTED:PERSISTED': {
							color: { background: '#f5f56e', border: '#000000' },
						},
						'SELECTED:PERSISTED:CACHED': {
							color: { background: '#f5f56e', border: '#000000' },
						},
						'SELECTED:CACHED': {
							color: { background: '#f5f56e', border: '#000000' },
						},
						'PUBLIC:SELECTED': {
							color: { background: '#f5f56e', border: '#000000' },
						},
						'PUBLIC:SELECTED:PERSISTED': {
							color: { background: '#f5f56e', border: '#000000' },
						},
						'PUBLIC:SELECTED:PERSISTED:CACHED': {
							color: { background: '#f5f56e', border: '#000000' },
						},
					};

					var getGraphHiearchieOptions = function () {
						return {
							groups: groups,
							nodes: {
								shape: 'dot',
								size: 50,
								scaling: {
									min: 10,
									max: 30,
								},
								font: {
									size: 20,
									face: 'Arial',
									//  strokeWidth: 50
								},
								borderWidth: 3,
								shadow: true,
							},
							edges: {
								width: 2,
								hoverWidth: 20,
								selectionWidth: 10,
								color: {
									inherit: 'from',
								},
								smooth: {
									type: 'continuous',
								},
								shadow: true,
							},
							physics: {
								enabled: true,
								stabilization: true,
								barnesHut: {
									avoidOverlap: 1,
									gravitationalConstant: -5000,
									springConstant: 0.001,
									springLength: 90,
								},
								hierarchicalRepulsion: {
									centralGravity: 0,
									nodeDistance: 250,
								},
							},

							interaction: {
								navigationButtons: false,
								tooltipDelay: 400,
								hideEdgesOnDrag: true,
								keyboard: {
									enabled: false,
									speed: {
										zoom: 0.002,
									},
									bindToWindow: true,
								},
							},

							layout: {
								hierarchical: {
									enabled: true,
									direction: $scope.isVertical ? 'UD' : 'RL',
									levelSeparation: 500,
									nodeSpacing: 50,
									treeSpacing: 10,
									sortMethod: 'directed',
									parentCentralization: true,
								},
							},
						};
					};

					function redrawAll(nod, edg, h) {
						// remove positoins
						for (var i = 0; i < nod.length; i++) {
							delete nod[i].x;
							delete nod[i].y;
						}
						// create a network
						var container = document.getElementById('hdhGraph');
						for (var n in edg) {
							edg[n]['shape'] = 'dot';
						}

						$scope.data = {
							nodes: new vis.DataSet(nod),
							edges: edg,
						};

						if (h)
							network = new vis.Network(
								container,
								$scope.data,
								getGraphHiearchieOptions()
							);
						else network = new vis.Network(container, $scope.data, options);

						network.on('select', function (p) {
							$scope.selectNode(p);
						});

						network.on('doubleClick', function (properties) {
							var node = _.find($scope.nodes.nodes, {
								id: properties.nodes[0],
							});
							$scope.filtredLabel = node.label;
							$scope.filtredType = node.type;
							$scope.filtredId = node.real_id;
							$scope.getGraphe(true);
						});
					}

					$scope.clearFilter = function () {
						if ($scope.vm.graphData.centeredElementType) {
							$scope.filtredLabel = $scope.vm.graphData.centeredElementLabel;
							$scope.filtredType = $scope.vm.graphData.centeredElementType;
							$scope.filtredId = $scope.vm.graphData.centeredElementId;
						} else {
							delete $scope.filtredLabel;
							delete $scope.filtredType;
							delete $scope.filtredId;
						}

						$scope.getGraphe(true);
					};

					$scope.getGraphe = function (h) {
						delete $scope.selectedNode;
						delete $scope.selectedType;
						hideElementInfo();
						var filter = {};
						delete $scope.message;
						TypeEntiteService.getHdhGraph(
							$scope.selectedAxe,
							filter,
							$scope.filtredType,
							$scope.filtredId,
							$scope.withPersistenceInfo
						).then(function (response) {
							if (response.data.nodes[0]) {
								$scope.nodes = response.data;
								redrawAll($scope.nodes.nodes, $scope.nodes.edges, h);
							} else {
								redrawAll([], [], h);
								$scope.message = translateMessage;
							}
						});
					};

					var options = {
						groups: groups,
						nodes: {
							shape: 'dot',
							size: 50,
							scaling: {
								min: 10,
								max: 30,
							},
							font: {
								size: 20,
								face: 'Arial',
								//  strokeWidth: 50
							},
							borderWidth: 3,
							shadow: true,
						},
						edges: {
							width: 2,
							hoverWidth: 20,
							selectionWidth: 10,
							color: {
								inherit: 'from',
							},
							smooth: {
								type: 'continuous',
							},
							shadow: true,
						},
						physics: {
							enabled: true,
							stabilization: true,
							barnesHut: {
								avoidOverlap: 1,
								gravitationalConstant: -5000,
								springConstant: 0.001,
								springLength: 90,
							},
							hierarchicalRepulsion: {
								centralGravity: 0,
								nodeDistance: 250,
							},
						},

						interaction: {
							navigationButtons: false,
							tooltipDelay: 400,
							hideEdgesOnDrag: true,
							keyboard: {
								enabled: false,
								speed: {
									zoom: 0.002,
								},
								bindToWindow: true,
							},
						},
					};

					$scope.$on('job_global', function (event, message) {
						if (!$scope.withPersistenceInfo) {
							return;
						}
						if (message.data && message.data.status === 'SUCCESS') {
							let updatedNode = _.find(
								$scope.data.nodes._data,
								function (item) {
									return (
										item.real_id === message.element_id &&
										(item.type === 'DATABLOCK' || item.type === 'ENTITETYPE')
									);
								}
							);
							if (updatedNode) {
								let newGroup = updatedNode.group;
								if (message.data.persisted === true) {
									newGroup = newGroup + ':PERSISTED';
									newGroup = newGroup.replace(
										'CACHED:PERSISTED',
										'PERSISTED:CACHED'
									);
								} else if (message.data.persisted === false) {
									newGroup = newGroup.replace(':PERSISTED', '');
								}
								if (message.data.cached === true) {
									newGroup = newGroup + ':CACHED';
								} else if (message.data.cached === false) {
									newGroup = newGroup.replace(':CACHED', '');
								}
								$scope.data.nodes.update({
									id: updatedNode.id,
									group: newGroup,
								});
							}
						}
					});

					$scope.layout = function () {
						if (!$scope.isHiearchie) {
							$scope.getGraphe(true);
							$scope.isHiearchie = true;
						} else {
							$scope.getGraphe(false);
							$scope.isHiearchie = false;
						}
					};

					$scope.changeDirection = function () {
						if ($scope.isVertical) {
							$scope.isVertical = false;
							$scope.getGraphe(true);
						} else {
							$scope.isVertical = true;
							$scope.getGraphe(true);
						}
					};

					$scope.togglePersistenceInfo = function () {
						if ($scope.withPersistenceInfo) {
							$scope.withPersistenceInfo = false;
							$scope.getGraphe(true);
						} else {
							$scope.withPersistenceInfo = true;
							$scope.getGraphe(true);
						}
					};
					var getTranslatedTypes = function (type) {
						let suitableType = type ? type.toLowerCase() : type;
						switch (suitableType) {
							case 'exposition':
								suitableType = "EXPOSITION";
								break;
							case 'dashboard':
								suitableType = "DASHBOARD";
								break;
							case 'chart':
								suitableType = "CHART";
								break;
							case 'grid':
								suitableType = "GRID";
								break;
							case 'media':
								suitableType = "MEDIA";
								break;
							case 'timeline':
								suitableType = "TIMELINE";
								break;
							case 'connector':
								suitableType = "CONNECTOR";
								break;
							case 'depot':
							case 'repository':
								suitableType = "DEPOT";
								break;
							case 'map':
								suitableType = "MAP";
								break;
							case 'datablock':
								suitableType = "DATABLOCK";
								break;
							case 'entity':
								suitableType = "ENTITETYPE";
								break;
							default:
								suitableType = type ? type.toUpperCase() : type;
								break;
						}
						return gettextCatalog.getString(
							getTypeLabelFromHdhType(suitableType)
						);
					};
				},
			];

			return {
				restrict: 'E',

				scope: {
					graphData: '=',
					height: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl: './src/components/directives/hdhGraph/hdhGraph.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {
					//scope.graphData.initGraph = scope.init;
					scope.height = '880px';
					if (scope.vm.height) {
						scope.height = scope.vm.height + 'px';
					}
					scope.init();
				},
			};
		},
	]);
})();
