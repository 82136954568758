(function () {
	'use strict';

	angular.module('dcApp').factory('UserService', [
		'$log',
		'$http',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		'$q',
		'UserAdapter',
		'ProjectMemberAdapter',
		function (
			$log,
			$http,
			$rootScope,
			API_BASE_URL_BACKEND,
			$q,
			UserAdapter,
			ProjectMemberAdapter
		) {
			var _allUsers = undefined;

			var UserService = {};

			var OLD_STATE = undefined;

			UserService.getUsers = function (active, shouldRefresh) {
				var deferred = $q.defer();
				var url = API_BASE_URL_BACKEND + 'account/users';

				if (active !== undefined) {
					url = API_BASE_URL_BACKEND + 'account/users' + '?actif=' + active;
				}
				$http.get(url).then(function (response) {
					_allUsers = UserAdapter.generateUsersList(response.data);
					deferred.resolve(_allUsers);
				});
				return deferred.promise;
			};

			UserService.getProjectMembers = function () {
				const deferred = $q.defer();
				var url = API_BASE_URL_BACKEND + 'account/users/basic';
				$http.get(url).then(function (response) {
					deferred.resolve(ProjectMemberAdapter.generateMember(response.data));
				});
				return deferred.promise;
			};

			UserService.getUserById = function (userId, isGroup) {
				let isOfGroupType =
					isGroup === null || isGroup === undefined ? false : isGroup;
				let url =
					API_BASE_URL_BACKEND +
					'account/users/' +
					userId +
					'/basic?isGroup=' +
					isOfGroupType;
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			UserService.getUser = function (userId) {
				var deferred = $q.defer();
				const url = API_BASE_URL_BACKEND + 'account/users/' + userId;
				$http.get(url).then(function (response) {
					deferred.resolve(UserAdapter.generateUser(response.data));
				});
				return deferred.promise;
			};

			UserService.isAdmin = function () {
				const url = API_BASE_URL_BACKEND + 'account/users/is_admin';
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			UserService.hasAccessToV2 = function () {
				const url = API_BASE_URL_BACKEND + '/private/web_ui_configuration';
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			UserService.getUserProfile = function () {
				const url = API_BASE_URL_BACKEND + 'account/users/profile';
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			UserService.getUserAvatar = function () {
				const url = API_BASE_URL_BACKEND + 'account/users/avatar';
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			UserService.saveAvatar = function (avatar) {
				var fd = new FormData();
				fd.append('file', avatar);
				const url = API_BASE_URL_BACKEND + 'account/users/avatar';
				return $http
					.post(url, fd, {
						transformRequest: angular.identity,
						headers: { 'Content-Type': undefined },
					})
					.then(
						function (response) {
							return response;
						},
						function (err) {
							$log.error(err);
						}
					);
			};

			UserService.updateUserProfile = function (user) {
				const url = API_BASE_URL_BACKEND + 'account/users/profile';
				return $http.put(url, user).then(function (response) {
					return response;
				});
			};

			UserService.changeUserMail = function (user) {
				const url = API_BASE_URL_BACKEND + 'account/users/mail';
				return $http.post(url, user).then(function (response) {
					return response;
				});
			};

			UserService.deleteUser = function (userId) {
				const url = API_BASE_URL_BACKEND + 'account/users/' + userId;
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			UserService.getUsersByPermission = function (permissionId) {
				const url =
					API_BASE_URL_BACKEND +
					'/account/permission/' +
					permissionId +
					'/identites';
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			UserService.createUser = function (user) {
				var deferred = $q.defer();
				const url = API_BASE_URL_BACKEND + 'account/users';
				$http.post(url, user).then(function (response) {
					var results = {};
					results.data = response.data;
					results.headers = response.headers();
					results.status = response.status;
					results.config = response.config;
					deferred.resolve(results);
				});
				return deferred.promise;
			};

			UserService.editUser = function (
				userId,
				userData,
				deleteImpactedTokens,
				onError
			) {
				var deferred = $q.defer();
				let url =
					API_BASE_URL_BACKEND +
					'account/users/' +
					userId +
					'?deleteImpactedTokens=' +
					deleteImpactedTokens;
				$http.put(url, userData).then(
					function (response) {
						var results = {};
						results.data = response.data;
						results.headers = response.headers();
						results.status = response.status;
						results.config = response.config;
						deferred.resolve(results);
					},
					function (err) {
						if (onError) {
							onError(err);
						}
					}
				);
				return deferred.promise;
			};

			UserService.deleteUser = function (userId) {
				const url = API_BASE_URL_BACKEND + 'account/users/' + userId;
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			UserService.attachOrDetachToPermission = function (userId, permissionId) {
				var deferred = $q.defer();
				const url =
					API_BASE_URL_BACKEND +
					'account/user/' +
					userId +
					'/permission/' +
					permissionId;
				$http.get(url).then(function (response) {
					var results = {};
					results.data = response.data;
					results.headers = response.headers();
					results.status = response.status;
					results.config = response.config;
					deferred.resolve(results);
				});
				return deferred.promise;
			};

			UserService.setOldState = function (oldState) {
				OLD_STATE = oldState;
			};

			UserService.getOldState = function () {
				return OLD_STATE;
			};

			UserService.sendResetPasswordMailByUserId = function (userId) {
				const url =
					API_BASE_URL_BACKEND +
					'account/users/' +
					userId +
					'/update_pwd_action';
				return $http.put(url, null).then(function (response) {
					return response;
				});
			};

			UserService.sendResetPasswordMail = function (mail) {
				const url =
					API_BASE_URL_BACKEND + 'account/users/profile/update_pwd_action';
				return $http.put(url, null).then(function (response) {
					return response;
				});
			};

			UserService.getBeforeLastLoginDate = function () {
				const url =
					API_BASE_URL_BACKEND + 'account/users/before_last_login_date';
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			UserService.getInactivityMaxDelay = function () {
				const url = API_BASE_URL_BACKEND + 'account/users/inactivity_max_delay';
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			UserService.getSessionExpirationDelay = function () {
				const url =
					API_BASE_URL_BACKEND +
					'account/users/current_session/expiration_delay';
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			UserService.getTokens = function (clientId, offline) {
				const url =
					API_BASE_URL_BACKEND +
					'users/tokens?clientId=' +
					clientId +
					'&offline=' +
					offline;
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			UserService.createToken = function (token) {
				var deferred = $q.defer();
				const url = API_BASE_URL_BACKEND + 'users/tokens';
				$http.post(url, token).then(function (response) {
					var results = {};
					results.data = response.data;
					results.headers = response.headers();
					results.status = response.status;
					results.config = response.config;
					deferred.resolve(results);
				});
				return deferred.promise;
			};

			UserService.deleteAllTokens = function (clientId, offline) {
				const url =
					API_BASE_URL_BACKEND +
					'users/tokens?clientId=' +
					clientId +
					'&offline=' +
					offline;
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			UserService.deleteToken = function (sessionId) {
				const url = API_BASE_URL_BACKEND + 'users/tokens/' + sessionId;
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			UserService.getExpositionAccessTokens = function () {
				const url = API_BASE_URL_BACKEND + 'users/exposition_tokens';
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			UserService.createExpositionAccessToken = function (token) {
				var deferred = $q.defer();
				const url = API_BASE_URL_BACKEND + 'users/exposition_tokens';
				$http.post(url, token).then(function (response) {
					var results = {};
					results.data = response.data;
					results.headers = response.headers();
					results.status = response.status;
					results.config = response.config;
					deferred.resolve(results);
				});
				return deferred.promise;
			};

			UserService.getClients = function () {
				const url = API_BASE_URL_BACKEND + 'users/clients';
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			UserService.updateStatus = function (userId, active) {
				var deferred = $q.defer();
				let url = API_BASE_URL_BACKEND + '/users/' + userId + '/status';
				$http.put(url, active).then(function (response) {
					var results = {};
					results.data = response.data;
					results.headers = response.headers();
					results.status = response.status;
					results.config = response.config;
					deferred.resolve(results);
				});

				return deferred.promise;
			};

			UserService.logoutUser = function (userId, deleteApiTokens) {
				var deferred = $q.defer();
				let url = API_BASE_URL_BACKEND + '/users/' + userId + '/logout';
				$http.put(url, deleteApiTokens).then(function (response) {
					var results = {};
					results.data = response.data;
					results.headers = response.headers();
					results.status = response.status;
					results.config = response.config;
					deferred.resolve(results);
				});

				return deferred.promise;
			};

			UserService.userIsConnected = function (userId) {
				let url = API_BASE_URL_BACKEND + '/users/' + userId + '/connected';
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			UserService.getLastLoginDate = function (userId) {
				let url =
					API_BASE_URL_BACKEND + '/users/' + userId + '/last_login_date';
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			UserService.hasDefaultUserRoleActive = function () {
				var deferred = $q.defer();
				var url =
					API_BASE_URL_BACKEND +
					'account/' +
					$rootScope.getUrl('USER_DEFAULT_ROLE');
				$http.get(url).then(function (response) {
					deferred.resolve(response);
				});
				return deferred.promise;
			};

			return UserService;
		},
	]);
})();
