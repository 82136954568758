(function () {
	'use strict';

	angular.module('dcApp').directive('datablockLink', [
		'$parse',
		function ($parse) {
			var controller = [
				'$rootScope',
				'$scope',
				'$q',
				'DataBlocksService',
				'toaster',
				'$sce',
				'CaracService',
				'TypeEntiteService',
				'gettextCatalog',
				'DatablockLinkEntity',
				'LINK_TYPES',
				'DatablockLinkService',
				'GrammarUtils',
				function (
					$rootScope,
					$scope,
					$q,
					DataBlocksService,
					toaster,
					$sce,
					CaracService,
					TypeEntiteService,
					gettextCatalog,
					DatablockLinkEntity,
					LINK_TYPES,
					DatablockLinkService,
					GrammarUtils
				) {
					var vm = this;

					const yesTra = gettextCatalog.getString('Oui');
					const ENTITY_TYPE = {
						datablock: 'DATABLOCK',
						entity: 'ENTITE',
					};

					$scope.columnsSort = 0;
					$scope.linkStatData = {};

					$scope.types = _.sortBy(
						DatablockLinkService.generateLinkTypes(),
						function (elm) {
							return elm.label;
						}
					);

					$scope.operators = DatablockLinkService.generateMappingOperators();

					vm.onSourceMappingChange = function (
						selectedSourceColumn,
						linkMapping
					) {
						linkMapping.right_column_uuid = undefined;
						linkMapping.right_carac_code = undefined;
						linkMapping.computeEligibleTargetColumns(
							selectedSourceColumn,
							$scope.selectedLink.data_columns
						);
					};

					// utility function
					$scope.getHtmlIconByType = function (type, isList) {
						if (type !== undefined) {
							type = type.toLowerCase();
							return $sce.trustAsHtml(getHtmlIconByType(type, isList, GrammarUtils.getTypeLabel(type)));
						}
					};

					$scope.toggleSelectAllColumns = function (
						isAllSelected,
						selectedLink
					) {
						selectedLink.allSelected = isAllSelected;
						selectedLink.data_columns.forEach(
							(c) => (c.selected = !!selectedLink.allSelected)
						);
						selectedLink.columns = selectedLink.data_columns.filter(
							(c) => c.selected
						);
					};

					$scope.toggleSelectColumn = function (isSelected, col) {
						col.selected = isSelected;
						if (!col.selected) {
							$scope.selectedLink.columns = $scope.selectedLink.columns.filter(
								(c) => c.selected
							);
							return;
						}
						$scope.selectedLink.columns.push(col);
					};

					$scope.addNewLink = function () {
						for (let l in $scope.links) {
							$scope.links[l].checkLinkValidity(vm.data.datablock.id);
							if ($scope.links[l].not_valid) {
								return;
							}
						}
						$scope.linkId++;
						$scope.selectedLink = new DatablockLinkEntity({
							type: LINK_TYPES.Cross,
						});
						$scope.links.push($scope.selectedLink);
					};

					$scope.deleteLinkConfirmation = function (link, modalId) {
						$scope.linkToDelete = link;
						$('#' + modalId).modal('show');
					};

					$scope.closeMe = function (modalId) {
						$('#' + modalId).modal('hide');
					};

					$scope.deleteLink = function (modalId) {
						var link = $scope.linkToDelete;
						if ($scope.selectedLink && link.id === $scope.selectedLink.id) {
							delete $scope.selectedLink;
						}
						$scope.links.splice($scope.links.indexOf(link), 1);
						$('#' + modalId).modal('hide');
					};

					$scope.deleteMapping = function (index) {
						$scope.selectedLink.mappings.splice(index, 1);
					};

					$scope.openLinkStat = function (link) {
						vm.widgetData.showLinkStat = true;
						$scope.linkStatData.targetId = link.db_id;
						$scope.linkStatData.sourceGrammar = vm.data.linkSourceGrammar;
						$scope.linkStatData.sourceColumns = vm.data.linkSourceColumns;
						$scope.linkStatData.sourceLib = vm.data.linkSourceLabel;
						$scope.linkStatData.fromEntite = false;

						$scope.linkStatData.linkMappings = link.mappings;
						$scope.linkStatData.availableLinks = [];
						$scope.linkStatData.linkData = { from: [], to: [] };
						let rightCaracCodes = [];
						for (let m in link.mappings) {
							$scope.linkStatData.availableLinks.push(m);
							let leftCol = _.find(
								$scope.linkStatData.sourceColumns,
								function (item) {
									return item.uuid === link.mappings[m].left_column_uuid;
								}
							);
							$scope.linkStatData.linkData.from.push({
								lib: leftCol.lib,
								code: leftCol.uuid,
							});
							let rightCode = link.mappings[m].right_carac_code
								? link.mappings[m].right_carac_code
								: 'COL_' + link.mappings[m].right_column_uuid;
							$scope.linkStatData.linkData.to.push({ code: rightCode });
							rightCaracCodes.push(rightCode);
						}
						TypeEntiteService.getLibsForMany([
							$scope.linkStatData.targetId,
						]).then(function (response) {
							$scope.linkStatData.targetLib =
								response.data[$scope.linkStatData.targetId];
							TypeEntiteService.getCaracLibsByCodes(
								$scope.linkStatData.targetId,
								rightCaracCodes
							).then(function (responseC) {
								for (let m in $scope.linkStatData.linkData.to) {
									$scope.linkStatData.linkData.to[m].lib =
										responseC.data[$scope.linkStatData.linkData.to[m].code];
								}
								$scope.vm.widgetData.showLinkStat = true;
							});
						});
					};

					$scope.selectLink = function (link) {
						const deferred = $q.defer();
						$scope.selectedLink = link;
						const isNotValid = link.checkLinkValidity(vm.data.datablock.id);
						if (isNotValid) {
							return;
						}
						setTargetEntityColumns(link).then(function () {
							deferred.resolve();
						});
						return deferred.promise;
					};

					$scope.addNewLinkMapping = function () {
						$scope.selectedLink.createMapping();
					};

					$scope.showWidgetList = function () {
						vm.widgetData.hdhElementRestrictedList = [];
						showWidgetSelectionList();
					};

					$scope.closeConfirmation = function () {
						vm.closeModal();
					};

					$scope.saveConfirmation = function () {
						const modalId = 'saveConfirmationModal';
						if (
							$scope.selectedLink &&
							($scope.selectedLink.mappings === undefined ||
								$scope.selectedLink.mappings.length === 0)
						) {
							$('#' + modalId).modal('show');
						} else {
							$scope.save();
						}
					};

					$scope.deleteLinks = function () {
						vm.widgetData.showConfirmationDialog = true;
						vm.widgetData.confirmationDialogTitle = gettextCatalog.getString(
							'Confirmer la suppression'
						);
						vm.widgetData.confirmationDialogMessage = gettextCatalog.getString(
							'Tous les paramètres ici définis seront perdus. Confirmer cette action ?'
						);
						vm.widgetData.confirmationDialogActionName = yesTra;
						vm.widgetData.enableConfirmDialogAction = true;
						vm.widgetData.confirmDialogAction = function () {
							confirmDeleteLinks();
						};
					};

					$scope.save = function (ignoreCartesian) {
						if (!$scope.links || !$scope.links[0]) {
							toaster.pop(
								'error',
								gettextCatalog.getString('Erreur'),
								gettextCatalog.getString('Le Lien est invalide')
							);
							return;
						}

						let notValid = false;

						$scope.links.forEach(function (link) {
							link.checkLinkValidity(vm.data.datablock.id);
							if (link.not_valid) {
								notValid = true;
							}
							link.addSelectColumns();
						});

						if (notValid) {
							return;
						}
						if (!ignoreCartesian) {
							const filtered = $scope.links.filter(function (link) {
								return !link.mappings || link.mappings.length === 0;
							});

							filtered.forEach(function (link) {
								link.setLinkType(LINK_TYPES.Cross);
							});

							if (filtered && filtered.length !== 0) {
								vm.widgetData.showConfirmationDialog = true;
								vm.widgetData.confirmationDialogTitle =
									gettextCatalog.getString('datablock.link.cartesianProduct');
								vm.widgetData.confirmationDialogMessage =
									gettextCatalog.getString(
										'datablock.link.cartesianProduct.disclaimer'
									);
								vm.widgetData.confirmationDialogActionName = yesTra;
								vm.widgetData.enableConfirmDialogAction = true;
								vm.widgetData.confirmDialogAction = function () {
									$scope.save(true);
								};
								return;
							}
						}

						vm.data.saveLinksMethod($scope.links, saveAndClose);
					};

					$scope.availableForStat = function (link) {
						if (!link.db_id) return false;
						if (!link.mappings || !link.mappings[0]) return false;
						if (
							_.filter(link.mappings, function (item) {
								return (
									(!item.left_column_uuid && !item.source_value) ||
									(!item.right_column_uuid &&
										!item.right_carac_code &&
										!item.target_value)
								);
							})[0]
						)
							return false;
						return true;
					};

					/**
					 * binded functions
					 */

					vm.closeModal = closeModal;
					vm.generateLinks = generateLinks;
					vm.toggleSourceMapping = toggleSourceMapping;
					vm.toggleTargetMapping = toggleTargetMapping;

					/**
					 * private functions
					 */

					function setTargetEntityColumns(link) {
						const deferred = $q.defer();
						const entityId = link.db_id;
						if (!entityId) {
							return;
						}
						DataBlocksService.isDataBlock(entityId).then(function (response) {
							if (response.data) {
								setSelectedDataBlock(entityId).then(function () {
									return deferred.resolve();
								});
							} else {
								TypeEntiteService.getTypeWithLibs(entityId).then(function (
									response_r
								) {
									setSelectedEntity(response_r.data).then(function () {
										return deferred.resolve();
									});
								});
							}
						});
						return deferred.promise;
					}

					function toggleSourceMapping(mapping) {
						mapping.toggleSourceMapping($scope.selectedLink.data_columns);
					}

					function toggleTargetMapping(mapping) {
						mapping.toggleTargetMapping();
					}

					function closeModal() {
						$(vm.element).modal('hide');
						vm.data.showDbLinks = false;
					}

					function generateLinks(inputLinks, inputColumns) {
						$scope.inputSourceColumns = inputColumns;
						$scope.links = inputLinks.map(function (l) {
							const linkEntity = new DatablockLinkEntity(l);
							linkEntity.generateMappings(l.mappings);
							return linkEntity;
						});
						if ($scope.links && $scope.links.length !== 0) {
							$scope.selectLink($scope.links[0]);
						}
					}

					function saveAndClose() {
						$('#saveConfirmationModal').modal('hide');
						vm.closeModal();
						toaster.pop(
							'success',
							gettextCatalog.getString('Succès'),
							gettextCatalog.getString('Enregistrement effectué')
						);
					}

					function showWidgetSelectionList() {
						vm.widgetData.elementSelectionType = 'DATABLOCK_ENTITE';
						if (vm.datablock.id) {
							DataBlocksService.getReferencedDatablocks(vm.datablock.id).then(
								function (response) {
									openElementSelection(response.data);
								}
							);
						} else {
							openElementSelection([]);
						}
					}

					function selectDataBlockOrEntity(element, type) {
						clearMappingColumns();
						if (type === ENTITY_TYPE.datablock) {
							setSelectedDataBlock(element.id);
						} else if (type === ENTITY_TYPE.entity) {
							setSelectedEntity(element);
						}
					}

					function setSelectedDataBlock(dataBlockId) {
						const deferred = $q.defer();
						DataBlocksService.getDataBlock(dataBlockId).then(function (
							response
						) {
							let datablock = response.data;
							$scope.selectedLink.fromEntite = false;
							$scope.selectedLink.db_id = datablock.id;
							$scope.selectedLink.datablock = datablock;
							if (
								!$scope.selectedLink.lib ||
								$scope.selectedLink.lib.length === 0
							) {
								$scope.selectedLink.lib = datablock.metadata.label;
							}
							$scope.selectedLink.db_lib = datablock.metadata.label;

							let currentSelection;
							if (
								!$scope.selectedLink.columns &&
								$scope.selectedLink.columns.length === 0 &&
								$scope.selectedLink.data_columns &&
								$scope.selectedLink.data_columns.length !== 0
							) {
								currentSelection = $scope.selectedLink.data_columns.filter(
									(columns) => columns.selected === true
								);
							} else if (
								$scope.selectedLink.data_columns &&
								$scope.selectedLink.data_columns.length !== 0
							) {
								currentSelection = $scope.selectedLink.data_columns
									.filter((columns) => columns.selected === true)
									.map((c) => {
										const scFound = $scope.selectedLink.columns.find(
											(sc) => sc['column_uuid'] === c['column_uuid']
										);
										if (!scFound) {
											return;
										}
										c.uuid = scFound['uuid'];

										return c;
									});
							} else {
								currentSelection = $scope.selectedLink.columns;
							}
							DataBlocksService.getDBBuilderColumns(datablock.grammar).then(
								function (response) {
									const columns = response.data.map((columnConfig) => ({
										alias: columnConfig.lib,
										column_uuid: columnConfig.uuid,
										type: columnConfig.type,
										is_list: columnConfig.is_list,
									}));

									$scope.selectedLink.data_columns = columns;
									$scope.selectedLink.columns = currentSelection;
									$scope.selectedLink.updateMappings($scope.inputSourceColumns);
									if (
										$scope.selectedLink.columns &&
										$scope.selectedLink.columns.length !== 0
									) {
										for (let selectedColumn of $scope.selectedLink.columns) {
											const isReferenced =
												$scope.selectedLink.data_columns.find(
													(c) =>
														c['column_uuid'] === selectedColumn['column_uuid']
												);
											if (isReferenced === undefined) {
												return;
											}
											isReferenced.selected = true;
											isReferenced.uuid = selectedColumn.uuid;
										}
									}
									deferred.resolve();
								}
							);
						});
						return deferred.promise;
					}

					function setSelectedEntity(entity) {
						const deferred = $q.defer();
						$scope.selectedLink.fromEntite = true;
						$scope.selectedLink.db_id = entity.id;
						$scope.selectedLink.datablock = entity;
						var lib = entity.label;
						if (
							!$scope.selectedLink.lib ||
							$scope.selectedLink.lib.length === 0
						) {
							$scope.selectedLink.lib = lib;
						}
						$scope.selectedLink.db_lib = lib;

						let currentSelection;
						if (
							!$scope.selectedLink.columns &&
							$scope.selectedLink.columns.length === 0 &&
							$scope.selectedLink.data_columns &&
							$scope.selectedLink.data_columns.length !== 0
						) {
							currentSelection = $scope.selectedLink.data_columns.filter(
								(columns) => columns.selected === true
							);
						} else if (
							$scope.selectedLink.data_columns &&
							$scope.selectedLink.data_columns.length !== 0
						) {
							currentSelection = $scope.selectedLink.data_columns
								.filter((columns) => columns.selected === true)
								.map((c) => {
									const scFound = $scope.selectedLink.columns.find(
										(sc) => sc['carac_code'] === c['carac_code']
									);
									if (!scFound) {
										return;
									}
									c.uuid = scFound['uuid'];

									return c;
								});
						} else {
							currentSelection = $scope.selectedLink.columns;
						}
						CaracService.getSimpleCaracsByEntiteId(entity.id).then(function (
							response
						) {
							const columns = response.data.map((columnConfig) => ({
								alias: columnConfig.lib,
								carac_code: columnConfig.code,
								type: getTypeFromEntiteTypeCode(columnConfig.entite_type.code),
								is_list: columnConfig.list,
							}));

							$scope.selectedLink.data_columns = columns;
							$scope.selectedLink.columns = currentSelection;
							$scope.selectedLink.updateMappings($scope.inputSourceColumns);
							if (
								$scope.selectedLink.columns &&
								$scope.selectedLink.columns.length !== 0
							) {
								for (let selectedColumn of $scope.selectedLink.columns) {
									const isReferenced = $scope.selectedLink.data_columns.find(
										(c) => c['carac_code'] === selectedColumn['carac_code']
									);
									if (isReferenced === undefined) {
										return;
									}
									isReferenced.selected = true;
									isReferenced.uuid = selectedColumn.uuid;
								}
							}
							deferred.resolve();
						});
						return deferred.promise;
					}

					function clearMappingColumns() {
						$scope.selectedLink.mappings.forEach(function (mapping) {
							delete mapping.right_carac_code;
							delete mapping.right_column_uuid;
						});
					}

					function openElementSelection(elementSelectionExclude) {
						vm.widgetData.elementSelectionExclude = elementSelectionExclude;
						vm.widgetData.showHdhElementSelection = true;
						vm.widgetData.elementSelectionSaveMethod = selectDataBlockOrEntity;
					}

					function confirmDeleteLinks() {
						vm.data.saveLinksMethod([], saveAndClose);
					}
				},
			];

			return {
				restrict: 'E',

				scope: {
					data: '=',
					datablock: '=',
					widgetData: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/dataOperationTools/datablockLink/datablockLink.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs, $ctrl) {
					$(element).modal({
						show: false,
						keyboard: attrs.keyboard,
						backdrop: attrs.backdrop,
					});
					$(element).on('hidden.bs.modal', function (event) {
						if (event.target === element[0] && $ctrl.data.showDbLinks) {
							$ctrl.closeModal();
						}
					});

					scope.$watch(
						function () {
							return $ctrl.data.showDbLinks;
						},
						function (value) {
							$ctrl.element = element;
							if (value === true) {
								delete scope.selectedLink;
								for (var c in $ctrl.data.columns) {
									$ctrl.data.columns[c].index = c;
								}
								$ctrl.generateLinks($ctrl.data.step.links, $ctrl.data.columns);
								scope.linkId = 0;
								$(element).modal(
									{ backdrop: 'static', keyboard: false },
									'show'
								);
							} else {
								$(element).modal('hide');
							}
						}
					);
				},
			};
		},
	]);
})();
