(function () {
	'use strict';

	function log(msg, module, lvl, logFn) {
		const printed = module ? `[${module}] ${msg}` : `[N/A] ${msg}`;
		logFn(printed);
	}

	angular.module('dcApp').factory('DcLogger', DcLogger);

	function DcLogger() {
		function LoggerInstance(source, logFn, enabled = true) {
			this.source = source;
			this.logFn = logFn;
			this.enabled = enabled;
		}

		LoggerInstance.prototype = Object.create(null);
		LoggerInstance.prototype.constructor = LoggerInstance;

		LoggerInstance.prototype.debug = debugFn;
		LoggerInstance.prototype.log = loggingFn;
		LoggerInstance.prototype.info = infoFn;
		LoggerInstance.prototype.warn = warnFn;
		LoggerInstance.prototype.error = errorFn;

		function loggingFn(msg) {
			if (!this.enabled) {
				return;
			}
			log(msg, this.source, 'LOG', this.logFn.log);
		}

		function debugFn(msg) {
			if (!this.enabled) {
				return;
			}
			log(msg, this.source, 'DEBUG', this.logFn.debug);
		}

		function infoFn(msg) {
			if (!this.enabled) {
				return;
			}
			log(msg, this.source, 'INFO', this.logFn.info);
		}

		function warnFn(msg) {
			if (!this.enabled) {
				return;
			}
			log(msg, this.source, 'WARN', this.logFn.warn);
		}

		function errorFn(msg) {
			if (!this.enabled) {
				return;
			}
			log(msg, this.source, 'ERROR', this.logFn.error);
		}

		return LoggerInstance;
	}

	angular.module('dcApp').factory('LoggerFactory', LoggerFactory);

	LoggerFactory.$inject = ['$log', 'DcLogger'];

	function LoggerFactory($log, DcLogger) {
		return {
			getLogger: instantiateLogger,
		};

		function instantiateLogger(moduleName, enabled) {
			return new DcLogger(moduleName, $log, enabled);
		}
	}
})();
