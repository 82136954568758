(function () {
	'use strict';

	angular.module('dcApp').controller('DepotEditController', [
		'$scope',
		'$rootScope',
		'$stateParams',
		'toaster',
		'$state',
		'$filter',
		'$timeout',
		'$window',
		'$log',
		'PAGINATIONS_SIZE',
		'PAGINATIONS_SIZES',
		'DATE_TIME_PATTERN',
		'HTTPConnectorService',
		'ConnectorService',
		'DepotService',
		'DbSQLDepotService',
		'API_BASE_URL_BACKEND',
		'$http',
		'EntiteDepotService',
		'DepotExtractionFilterService',
		'TypeEntiteService',
		'DepotExtractionService',
		'CsvDepotService',
		'ParquetDepotService',
		'JsonDepotService',
		'DepotFileService',
		'ExcelDepotService',
		'$sce',
		'BinaryDepotService',
		'ParametersServices',
		'CTX_VAR_NAME',
		'frameService',
		'MetadataService',
		'CommonServices',
		'XmlDepotService',
		'MongodbDepotService',
		'Neo4jDepotService',
		'DBConnectorService',
		'gettextCatalog',
		'ModalService',
		'AllDepotService',
		function (
			$scope,
			$rootScope,
			$stateParams,
			toaster,
			$state,
			$filter,
			$timeout,
			$window,
			$log,
			PAGINATIONS_SIZE,
			PAGINATIONS_SIZES,
			DATE_TIME_PATTERN,
			HTTPConnectorService,
			ConnectorService,
			DepotService,
			DbSQLDepotService,
			API_BASE_URL_BACKEND,
			$http,
			EntiteDepotService,
			DepotExtractionFilterService,
			TypeEntiteService,
			DepotExtractionService,
			CsvDepotService,
			ParquetDepotService,
			JsonDepotService,
			DepotFileService,
			ExcelDepotService,
			$sce,
			BinaryDepotService,
			ParametersServices,
			CTX_VAR_NAME,
			frameService,
			MetadataService,
			CommonServices,
			XmlDepotService,
			MongodbDepotService,
			Neo4jDepotService,
			DBConnectorService,
			gettextCatalog,
			ModalService,
			AllDepotService
		) {
			const HEADER_REFERENCING_MODE_POSITION = 'POSITION';
			const HEADER_REFERENCING_MODE_LABEL = 'LABEL';
			let toasterSuccess = gettextCatalog.getString('Succès');
			let toasterPopEditDb = gettextCatalog.getString(
				'Modification du Dépôt Base de données effectuée'
			);

			let toasterPopError = gettextCatalog.getString('Erreur');
			let toasterPopErrorMessage = gettextCatalog.getString(
				'Un Connecteur est obligatoire !'
			);
			let toasterPopErrorTest = gettextCatalog.getString('Echec du test');
			let toasterPopTest = gettextCatalog.getString('Test Effectué');
			let toasterPopEditHeader = gettextCatalog.getString(
				"Modification de l'entête effectuée"
			);
			let toasterPopAddHeader = gettextCatalog.getString('Entête ajouté');
			let toasterPopDeleteHeader = gettextCatalog.getString(
				"Suppression de l'entête effectuée"
			);
			let toasterPopUpdateHeader = gettextCatalog.getString(
				'Mise à jour des entêtes effectuée'
			);
			let toasterPopDeleteAllHeader = gettextCatalog.getString(
				'Suppression de All defined settings will be lost. Do you confirm this action? les entêtes effectuée'
			);

			let toasterPopFile = gettextCatalog.getString('Le fichier');
			let toasterPopFileMask = gettextCatalog.getString(
				'ne correspond pas au masque de lecture'
			);
			let toasterPopFileSize = gettextCatalog.getString('est trop grand');

			let toasterPopOperationNotSupported = gettextCatalog.getString(
				'Opération non supportée'
			);
			let toasterPopOperationType = gettextCatalog.getString(
				'Opération uniquement possible pour les Dépôts utilisant un Connecteur LOCAL ou HDFS'
			);
			let toasterPopDepotEntityLink = gettextCatalog.getString(
				'Lien Entité métier-Dépôt effectué'
			);
			let toasterPopCreationExtraction = gettextCatalog.getString(
				'Extraction effectuée'
			);
			let toasterPopDeleteFilter = gettextCatalog.getString(
				'Suppression du filtre effectuée'
			);
			let toasterPopDeleteExtraction = gettextCatalog.getString(
				"Suppression de l'Extraction effectuée"
			);

			let toasterPopInputMask = gettextCatalog.getString(
				"Vous devez d'abord saisir un masque de lecture"
			);
			let toasterPopAddFilter = gettextCatalog.getString(
				'Ajout du filtre Extraction effectué'
			);
			let toasterNoHeaderFound = gettextCatalog.getString(
				'Aucun entête trouvé'
			);

			let addButton = gettextCatalog.getString('Ajouter');
			let saveButton = gettextCatalog.getString('Enregistrer');

			let clearFolderNames = {
				execution: gettextCatalog.getString('Exécution'),
				panding: gettextCatalog.getString('En attente'),
				Archives: gettextCatalog.getString('Archives'),
				rejected: gettextCatalog.getString('Fichiers rejetés'),
			};

			let translateErrorMessage = gettextCatalog.getString(
				'context.unavailable'
			);

			let fileNameTra = gettextCatalog.getString('Nom du fichier');

			let fileTra = gettextCatalog.getString('Fichier');

			let depotDetailsTra = gettextCatalog.getString('Détails Dépôt');

			let readerTypeSeparator = gettextCatalog.getString(
				'Fichier avec séparateur'
			);
			let readerTypeSql = gettextCatalog.getString('Syntaxe SQL');
			let readerTypeParquet = gettextCatalog.getString('Fichier Parquet');
			let readerTypeJson = gettextCatalog.getString('Fichier JSON');
			let readerTypeExcel = gettextCatalog.getString('Fichier Excel');
			let readerTypeBinary = gettextCatalog.getString(
				'Fichier Binaire (Tous fichiers)'
			);
			let readerTypeXML = gettextCatalog.getString('Fichier XML');
			let readerTypeMongodb = gettextCatalog.getString('Collection MongoDB');

			let depotCreation = gettextCatalog.getString('Création du Dépôt');
			let depotCreationOrUpdateSuccess = gettextCatalog.getString('effectuée');
			let depotUpdate = gettextCatalog.getString('Modification du Dépôt');
			let filtersDateOperatorsAll = gettextCatalog.getString('Toutes');
			let filtersDateOperatorsLast = gettextCatalog.getString('Dernier');
			let filtersDateOperatorsFrist = gettextCatalog.getString('Premier');
			let filtersDateOperatorsBefore = gettextCatalog.getString('Avant');
			let filtersDateOperatorsBetween = gettextCatalog.getString('Entre');
			let filtersDateOperatorsAfter = gettextCatalog.getString('Après');
			let filtersDateOperatorsWeekInMonth = gettextCatalog.getString(
				'Numéro de semaine dans le mois'
			);
			let filtersDateOperatorsWeekInYear = gettextCatalog.getString(
				"Numéro de semaine dans l'année"
			);
			let filtersDateOperatorsDayInWeek = gettextCatalog.getString(
				'Numéro de jour dans la semaine'
			);
			let filtersDateOperatorsDayInMonth = gettextCatalog.getString(
				'Numéro de jour dans le mois'
			);
			let filtersDateOperatorsDayInYear = gettextCatalog.getString(
				"Numéro de jour dans l'année"
			);
			let depotDeleteMessage = gettextCatalog.getString('Suppression du Dépôt');
			let DeleteToasterSuccess = gettextCatalog.getString('Succès');
			let readerTypeNo4j = gettextCatalog.getString('depot.reader.no4j');

			init();

			/**
			 * binded functions
			 */
			$scope.openUriParserModal = function () {
				if (!$scope.depot.path || $scope.depot.path === '') {
					return;
				}
				var locals = {
					title: gettextCatalog.getString('uriParser.title'),
					uri: $scope.depot.path,
				};

				ModalService.open({
					controllerAs: 'vm',
					bindToController: true,
					templateUrl:
						'./src/components/modules/depot/uri-parser/uri-parser.html',
					controller: 'UriParserController',
					disableBackdrop: true,
					locals: locals,
				}).then(function (uri) {
					$scope.depot.path = uri;
				});
			};

			$scope.openFileManagerModal = function () {
				if (
					!$scope.depot.connector &&
					!$scope.depot.connector.id &&
					!$scope.depot.connector.connector_type
				) {
					return;
				}

				let connectorId = $scope.depot.connector.id;
				let connectorType = $scope.depot.connector.connector_type;
				let connectorLabel = $scope.depot.connector.metadata
					? $scope.depot.connector.metadata.label
					: $scope.depot.connector.lib;
				let title = gettextCatalog.getString(
					'connector.remote.files.explorer',
					{
						connectorType: $filter('connectorTranslator')(connectorType),
						connectorLabel: connectorLabel,
					}
				);
				var locals = {
					title: title,
					connectorId: connectorId,
					type: connectorType,
				};

				ModalService.open({
					controllerAs: 'vm',
					bindToController: true,
					templateUrl:
						'./src/components/modules/connector/remote-file-explorer/remote-file-explorer.html',
					controller: 'RemoteFilesExplorerController',
					disableBackdrop: true,
					locals: locals,
				}).then(function (path) {
					$scope.depot.path = path;
				});
			};

			$scope.selectOrUnSelectConnector = function () {
				if ($scope.isWithConnector === 'true') {
					$scope.depot.connector =
						$scope.isNewDepot && $stateParams.duplicatedId == undefined
							? {}
							: $scope.depot.connector;
					$scope.depot.depot_type =
						$scope.isNewDepot && $stateParams.duplicatedId == undefined
							? undefined
							: $scope.depot.depot_type;
				} else {
					$scope.depot.connector = $scope.isNewDepot
						? {
								lib: $scope.localMode,
								connector_type: 'LOCAL',
								isNatif: true,
						  }
						: $scope.depot.connector;
					$scope.depot.depot_type =
						$scope.isNewDepot && $stateParams.duplicatedId == undefined
							? 'CSV'
							: $scope.depot.depot_type;
				}
				$scope.filterReaders();
			};

			$scope.filterReaders = function () {
				let connectorType = $scope.depot.connector
					? $scope.depot.connector.connector_type
					: '';
				if (connectorType !== '' && connectorType !== undefined) {
					$scope.filteredreaderTypes = _.filter(
						$scope.readerTypes,
						function (elm) {
							return _.indexOf(elm.supportedConnectors, connectorType) != -1;
						}
					);
				} else {
					$scope.filteredreaderTypes = $scope.readerTypes;
				}
				if (connectorType === 'HTTP') {
					$scope.depot.additional_params = $scope.depot.additional_params
						? $scope.depot.additional_params
						: {};
					$scope.depot.additional_params.iteration =
						$scope.depot.additional_params.iteration !== undefined
							? $scope.depot.additional_params.iteration
							: {};
					$scope.depot.additional_params.iteration.mode =
						$scope.depot.additional_params.iteration.mode !== undefined
							? $scope.depot.additional_params.iteration.mode
							: $scope.httpIterationModes[0].value;
				}
			};

			$scope.setSelectedCharset = function (autoCharset) {
				let connectorType =
					$scope.depot &&
					$scope.depot.connector &&
					$scope.depot.connector.connector_type
						? $scope.depot.connector.connector_type
						: undefined;
				$scope.selectedCharset =
					connectorType === 'S3' &&
					$scope.selectedCharset &&
					$scope.selectedCharset.value === 'DEFAULT'
						? undefined
						: $scope.selectedCharset;
				let found = false;
				for (let i in $scope.charsets) {
					if (
						$scope.depot &&
						$scope.depot.charset === $scope.charsets[i].value
					) {
						$scope.selectedCharset = $scope.charsets[i];
						found = true;
						break;
					}
				}
				if (
					(!found && autoCharset !== undefined) ||
					$scope.depot.charset === undefined
				) {
					if (autoCharset === undefined) {
						for (let i in $scope.charsets) {
							if ($scope.charsets[i].value === 'DEFAULT') {
								$scope.selectedCharset = $scope.charsets[i];
								break;
							}
						}
					}
				}
				$scope.depot.charset = $scope.selectedCharset.value;
			};

			$scope.refreshHeader = function () {
				if ($scope.depot.has_header == false)
				{
					$scope.depot.headers_referencing_mode = HEADER_REFERENCING_MODE_POSITION;
				}
			};

			$scope.filterEncodingAndReaders = function () {
				filterReaders();
				filterCharset();
			};

			$scope.showConfirmationModal = function () {
				$('#confirmationModal').modal('show');
			};

			$scope.redirectionToList = redirectionToList;

			$scope.saveDepot = function () {
				if (!$scope.isNewDepot) {
					$scope.checkPermissionData.elementId = $scope.depot.id;
					$scope.checkPermissionData.type = 'data_source';
					$scope.checkPermissionData.action = 'edit';
					$scope.checkPermissionData.actionIfPermitted = $scope.save;
					$scope.checkPermissionData.showPermissionCheck = true;
				} else {
					$scope.save();
				}
			};

			$scope.detectionHeadersDepot = function () {
				if (!$scope.isNewDepot) {
					$scope.checkPermissionData.elementId = $scope.depot.id;
					$scope.checkPermissionData.type = 'data_source';
					$scope.checkPermissionData.action = 'edit';
					$scope.checkPermissionData.actionIfPermitted = $scope.showSyncHeadersConfirmationMessage;
					$scope.checkPermissionData.showPermissionCheck = true;
				} else {
					$scope.showSyncHeadersConfirmationMessage();
				}
			};

			$scope.updatePositionHeadersDepot = function (index) {
				$scope.newHeader =
					index !== undefined ? $scope.headers[index].column : '';
				$scope.newHeaderType =
					index !== undefined ? $scope.headers[index].data_type : 'StringType';
				$scope.headerToUpdateIndex = index;
				$scope.buttonName = index !== undefined ? saveButton : addButton;
				if (!$scope.isNewDepot) {
					$scope.checkPermissionData.elementId = $scope.depot.id;
					$scope.checkPermissionData.type = 'data_source';
					$scope.checkPermissionData.action = 'edit';
					$scope.checkPermissionData.actionIfPermitted = $scope.showAddHeaderModal;
					$scope.checkPermissionData.showPermissionCheck = true;
				} else {
					$scope.showAddHeaderModal();
				}
			};

			$scope.deleteHeadersDepot = function (index) {
				$scope.headerToUpdateIndex = index;
				$scope.headerLibToDel =
					$scope.headers != undefined && $scope.headers[index] != undefined
						? $scope.headers[index].column
						: '-';
				if (!$scope.isNewDepot) {
					$scope.checkPermissionData.elementId = $scope.depot.id;
					$scope.checkPermissionData.type = 'data_source';
					$scope.checkPermissionData.action = 'edit';
					$scope.checkPermissionData.actionIfPermitted = $scope.showDeleteHeaderModal;
					$scope.checkPermissionData.showPermissionCheck = true;
				} else {
					$scope.showDeleteHeaderModal();
				}
			};

			$scope.deleteAllHeadersDepot = function () {
				if (!$scope.isNewDepot) {
					$scope.checkPermissionData.elementId = $scope.depot.id;
					$scope.checkPermissionData.type = 'data_source';
					$scope.checkPermissionData.action = 'edit';
					$scope.checkPermissionData.actionIfPermitted = $scope.showDeleteAllHeadersModal;
					$scope.checkPermissionData.showPermissionCheck = true;
				} else {
					$scope.showDeleteAllHeadersModal();
				}
			};

			$scope.createExtractionDepot = function () {
				if (!$scope.isNewDepot) {
					$scope.checkPermissionData.elementId = $scope.depot.id;
					$scope.checkPermissionData.type = 'data_source';
					$scope.checkPermissionData.action = 'edit';
					$scope.checkPermissionData.actionIfPermitted = $scope.createDepotExtraction;
					$scope.checkPermissionData.showPermissionCheck = true;
				} else {
					$scope.createDepotExtraction();
				}
			};

			$scope.createFilterExtractionDepot = function (filter) {
				$scope.filterValue=filter;
				if (!$scope.isNewDepot) {
					$scope.checkPermissionData.elementId = $scope.depot.id;
					$scope.checkPermissionData.type = 'data_source';
					$scope.checkPermissionData.action = 'edit';
					$scope.checkPermissionData.actionIfPermitted = $scope.showAddEditNewFilterModal;
					$scope.checkPermissionData.showPermissionCheck = true;
				} else {
					$scope.showAddEditNewFilterModal();
				}	
			};

			$scope.deleteFilterExtractionDepot = function (entity) {
				$scope.FilterTodeleteId = entity.id;
				if (!$scope.isNewDepot) {
					$scope.checkPermissionData.elementId = $scope.depot.id;
					$scope.checkPermissionData.type = 'data_source';
					$scope.checkPermissionData.action = 'edit';
					$scope.checkPermissionData.actionIfPermitted = $scope.showDeleteFilterConfirmationModal;
					$scope.checkPermissionData.showPermissionCheck = true;
				} else {
					$scope.showDeleteFilterConfirmationModal();
				}
			};

			$scope.deleteExtractionDepot = function (entity) {
				$scope.archiveTodeleteId = entity.id != undefined ? entity.id : entity.date;
				if (!$scope.isNewDepot) {
					$scope.checkPermissionData.elementId = $scope.depot.id;
					$scope.checkPermissionData.type = 'data_source';
					$scope.checkPermissionData.action = 'edit';
					$scope.checkPermissionData.actionIfPermitted = $scope.showDeleteArchiveConfirmationModal;
					$scope.checkPermissionData.showPermissionCheck = true;
				} else {
					$scope.showDeleteArchiveConfirmationModal();
				}
			};

			$scope.save = save;

			$scope.saveDbDepot = function (returnToList) {
				$('#confirmationModal').modal('hide');

				// set metadata
				for (let t in $scope.metadata.tags) {
					$scope.metadata.tags[t].color =
						$scope.metadata.tags[t].color !== undefined
							? $scope.metadata.tags[t].color
							: '#dbf5d1';
				}

				let dbDepot = {
					metadata: $scope.metadata,
					sql_query: $scope.depot.sql_query,
					code: $scope.metadata.code,
					headers: $scope.headers,
					connector_id: $scope.depot.connector.id,
				};

				$scope.savingInProgress = true;

				if ($scope.isNewDepot) {
					DbSQLDepotService.createDbDepot(dbDepot)
						.then(function (response) {
							createDepotSuccess(returnToList, response.data, 'SQL');
						})
						.catch(catchErrors);
				} else {
					DbSQLDepotService.editDbDepot($scope.depot.id, dbDepot)
						.then(function (response) {
							toaster.pop('success', toasterSuccess, toasterPopEditDb);
							if (returnToList) {
								$timeout(function () {
									$state.go('depots');
								}, 300);
							}
						})
						.catch(catchErrors);
				}
			};

			$scope.saveMongodbDepot = function (returnToList) {
				$('#confirmationModal').modal('hide');

				// set metadata
				for (let t in $scope.metadata.tags) {
					$scope.metadata.tags[t].color =
						$scope.metadata.tags[t].color !== undefined
							? $scope.metadata.tags[t].color
							: '#dbf5d1';
				}

				let mongodbDepot = angular.copy($scope.depot);
				delete mongodbDepot.delimiter_csv;
				delete mongodbDepot.quote_csv;
				delete mongodbDepot.has_header;
				delete mongodbDepot.auto_integration;
				delete mongodbDepot.replace_file;
				delete mongodbDepot.charset;
				delete mongodbDepot.depot_type;
				delete mongodbDepot.query;
				mongodbDepot.metadata = $scope.metadata;
				mongodbDepot.code = $scope.metadata.code;
				mongodbDepot.headers = $scope.headers;
				mongodbDepot.connector_id = mongodbDepot.connector.id;
				delete mongodbDepot.connector;
				delete mongodbDepot.id;
				$scope.savingInProgress = true;

				if ($scope.isNewDepot) {
					MongodbDepotService.createMongodbDepot(mongodbDepot)
						.then(function (response) {
							createDepotSuccess(returnToList, response.data, 'MONGODB');
						})
						.catch(catchErrors);
				} else {
					MongodbDepotService.editMongodbDepot($scope.depot.id, mongodbDepot)
						.then(function (response) {
							toaster.pop(
								'success',
								toasterSuccess,
								depotUpdate +
									depotTypeLib('MONGODB') +
									depotCreationOrUpdateSuccess
							);
							if (returnToList) {
								$timeout(function () {
									$state.go('depots');
								}, 300);
							}
						})
						.catch(catchErrors);
				}
			};

			$scope.saveNeo4jDepot = function (returnToList) {
				$('#confirmationModal').modal('hide');

				// set metadata
				for (let t in $scope.metadata.tags) {
					$scope.metadata.tags[t].color =
						$scope.metadata.tags[t].color !== undefined
							? $scope.metadata.tags[t].color
							: '#dbf5d1';
				}

				let neo4JDepot = {
					metadata: $scope.metadata,
					query: $scope.depot.query,
					code: $scope.metadata.code,
					headers: $scope.headers,
					connector_id: $scope.depot.connector.id,
				};
				$scope.savingInProgress = true;

				if ($scope.isNewDepot) {
					Neo4jDepotService.createNeo4jDepot(neo4JDepot)
						.then(function (response) {
							createDepotSuccess(returnToList, response.data, 'NEO4J');
						})
						.catch(catchErrors);
				} else {
					Neo4jDepotService.editNeo4jDepot($scope.depot.id, neo4JDepot)
						.then(function (response) {
							toaster.pop(
								'success',
								toasterSuccess,
								depotUpdate +
									depotTypeLib('NEO4J') +
									depotCreationOrUpdateSuccess
							);
							if (returnToList) {
								$timeout(function () {
									$state.go('depots');
								}, 300);
							}
						})
						.catch(catchErrors);
				}
			};

			$scope.saveCsvDepot = function (returnToList) {
				$('#confirmationModal').modal('hide');
				// set metadata
				for (let t in $scope.metadata.tags) {
					$scope.metadata.tags[t].color =
						$scope.metadata.tags[t].color !== undefined
							? $scope.metadata.tags[t].color
							: '#dbf5d1';
				}

				let csvDepot = angular.copy($scope.depot);
				csvDepot.metadata = $scope.metadata;
				csvDepot.code = $scope.metadata.code;
				csvDepot.headers = $scope.headers;
				csvDepot.current_version_mask =
					csvDepot.current_version_mask != undefined
						? csvDepot.current_version_mask
						: '*.{csv,CSV,cSv,cSV,Csv,CSv,csV,CsV}';
				delete csvDepot.query;
				csvDepot.connector_id = csvDepot.connector.id;
				delete csvDepot.connector;
				$scope.savingInProgress = true;

				if ($scope.isNewDepot) {
					CsvDepotService.createCsvDepot(csvDepot)
						.then(function (response) {
							createDepotSuccess(returnToList, response.data, 'CSV');
						})
						.catch(catchErrors);
				} else {
					CsvDepotService.editCsvDepot($scope.depot.id, csvDepot)
						.then(function (response) {
							saveDepotSuccess(returnToList, response.data, 'CSV');
						})
						.catch(catchErrors);
				}
			};

			$scope.saveParquetDepot = function (returnToList) {
				$('#confirmationModal').modal('hide');
				// set metadata
				for (let t in $scope.metadata.tags) {
					$scope.metadata.tags[t].color =
						$scope.metadata.tags[t].color !== undefined
							? $scope.metadata.tags[t].color
							: '#dbf5d1';
				}

				let parquetDepot = angular.copy($scope.depot);
				parquetDepot.metadata = $scope.metadata;
				parquetDepot.code = $scope.metadata.code;
				parquetDepot.headers = $scope.headers;
				parquetDepot.current_version_mask =
					parquetDepot.current_version_mask != undefined
						? parquetDepot.current_version_mask
						: '*.{parquet,PARQUET,Parquet}';
				delete parquetDepot.query;
				delete parquetDepot.charset;
				parquetDepot.connector_id = parquetDepot.connector.id;
				delete parquetDepot.connector;
				$scope.savingInProgress = true;

				if ($scope.isNewDepot) {
					ParquetDepotService.createParquetDepot(parquetDepot)
						.then(function (response) {
							createDepotSuccess(returnToList, response.data, 'PARQUET');
						})
						.catch(catchErrors);
				} else {
					ParquetDepotService.editParquetDepot($scope.depot.id, parquetDepot)
						.then(function (response) {
							saveDepotSuccess(returnToList, response.data, 'PARQUET');
						})
						.catch(catchErrors);
				}
			};

			$scope.saveJsonDepot = function (returnToList) {
				$('#confirmationModal').modal('hide');
				// set metadata
				for (let t in $scope.metadata.tags) {
					$scope.metadata.tags[t].color =
						$scope.metadata.tags[t].color !== undefined
							? $scope.metadata.tags[t].color
							: '#dbf5d1';
				}

				let jsonDepot = angular.copy($scope.depot);
				jsonDepot.metadata = $scope.metadata;
				jsonDepot.code = $scope.metadata.code;
				jsonDepot.headers = $scope.headers;
				jsonDepot.current_version_mask =
					jsonDepot.current_version_mask != undefined
						? jsonDepot.current_version_mask
						: '*.{pdf,PDF,pDf,pDF,Pdf,PDf,pdF,PdF}';
				delete jsonDepot.query;
				jsonDepot.connector_id = jsonDepot.connector.id;
				delete jsonDepot.connector;
				delete jsonDepot.headers_referencing_mode;
				$scope.savingInProgress = true;

				if ($scope.isNewDepot) {
					JsonDepotService.createJsonDepot(jsonDepot)
						.then(function (response) {
							createDepotSuccess(returnToList, response.data, 'JSON');
						})
						.catch(catchErrors);
				} else {
					JsonDepotService.editJsonDepot($scope.depot.id, jsonDepot)
						.then(function (response) {
							saveDepotSuccess(returnToList, response.data, 'JSON');
						})
						.catch(catchErrors);
				}
			};

			$scope.saveExcelDepot = function (returnToList) {
				$('#confirmationModal').modal('hide');
				// set metadata
				for (let t in $scope.metadata.tags) {
					$scope.metadata.tags[t].color =
						$scope.metadata.tags[t].color !== undefined
							? $scope.metadata.tags[t].color
							: '#dbf5d1';
				}

				let excelDepot = angular.copy($scope.depot);
				excelDepot.metadata = $scope.metadata;
				excelDepot.code = $scope.metadata.code;
				excelDepot.headers = $scope.headers;
				delete excelDepot.query;
				delete excelDepot.charset;
				excelDepot.connector_id = excelDepot.connector.id;
				excelDepot.current_version_mask =
					excelDepot.current_version_mask != undefined
						? excelDepot.current_version_mask
						: '*.{xls,xlsx,XLS,xLs,xLS,Xls,XLs,xlS,XlS}';
				delete excelDepot.connector;
				$scope.savingInProgress = true;

				if ($scope.isNewDepot) {
					ExcelDepotService.createExcelDepot(excelDepot)
						.then(function (response) {
							createDepotSuccess(returnToList, response.data, 'EXCEL');
						})
						.catch(catchErrors);
				} else {
					ExcelDepotService.editExcelDepot($scope.depot.id, excelDepot)
						.then(function (response) {
							saveDepotSuccess(returnToList, response.data, 'EXCEL');
						})
						.catch(catchErrors);
				}
			};

			$scope.saveBinaryDepot = function (returnToList) {
				$('#confirmationModal').modal('hide');
				// set metadata
				for (let t in $scope.metadata.tags) {
					$scope.metadata.tags[t].color =
						$scope.metadata.tags[t].color !== undefined
							? $scope.metadata.tags[t].color
							: '#dbf5d1';
				}
				let binaryDepot = angular.copy($scope.depot);
				binaryDepot.metadata = $scope.metadata;
				binaryDepot.code = $scope.metadata.code;
				binaryDepot.headers = $scope.headers;
				binaryDepot.current_version_mask =
					binaryDepot.current_version_mask != undefined
						? binaryDepot.current_version_mask
						: '*.{json,JSON,Json,ndjson}';
				delete binaryDepot.query;
				delete binaryDepot.charset;
				binaryDepot.connector_id = binaryDepot.connector.id;
				delete binaryDepot.connector;
				$scope.savingInProgress = true;

				if ($scope.isNewDepot) {
					BinaryDepotService.createBinaryDepot(binaryDepot).then(function (
						response
					) {
						createDepotSuccess(returnToList, response.data, 'BINARY');
					});
				} else {
					BinaryDepotService.editBinaryDepot($scope.depot.id, binaryDepot)
						.then(function (response) {
							saveDepotSuccess(returnToList, response.data, 'BINARY');
						})
						.catch(catchErrors);
				}
			};

			$scope.saveXmlDepot = function (returnToList) {
				$('#confirmationModal').modal('hide');
				// set metadata
				for (let t in $scope.metadata.tags) {
					$scope.metadata.tags[t].color =
						$scope.metadata.tags[t].color !== undefined
							? $scope.metadata.tags[t].color
							: '#dbf5d1';
				}

				let xmlDepot = angular.copy($scope.depot);
				xmlDepot.metadata = $scope.metadata;
				xmlDepot.code = $scope.metadata.code;
				xmlDepot.headers = $scope.headers;
				xmlDepot.current_version_mask =
					xmlDepot.current_version_mask != undefined
						? xmlDepot.current_version_mask
						: '*.{xml,XML,Xml,XMl,xML,xmL,XmL}';
				delete xmlDepot.query;
				xmlDepot.connector_id = xmlDepot.connector.id;
				delete xmlDepot.connector;
				delete xmlDepot.headers_referencing_mode;
				$scope.savingInProgress = true;

				if ($scope.isNewDepot) {
					XmlDepotService.createXmlDepot(xmlDepot)
						.then(function (response) {
							createDepotSuccess(returnToList, response.data, 'XML');
						})
						.catch(catchErrors);
				} else {
					XmlDepotService.editXmlDepot($scope.depot.id, xmlDepot)
						.then(function (response) {
							saveDepotSuccess(returnToList, response.data, 'XML');
						})
						.catch(catchErrors);
				}
			};

			$scope.showLimitConfirmationModal = function () {
				$scope.sqlTestQuery = angular.copy($scope.depot.sql_query);
				$scope.checkPermissionData.elementId = $scope.depot.connector.id;
				$scope.checkPermissionData.type = 'connector';
				$scope.checkPermissionData.action = 'data';
				$scope.checkPermissionData.actionIfPermitted =
					$scope.doShowLimitConfirmationModal;
				$scope.checkPermissionData.showPermissionCheck = true;
			};

			$scope.doShowLimitConfirmationModal = function () {
				$scope.checkPermissionData.elementId = $scope.depot.connector.id;
				$scope.checkPermissionData.type = 'connector';
				$scope.checkPermissionData.action = 'data';
				$scope.checkPermissionData.actionIfPermitted = $scope.save;
				$scope.checkPermissionData.showPermissionCheck = true;
				$scope.sqlTestQuery = angular.copy($scope.depot.sql_query);
				$('#sqlLimitConfirmation').modal('show');
			};

			$scope.testQuery = function (modalId) {
				if (
					$scope.depot.connector == null ||
					$scope.depot.connector == undefined
				) {
					toaster.pop('error', toasterPopError, toasterPopErrorMessage);
					return;
				}
				let data = {
					metadata: $scope.metadata,
					sql_query: $scope.sqlTestQuery,
					code: $scope.metadata.code,
					headers: $scope.headers,
					connector_id: $scope.depot.connector.id,
				};
				$scope.jobError = {};
				$scope.jobError.mainPosition = false;
				DbSQLDepotService.getDbPreview(data, true)
					.then(function (response) {
						$('#sqlLimitConfirmation').modal('hide');
						$scope.entiteTypeFileHeader = getHeaders(response.data.headers);
						$scope.entiteTypeFileDataPreview = response.data.data_preview;
						if (
							$scope.entiteTypeFileHeader == undefined ||
							$scope.entiteTypeFileDataPreview == undefined
						) {
							$scope.entiteTypeFileHeader = {};
							$scope.entiteTypeFileDataPreview = {};
							toaster.pop('error', toasterPopError, toasterPopErrorTest);
						} else {
							toaster.pop('success', toasterSuccess, toasterPopTest);
						}
						$scope.initGridData();
						$('#' + modalId).modal('show');
					})
					.catch(catchErrors);
			};

			$scope.testNeo4jQuery = function () {
				if (
					$scope.depot.connector == null ||
					$scope.depot.connector == undefined
				) {
					toaster.pop('error', toasterPopError, toasterPopErrorMessage);
					return;
				}
				let data = {
					query: $scope.depot.query,
					connector_id: $scope.depot.connector.id,
				};
				$scope.jobError = {};
				$scope.jobError.mainPosition = false;
				let modalId = 'dataPreviewModal';
				Neo4jDepotService.getNeo4jPreview(data, true)
					.then(function (response) {
						$scope.entiteTypeFileHeader = getHeaders(response.data.headers);
						$scope.entiteTypeFileDataPreview = response.data.data_preview;
						if (
							$scope.entiteTypeFileHeader === undefined ||
							$scope.entiteTypeFileDataPreview === undefined
						) {
							$scope.entiteTypeFileHeader = {};
							$scope.entiteTypeFileDataPreview = {};
							toaster.pop('error', toasterPopError, toasterPopErrorTest);
						} else {
							toaster.pop('success', toasterSuccess, toasterPopTest);
						}
						$scope.initGridData();
						$('#' + modalId).modal('show');
					})
					.catch(catchErrors);
			};

			$scope.openDataPreviewer = function (file) {
				$scope.fileToView = file;
				if (
					$scope.depot.connector &&
					$scope.depot.connector.connector_type !== 'LOCAL'
				) {
					$scope.checkPermissionData.elementId = $scope.depot.connector.id;
					$scope.checkPermissionData.type = 'connector';
					$scope.checkPermissionData.action = 'data';
				} else if (
					$scope.depot.connector &&
					$scope.depot.connector.connector_type == 'LOCAL'
				) {
					$scope.checkPermissionData.elementId = $scope.depot.id;
					$scope.checkPermissionData.type = 'data_source';
					$scope.checkPermissionData.action = 'data';
				} else {
					$scope.doOpenDataPreviewer();
					return;
				}

				$scope.checkPermissionData.actionIfPermitted =
					$scope.doOpenDataPreviewer;
				$scope.checkPermissionData.showPermissionCheck = true;
			};

			$scope.downloadLocalFile = function (fileName, depotId, depotType) {
				AllDepotService.downloadLocalFile(fileName, depotId, depotType);
			};

			$scope.doOpenDataPreviewer = function () {
				let file = $scope.fileToView;
				let modalId = 'dataPreviewModal';
				$scope.isExtractionPreview = false;
				$scope.jobError = {};
				$scope.jobError.mainPosition = true;
				if ($scope.depot.depot_type === 'BINARY') {
					$scope.binaryReady = false;
					DepotFileService.getRemoteBinaryFilePreview(
						$scope.depot.id,
						file.fileName,
						$stateParams.type !== undefined
							? $stateParams.type
							: $scope.depot.depot_type
					)
						.then(function (response) {
							let type = response.headers('Content-Type');
							type = type !== undefined ? type : 'application/octet-stream';
							$scope.dataPdfTest = 'data:' + type + ';base64,' + response.data;
							$scope.dataResponseType = type;
							$('#' + modalId).modal('show');
							$scope.binaryReady = true;
						})
						.catch(catchErrors);
				} else if ($scope.depot.depot_type === 'MONGODB') {
					if (
						$scope.depot.connector === null ||
						$scope.depot.connector === undefined
					) {
						toaster.pop('error', toasterPopError, toasterPopErrorMessage);
						return;
					}
					let data = angular.copy($scope.depot);
					data.connector_id = data.connector.id;
					delete data.connector;
					$scope.jobError = {};
					$scope.jobError.mainPosition = false;
					MongodbDepotService.getMongodbPreview(data, true)
						.then(function (response) {
							$scope.entiteTypeFileHeader = getHeaders(response.data.headers);
							$scope.entiteTypeFileDataPreview = response.data.data_preview;
							$scope.initGridData();
							$('#' + modalId).modal('show');
						})
						.catch(catchErrors);
				} else if ($scope.depot.depot_type === 'NEO4J') {
					if (
						$scope.depot.connector === null ||
						$scope.depot.connector === undefined
					) {
						toaster.pop('error', toasterPopError, toasterPopErrorMessage);
						return;
					}
					let data = angular.copy($scope.depot);
					data.connector_id = data.connector.id;
					delete data.connector;
					$scope.jobError = {};
					$scope.jobError.mainPosition = false;
					Neo4jDepotService.getNeo4jPreview(data, true)
						.then(function (response) {
							$scope.entiteTypeFileHeader = getHeaders(response.data.headers);
							$scope.entiteTypeFileDataPreview = response.data.data_preview;
							$scope.initGridData();
							$('#' + modalId).modal('show');
						})
						.catch(catchErrors);
				} else {
					DepotFileService.getRemoteFilePreview(
						$scope.depot.id,
						file.fileName,
						$stateParams.type !== undefined
							? $stateParams.type
							: $scope.depot.depot_type
					)
						.then(function (response) {
							$scope.entiteTypeFileHeader = getHeaders(response.data.headers);
							$scope.entiteTypeFileDataPreview = response.data.data_preview;
							$scope.initGridData();
							$('#' + modalId).modal('show');
						})
						.catch(catchErrors);
				}
			};

			$scope.showExtractionPreview = function (Extraction) {
				$scope.extractionToView = Extraction;
				$scope.checkPermissionData.elementId = $scope.depot.id;
				$scope.checkPermissionData.type = 'data_source';
				$scope.checkPermissionData.action = 'data';
				$scope.checkPermissionData.actionIfPermitted =
					$scope.doShowExtractionPreview;
				$scope.checkPermissionData.showPermissionCheck = true;
			};

			$scope.doShowExtractionPreview = function () {
				$scope.jobError = {};
				$scope.jobError.mainPosition = true;
				DepotExtractionService.getExtractionPreview(
					$scope.depot.id,
					$scope.extractionToView.date,
					$stateParams.type != undefined
						? $stateParams.type
						: $scope.depot.depot_type
				)
					.then(function (response) {
						$scope.entiteTypeFileHeader = getHeaders(response.data.headers);
						$scope.entiteTypeFileDataPreview = response.data.data_preview;
						$scope.initGridData();
						$scope.isExtractionPreview = true;
						$('#dataPreviewModal').modal('show');
					})
					.catch(catchErrors);
			};

			let resolveGridInstance = function () {
				if (Object.keys($scope.gridOptions).length === 0) {
					return;
				}
				return Promise.resolve($scope.gridOptions.onInitialized);
			};

			$scope.initGridData = async function () {
				$scope.showDataPreviewGrid = false;
				$scope.gridData = [];
				$scope.gridOptions = {};
				let generatedColumns = [];
				//Create Grid Columns
				let headearsWithNoSpecChar = [];
				for (let i = 0; i < $scope.entiteTypeFileHeader.length; i++) {
					let columnName = $scope.entiteTypeFileHeader[i];
					let columnDepName =
						$scope.headers[i] != undefined ? $scope.headers[i].column : '';
					let headerNoSpecChar = columnName
						.replace(/[^a-zA-Z0-9_-]/g, '_')
						.toLowerCase();
					headearsWithNoSpecChar[i] = headerNoSpecChar;
					if (i < 10) {
						$scope.moreThanTenColumns = false;
						generatedColumns.push({
							dataField: headerNoSpecChar,
							caption: '*' + columnDepName + ' / ' + columnName,
						});
					} else {
						$scope.moreThanTenColumns = true;
						generatedColumns.push({
							dataField: headerNoSpecChar,
							caption: '*' + columnDepName + ' / ' + columnName,
							visible: false,
						});
					}
				}
				//Load Data
				for (
					let count = 0;
					count < $scope.entiteTypeFileDataPreview.length;
					count++
				) {
					let object = $scope.gridData[count];
					let value = $scope.entiteTypeFileDataPreview[count];
					for (let l = 0; l < headearsWithNoSpecChar.length; l++) {
						let colName = headearsWithNoSpecChar[l];
						if (object == undefined) {
							object = {};
						}

						object[colName] = value[l];
						$scope.gridData[count] = object;
					}
				}
				$scope.gridOptions.pager = {
					showPageSizeSelector: true,
					allowedPageSizes: PAGINATIONS_SIZES,
					showInfo: true,
					visible: true,
				};
				$scope.gridOptions.paging = {
					enabled: true,
					pageSize: PAGINATIONS_SIZE,
				};
				$scope.gridOptions.filterRow = {
					visible: true,
					applyFilter: 'auto',
				};
				$scope.gridOptions.rowAlternationEnabled = true;
				$scope.gridOptions.headerFilter = {
					visible: true,
					applyFilter: 'auto',
				};
				$scope.gridOptions.columnChooser = {
					enabled: true,
				};
				$scope.gridOptions.onContentReady = function (e) {
					$scope.noColumnToChoose =
						e.component.getVisibleColumns().length === generatedColumns.length;
				};
				$scope.gridOptions.showColumnLines = true;
				$scope.gridOptions.showRowLines = true;
				$scope.gridOptions.allowColumnReordering = true;
				$scope.gridOptions.allowColumnResizing = true;
				$scope.gridOptions.columnAutoWidth = true;
				$scope.gridOptions.showBorders = true;
				$scope.gridOptions.onInitialized = function (e) {
					$scope.gridInstance = e.component;
				};
				$scope.gridOptions.dataSource = $scope.gridData;
				// Set a bigger z-index
				$(function () {
					DevExpress.ui.dxOverlay.baseZIndex(1999999998);
				});
				// Show Cell value in a popup
				$scope.gridOptions.onCellClick = function (e) {
					showValuePreviewOnCellDoubleClick(e, $scope.getShowValuePopup);
				};
				$scope.showDataPreviewGrid = true;
				await resolveGridInstance();
				if ($scope.gridInstance && $scope.gridInstance.option) {
					$scope.gridInstance.option('columns', generatedColumns);
					$scope.gridInstance.option('dataSource', $scope.gridData);
					$scope.gridInstance.refresh();
				}
			};

			$scope.getShowValuePopup = function (val) {
				$scope.previewModal.value = val;
				$scope.previewModal.showValuePreview = true;
			};

			$scope.showAddHeaderModal = function () {
				$('#showAddHeaderModal').modal('show');
			};

			$scope.addOrEditHeader = function (modalId) {
				if ($scope.headerToUpdateIndex !== undefined) {
					if ($scope.isNewDepot) {
						$scope.headers[$scope.headerToUpdateIndex].column =
							$scope.newHeader;
						$scope.headers[$scope.headerToUpdateIndex].data_type =
							$scope.newHeaderType;
						$('#' + modalId).modal('hide');
					} else {
						if (
							$scope.headers[$scope.headerToUpdateIndex] !== undefined &&
							$scope.headers[$scope.headerToUpdateIndex].id !== undefined
						) {
							let elm = angular.copy(
								$scope.headers[$scope.headerToUpdateIndex]
							);
							let headerId = elm.id;
							elm.column = $scope.newHeader;
							elm.data_type = $scope.newHeaderType;
							delete elm.id;
							DepotService.editHeader(
								headerId,
								elm,
								$stateParams.type != undefined
									? $stateParams.type
									: $scope.depot.depot_type
							).then(function (response) {
								$scope.headers[$scope.headerToUpdateIndex] = response.data;
								toaster.pop('success', toasterSuccess, toasterPopEditHeader);
								$('#' + modalId).modal('hide');
							});
						} else if (
							$scope.headers[$scope.headerToUpdateIndex].id == undefined
						) {
							$scope.saveFileHeadersPosition();
						}
					}
				} else {
					let header = {
						column: $scope.newHeader,
						position: $scope.headers.length,
						data_type: $scope.newHeaderType,
					};
					if ($scope.isNewDepot) {
						$scope.headers.push(header);
						$('#' + modalId).modal('hide');
					} else {
						DepotService.createHeader(
							$scope.depot.id,
							header,
							$stateParams.type != undefined
								? $stateParams.type
								: $scope.depot.depot_type
						).then(function (response) {
							$scope.headers = response.data;
							toaster.pop('success', toasterSuccess, toasterPopAddHeader);
							$('#' + modalId).modal('hide');
						});
					}
				}
			};

			$scope.showDeleteHeaderModal = function () {
				$('#showDelHeaderModal').modal('show');
			};

			$scope.deleteHeader = function (modalId) {
				let elem = $scope.headers[$scope.headerToUpdateIndex];
				if (elem.id != undefined && elem.id != '') {
					if ($scope.depot.depot_type != 'BINARY') {
						DepotService.deleteHeader(
							elem.id,
							$stateParams.type != undefined
								? $stateParams.type
								: $scope.depot.depot_type
						).then(function (response) {
							$scope.headers.splice($scope.headerToUpdateIndex, 1);
							toaster.pop('success', toasterSuccess, toasterPopDeleteHeader);
							$('#' + modalId).modal('hide');
						});
					}
				} else {
					$scope.headers.splice($scope.headerToUpdateIndex, 1);
					toaster.pop('success', toasterSuccess, toasterPopUpdateHeader);
					$('#' + modalId).modal('hide');
				}
			};

			$scope.getHeaderRealType = function (type) {
				let realType = _.find($scope.dataTypes, function (elm) {
					return elm.value === type;
				});
				return realType != undefined && realType.label ? realType.label : type;
			};

			$scope.uploadFileAndGetHeaders = function (files, event, flow) {
				flow.opts.query = {
					isDepot: true,
					code: $scope.metadata.code,
					depotType: $scope.depot.depot_type,
					charset: $scope.depot.charset,
				};

				flow.opts.headers = {
					"Authorization": 'Bearer ' + window._keycloak.token,
					"Accept-Language": $rootScope.currentLangCode,
				};

				if ($scope.metadata.code == undefined || $scope.metadata.code == '') {
					MetadataService.getMatadataUniqueCode().then(function (response) {
						$scope.metadata.code = response.data;
						flow.upload();
						$scope.startSpin();
					});
				} else {
					flow.upload();
					$scope.startSpin();
				}
			};

			$scope.getHeadersFromResponse = function (flow, file, response) {
				let data = JSON.parse(response);
				$scope.createDepotHeaders(data, true);
				if (!$scope.isNewDepot) {
					$scope.headersReUploaded = false;
				}
				$scope.stopSpin();
			};

			$scope.handlUploadFileForHeaderError = function (response) {
				$scope.newFileUploaded = false;
				let data = JSON.parse(response);
				toaster.pop('error', toasterPopError, data.message, 20000);
				$scope.stopSpin();
			};

			$scope.createDepotHeaders = function (data, reuploaded) {
				if (data && data.length == 0) {
					toaster.pop('error', toasterPopError, toasterNoHeaderFound);
				}
				$scope.headers = [];
				for (let i in data) {
					let header = {
						column: data[i][0],
						position: i,
						data_type: getRootType(data[i][1]),
					};
					$scope.headers.push(header);
				}
				$scope.headersReUploaded = reuploaded;
			};

			$scope.prepareHeaders = function () {
				for (let i in $scope.headers) {
					$scope.headers[i].position = i;
				}
			};

			$scope.saveFileHeadersPosition = function (fromCreate) {
				if (!fromCreate) {
					createMetadataHeader($scope.headers);
				}

				for (let i in $scope.headers) {
					$scope.headers[i].position = i;
				}
				DepotService.updateAllHeadersPositions(
					$scope.depot.id,
					$scope.headers,
					$scope.headersReUploaded,
					$stateParams.type != undefined
						? $stateParams.type
						: $scope.depot.depot_type
				).then(function () {
					DepotService.getHeadersByDepotId(
						$scope.depot.id,
						$stateParams.type != undefined
							? $stateParams.type
							: $scope.depot.depot_type
					).then(function (response) {
						$scope.headers = response.data;
						$scope.headersReUploaded = false;
						toaster.pop('success', toasterSuccess, toasterPopUpdateHeader);
					});
				});
			};

			$scope.showDeleteAllHeadersModal = function () {
				$('#deleteAllheadersModal').modal('show');
			};

			$scope.deleteAllHeaders = function (modalId) {
				if ($scope.depot.id != undefined) {
					DepotService.deleteAllHeaders(
						$scope.depot.id,
						$stateParams.type != undefined
							? $stateParams.type
							: $scope.depot.depot_type
					).then(function () {
						DepotService.getHeadersByDepotId(
							$scope.depot.id,
							$stateParams.type != undefined
								? $stateParams.type
								: $scope.depot.depot_type
						).then(function (response) {
							$scope.headers = response.data;
							toaster.pop('success', toasterSuccess, toasterPopDeleteAllHeader);
							$('#' + modalId).modal('hide');
						});
					});
				} else {
					$scope.headers = [];
					toaster.pop('success', toasterSuccess, toasterPopDeleteAllHeader);
					$('#' + modalId).modal('hide');
				}
			};

			$scope.submitFlow = function (files, event, flow) {
				$scope.errorMessageCounter = 0;
				if ($scope.metadata.code == undefined || $scope.metadata.code == '') {
					MetadataService.getMatadataUniqueCode().then(function (response) {
						$scope.metadata.code = response.data;
						$scope.uploadFile(flow);
					});
				} else {
					$scope.uploadFile(flow);
				}
			};

			$scope.uploadFile = function (flow) {
				$scope.HeadersFormUploadedFile =
					$scope.isNewDepot && $scope.headers.length == 0;
				$scope.currentFlow = flow;
				$scope.isUpload = true;
				handleUpload();
			};

			$scope.resume = function (flow, file) {
				$scope.HeadersFormUploadedFile =
					$scope.isNewDepot && $scope.headers.length == 0;
				$scope.currentFlow = flow;
				$scope.currentUploadFile = file;
				$scope.isUpload = false;
				let replaceFiles = $scope.getShowReplaceConfirmation(flow.files);
				if (replaceFiles) {
					$('#replaceFileConfirmation').modal('show');
				} else {
					resumeUpload($scope.depot.replace_file);
				}
			};

			$scope.setSaveButtonDisabledAndGetHeaders = function (
				flow,
				file,
				response
			) {
				let data = JSON.parse(response);
				$scope.uploadedFileProcessingDone[file.uniqueIdentifier] = true;
				$scope.filesWithHeadersCheck =
					$scope.depot.depot_type == 'CSV' ? data.depot_files : data.v2;
				$scope.initFilesGrid();
				$scope.newFileUploaded = true;
				$scope.showDataPreview = true;
				$scope.depot.original_name =
					$scope.depot.depot_type == 'CSV'
						? data.original_name
						: data.v1
						? data.v1.v3
						: undefined;
				$scope.depot.isTemp = data.is_temp;
				$scope.disableMetaDataCode = true;
				$scope.fileNamesMapper[$scope.depot.original_name] =
					$scope.depot.depot_type == 'CSV'
						? data.file_name
						: data.v1
						? data.v1.v2
						: undefined;

				// Get Remote files
				if (!$scope.isNewDepot) {
					$scope.getDepotRemoteFiles();
				}

				if ($scope.HeadersFormUploadedFile) {
					$scope.createDepotHeaders(data.file_headers, false);
				}

				$scope.atLeastOneFileUploaded = true;
				$scope.oldDepotType = angular.copy($scope.depot.depot_type);
			};

			$scope.uploadError = function (file, response, flow) {
				let data = JSON.parse(response);
				if (data.code == '34') {
					toaster.pop('warning', data.message);
					flow.cancel();
					return;
				}
				let error =
					data.field_errors != undefined && data.field_errors.length > 0
						? data.field_errors[0]
						: data;
				if (error.field != undefined) {
					angular.forEach($scope.files.flow.files, function (flowUnit) {
						flowUnit.cancel();
					});
				}
				toaster.pop('error', '', error.message, 20000);
				if (error.element != undefined) {
					$scope.fileNamesMapper[file.name] = error.element;
				}
			};

			$scope.initFilesGrid = function () {
				$scope.filesData = [];
				$scope.isExecutionFilesReady = false;
				if ($scope.filesWithHeadersCheck != undefined) {
					angular.forEach($scope.filesWithHeadersCheck, function (value) {
						$scope.filesData.push({
							fileName: value.name,
							isTemp: value.temp,
							code: value.code,
							isPending: false,
							displayName: value.display_name,
							creationDate: $filter('date')(
								value.creation_date,
								DATE_TIME_PATTERN
							),
						});
					});
				}
				$scope.loadFilesData = $scope.filesData;
				$scope.filesGridOptions.dataSource = $scope.loadFilesData;
				$scope.isExecutionFilesReady = true;
				if ($scope.filesGridInstance && $scope.filesGridInstance.refresh) {
					$scope.filesGridInstance.option('dataSource', $scope.loadFilesData);
					$scope.filesGridInstance.refresh();
				}
			};

			$scope.initArchivesGrid = function () {
				$scope.isArchiveReady = false;
				$scope.archivesData = [];
				if ($scope.archives != undefined) {
					angular.forEach($scope.archives, function (value) {
						$scope.archivesData.push({
							date: value,
							displayName: decodeURIComponent(value),
						});
					});

					$scope.archivesDataGridOptions.dataSource = $scope.archivesData;
					$scope.isArchiveReady = true;
					if (
						$scope.gridArchivesInstance &&
						$scope.gridArchivesInstance.refresh
					) {
						$scope.gridArchivesInstance.option(
							'dataSource',
							$scope.archivesData
						);
						$scope.gridArchivesInstance.refresh();
					}
				}
			};

			$scope.initRemoteFilesGrid = function () {
				$scope.remotFiles = [];
				$scope.isRemoteFilesReady = false;
				if (
					$scope.filesWithHeadersCheck != undefined ||
					($scope.depot &&
						$scope.depot.connector &&
						($scope.depot.connector.connector_type == 'SFTP' ||
							$scope.depot.connector.connector_type == 'S3' ||
							$scope.depot.connector.connector_type == 'HDFS' ||
							$scope.depot.connector.connector_type == 'LOCAL'))
				) {
					angular.forEach($scope.remotefiles, function (value) {
						$scope.remotFiles.push({
							fileName: value.name,
							displayName:
								value.display_name != undefined
									? value.display_name
									: value.name,
							remote: true,
							hdfs:
								$scope.depot.connector.connector_type == 'HDFS' ||
								$scope.depot.connector.connector_type == 'LOCAL',
							isTemp: value.temp || value.isTemp,
							creationDate: $filter('date')(
								value.creation_date,
								DATE_TIME_PATTERN
							),
						});
					});
				}
				$scope.loadremotefilesData = $scope.remotFiles;
				$scope.remoteFilesGridOptions.dataSource = $scope.loadremotefilesData;
				$scope.isRemoteFilesReady = true;
				if (
					$scope.remoteFilesGridInstance &&
					$scope.remoteFilesGridInstance.refresh
				) {
					$scope.remoteFilesGridInstance.option(
						'dataSource',
						$scope.loadremotefilesData
					);
					$scope.remoteFilesGridInstance.refresh();
				}
			};

			$scope.showDeleteFileByNameConfirmationModal = function (
				modalId,
				node,
				file
			) {
				$scope.depotFileToDelete = {
					name: node.fileName,
					temp: node.isTemp,
					code: $scope.depot.metadata.code,
					depot_type: $scope.depot.depot_type,
					deleteFromFlow: node.deleteFromFlow,
					originalName: node.originalName,
				};

				if ($scope.depot && $scope.depot.id != undefined) {
					$scope.fileToDeleteByNameId = $scope.depot.id;
					EntiteDepotService.getUetByDepotId(
						$scope.fileToDeleteByNameId,
						true
					).then(function (response) {
						$scope.linkedEntitites = response.data;
					});
				}
				$('#' + modalId).modal('show');
				$scope.fileFlowToCancel = file;
			};

			$scope.delByNameConfirmation = function (delModal) {
				if ($scope.depotFileToDelete.temp) {
					$scope.deleteFileByName(delModal, false);
				} else {
					$scope.deleteFileByName(delModal);
				}
			};

			$scope.deleteFileByName = function (modalId) {
				if (
					$scope.depot.connector.connector_type == 'HDFS' ||
					$scope.depot.connector.connector_type == 'LOCAL'
				) {
					$scope.depotFileToDelete.connector_id = $scope.depot.connector.id;
					$scope.depotFileToDelete.depot_path = $scope.depot.path;
					$scope.jobError = {};
					$scope.jobError.mainPosition = true;
					DepotFileService.deleteFileByName(
						$scope.depotFileToDelete,
						$scope.depot.id,
						$stateParams.type != undefined
							? $stateParams.type
							: $scope.depot.depot_type
					)
						.then(function (response) {
							toaster.pop('success', toasterSuccess, toasterPopDeleteHeader);
							$scope.getDepotRemoteFiles();
						})
						.catch(catchErrors);
				} else {
					toaster.pop(
						'error',
						toasterPopOperationNotSupported,
						toasterPopOperationType
					);
				}
				$('#' + modalId).modal('hide');
			};

			$scope.getLinkedEntiteTypes = function () {
				$scope.showTable = true;
			};

			$scope.showDeleteEntiteTypeModal = function (modalId, linkId) {
				$scope.entiteTypeToDeleteId = linkId;
				$('#' + modalId).modal('show');
			};

			$scope.deleteEntiteTypeFileMapping = function (modalId) {
				EntiteDepotService.deleteEntiteTypeDepotLink(
					$scope.entiteTypeToDeleteId
				).then(function () {
					toaster.pop('success', toasterSuccess, toasterPopDepotEntityLink);
					$scope.getLinkedEntiteTypes();
					$('#' + modalId).modal('hide');
				});
			};

			$scope.createDepotExtraction = function () {
				$scope.jobError = {};
				$scope.jobError.mainPosition = true;
				DepotExtractionService.createExtraction(
					$scope.depot.id,
					$stateParams.type != undefined
						? $stateParams.type
						: $scope.depot.depot_type
				)
					.then(function (response) {
						$scope.getExtractions();
						toaster.pop(
							'success',
							toasterSuccess,
							toasterPopCreationExtraction
						);
					})
					.catch(catchErrors);
			};

			$scope.getExtractions = function () {
				$scope.jobError = {};
				$scope.jobError.mainPosition = true;
				DepotExtractionService.getExtractions(
					$scope.depot.id,
					$stateParams.type != undefined
						? $stateParams.type
						: $scope.depot.depot_type
				)
					.then(function (response) {
						$scope.archives = response.data;
						$scope.initArchivesGrid();
					})
					.catch(catchErrors);
			};

			$scope.initFiltersGrid = function () {
				$scope.isFiltersReady = false;
				$scope.loadFiltersData = $scope.filters;
				$scope.filtersDataGridOptions.dataSource = $scope.filters;
				$scope.isFiltersReady = true;
			};

			$scope.getFilters = function () {
				DepotExtractionFilterService.findAllExtractionsFilters(
					$scope.depot.id,
					$stateParams.type != undefined
						? $stateParams.type
						: $scope.depot.depot_type
				).then(function (response) {
					$scope.filters = response.data;
					$scope.initFiltersGrid();
				});
			};

			$scope.showDeleteFilterConfirmationModal = function () {
				$('#delFilterModal').modal('show');
			};

			$scope.deleteFilter = function () {
				DepotExtractionFilterService.deleteExtractionFilter(
					$scope.FilterTodeleteId,
					$stateParams.type != undefined
						? $stateParams.type
						: $scope.depot.depot_type
				).then(function (response) {
					for (let i in $scope.filters) {
						if (response.data == $scope.filters[i].id) {
							$scope.filters.splice(i, 1);
							break;
						}
					}
					toaster.pop('success', toasterSuccess, toasterPopDeleteFilter);
					$scope.initFiltersGrid();
					$scope.gridFilterExtractionInstance.refresh();
					$('#delFilterModal').modal('hide');
				});
			};

			$scope.showDeleteArchiveConfirmationModal = function () {
				$('#delArchiveModal').modal('show');
			};

			$scope.deleteDepotExtraction = function (modalId) {
				$scope.jobError = {};
				$scope.jobError.mainPosition = true;
				DepotExtractionService.deleteExtraction(
					$scope.depot.id,
					$scope.archiveTodeleteId,
					$stateParams.type != undefined
						? $stateParams.type
						: $scope.depot.depot_type
				)
					.then(function (response) {
						$scope.getExtractions();
						toaster.pop('success', toasterSuccess, toasterPopDeleteExtraction);
						$('#' + modalId).modal('hide');
					})
					.catch(catchErrors);
			};

			$scope.showClearFolderConfirmation = function (modalId, type) {
				$scope.clearFolderName =
					type == 'execution'
						? clearFolderNames.execution
						: type == 'panding'
						? clearFolderNames.panding
						: type == 'rejected'
						? clearFolderNames.rejected
						: clearFolderNames.Archives;
				$scope.clearFolderType = type;
				$('#' + modalId).modal('show');
			};

			$scope.getDepotRemoteFiles = function () {
				$scope.jobError = {};
				$scope.jobError.mainPosition = true;
				DepotFileService.getDepotRemoteFiles(
					$scope.depot.id,
					$scope.depot.path,
					$scope.depot.current_version_mask,
					$scope.depot.depot_type,
					$scope.depot.fetched_files_limit
				)
					.then(function (response) {
						$scope.remotefiles = response.data;
						$scope.initRemoteFilesGrid();
					})
					.catch(catchErrors);
			};

			$scope.showSyncHeadersConfirmationMessage = function () {
				if ($scope.headers == undefined || $scope.headers.length == 0) {
					$scope.getHeadersFromDepot('syncHeadersConfirmation');
				} else {
					$('#syncHeadersConfirmation').modal('show');
				}
			};

			$scope.getHeadersFromDepot = function (modalId) {
				let depot = angular.copy($scope.depot);
				depot.metadata = $scope.metadata;
				$scope.headersReUploaded = true;
				depot.connector_id =
					depot.connector != undefined ? depot.connector.id : depot.connectorId;
				delete depot.connector;
				$scope.jobError = {};
				$scope.jobError.mainPosition = true;
				DepotService.getHeadersFromDepot(
					depot,
					true,
					$stateParams.type != undefined
						? $stateParams.type
						: $scope.depot.depot_type
				)
					.then(function (response) {
						$scope.createDepotHeaders(response.data, true);
					})
					.catch(catchErrors);
				$('#' + modalId).modal('hide');
			};

			$scope.showAddEditNewFilterModal = function () {
				let filter = $scope.filterValue;
				$scope.isNewFilter = filter == undefined;
				$scope.buttonName = $scope.isNewFilter ? addButton : saveButton;
				$scope.filter = $scope.isNewFilter ? {} : angular.copy(filter);
				$scope.filter.first_date_value = $rootScope.getDateWithPattern(
					$scope.filter.first_date_value
				);
				$scope.filter.second_date_value = $rootScope.getDateWithPattern(
					$scope.filter.second_date_value
				);
				$('#addEditFilterModal').modal('show');
			};

			$scope.addOrEditFilter = function () {
				if ($scope.isNewFilter) {
					DepotExtractionFilterService.createExtractionFilter(
						$scope.depot.id,
						$scope.filter,
						$stateParams.type != undefined
							? $stateParams.type
							: $scope.depot.depot_type
					).then(function (resp) {
						$scope.filters.unshift(resp.data);
						toaster.pop('success', toasterSuccess, toasterPopAddFilter);
						$scope.initFiltersGrid();
						$('#addEditFilterModal').modal('hide');
						$scope.gridFilterExtractionInstance.refresh();
					});
				} else {
					let filterToEdit = angular.copy($scope.filter);
					delete filterToEdit.id;
					DepotExtractionFilterService.editExtractionFilter(
						$scope.filter.id,
						filterToEdit,
						$stateParams.type != undefined
							? $stateParams.type
							: $scope.depot.depot_type
					).then(function (resp) {
						for (let i in $scope.filters) {
							if ($scope.filters[i].id == resp.data.id) {
								$scope.filters[i] = resp.data;
								break;
							}
						}
						$scope.initFiltersGrid();
						toaster.pop('success', toasterSuccess, toasterPopAddFilter);
						$('#addEditFilterModal').modal('hide');
						$scope.gridFilterExtractionInstance.refresh();
					});
				}
			};

			$scope.showDeleteModal = function () {
				$scope.objToDelete = angular.copy($scope.depot);
				$scope.checkPermissionData.elementId = $scope.objToDelete.id;
				$scope.checkPermissionData.type = 'data_source';
				$scope.checkPermissionData.action = 'delete';
				$scope.checkPermissionData.actionIfPermitted = $scope.doShowDeleteModal;
				$scope.checkPermissionData.showPermissionCheck = true;
			};

			$scope.doShowDeleteModal = function () {
				$scope.deleteElementData = {
					label: $scope.objToDelete.metadata
						? $scope.objToDelete.metadata.label
						: '',
					doDelete: $scope.deleteDepot,
					showConfirmDeleteElement: true,
					bulk: false,
				};
			};

			$scope.deleteDepot = function () {
				$scope.jobError = {};
				$scope.jobError.mainPosition = true;
				if ($scope.objToDelete.depot_type === 'SQL') {
					DbSQLDepotService.deleteDbDepot($scope.objToDelete.id)
						.then(function () {
							actionsAfterDeleteDepot('SQL');
						})
						.catch(catchErrors);
				} else if ($scope.objToDelete.depot_type === 'MONGODB') {
					MongodbDepotService.deleteMongodbDepot($scope.objToDelete.id)
						.then(function () {
							actionsAfterDeleteDepot('MONGODB');
						})
						.catch(catchErrors);
				} else if ($scope.objToDelete.depot_type === 'NEO4J') {
					Neo4jDepotService.deleteNeo4jDepot($scope.objToDelete.id)
						.then(function () {
							actionsAfterDeleteDepot('NEO4J');
						})
						.catch(catchErrors);
				} else if ($scope.objToDelete.depot_type === 'CSV') {
					CsvDepotService.deleteCsvDepot($scope.objToDelete.id)
						.then(function () {
							actionsAfterDeleteDepot('CSV');
						})
						.catch(catchErrors);
				} else if ($scope.objToDelete.depot_type === 'PARQUET') {
					ParquetDepotService.deleteParquetDepot($scope.objToDelete.id)
						.then(function () {
							actionsAfterDeleteDepot('PARQUET');
						})
						.catch(catchErrors);
				} else if ($scope.objToDelete.depot_type === 'JSON') {
					JsonDepotService.deleteJsonDepot($scope.objToDelete.id)
						.then(function () {
							actionsAfterDeleteDepot('JSON');
						})
						.catch(catchErrors);
				} else if ($scope.objToDelete.depot_type === 'BINARY') {
					BinaryDepotService.deleteBinaryDepot($scope.objToDelete.id)
						.then(function () {
							actionsAfterDeleteDepot('BINARY');
						})
						.catch(catchErrors);
				} else if ($scope.objToDelete.depot_type === 'EXCEL') {
					ExcelDepotService.deleteExcelDepot($scope.objToDelete.id)
						.then(function () {
							actionsAfterDeleteDepot('EXCEL');
						})
						.catch(catchErrors);
				} else if ($scope.objToDelete.depot_type === 'XML') {
					XmlDepotService.deleteXmlDepot($scope.objToDelete.id)
						.then(function () {
							actionsAfterDeleteDepot('XML');
						})
						.catch(catchErrors);
				}
			};

			$scope.showHdhGraph = function () {
				$scope.elementGraphData.centeredElementLabel =
					$scope.depot.metadata.label;
				$scope.elementGraphData.centeredElementType = 'DEPOT';
				$scope.elementGraphData.centeredElementId = $scope.depot.id;
				$scope.elementGraphData.showHdhElementGraph = true;
			};

			$scope.doShowPermissions = function () {
				$scope.permissionData.metadataId = $scope.depot.metadata.id;
				$scope.permissionData.type = 'data_source';
				$scope.permissionData.elementId = $scope.depot.id;
				$scope.permissionData.lib = $scope.depot.metadata.label;
				$scope.permissionData.showElementPermissions = true;
			};

			$scope.showPermissions = function () {
				$scope.checkPermissionData.elementId = $scope.depot.id;
				$scope.checkPermissionData.type = 'data_source';
				$scope.checkPermissionData.action = 'permissions';
				$scope.checkPermissionData.actionIfPermitted = $scope.doShowPermissions;
				$scope.checkPermissionData.showPermissionCheck = true;
			};

			$scope.getRealDbType = function (type) {
				switch (type) {
					case 'mariaDb':
						return 'MariaDB';
					case 'mySql':
						return 'MySQL';
					case 'oracle':
						return 'Oracle';
					case 'pgSql':
						return 'PostgreSQL';
					case 'sqlServer':
						return 'SQL Server';
					case 'mongoDb':
						return 'MongoDB';
					case 'neo4j':
						return 'Neo4j';
					default:
						return type;
				}
			};

			$scope.getRealConnectorType = function (type) {
				switch (type) {
					case 'SQL':
						return ' (Bdd)';
					case 'HTTP':
						return ' (HTTP)';
					case 'S3':
						return ' (S3 / MinIO)';
					case 'SFTP':
						return ' (SFTP)';
					case 'HDFS':
						return ' (HDFS)';
					case 'HTTP':
						return ' (HTTP)';
					default:
						return type;
				}
			};

			$scope.$watch('headers', function (newValue, oldValue) {
				if (
					newValue &&
					oldValue &&
					!_.isEqual(_.omit(newValue, 'position'), _.omit(oldValue, 'position'))
				) {
					createMetadataHeader(newValue);
				}
			});

			$scope.copyToClipboard = function (e) {
				let $temp_input = $('<input>');
				$('body').append($temp_input);
				$temp_input.val(e).select();
				document.execCommand('copy');
				$temp_input.remove();
				if ($scope.readingMaskCopy) {
					$scope.depot.current_version_mask = e != undefined ? e.trim() : e;
					$scope.readingMaskCopy = false;
					$('#currentVersionMAskSuggestions').modal('hide');
				} else if ($scope.sepValueCopy) {
					$scope.depot.delimiter_csv = e != undefined ? e.trim() : e;
					$scope.sepValueCopy = false;
					$('#sepSuggestions').modal('hide');
				}
			};

			$scope.showPossibleValuesVersionMask = function (modalId) {
				$scope.readingMaskCopy = true;
				$('#' + modalId).modal('show');
			};

			$scope.showDataAddressHelp = function (modalId) {
				$('#' + modalId).modal('show');
			};

			$scope.showPossibleSepValues = function (modalId) {
				$scope.sepValueCopy = true;
				$('#' + modalId).modal('show');
			};

			$scope.setDefaultFetchedFiles = function () {
				$scope.depot.fetched_files_limit =
					$scope.depot.fetched_files_limit == undefined ||
					$scope.depot.fetched_files_limit == ''
						? $scope.limitDisplayedFiles[0]
						: $scope.depot.fetched_files_limit;
			};

			$scope.addHeaderValue = function () {
				$scope.depot.additional_params =
					$scope.depot.additional_params != undefined
						? $scope.depot.additional_params
						: {};
				$scope.depot.additional_params.headers =
					$scope.depot.additional_params.headers != undefined
						? $scope.depot.additional_params.headers
						: {};
				let length = Object.keys($scope.depot.additional_params.headers).length;
				$scope.depot.additional_params.headers['key' + length] =
					'value' + length;
			};

			$scope.deleteHeaderValue = function (index) {
				let objIndex = 0;
				for (let i in $scope.depot.additional_params.headers) {
					if (objIndex === index) {
						delete $scope.depot.additional_params.headers[i];
						break;
					}

					objIndex++;
				}
			};

			$scope.updateHeaderKey = function (key, index) {
				var keys = Object.keys($scope.depot.additional_params.headers);
				var oldKey = keys[index];
				$scope.depot.additional_params.headers = keys.reduce((acc, val) => {
					if (val === oldKey) {
						acc[key] = $scope.depot.additional_params.headers[oldKey];
					} else {
						acc[val] = $scope.depot.additional_params.headers[val];
					}
					return acc;
				}, {});
			};

			$scope.handleDepotTyeChange = function () {
				generateBinaryDepotHeaders();
				moveTempUploadedFileToRightType();
			};

			$scope.showReadingModeWiki = function (modalId) {
				$('#' + modalId).modal('show');
			};

			$scope.showXmlTagHelp = function (modalId) {
				$('#' + modalId).modal('show');
			};

			$scope.detectCharset = function () {
				if ($scope.depot.id !== undefined) {
					$scope.jobError = {};
					$scope.jobError.mainPosition = true;
					DepotService.detectCharset(
						$scope.depot.id,
						$stateParams.type !== undefined
							? $stateParams.type
							: $scope.depot.depot_type
					)
						.then(function (response) {
							let charset = response.data;
							if (charset !== undefined && charset !== '') {
								if (!Array.isArray($scope.charsets)) {
									$scope.charsets = [];
								}
								let charsetExist = false;
								for (let i in $scope.charsets) {
									if ($scope.charsets[i].value === charset) {
										charsetExist = true;
										break;
									}
								}
								if (!charsetExist) {
									let charsetObj = {
										label: charset,
										value: charset,
									};
									$scope.charsets.unshift(charsetObj);
								}
								$scope.depot.charset = charset;
							}
						})
						.catch(catchErrors);
				}
			};

			$scope.getFileMaxSize = function () {
				ParametersServices.getFileMaxSize().then(function (response) {
					$scope.fileMaxSize = response.data;
				});
			};

			$scope.checkDate = function () {
				let firstDate = new Date($scope.filter.first_date_value);
				let secondDate = new Date($scope.filter.second_date_value);
				if (secondDate < firstDate) {
					return ($scope.inValidDate = true);
				}
				return ($scope.inValidDate = false);
			};

			$scope.clearBodyIfGet = function () {
				if ($scope.depot.additional_params.method === 'GET') {
					$scope.depot.additional_params.body = null;
				}
			};

			$scope.addHttpIterationHeaderValue = function () {
				$scope.depot.additional_params =
					$scope.depot.additional_params != undefined
						? $scope.depot.additional_params
						: {};
				$scope.depot.additional_params.iteration =
					$scope.depot.additional_params.iteration != undefined
						? $scope.depot.additional_params.iteration
						: {};
				$scope.depot.additional_params.iteration.end_condition_headers =
					$scope.depot.additional_params.iteration.end_condition_headers !=
					undefined
						? $scope.depot.additional_params.iteration.end_condition_headers
						: {};
				let length = Object.keys(
					$scope.depot.additional_params.iteration.end_condition_headers
				).length;
				$scope.depot.additional_params.iteration.end_condition_headers[
					'key' + length
				] = 'value' + length;
			};

			$scope.deleteIterationHeaderValue = function (index) {
				let objIndex = 0;
				for (let i in $scope.depot.additional_params.iteration
					.end_condition_headers) {
					if (objIndex === index) {
						delete $scope.depot.additional_params.iteration
							.end_condition_headers[i];
						break;
					}

					objIndex++;
				}
			};

			$scope.updateIterationHeaderKey = function (key, index) {
				var keys = Object.keys(
					$scope.depot.additional_params.iteration.end_condition_headers
				);
				var oldKey = keys[index];
				$scope.depot.additional_params.iteration.end_condition_headers =
					keys.reduce((acc, val) => {
						if (val === oldKey) {
							acc[key] =
								$scope.depot.additional_params.iteration.end_condition_headers[
									oldKey
								];
						} else {
							acc[val] =
								$scope.depot.additional_params.iteration.end_condition_headers[
									val
								];
						}
						return acc;
					}, {});
			};

			$scope.iterationModeChange = function () {
				delete $scope.depot.additional_params.iteration.end_or_limit;
				delete $scope.depot.additional_params.iteration.iteration_number;
				delete $scope.depot.additional_params.iteration.start;
				delete $scope.depot.additional_params.iteration.variable;
				delete $scope.depot.additional_params.iteration.waiting;
				delete $scope.depot.additional_params.iteration.en_condition_body;
				delete $scope.depot.additional_params.iteration.end_condition_mode;
				delete $scope.depot.additional_params.iteration.is_regex;
				delete $scope.depot.additional_params.iteration.end_condition_headers;
			};

			/**
			 * private functions
			 */

			function init() {
				initVariables();
				initListeners();

				if (
					($stateParams.id && $stateParams.type !== undefined
						? $stateParams.type
						: $scope.depot.depot_type) ||
					($stateParams.duplicatedId && $stateParams.type !== undefined
						? $stateParams.type
						: $scope.depot.depot_type)
				) {
					// edit
					$scope.isNewDepot = $stateParams.duplicatedId !== undefined;
					$scope.connector = {};
					let withHeaders = true;
					let linkedUetCount = true;
					let depotId =
						$stateParams.id !== undefined
							? $stateParams.id
							: $stateParams.duplicatedId;
					let filesCount = true;
					$scope.jobError = {};
					$scope.jobError.mainPosition = true;
					$scope.savingInProgress = false;

					if ($stateParams.type && $stateParams.type.includes('SQL')) {
						DbSQLDepotService.getDbDepotById(depotId, withHeaders)
							.then(function (response) {
								initDepotData(response.data, false);
							})
							.catch(catchErrors);
					} else if (
						$stateParams.type &&
						$stateParams.type.includes('MONGODB')
					) {
						MongodbDepotService.getMongodbDepotById(depotId, withHeaders)
							.then(function (response) {
								initDepotData(response.data, false);
							})
							.catch(catchErrors);
					} else if ($stateParams.type && $stateParams.type.includes('NEO4J')) {
						Neo4jDepotService.getNeo4jDepotById(depotId, withHeaders)
							.then(function (response) {
								initDepotData(response.data, false);
							})
							.catch(catchErrors);
					} else if ($stateParams.type && $stateParams.type.includes('CSV')) {
						CsvDepotService.getCsvDepotById(depotId, withHeaders)
							.then(function (response) {
								initDepotData(response.data, true);
							})
							.catch(catchErrors);
					} else if (
						$stateParams.type &&
						$stateParams.type.includes('PARQUET')
					) {
						ParquetDepotService.getParquetDepotById(depotId, withHeaders)
							.then(function (response) {
								initDepotData(response.data, true);
							})
							.catch(catchErrors);
					} else if ($stateParams.type && $stateParams.type.includes('JSON')) {
						JsonDepotService.getJsonDepotById(
							depotId,
							withHeaders,
							linkedUetCount,
							filesCount
						)
							.then(function (response) {
								initDepotData(response.data, true);
							})
							.catch(catchErrors);
					} else if ($stateParams.type && $stateParams.type.includes('EXCEL')) {
						ExcelDepotService.getExcelDepotById(
							depotId,
							withHeaders,
							linkedUetCount,
							filesCount
						)
							.then(function (response) {
								initDepotData(response.data, true);
							})
							.catch(catchErrors);
					} else if (
						$stateParams.type &&
						$stateParams.type.includes('BINARY')
					) {
						BinaryDepotService.getBinaryDepotById(
							depotId,
							withHeaders,
							linkedUetCount,
							filesCount
						)
							.then(function (response) {
								initDepotData(response.data, true);
							})
							.catch(catchErrors);
					} else if ($stateParams.type && $stateParams.type.includes('XML')) {
						XmlDepotService.getXmlDepotById(
							depotId,
							withHeaders,
							linkedUetCount,
							filesCount
						)
							.then(function (response) {
								initDepotData(response.data, true);
							})
							.catch(catchErrors);
					} else {
						toaster.pop(
							'error',
							toasterPopError,
							gettextCatalog.getString('type.non.supported')
						);
					}
					$scope.depot.additional_params =
						$scope.depot.additional_params !== undefined
							? $scope.depot.additional_params
							: {};
					$scope.depot.additional_params.header =
						$scope.depot.additional_params.header !== undefined
							? $scope.depot.additional_params.header
							: {};
				} else {
					$scope.depot = $scope.depot != undefined ? $scope.depot : {};
					$scope.depot.additional_params =
						$scope.depot.additional_params != undefined
							? $scope.depot.additional_params
							: {};
					$scope.depot.additional_params.header =
						$scope.depot.additional_params.header != undefined
							? $scope.depot.additional_params.header
							: {};
					$scope.depot.current_version_mask = '*.*';
					$scope.depot.parsing_mode = 'PERMISSIVE';
					$scope.isNewDepot = true;
					$scope.metadataLoaded = true;
					initLibs();
					initConnector();
					initHeadersSortableOptions();
				}
			}

			function initVariables() {
				$scope.helpTitleTra = gettextCatalog.getString('Aide Dépôts');
				$scope.SheetTra = "'" + gettextCatalog.getString('Feuil1') + "'!B3:C35";
				$scope.hidePageTitle = true;
				$scope.metadata = {};
				$scope.metadata.code = '';
				$scope.metadata.actif = true;
				$scope.metadata.tags = [];
				$scope.depot = {
					delimiter_csv: ';',
					quote_csv: '',
					has_header: true,
					include_subtree: false,
					infer_schema: true,
					auto_integration: true,
					replace_file: false,
					reset_depot: false,
					sql_query: '',
				};
				$scope.depotFlow = {};
				$scope.selectedCharset = {};
				$scope.headers = [];
				$scope.headersReUploaded = false;
				$scope.initialCharsets = [];
				$scope.checkPermissionData = {};
				$scope.fileMaxSize = undefined;
				$scope.entiteConfig = {};
				$scope.entiteConfig.isDepotType = true;
				$scope.inValidDate = false;
				$scope.permissiveMode = gettextCatalog.getString('PERMISSIVE');
				$scope.dropmalformedMode = gettextCatalog.getString('DROPMALFORMED');
				$scope.failfastMode = gettextCatalog.getString('FAILFAST');
				$scope.localMode = gettextCatalog.getString('Mode Local');
				$scope.parsingModes = [
					$scope.permissiveMode,
					$scope.dropmalformedMode,
					$scope.failfastMode,
				];
				$scope.mongodbAvailableCollections = [];
				$scope.connectorsTypes = [
					{ value: 'SQL', label: 'Base De Données' },
					{ value: 'HTTP', label: 'HTTP' },
					{
						value: 'S3',
						label: 'S3-AWS',
					},
					{
						value: 'SFTP',
						label: 'SFTP',
					},
				];
				$scope.isNewDepot = true;
				$scope.saveButtonDisabled = true;
				$scope.readerTypes = [
					{
						value: 'CSV',
						label: readerTypeSeparator,
						supportedConnectors: ['HTTP', 'LOCAL', 'S3', 'SFTP', 'HDFS'],
					},
					{
						value: 'SQL',
						label: readerTypeSql,
						supportedConnectors: ['SQL'],
					},
					{
						value: 'PARQUET',
						label: readerTypeParquet,
						supportedConnectors: ['HTTP', 'S3', 'SFTP', 'HDFS', 'LOCAL'],
					},
					{
						value: 'JSON',
						label: readerTypeJson,
						supportedConnectors: ['HTTP', 'S3', 'SFTP', 'HDFS', 'LOCAL'],
					},
					{
						value: 'EXCEL',
						label: readerTypeExcel,
						supportedConnectors: ['HTTP', 'S3', 'SFTP', 'HDFS', 'LOCAL'],
					},
					{
						value: 'BINARY',
						label: readerTypeBinary,
						supportedConnectors: ['SFTP', 'HDFS', 'S3', 'LOCAL'],
					},
					{
						value: 'MONGODB',
						label: readerTypeMongodb,
						supportedConnectors: ['MONGODB'],
					},
					{
						value: 'NEO4J',
						label: readerTypeNo4j,
						supportedConnectors: ['NEO4J'],
					},
					{
						value: 'XML',
						label: readerTypeXML,
						supportedConnectors: ['HTTP', 'LOCAL', 'S3', 'SFTP', 'HDFS'],
					},
				];
				$scope.filteredreaderTypes = $scope.readerTypes;
				$scope.savingInProgress = false;
				$scope.dataModel = {
					save: function () {
						save(true);
					},
					redirectionToList: function () {
						redirectionToList();
					},
				};
				$scope.isNewDepot = true;
				$scope.isWithConnector = 'false';
				$scope.sqlTestQuery = '';
				$scope.metadataConfig = {
					htmlId: 'depot',
				};
				$scope.isExtractionPreview = false;
				$scope.moreThanTenColumns = false;
				$scope.gridOptions = {};
				$scope.gridOptions.data = [];
				$scope.previewModal = { showValuePreview: false, value: '' };
				$scope.dataTypeString = gettextCatalog.getString(
					'Chaîne de caractères'
				);
				$scope.dataTypeBoolean = gettextCatalog.getString('Booléen');
				$scope.dataTypeDate = gettextCatalog.getString('Date');
				$scope.dataTypeTime = gettextCatalog.getString(
					'Horodatage (Timestamp)'
				);
				$scope.dataTypeDemicalNumber =
					gettextCatalog.getString('Nombre décimal');
				$scope.dataTypeBigNumber = gettextCatalog.getString(
					'Grand nombre entier'
				);
				$scope.dataTypeInteger = gettextCatalog.getString(
					'Nombre entier (<2 Md)'
				);

				$scope.dataTypes = [
					{ value: 'StringType', label: $scope.dataTypeString },
					{ value: 'BooleanType', label: $scope.dataTypeBoolean },
					{ value: 'TimestampType', label: $scope.dataTypeTime },
					{ value: 'DecimalType', label: $scope.dataTypeDemicalNumber },
					{ value: 'LongType', label: $scope.dataTypeBigNumber },
					{ value: 'IntegerType', label: $scope.dataTypeInteger },
				];

				$scope.extractHeadersUrl =
					API_BASE_URL_BACKEND +
					'depot/local/headers/extract?ctx=' +
					$window.sessionStorage.getItem(CTX_VAR_NAME);

				$scope.uploadedFileProcessingDone = {};
				$scope.files = {};
				$scope.fileNamesMapper = {};
				$scope.atLeastOneFileUploaded = false;
				$scope.oldDepotType = undefined;
				$scope.filesListDataGridColumn = {};
				$scope.filesListDataGridColumn.fileName = fileNameTra;
				$scope.filesListDataGridColumn.techniqueName = gettextCatalog.getString(
					'Nom technique du fichier'
				);
				$scope.filesListDataGridColumn.importedTime = gettextCatalog.getString(
					"Date et heure d'import"
				);
				$scope.filesListDataGridColumn.actions =
					gettextCatalog.getString('Actions');
				$scope.filesListDataGridColumn.date = gettextCatalog.getString('Date');

				// Files uiGrid
				$scope.filesListDataGridCommonOptions = {
					dataSource: $scope.archivesData,
					paging: {
						enabled: true,
						pageSize: PAGINATIONS_SIZE,
					},
					pager: {
						showPageSizeSelector: true,
						allowedPageSizes: PAGINATIONS_SIZES,
						showInfo: true,
						visible: true,
					},
					filterRow: {
						visible: true,
						applyFilter: 'auto',
					},
					rowAlternationEnabled: true,
					headerFilter: {
						visible: true,
						applyFilter: 'auto',
					},
					showColumnLines: true,
					showRowLines: true,
					allowColumnReordering: true,
					allowColumnResizing: true,
					columnAutoWidth: true,
					showBorders: true,
					columnFixing: {
						enabled: true,
					},
					columns: [
						{
							caption: $scope.filesListDataGridColumn.fileName,
							dataField: 'displayName',
							sortOrder: 'asc',
							cellTemplate: 'fileName',
						},
						{
							caption: $scope.filesListDataGridColumn.techniqueName,
							dataField: 'fileName',
						},
						{
							caption: $scope.filesListDataGridColumn.importedTime,
							dataField: 'creationDate',
							sortingMethod: function (value1, value2) {
								return compareTwoDatesForDxGrid(value1, value2, this.sortOrder);
							},
						},
						{
							caption: $scope.filesListDataGridColumn.actions,
							alignment: 'center',
							cellTemplate: 'actions',
							width: 120,
							allowReordering: false,
						},
					],
				};

				$scope.filesGridOptions = {
					dataSource: $scope.loadFilesData,
					onInitialized: function (e) {
						$scope.filesGridInstance = e.component;
					},
				};

				$scope.remoteFilesGridOptions = {
					dataSource: $scope.loadremotefilesData,
					onInitialized: function (e) {
						$scope.remoteFilesGridInstance = e.component;
					},
				};

				$scope.pendingFilesGridOptions = {
					dataSource: $scope.loadPendingFilesData,
					onInitialized: function (e) {
						$scope.pendingFilesGridInstance = e.component;
					},
				};

				$scope.rejectedFilesGridOptions = {
					dataSource: $scope.loadRejectedFilesData,
					onInitialized: function (e) {
						$scope.rejectedFilesGridInstance = e.component;
					},
				};

				$scope.archivesDataGridOptions = {
					dataSource: $scope.archivesData,
					paging: {
						enabled: true,
						pageSize: PAGINATIONS_SIZE,
					},
					pager: {
						showPageSizeSelector: true,
						allowedPageSizes: PAGINATIONS_SIZES,
						showInfo: true,
						visible: true,
					},
					filterRow: {
						visible: true,
						applyFilter: 'auto',
					},
					rowAlternationEnabled: true,
					headerFilter: {
						visible: true,
						applyFilter: 'auto',
					},
					showColumnLines: true,
					showRowLines: true,
					allowColumnReordering: true,
					allowColumnResizing: true,
					columnAutoWidth: true,
					showBorders: true,
					columnFixing: {
						enabled: true,
					},
					columns: [
						{
							caption: $scope.filesListDataGridColumn.date,
							dataField: 'date',
							visible: false,
						},
						{
							caption: $scope.filesListDataGridColumn.date,
							dataField: 'displayName',
						},
						{
							caption: $scope.filesListDataGridColumn.actions,
							alignment: 'center',
							cellTemplate: 'actions',
							width: 120,
							allowReordering: false,
						},
					],
					onInitialized: function (e) {
						$scope.gridArchivesInstance = e.component;
					},
				};

				$scope.filesGridOptions = _.merge(
					$scope.filesGridOptions,
					$scope.filesListDataGridCommonOptions
				);

				$scope.remoteFilesGridOptions = _.merge(
					$scope.remoteFilesGridOptions,
					$scope.filesListDataGridCommonOptions
				);

				$scope.pendingFilesGridOptions = _.merge(
					$scope.pendingFilesGridOptions,
					$scope.filesListDataGridCommonOptions
				);

				$scope.rejectedFilesGridOptions = _.merge(
					$scope.rejectedFilesGridOptions,
					$scope.filesListDataGridCommonOptions
				);

				$scope.showTable = false;
				$scope.filtersDataGridColumn = {};
				$scope.filesListDataGridColumn.label =
					gettextCatalog.getString('Libellé');

				$scope.filtersDataGridOptions = {
					dataSource: $scope.filters,
					paging: {
						enabled: true,
						pageSize: PAGINATIONS_SIZE,
					},
					pager: {
						showPageSizeSelector: true,
						allowedPageSizes: PAGINATIONS_SIZES,
						showInfo: true,
						visible: true,
					},
					filterRow: {
						visible: true,
						applyFilter: 'auto',
					},
					rowAlternationEnabled: true,
					headerFilter: {
						visible: true,
						applyFilter: 'auto',
					},
					showColumnLines: true,
					showRowLines: true,
					allowColumnReordering: true,
					allowColumnResizing: true,
					columnAutoWidth: true,
					showBorders: true,
					columnFixing: {
						enabled: true,
					},
					columns: [
						{
							caption: $scope.filesListDataGridColumn.label,
							dataField: 'label',
							allowReordering: false,
						},
						{
							caption: $scope.filesListDataGridColumn.actions,
							alignment: 'center',
							cellTemplate: 'actions',
							width: 120,
							allowReordering: false,
						},
					],
					onInitialized: function (e) {
						$scope.gridFilterExtractionInstance = e.component;
					},
				};

				$scope.isFiltersReady = false;
				$scope.filter = {};
				$scope.filters = [];
				$scope.getDateWithPattern = $rootScope.getDateWithPattern;
				$scope.filtersDateOperators = [
					{ value: 'ALL', label: filtersDateOperatorsAll },
					{ value: 'LAST', label: filtersDateOperatorsLast },
					{ value: 'FIRST', label: filtersDateOperatorsFrist },
					{ value: 'BEFORE', label: filtersDateOperatorsBefore },
					{
						value: 'AFTER',
						label: filtersDateOperatorsAfter,
					},
					{ value: 'BETWEEN', label: filtersDateOperatorsBetween },
					{
						value: 'WEEK_IN_MONTH',
						label: filtersDateOperatorsWeekInMonth,
					},
					{ value: 'WEEK_IN_YEAR', label: filtersDateOperatorsWeekInYear },
					{
						value: 'DAY_IN_WEEK',
						label: filtersDateOperatorsDayInWeek,
					},
					{ value: 'DAY_IN_MONTH', label: filtersDateOperatorsDayInMonth },
					{ value: 'DAY_IN_YEAR', label: filtersDateOperatorsDayInYear },
				];
				$scope.deleteElementData = {};
				$scope.elementGraphData = {};

				$scope.permissionData = {};

				$scope.possibleCurrentVersionMaskValues = [
					'*.{csv,CSV,cSv,cSV,Csv,CSv,csV,CsV}',
					'*.{txt,TXT,tXt,tXT,Txt,TXt,txT,TxT}',
					'*.{xls,xlsx,XLS,xLs,xLS,Xls,XLs,xlS,XlS}',
					'*.{pdf,PDF,pDf,pDF,Pdf,PDf,pdF,PdF}',
					'*.{xls,XLS,xLs,xLS,Xls,XLs,xlS,XlS,csv,CSV,cSv,cSV,Csv,CSv,csV,CsV,txt,TXT,tXt,tXT,Txt,TXt,txT,TxT}',
					'*.{json,Json,JSON}',
					'*.{parquet, Parquet, PARQUET}',
					'*.{xml,XML,Xml,XMl,xML,xmL,XmL}',
				];

				$scope.possibleSepValues = [',', ';', '|', '\\t'];

				$scope.httpMethods = ['GET', 'POST'];

				$scope.httpIterationModes = [
					{ label: gettextCatalog.getString('Sans'), value: 'WITHOUT' },
					{ label: gettextCatalog.getString('Par Page'), value: 'PER_PAGE' },
					{
						label: gettextCatalog.getString('Par Offset'),
						value: 'PER_OFFSET',
					},
				];

				$scope.httpIterationEndModes = [
					{ label: gettextCatalog.getString('Sans REGEX'), value: false },
					{ label: gettextCatalog.getString('Avec REGEX'), value: true },
				];

				$scope.limitDisplayedFiles = [10, 100, 1000, 10000, 100000];

				$scope.filtersDateOperators = _.sortBy(
					$scope.filtersDateOperators,
					function (elm) {
						return elm.label.toLowerCase();
					}
				);

				$scope.headersReferencingModes = [];
				AllDepotService.findAllDepotsHeadersReferencingModes().then(function (
					modesRes
				) {
					$scope.headersReferencingModes = modesRes.data;
					$scope.depot.headers_referencing_mode =
						HEADER_REFERENCING_MODE_LABEL;
				});
			}

			function initListeners() {
				$(document).ready(function () {
					$('[copy-size]').each(function () {
						var copyEl = $($(this).attr('copy-size'));
						var targetEl = $(this);
						targetEl.width(copyEl.width() + 'px');
						targetEl.height(copyEl.height() + 'px');
					});
				});

				$('#currentVersionMAskSuggestions').on('hidden.bs.modal', function () {
					$scope.readingMaskCopy = false;
				});

				$('#sepSuggestions').on('hidden.bs.modal', function () {
					$scope.sepValueCopy = false;
				});

				$scope.$on('$destroy', function () {
					$log.debug('depot controller destroyed');
					$('#currentVersionMAskSuggestions').off('hidden.bs.modal');
					$('#sepSuggestions').off('hidden.bs.modal');
				});
			}

			function initHeadersSortableOptions() {
				$scope.sortableOptions = {
					start: function () {
						$scope.oldHeaders = angular.copy($scope.headers);
					},
					stop: function (e, ui) {
						if (
							!_.isEqual($scope.oldHeaders, $scope.headers) &&
							$scope.depot.headers_referencing_mode ===
								HEADER_REFERENCING_MODE_POSITION
						) {
							$scope.saveFileHeadersPosition();
						}
					},
					disabled:
						$scope.depot.headers_referencing_mode ===
						HEADER_REFERENCING_MODE_POSITION,
				};
			}

			function catchErrors(error) {
				if (
					(error &&
						error.data &&
						(error.data.code == '37' ||
							error.data.code == '38' ||
							error.data.code == '39')) ||
					(error &&
						(error.code == '37' || error.code == '38' || error.code == '39'))
				) {
					$scope.jobError.errorMessage = translateErrorMessage;
				}
				$scope.jobError.error = true;
				$scope.savingInProgress = false;
			}

			function getConnectorsForCurrentDepot() {
				if (
					$scope.depot &&
					$scope.depot.connector &&
					$scope.depot.connector.connector_type != 'LOCAL' &&
					$scope.depot.connector.connector_type != 'SQL'
				) {
					ConnectorService.getConnectorsList(
						undefined,
						$scope.depot.connector.connector_type,
						false,
						false,
						true
					).then(function (response) {
						$scope.connectors = _.sortBy(response.data, function (elm) {
							return elm.lib.toLowerCase();
						});
					});
				} else if ($scope.depot.connector.connector_type == 'SQL') {
					DBConnectorService.getSimilarDBConnectors(
						$scope.depot.connector.id
					).then(function (response) {
						$scope.connectors = _.sortBy(
							_.map(response.data, function (elm) {
								return {
									id: elm.id,
									code: elm.metadata.code,
									lib: elm.metadata.label,
									connector_type: $scope.depot.connector.connector_type,
								};
							}),
							function (elm) {
								return elm.lib.toLowerCase();
							}
						);
					});
				}
			}

			function initLibs() {
				if ($scope.isNewDepot) {
					ConnectorService.getConnectorsList(
						undefined,
						undefined,
						false,
						false,
						true
					).then(function (response) {
						let connectorId = $stateParams.connectorId;
						$scope.connectors = _.sortBy(response.data, function (elm) {
							return elm.lib.toLowerCase();
						});
						if (connectorId) {
							for (let i in $scope.connectors) {
								if ($scope.connectors[i].id == connectorId) {
									$scope.depot.connector = $scope.connectors[i];
									$scope.isWithConnector = 'true';
									break;
								}
							}
						}
						setNativeConnectorMode($scope.depot.connector);
						filterReaders();
					});
				}
				CommonServices.getCharsets().then(function (response) {
					$scope.charsets = response.data;
					$scope.initialCharsets = $scope.charsets;
					let autoCharset = undefined;
					for (let i in $scope.charsets) {
						if ($scope.charsets[i].value == 'DEFAULT') {
							autoCharset = $scope.charsets[i];
							break;
						}
					}
					$scope.setSelectedCharset(autoCharset);
					filterCharset();
				});
			}

			function getMongodbCollections() {
				if (
					$scope.depot &&
					$scope.depot.connector &&
					$scope.depot.connector.id &&
					$scope.depot.connector.connector_type == 'MONGODB'
				) {
					DBConnectorService.getMongodbCollections(
						$scope.depot.connector.id
					).then(function (response) {
						$scope.mongodbAvailableCollections = response.data;
					});
				}
			}

			function createDepotSuccess(returnToList, responseData, type) {
				toaster.pop(
					'success',
					toasterSuccess,
					depotCreation +
						' ' +
						depotTypeLib(type) +
						' ' +
						depotCreationOrUpdateSuccess
				);
				if (returnToList) {
					$timeout(function () {
						$scope.savingInProgress = false;
						$state.go('depots');
					}, 300);
				} else {
					$timeout(function () {
						$scope.savingInProgress = false;
						$state.go('depots-edit', { id: responseData.id, type: type });
					}, 300);
				}
			}

			function depotTypeLib(type) {
				switch (type) {
					case 'CSV':
						return readerTypeSeparator;
					case 'SQL':
						return readerTypeSql;
					case 'MONGODB':
						return readerTypeMongodb;
					case 'PARQUET':
						return readerTypeParquet;
					case 'NEO4J':
						return readerTypeNo4j;
					case 'JSON':
						return readerTypeJson;
					case 'BINARY':
						return readerTypeBinary;
					case 'EXCEL':
						return readerTypeExcel;
					case 'XML':
						return readerTypeXML;
					default:
						return '';
				}
			}

			function filterReaders() {
				$scope.filterReaders();
				let connectorId =
					$scope.depot.connector_id != undefined
						? $scope.depot.connector_id
						: $scope.depot && $scope.depot.connector
						? $scope.depot.connector.id
						: undefined;
				if (
					$stateParams.id == undefined &&
					$stateParams.duplicatedId == undefined
				) {
					$scope.depot.depot_type =
						$scope.filteredreaderTypes && $scope.filteredreaderTypes[0]
							? $scope.filteredreaderTypes[0].value
							: 'CSV';
				}
				$scope.isWithConnector = Number.isInteger(connectorId)
					? 'true'
					: 'false';
				getMongodbCollections();
			}

			function filterCharset() {
				$scope.charsets = $scope.initialCharsets;
				$scope.setSelectedCharset();
			}

			function saveDepotSuccess(returnToList, responseData, type) {
				$scope.remoteFilesCount = responseData.files_count;
				toaster.pop(
					'success',
					toasterSuccess,
					depotUpdate +
						' ' +
						depotTypeLib(type) +
						' ' +
						depotCreationOrUpdateSuccess
				);
				if (returnToList) {
					$timeout(function () {
						$state.go('depots');
					}, 300);
				}
				$scope.savingInProgress = false;
			}

			function initConnector() {
				if ($scope.isNewDepot && $stateParams.duplicatedId == undefined) {
					$scope.isWithConnector = 'false';
					$scope.depot.depot_type = 'CSV';
					$scope.depot.connector = { connector_type: 'LOCAL' };
				} else {
					$scope.isWithConnector = $scope.depot.connector_id != undefined;
					if ($scope.isWithConnector) {
						ConnectorService.getConnectorById($scope.depot.connector_id).then(
							function (response) {
								$scope.depot.connector = response.data;
								if ($scope.depot.connector && $scope.depot.connector.metadata) {
									$scope.depot.connector.lib =
										$scope.depot.connector.metadata.label;
								}
								$scope.selectOrUnSelectConnector();
								getMongodbCollections();
								getConnectorsForCurrentDepot();
							}
						);
					} else {
						$scope.depot.connector = { connector_type: 'LOCAL' };
						$scope.selectOrUnSelectConnector();
					}

					$scope.isWithConnector = $scope.isWithConnector + '';
				}
			}

			function getlinkedUetCount() {
				if ($scope.depot.id && $stateParams.duplicatedId == undefined) {
					EntiteDepotService.getUetCountByDepotId($scope.depot.id).then(
						function (response) {
							$scope.entiteConfig = $scope.entiteConfig
								? $scope.entiteConfig
								: {};
							$scope.entiteConfig.entitieTypesCount = response.data;
						}
					);
				}
			}

			function setLinkedUetCount() {
				$scope.entiteConfig = $scope.entiteConfig ? $scope.entiteConfig : {};
				$scope.entiteConfig.entitieTypesCount = $scope.depot.linked_uet_count;
			}

			function initDepotData(data, notSQL) {
				$scope.depot = data;
				$scope.depot.id = $scope.isNewDepot ? undefined : $scope.depot.id;
				$scope.depot.depot_type =
					$stateParams.type != undefined
						? $stateParams.type
						: $scope.depot.depot_type;
				$scope.metadata = $scope.isNewDepot
					? $scope.metadata
					: $scope.depot.metadata;
				$scope.headers = $scope.depot.headers;
				$scope.metadataLoaded = true;
				setLinkedUetCount();
				initLibs();
				$scope.archives = $scope.depot.archives;
				$scope.initArchivesGrid();
				$scope.setDefaultFetchedFiles();
				initConnector();
				getlinkedUetCount();
				initHeadersSortableOptions();

				if (notSQL) {
					$scope.remoteFilesCount = $scope.depot.files_count;
				}
			}

			// set native connector
			function setNativeConnectorMode(connector) {
				if (connector) {
					for (let i in $scope.connectors) {
						if (connector.id == $scope.connectors[i].id) {
							$scope.depot.connector = $scope.connectors[i];
							break;
						}
					}
				}
			}

			function generateBinaryDepotHeaders() {
				$scope.headers = $scope.isNewDepot ? [] : $scope.headers;
				$scope.sortableOptions.disabled =
					$scope.depot.depot_type === 'BINARY' ||
					$scope.depot.headers_referencing_mode ===
						HEADER_REFERENCING_MODE_LABEL;
				if ($scope.isNewDepot && $scope.depot.depot_type === 'BINARY') {
					$scope.headers = [];
					let fileName = {
						column: fileNameTra,
						position: 0,
						metadata: false,
						data_type: 'StringType',
					};
					$scope.headers.push(fileName);
					let file = {
						column: fileTra,
						position: 1,
						metadata: false,
						data_type: 'BinaryType',
					};
					$scope.headers.push(file);
					let details = {
						column: depotDetailsTra,
						position: 2,
						metadata: true,
						data_type: 'StringType',
					};
					$scope.headers.push(details);
				}
			}

			function moveTempUploadedFileToRightType() {
				if (
					$scope.isNewDepot &&
					$scope.metadata &&
					$scope.metadata.code &&
					$scope.oldDepotType &&
					$scope.depot.depot_type != $scope.oldDepotType
				) {
					DepotFileService.moveTmpFolderOnTypeUpdate(
						$scope.oldDepotType,
						$scope.depot.depot_type,
						$scope.metadata.code
					).then(function () {});
				}
				$scope.oldDepotType = angular.copy($scope.depot.depot_type);
			}

			function createMetadataHeader(headers) {
				let detailsCreatedOrUpdated = false;
				if (headers != undefined && headers.length > 0) {
					let detailsHeader = _.find(headers, function (elm) {
						return elm.depot_details;
					});
					if (detailsHeader == undefined) {
						let maxPosition = _.max(headers, function (elm) {
							return elm.position;
						});
						if (maxPosition == undefined) {
							maxPosition = {
								position: -1,
							};
						}
						maxPosition.position = +maxPosition.position;
						let details = {
							column: depotDetailsTra,
							position: maxPosition.position + 1,
							depot_details: true,
							data_type: 'StringType',
						};
						headers.push(details);
						detailsCreatedOrUpdated = true;
					}

					if (!$scope.isNewDepot && detailsCreatedOrUpdated) {
						$scope.saveFileHeadersPosition(true);
					}
				}
			}

			function actionsAfterDeleteDepot(type) {
				toaster.pop(
					'success',
					DeleteToasterSuccess,
					depotDeleteMessage +
						' ' +
						depotTypeLib(type) +
						' ' +
						depotCreationOrUpdateSuccess
				);
				$timeout(function () {
					$state.go('depots');
				}, 300);
			}

			function checkReadingMask(flow) {
				if (
					$scope.depot &&
					$scope.depot.connector &&
					$scope.depot.connector.connector_type !== 'HTTP' &&
					$scope.depot.depot_type !== 'SQL' &&
					$scope.depot.connector.connector_type !== 'SQL' &&
					$scope.depot.connector.connector_type !== 'MONGODB' &&
					$scope.depot.connector.connector_type !== 'NEO4J' &&
					($scope.depot.current_version_mask === '' ||
						$scope.depot.current_version_mask === undefined)
				) {
					toaster.pop('error', toasterPopError, toasterPopInputMask);
					$scope.readingMaskError = true;
					if (flow != undefined) {
						flow.cancel();
					}
					$('#readingMask').focus();
					return false;
				}

				return true;
			}

			function getUploadUrlPath(depotType) {
				let urlPath = $rootScope.getUrl('DEPOT_FILES_UPLOAD');
				if (depotType && typeof depotType === 'string') {
					if (depotType.toLowerCase() === 'csv') {
						urlPath = $rootScope.getUrl('CSV_DEPOT_FILES_UPLOAD');
					} else if (depotType.toLowerCase() === 'binary') {
						urlPath = $rootScope.getUrl('BINARY_DEPOT_FILES_UPLOAD');
					} else if (depotType.toLowerCase() === 'excel') {
						urlPath = $rootScope.getUrl('EXCEL_DEPOT_FILES_UPLOAD');
					} else if (depotType.toLowerCase() === 'json') {
						urlPath = $rootScope.getUrl('JSON_DEPOT_FILES_UPLOAD');
					} else if (depotType.toLowerCase() === 'parquet') {
						urlPath = $rootScope.getUrl('PARQUET_DEPOT_FILES_UPLOAD');
					} else if (depotType.toLowerCase() === 'xml') {
						urlPath = $rootScope.getUrl('XML_DEPOT_FILES_UPLOAD');
					}
				}
				return urlPath;
			}

			function getRootType(type) {
				return type !== undefined ? type.replace(/\((.+?)\)/gi, '') : type;
			}

			function handleUpload() {
				let flow = $scope.currentFlow;
				checkReadingMask(flow);
				flow.opts.query = {
					isDepot: true,
					code: $scope.metadata.code,
					depotType: $scope.depot.depot_type,
				};
				if ($scope.depot.connector) {
					flow.opts.query['connectorId'] = $scope.depot.connector.id;
				}
				flow.opts.headers = {
					Authorization: 'Bearer ' + window._keycloak.token,
					"Accept-Language": $rootScope.currentLangCode,
				};
				// Define the upload URL
				flow.opts.target =
					API_BASE_URL_BACKEND +
					getUploadUrlPath(
						$stateParams.type != undefined
							? $stateParams.type
							: $scope.depot.depot_type
					) +
					'?isNew=' +
					$scope.isNewDepot +
					'&ctx=' +
					$window.sessionStorage.getItem(CTX_VAR_NAME) +
					'&project=' +
					$rootScope.currentProjectId;
				$scope.depotFlow.flow = flow;
				$scope.uploadedFileProcessingDone = {};
				for (let i in flow.files) {
					CommonServices.validateFileByReadingMaskGlob(
						flow.files[i].name,
						$scope.depot.current_version_mask
					).then(function (response) {
						if (!response.data) {
							toaster.pop(
								'error',
								toasterPopError,
								toasterPopFile +
									" '" +
									flow.files[i].name +
									"' " +
									toasterPopFileMask
							);
							flow.files[i].cancel();
							delete flow.files[i];
							i--;
						} else {
							if (
								$scope.fileMaxSize &&
								flow.files[i].size > $scope.fileMaxSize
							) {
								toaster.pop(
									'error',
									toasterPopError,
									toasterPopFile +
										" '" +
										flow.files[i].name +
										"' " +
										toasterPopFileSize
								);
								flow.files[i].cancel();
								delete flow.files[i];
								i--;
							} else {
								flow.files[i].uniqueIdentifier = i + 1;
							}
						}
						if (i == flow.files.length - 1) {
							if (flow.files[i] && flow.files[i].uniqueIdentifier) {
								$scope.uploadedFileProcessingDone[
									flow.files[i].uniqueIdentifier
								] = false;
							}
							flow.upload();
						}
					});
				}
			}

			function resumeUpload(replaceFile) {
				let flow = $scope.currentFlow;
				let file = $scope.currentUploadFile;
				flow.opts.query = {
					isDepot: true,
					code: $scope.metadata.code,
					depotType: $scope.depot.depot_type,
				};
				flow.opts.headers = {
					Authorization: 'Bearer ' + window._keycloak.token,
					"Accept-Language": $rootScope.currentLangCode,
				};
				$scope.depotFlow.flow = flow;

				angular.forEach(flow.files, function (fileUnit, index) {
					flow.files[index].uniqueIdentifier = index + 1;
				});

				file.resume();
			}

			function save(returnToList) {
				let isValidMask = checkReadingMask();
				$scope.jobError = {};
				$scope.jobError.mainPosition = true;
				if (isValidMask) {
					if ($scope.depot.depot_type === 'SQL') {
						$scope.saveDbDepot(returnToList);
					} else if ($scope.depot.depot_type === 'MONGODB') {
						$scope.saveMongodbDepot(returnToList);
					} else if ($scope.depot.depot_type === 'NEO4J') {
						$scope.saveNeo4jDepot(returnToList);
					} else if ($scope.depot.depot_type === 'PARQUET') {
						$scope.saveParquetDepot(returnToList);
					} else if ($scope.depot.depot_type === 'JSON') {
						$scope.saveJsonDepot(returnToList);
					} else if ($scope.depot.depot_type === 'EXCEL') {
						$scope.saveExcelDepot(returnToList);
					} else if ($scope.depot.depot_type === 'BINARY') {
						$scope.saveBinaryDepot(returnToList);
					} else if ($scope.depot.depot_type === 'CSV') {
						$scope.saveCsvDepot(returnToList);
					} else if ($scope.depot.depot_type === 'XML') {
						$scope.saveXmlDepot(returnToList);
					} else {
						toaster.pop(
							'error',
							toasterPopError,
							'Type ' + $scope.depot.depot_type + ' inconnu'
						);
					}
				}
			}

			function redirectionToList() {
				$('#confirmationModal').modal('hide');
				$timeout(function () {
					$state.go('depots');
				}, 300);
			}
		},
	]);
})();
