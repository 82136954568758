(function () {
	'use strict';

	angular.module('dcApp').config([
		'$stateProvider',
		'$urlRouterProvider',
		'$httpProvider',
		function ($stateProvider, $urlRouterProvider, $httpProvider) {
			$stateProvider.state('logged', {
				name: 'root',
				abstract: true,
				views: {
					'app@': {
						templateUrl: './src/components/layouts/main-layout.html',
					},
				},
				admin: false,
				access: '*',
				resolve: {
					userIsAdmin: [
						'$q',
						'$rootScope',
						'UserService',
						'LoggerFactory',
						function ($q, $rootScope, UserService, LoggerFactory) {
							const logger = LoggerFactory.getLogger('AppState');
							return UserService.isAdmin().then(function (userIsAdmin) {
								$rootScope.userIsAdmin = userIsAdmin.data;
								$rootScope.$on('ng:ready', function () {
									logger.info(
										'Angular ready...Synchronizing data from AngularJS'
									);
									setTimeout(() => {
										$rootScope.$broadcast(
											'sync:user:is-admin',
											userIsAdmin.data
										);
									}, 0);
								});
								return $q.resolve(userIsAdmin.data);
							});
						},
					],
					hasAccessToExpositionV2: [
						'$q',
						'$rootScope',
						'UserService',
						'LoggerFactory',
						function ($q, $rootScope, UserService, LoggerFactory) {
							return UserService.hasAccessToV2().then(function (response) {
								$rootScope.hasAccessToExpositionV2 = !!response.data['EXPOSITION_V2'];
								return $q.resolve(!!response.data['EXPOSITION_V2']);
							});
						},
					],
				},
			});

			function addState(config) {
				const stateConfig = { data: { title: config.title } };
				stateConfig.data.admin = config.admin;

				if (config.access) {
					stateConfig.data.access = config.access;
				}

				if (config.url) {
					stateConfig.url = config.url;
				}

				if (config.extraParams) {
					stateConfig.params = config.extraParams;
				}

				if (config.template) {
					stateConfig.views = {
						'content@logged': {
							template: config.template,
						},
					};
					return $stateProvider.state(config.name, stateConfig);
				}

				if (
					config.parent !== undefined &&
					config.controller &&
					config.templateUrl
				) {
					stateConfig.parent = config.parent;
					stateConfig.views = {
						'content@logged': {
							templateUrl: config.templateUrl,
							controller: config.controller,
							controllerAs: '$ctrl',
						},
					};
					return $stateProvider.state(config.name, stateConfig);
				}

				if (
					config.parent === undefined &&
					config.controller &&
					config.templateUrl
				) {
					stateConfig.parent = config.parent;
					stateConfig.views = {
						'app@': {
							templateUrl: config.templateUrl,
							controller: config.controller,
						},
					};
					return $stateProvider.state(config.name, stateConfig);
				}

				if (config.views) {
					stateConfig.views = config.views;
					return $stateProvider.state(config.name, stateConfig);
				}
			}

			addState({
				name: 'data',
				url: '/data/:dbName/info',
				parent: undefined,
				title: 'DataBlock',
				templateUrl: './src/components/modules/datablocks/expose.html',
				controller: 'DbExposeController',
				admin: false,
				access: '*',
			});

			addState({
				name: 'public',
				url: '/public/:link',
				parent: undefined,
				title: 'Publication',
				templateUrl: './src/components/modules/hd/dashboard/publication.html',
				controller: 'PublicationController',
				admin: false,
				access: '*',
			});

			addState({
				name: 'accessDenied',
				url: '/403',
				parent: 'logged',
				title: 'Accès Refusé',
				templateUrl: './src/components/base/error/403.html',
				controller: 'AccessDeniedController',
				admin: false,
				access: '*',
			});

			addState({
				name: 'notFound',
				url: '/404',
				parent: 'logged',
				title: 'Non trouvé',
				templateUrl: './src/components/base/error/404.html',
				controller: {},
				admin: false,
				access: '*',
			});

			addState({
				name: 'parameters',
				url: '/parameters',
				parent: 'logged',
				title: 'Paramètres',
				templateUrl: './src/components/modules/parameters/parameters.html',
				controller: 'ParametersController',
				admin: true,
				access: 'admin',
			});

			addState({
				name: 'web-services',
				url: '/web-services',
				parent: 'logged',
				title: 'Web Services',
				templateUrl: './src/components/modules/web-services/webServices.html',
				controller: 'WebServiceController',
				admin: true,
				access: '*',
			});

			addState({
				name: 'pictogrammes',
				url: '/pictogrammes',
				parent: 'logged',
				title: 'Pictogrammes',
				templateUrl: './src/components/modules/pictogrammes/pictogrammes.html',
				controller: 'PictogrammeController',
				admin: true,
				access: '*',
			});

			addState({
				name: 'semanticContext',
				url: '/semanticContext',
				parent: 'logged',
				title: 'Contexte Sémantique',
				templateUrl:
					'./src/components/modules/semantic-rules/semantic-rules.html',
				controller: 'SemanticRulesController',
				admin: true,
				access: '*',
			});

			addState({
				name: 'tags',
				url: '/tags',
				parent: 'logged',
				title: 'Tags',
				templateUrl: './src/components/modules/tags/tags.html',
				controller: 'TagsController',
				admin: true,
				access: 'member',
			});

			addState({
				name: 'monitoring',
				url: '/monitoring',
				parent: 'logged',
				title: 'Supervision',
				templateUrl: './src/components/modules/monitoring/monitoring.html',
				controller: 'MonitoringController',
				admin: true,
				access: 'admin',
			});

			addState({
				name: 'monitoring-spark',
				url: '/monitoring/spark/:ctx',
				parent: 'logged',
				title: 'Supervision Spark',
				templateUrl: './src/components/modules/monitoring/monitoring.html',
				controller: 'MonitoringController',
				admin: true,
				access: 'admin',
			});

			$stateProvider.state('data-catalogue', {
				url: '/data-catalogue',
				parent: 'logged',
				data: {
					admin: true,
					access: '*',
					title: 'Data Catalogue',
				},
				views: {
					'content@logged': {
						templateUrl:
							'./src/components/modules/data-catalog/data-catalogue.html',
						controller: 'DataCatalogueController',
						controllerAs: 'vm',
					},
				},
			});

			$stateProvider.state('orphan-projects', {
				url: '/orphan-projects',
				parent: 'logged',
				data: {
					admin: true,
					access: '*',
				},
				views: {
					'content@logged': {
						template: `<app-orphan-project-list></app-orphan-project-list>`,
					},
				},
			});

			$urlRouterProvider.otherwise(function ($injector, $location) {
				var $state = $injector.get('$state');
				$state.go('projects');
			});

			if (!$httpProvider.defaults.headers.get) {
				$httpProvider.defaults.headers.get = {};
			}

			// disable IE ajax request caching
			$httpProvider.defaults.headers.get['If-Modified-Since'] =
				'Mon, 26 Jul 1997 05:00:00 GMT';
			// extra
			$httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';

			$httpProvider.interceptors.push('ErrorInterceptor');
			$httpProvider.interceptors.push('RequestInterceptor');
		},
	]);
})();
