(function () {
	'use strict';

	angular.module('dcApp').directive('importFormula', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'$rootScope',
				'toaster',
				'gettextCatalog',
				'FormulaCatalogService',
				'API_BASE_URL_BACKEND',
				function (
					$scope,
					$rootScope,
					toaster,
					gettextCatalog,
					FormulaCatalogService,
					API_BASE_URL_BACKEND
				) {
					var vm = this;

					vm.importPopupShow = function () {
						$scope.visibleImportPopup = true;
					};

					$scope.visibleImportPopup = false;
					$scope.importPopupOptions = {
						width: 800,
						height: 'auto',
						shading: false,
						contentTemplate: 'importFormulaTemplate',
						titleTemplate: 'importFormulaTitleTemplate',
						showTitle: true,
						dragEnabled: false,
						closeOnOutsideClick: false,
						showCloseButton: true,
						bindingOptions: {
							visible: 'visibleImportPopup',
						},
						onContentReady: function (e) {
							let html = e.component.content();
							$(html).css('padding', '5');
						},
					};

					$scope.uploadDone = false;
					$scope.submitFlow = function (files, event, flow) {
						$scope.responseImportId = undefined;
						$scope.responseImportData = undefined;
						flow.opts.headers = {
							Authorization: 'Bearer ' + window._keycloak.token,
							"Accept-Language": $rootScope.currentLangCode,
						};
						flow.opts.target =
							API_BASE_URL_BACKEND +
							$rootScope.getUrl('FORMULA_CATALOG_IMPORT_UPLOAD') +
							'?project=' +
							$rootScope.currentProjectId;
						flow.upload();
						$scope.uploadDone = false;
					};

					$scope.uploadError = function (file, response, flow) {
						let data = JSON.parse(response);
						let error =
							data.field_errors != undefined && data.field_errors.length > 0
								? data.field_errors[0]
								: data;
						let msgHeader = gettextCatalog.getString('Erreur');
						if (error.field != undefined) {
							angular.forEach($scope.depots.flow.files, function (fl) {
								fl.cancel();
							});
						}
						toaster.pop('error', msgHeader, error.message, 20000);
						$scope.uploadDone = false;
					};

					let responseImportId;
					$scope.responseImportData = undefined;
					$scope.handleUploadResponse = function (flow, file, response) {
						let data = JSON.parse(response);
						$scope.responseImportData = data;
						responseImportId = data.id;
						$scope.uploadDone = true;
					};

					$scope.integrateImportedElements = function () {
						FormulaCatalogService.integrateImportedElements(
							responseImportId
						).then(function (response) {
							$scope.visibleImportPopup = false;
							$scope.uploadDone = false;
							toaster.pop(
								'success',
								gettextCatalog.getString('Succès'),
								gettextCatalog.getString('Import effectué')
							);
							vm.refreshActionAfterImport(response.data);
						});
					};

					$scope.closeButton = {
						icon: 'close',
						onClick() {
							$scope.visibleImportPopup = false;
							$scope.uploadDone = false;
							delete $scope.responseImportId;
							delete $scope.responseImportData;
						}
					};
				},
			];

			return {
				restrict: 'E',

				scope: {
					showImportPopup: '=',
					refreshActionAfterImport: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/dataOperationTools/formule/formulaImport/importFormula.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs, $ctrl) {
					$ctrl.showImportPopup = $ctrl.importPopupShow;
				},
			};
		},
	]);
})();
