(function () {
	angular.module('dcApp').directive('elementPermission', [
		'$parse',
		function ($parse) {
			let controller = [
				'$scope',
				'$rootScope',
				'PermissionService',
				'toaster',
				'gettextCatalog',
				'ProjectService',
				function (
					$scope,
					$rootScope,
					PermissionService,
					toaster,
					gettextCatalog,
					ProjectService
				) {
					var vm = this;

					$scope.permissionActionLibelles =
						$rootScope.permissionsActionLabelsTrans;
					$scope.selectedElementType = {};
					let isPublicProject = true;

					PermissionService.getElementPermissionRules().then(function (
						response
					) {
						$scope.hdhElementPermissions = response.data;
					});

					$scope.reload = function () {
						delete $scope.gridOptions;
					};

					$scope.element_types = [
						{
							code: 'connector',
							label: gettextCatalog.getString('Connecteurs'),
						},
						{ code: 'data_source', label: gettextCatalog.getString('Dépôts') },
						{
							code: 'entity',
							label: gettextCatalog.getString('Entités Métier'),
						},
						{ code: 'datablock', label: gettextCatalog.getString('DataBlock') },
						{
							code: 'dashboard',
							label: gettextCatalog.getString('Tableaux de Bord'),
						},
						{ code: 'timeline', label: gettextCatalog.getString('Timeline') },
						{ code: 'chart', label: gettextCatalog.getString('Graphiques') },
						{ code: 'media', label: gettextCatalog.getString('Médias') },
						{ code: 'grid', label: gettextCatalog.getString('Tableaux') },
						{ code: 'map', label: gettextCatalog.getString('Cartes') },
						{ code: 'export_template', label: gettextCatalog.getString('export.template') },
						{code: 'exposition', label: gettextCatalog.getString('exposition.name')},
					];

					let yesTra = gettextCatalog.getString('Oui');
					let noTra = gettextCatalog.getString('Non');

					$scope.reloadPermissions = function () {
						ProjectService.isPublicProject(vm.projectId).then(function (
							response
						) {
							isPublicProject = response.data;
							getPermissions();
						});
					};

					var getPermissions = function () {
						delete $scope.gridOptions;
						var options =
							$scope.hdhElementPermissions[$scope.selectedElementType.code];
						var columns = [
							{
								dataField: 'lib',
								caption: gettextCatalog.getString('Libellé'),
								allowFiltering: true,
								allowSorting: true,
								witdh: '150px',
							},
							{
								dataField: '',
								caption: gettextCatalog.getString('Tout'),
								width: '80px',
							},
							{
								dataField: 'desc',
								caption: gettextCatalog.getString('Description'),
								allowFiltering: true,
								allowSorting: true,
								witdh: '150px',
							},
						];
						columns[0].cellTemplate =
							'<div> {{gridElList.data[gridElList.column.dataField]}}';
						columns[1].cellTemplate =
							'<button type="button" class="btn btn-primary btn-xs pull-right" ng-click="changePermissionManyActions(gridElList.data, true)"> ' +
							yesTra +
							' </button> <button type="button" class="btn btn-default btn-xs pull-right" ng-click="changePermissionManyActions(gridElList.data, false)"> ' +
							noTra +
							' </button> </div>';
						for (var o in options) {
							if (
								options[o] == 'create' ||
								options[o] == 'list' ||
								((options[o] == 'view' || options[o] == 'data') &&
									isPublicProject)
							) {
								continue;
							}
							let col = {
								permission: options[o],
								dataField: options[o],
								caption: '',
								allowFiltering: false,
								allowSorting: false,
							};
							col.headerCellTemplate =
								'<div> ' +
								$scope.permissionActionLibelles[options[o]] +
								' <button ng-click="changePermissionManyElements(gridElList.column.dataField, true)" ng-if="selectedElements[0]" type="button" class="btn btn-primary btn-xs pull-right"> ' +
								yesTra +
								' </button> ';
							col.headerCellTemplate =
								col.headerCellTemplate +
								'<button ng-click="changePermissionManyElements(gridElList.column.dataField, false)" ng-if="selectedElements[0]" type="button" class="btn btn-default btn-xs pull-right" > ' +
								noTra +
								' </button> </div>';
							col.cellTemplate = 'permissionActionsEl';
							columns.push(col);
						}

						if (
							$scope.selectedElementType.code == 'datablock' ||
							$scope.selectedElementType.code == 'entity'
						) {
							let col = {
								permission: 'column',
								dataField: 'column',
								caption: '',
								allowFiltering: false,
								allowSorting: false,
								headerCellTemplate:
									'<div> ' + gettextCatalog.getString('Colonnes') + ' </div>',
							};
							col.cellTemplate =
								'<div class="text-center"> <button type="button" class="btn btn-xs btn-primary" style="margin-right: 10px;" ng-click="showColumnPermissions(gridElList.data.metadata_id)"> ' +
								gettextCatalog.getString('Voir') +
								' </button> </div>';
							col.headerCellTemplate =
								'' +
								'<div>' + gettextCatalog.getString('Colonnes') +
								'<button ng-click="changePermissionManyElements(gridElList.column.dataField, true)" ng-if="selectedElements[0]" type="button" class="btn btn-primary btn-xs pull-right"> ' +
								yesTra +
								'</button> ';
							col.headerCellTemplate =
								col.headerCellTemplate +
								'<button ng-click="changePermissionManyElements(gridElList.column.dataField, false)" ng-if="selectedElements[0]" type="button" class="btn btn-default btn-xs pull-right" > ' +
								noTra +
								' </button> </div>';
							columns.push(col);
						}
						$scope.selectedElements = [];
						PermissionService.getUserPermissions(
							vm.projectId,
							vm.userId,
							vm.isGroup,
							$scope.selectedElementType.code
						).then(function (response) {
							$scope.gridOptions = {
								headerFilter: { visible: true, applyFilter: 'auto' },
								filterRow: { visible: true, applyFilter: 'auto' },
								columnAutoWidth: true,
								showBorders: true,
								showColumnLines: true,
								showRowLines: true,
								columns: columns,
							};
							//store and key should be used to keep items selected after data change
							$scope.gridOptions.dataSource = {
								store: {
									type: 'array',
									data: response.data,
									key: 'metadata_id',
								},
							};
							$scope.gridOptions.selection = {
								mode: 'multiple',
								showCheckBoxesMode: 'always',
							};
							$scope.gridOptions.onSelectionChanged = function (selectedItems) {
								$scope.selectedElements = selectedItems.selectedRowsData;
							};
							$scope.gridOptions.paging = { enabled: true, pageSize: 5 };
							$scope.gridOptions.pager = {
								showPageSizeSelector: true,
								allowedPageSizes: [5, 25, 50, 100],
								showInfo: true,
								visible: true,
							};
						});
					};

					let successTra = gettextCatalog.getString('Succès');
					let successSaveTra = gettextCatalog.getString(
						'Enregistrement des permissions effectué'
					);
					$scope.changePermissionManyElements = function (code, permitted) {
						var metadata_ids = _.map($scope.selectedElements, function (item) {
							return item.metadata_id;
						});
						var permission = {
							user_id: vm.userId,
							is_group: vm.isGroup,
							action: code,
							metadata_ids: metadata_ids,
							permitted: permitted,
						};
						PermissionService.updateUserPermissionManyElements(
							vm.projectId,
							permission
						).then(function (response) {
							for (var e in $scope.selectedElements) {
								$scope.selectedElements[e].permissions[code] = permitted;
							}
							toaster.pop('success', successTra, successSaveTra);
						});
					};

					$scope.changePermissionManyActions = function (item, permitted) {
						var actions =
							$scope.hdhElementPermissions[$scope.selectedElementType.code];
						if (
							$scope.selectedElementType.code == 'datablock' ||
							$scope.selectedElementType.code == 'entity'
						) {
							actions.push('column');
						}
						var permission = {
							user_id: vm.userId,
							is_group: vm.isGroup,
							actions: actions,
							metadata_id: item.metadata_id,
							permitted: permitted,
						};
						PermissionService.updateUserPermissionManyActions(
							vm.projectId,
							permission
						).then(function (response) {
							for (var a in actions) {
								item.permissions[actions[a]] = permitted;
							}
							toaster.pop('success', successTra, successSaveTra);
						});
					};

					$scope.changePermission = function (item, code) {
						var permission = {
							user_id: vm.userId,
							is_group: vm.isGroup,
							action: code,
							metadata_id: item.metadata_id,
							permitted: item.permissions[code],
						};
						PermissionService.updateUserPermission(
							vm.projectId,
							permission
						).then(function (response) {
							toaster.pop('success', successTra, successSaveTra);
						});
					};

					$scope.showColumnPermissions = function (metadataId) {
						vm.permissionData.metadataId = metadataId;
						vm.permissionData.userId = vm.userId;
						vm.permissionData.isGroup = vm.isGroup;
						vm.permissionData.showColumnPermissions = true;
					};
				},
			];

			return {
				restrict: 'E',
				scope: {
					userId: '=',
					isGroup: '=',
					projectId: '=',
					permissionData: '=',
				},
				controller: controller,
				bindToController: true,
				transclude: true,
				replace: true,
				controllerAs: 'vm',
				templateUrl:
					'./src/components/directives/permission/element/elementPermissions.html',
				link: function (scope, element, attrs) {
					if (scope.permissionData) {
						scope.permissionData.reload = scope.reload;
					}
				},
			};
		},
	]);
})();
