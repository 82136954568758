(function () {
	'use strict';

	angular.module('dcApp').directive('dataview', [
		'$parse',
		'$compile',
		function ($parse, $compile) {
			var controller = [
				'$scope',
				'$rootScope',
				'SparkJobsServices',
				'hdSourceService',
				'PAGINATIONS_SIZE',
				'PAGINATIONS_SIZES',
				'$sce',
				'DataBlocksService',
				'gettextCatalog',
				'DateService',
				'$q',
				'GrammarUtils',
				function (
					$scope,
					$rootScope,
					SparkJobsServices,
					hdSourceService,
					PAGINATIONS_SIZE,
					PAGINATIONS_SIZES,
					$sce,
					DataBlocksService,
					gettextCatalog,
					DateService,
					$q,
					GrammarUtils
				) {
					let seeBackendTra = gettextCatalog.getString('Voir log backend');

					$scope.$on('job_private', function (event, message) {
						handleSocketMessageJobRunning(message);
					});
					var vm = this;

					if (vm.reloadTableOn) {
						$scope.$watch(
							vm.reloadTableOn,
							function (newValue, oldValue) {
								if (newValue && $scope.gridInstance) {
									$scope.gridInstance.refresh();
								} else if (newValue) {
									loadGrid();
								} else {
									delete $scope.gridInstance;
									delete $scope.gridOptions;
								}
							},
							true
						);
					}

					$scope.datablockData = { widgetMenuData: {} };
					$scope.dbBuilder = { widgetData: $scope.datablockData };

					var getGridOptions = function (columns, dataSource) {
						return {
							dataSource: dataSource,
							allowColumnReordering: true,
							allowColumnResizing: true,
							columnAutoWidth: true,
							showBorders: true,
							rowAlternationEnabled: true,
							paging: {
								enabled: true,
								pageSize: PAGINATIONS_SIZE,
							},
							pager: {
								showPageSizeSelector: true,
								allowedPageSizes: PAGINATIONS_SIZES,
								showInfo: true,
								visible: true,
							},
							headerFilter: {
								visible: true,
								allowSearch: true,
							},
							filterRow: {
								visible: true,
								applyFilter: 'auto',
							},
							noDataText: '',
							columns: columns,
							onInitialized: function (e) {
								$scope.gridInstance = e.component;
							},
							onContentReady: function (e) {
								limitColumnWithWhenLargerThan400(e);
								getFilteredOrSorted(e.component, $scope);
							},
							onCellPrepared: function (e) {
								if (e.rowType = "data" && e.column.col_type == 'date' && e.data && e.data[e.column.columnUuid] && e.data[e.column.columnUuid].rawDate) {
									e.cellElement.mouseover(function (arg) {
										$scope.currentDateValue = e.data[e.column.columnUuid].rawDate;
										$scope.tooltipInstance.show(arg.target);
									});
										e.cellElement.mouseout(function (arg) {
										$scope.tooltipInstance.hide();
									});
								}
							},
							onToolbarPreparing: warningSortFilterGenerator,
						};
					};

					$scope.popupWarningFilterOptions = popupWarningFilterOptions;
					$scope.visibleWarningSortPopup = false;
					$scope.showPopupWarningFilter = function () {
						$scope.visibleWarningSortPopup = true;
					};
					$scope.reloadGridView = function () {
						$scope.gridInstance.refresh();
					};

					$scope.containsFilter = function (i) {
						return (
							($scope.dataview &&
								$scope.dataview.grammar.steps[i].filter &&
								$scope.dataview.grammar.steps[i].filter.rules &&
								$scope.dataview.grammar.steps[i].filter.rules[0]) ||
							$scope.dataview.grammar.steps[i].limit
						);
					};

					$scope.filterIsInactive = function (i) {
						return (
							$scope.dataview &&
							$scope.dataview.grammar.steps[i].filter &&
							$scope.dataview.grammar.steps[i].filter.inactive
						);
					};

					$scope.containsFormula = function (i) {
						return (
							$scope.dataview &&
							$scope.dataview.grammar.steps[i].formulas &&
							$scope.dataview.grammar.steps[i].formulas[0]
						);
					};

					$scope.containsAgregation = function (i) {
						return (
							$scope.dataview &&
							stepContainsAgregation($scope.dataview.grammar.steps[i])
						);
					};

					$scope.containsSort = function (i) {
						return (
							$scope.dataview &&
							stepContainsSort($scope.dataview.grammar.steps[i])
						);
					};

					$scope.sortIsInactive = function (i) {
						return $scope.dataview.grammar.steps[i].sort && $scope.dataview.grammar.steps[i].sort.inactive;
					};

					function handleSocketMessageJobRunning(notif) {
						if (
							$scope.countLoading &&
							notif.data.client_id == $scope.countJobClientId
						) {
							handleCountJobStatus(notif);
						} else if (
							$scope.dataLoading &&
							notif.data.client_id == $scope.dataJobClientId
						) {
							handleDataJobStatus(notif);
						}
					}

					var handleCountJobStatus = function (notif) {
						if (
							notif.data.status == 'SUCCESS' ||
							notif.data.status == 'CACHED'
						) {
							getCountJobResult(notif.data.job_id);
						} else if (
							notif.data.status == 'CANCELLED' ||
							notif.data.status == 'ERROR'
						) {
							setCountJobStatus(notif);
						}
					};

					var handleDataJobStatus = function (notif) {
						if (
							notif.data.status == 'SUCCESS' ||
							notif.data.status == 'CACHED'
						) {
							getDataJobResult(notif.data.job_id);
						} else if (
							notif.data.status == 'CANCELLED' ||
							notif.data.status == 'ERROR'
						) {
							setDataJobStatus(notif);
						}
					};

					$scope.reinit = function (dataview) {
						$scope.dataview = dataview;
						if ($scope.vm.data.auto_execute) {
							$scope.exec();
						}
					};

					$scope.init = function () {
						$scope.vm.data.reinit = $scope.reinit;
						$scope.vm.data.exec = $scope.exec;
						$scope.dataview = $scope.vm.data.dataview;
						if ($scope.vm.data.auto_execute) {
							setTimeout(function () {
								if (!$scope.vm.data.full_data) {
									$scope.exec($scope.dataview.ignore_cache_on_init);
								} else {
									$scope.resultColumns = $scope.vm.data.full_data.columns;
									fillData($scope.vm.data.full_data.data);
								}
							});
						} else {
							$scope.loadGridColumns();
						}
					};

					$scope.close = function () {
						$scope.vm.data.close();
					};

					$scope.loadGridColumns = function () {
						DataBlocksService.getDBBuilderColumns($scope.dataview.grammar).then(
							function (response) {
								let columns = getColumns(response.data);
								if (vm.data.loadGrid) {
									$scope.gridOptions = getGridOptions(columns, []);
								}
							}
						);
					};

					$scope.exec = function (withoutCache) {
						if (vm.jobDetailsActive && $scope.jobDetails.init) {
							$scope.jobDetails.init();
						}
						if ($scope.vm.data.enableCount) {
							execCount(withoutCache);
						}
						execData(withoutCache);
					};

					$scope.execWithoutCache = function () {
						$scope.exec(true);
					};

					$scope.killCountJob = function () {
						if (!$scope.countJobId) {
							return;
						}
						SparkJobsServices.killJob($scope.countJobId).then(function (
							response
						) {});
					};

					$scope.killDataJob = function () {
						if (!$scope.dataJobId) {
							return;
						}
						SparkJobsServices.killJob($scope.dataJobId).then(function (
							response
						) {});
					};

					var execCount = function (noCache) {
						delete $scope.countJobOnError;
						delete $scope.countJobKilled;
						delete $scope.countJobError;
						$scope.countJobClientId = generateClientId(
							$rootScope.account.login
						);
						$scope.countLoading = true;
						var grammar = angular.copy(getGrammar());
						grammar.process_summary = true;
						grammar.steps[2].total_count = true;
						hdSourceService
							.execData(
								{
									grammar: grammar,
									centerings: getCenterings(),
									centering_modes: getCenteringModes(),
								},
								undefined,
								undefined,
								$scope.countJobClientId,
								noCache
							)
							.then(function (response_) {
								if (!response_.data || !response_.data.value) {
									$scope.countJobOnError = true;
									$scope.countJobError = { errorMessage: seeBackendTra };
									stopCountLoading();
								}
								if (response_.data.exec_data_result) {
									fillCount(response_.data.exec_data_result);
								}
							});
					};

					var getCenterings = function () {
						if (
							!$scope.dataview.centering ||
							!$scope.dataview.centering.active ||
							!$scope.dataview.centering.source_centering
						) {
							return null;
						}
						var centerings = [];
						for (var c in $scope.dataview.centering.centerings) {
							if ($scope.dataview.centering.centerings[c].inactive) {
								continue;
							}
							let centering =
								$scope.dataview.centering.source_centering[
									$scope.dataview.centering.centerings[c].uuid
								];
							if (centering && centering[$scope.dataview.source_id]) {
								$scope.dataview.centering.centerings[c].column_uuid =
									centering[$scope.dataview.source_id];
								centerings.push($scope.dataview.centering.centerings[c]);
							}
							if (
								$scope.dataview.centering.centerings[c].value
									.list_string_value &&
								$scope.dataview.centering.centerings[c].value
									.list_string_value[0] &&
								$scope.dataview.centering.centerings[c].value
									.list_string_value[0].text
							) {
								$scope.dataview.centering.centerings[
									c
								].value.list_string_value = _.map(
									$scope.dataview.centering.centerings[c].value
										.list_string_value,
									function (item) {
										return item.text;
									}
								);
							}
						}
						return centerings;
					};

					var getCenteringModes = function () {
						if (
							!$scope.dataview.centering ||
							!$scope.dataview.centering.active ||
							!$scope.dataview.centering.source_centering_modes
						) {
							return null;
						}
						return $scope.dataview.centering.source_centering_modes;
					};

					var execData = function (noCache) {
						delete $scope.gridOptions;
						delete $scope.dataJobOnError;
						delete $scope.dataJobKilled;
						delete $scope.dataJobError;
						$scope.dataJobClientId = generateClientId($rootScope.account.login);
						$scope.dataLoading = true;
						var grammar = angular.copy(getGrammar());

						hdSourceService
							.execData(
								{
									grammar: grammar,
									centerings: getCenterings(),
									centering_modes: getCenteringModes(),
								},
								undefined,
								undefined,
								$scope.dataJobClientId,
								noCache
							)
							.then(function (response_) {
								$scope.resultColumns = response_.data.columns;
								if (!response_.data || !response_.data.value) {
									$scope.dataJobOnError = true;
									$scope.dataJobError = { errorMessage: seeBackendTra };
									stopDataLoading();
								}
								if (response_.data.exec_data_result) {
									fillData(response_.data.exec_data_result);
								}
							});
					};

					var getCountJobResult = function (jobId) {
						hdSourceService.getDataByJobId(jobId).then(function (response) {
							if (response !== undefined && response.data) {
								fillCount(response.data);
							}
						});
					};

					var getDataJobResult = function (jobId) {
						hdSourceService.getDataByJobId(jobId).then(function (response) {
							if (response !== undefined && response.data) {
								fillData(response.data);
							}
						});
					};

					var setCountJobStatus = function (notif) {
						if (notif.data.status == 'ERROR') {
							$scope.countJobOnError = true;
							$scope.countJobError = { errorMessage: notif.data.error_message };
						} else if (notif.data.status == 'CANCELLED') {
							$scope.countJobKilled = true;
						}
						stopCountLoading();
					};

					var setDataJobStatus = function (notif) {
						if (notif.data.status == 'ERROR') {
							$scope.dataJobOnError = true;
							$scope.dataJobError = { errorMessage: notif.data.error_message };
						} else if (notif.data.status == 'CANCELLED') {
							$scope.dataJobKilled = true;
						}
						stopDataLoading();
					};

					var getGrammar = function () {
						var grammar = $scope.dataview.grammar;
						if ($scope.global_search_text && $scope.global_search_text[0]) {
							grammar.global_filter = [$scope.global_search_text];
							grammar.global_filter_ignore_case =
								$scope.global_search_ignore_case;
						} else {
							delete grammar.global_filter;
							delete grammar.global_filter_ignore_case;
						}
						return grammar;
					};

					var fillCount = function (data) {
						$scope.countJobDateExec = data.date_exec;
						$scope.dataTotalCount = data.data[0][0];
						$scope.countJobTimeExec = data.time_exec / 1000;
						let jobDetails = {
							is_cached: data.is_cached,
							name: 'Compte',
							pushDate: data.push_date,
							endExecutionDate: data.exec_end_date,
							duration: data.time_exec,
						};
						if (vm.jobDetailsActive) {
							$scope.jobDetails.update(jobDetails);
						} else if ($scope.vm.data.jobDetails) {
							$scope.vm.data.jobDetails.update(jobDetails);
						}
						stopCountLoading();
					};

					var getColumns = function (columns) {
						var columnsDxGrid = [];
						for (var c in columns) {
							var colDx = {
								allowEditing: false,
								allowFiltering: true,
								allowHeaderFiltering: true,
								allowFixing: false,
								allowHiding: false,
								allowReordering: false,
								allowSorting: true,
								allowSearch: false,
								dataField: columns[c].uuid,
								columnUuid: columns[c].uuid,
								visible: true,
								col_type: columns[c].type,
								caption: columns[c].lib,
								is_list: columns[c].is_list,
								headerCellTemplate: 'headerCellTemplate',
								cellTemplate: 'cellTemplate',
								calculateCellValue: function (rowData) {
									return rowData[this.dataField].val;
								},
							};
							columnsDxGrid.push(colDx);
						}
						return columnsDxGrid;
					};

					$scope.tooltipOptions = {
						position: "right",
						onInitialized: function (e) {
							$scope.tooltipInstance = e.component;
						},
						wrapperAttr: {
							 class: "iso-date-tooltip"
					 }
					}

					var fillData = function (data) {
						let jobDetails = {
							is_cached: data.is_cached,
							name: 'Données',
							pushDate: data.push_date,
							endExecutionDate: data.exec_end_date,
							duration: data.time_exec,
						};
						if (vm.jobDetailsActive) {
							$scope.jobDetails.update(jobDetails);
							$scope.jobDetails.setFromCache(data.is_cached);
						} else if (
							$scope.vm.data.jobDetails &&
							$scope.vm.data.jobDetails.update
						) {
							$scope.vm.data.jobDetails.update(jobDetails);
						}
						$scope.dataJobDateExec = data.date_exec;
						$scope.dataJobTimeExec = data.time_exec / 1000;

						var formattedData = [];
						var resultData = data.data;
						$scope.takeSize = resultData.length;
						$scope.filteredOrSorted = false;
						for (var d in resultData) {
							var o = {};
							var colsLength = resultData[d].length;
							var sizeExceededTab = resultData[d][colsLength - 1];
							for (var e = 0; e < colsLength - 1; e++) {
								o[$scope.resultColumns[e].uuid] = {};
								o[$scope.resultColumns[e].uuid].sizeExceeded =
									sizeExceededTab.indexOf(e) > -1;
								o[$scope.resultColumns[e].uuid].val = resultData[d][e];
								if ($scope.resultColumns[e].type == 'date') {
									if ($scope.resultColumns[e].is_list) {
										let dateValue = angular.copy(o[$scope.resultColumns[e].uuid].val);
										let timezone = $scope.resultColumns[e].date_timezone ? $scope.resultColumns[e].date_timezone : $rootScope.getDefaultTimezone();
										o[$scope.resultColumns[e].uuid].val = DateService.datesListToStringWithPatternAndTZ(
											dateValue,
											$rootScope.getCorrespondentDatePattern($scope.resultColumns[e].date_pattern),
											timezone
											);
										o[$scope.resultColumns[e].uuid].rawDate = DateService.datesListToISOString(
											dateValue,
											$scope.resultColumns[e].date_timezone
										);
									} else {
										let dateValue = angular.copy(o[$scope.resultColumns[e].uuid].val);
										o[$scope.resultColumns[e].uuid].timestamp = o[$scope.resultColumns[e].uuid].val;
										let timezone = $scope.resultColumns[e].date_timezone ? $scope.resultColumns[e].date_timezone : $rootScope.getDefaultTimezone();
										o[$scope.resultColumns[e].uuid].val = DateService.dateToStringWithPatternAndTZ(
											dateValue,
											$rootScope.getCorrespondentDatePattern($scope.resultColumns[e].date_pattern),
											timezone);
										o[$scope.resultColumns[e].uuid].rawDate = DateService.dateToISOString(
											dateValue
										);
									}
								}
							}
							formattedData.push(o);
						}

						if ($scope.vm.data.afterDataLoaded) {
							$scope.vm.data.afterDataLoaded(
								formattedData,
								$scope.resultColumns
							);
						}

						let columns = getColumns($scope.resultColumns);
						if ($scope.vm.data.showMarkerOption) {
							columns = [
								{
									allowEditing: false,
									allowFiltering: false,
									allowFixing: false,
									allowHiding: false,
									allowReordering: false,
									allowSorting: false,
									allowSearch: false,
									dataField: '',
									width: '40px',
									visible: true,
									cellTemplate: 'showMarkerColumnTemplate',
								},
							].concat(columns);
						}
						$scope.columns = columns;
						$scope.formattedData = formattedData;

						if (vm.data.loadGrid) {
							loadGrid();
						}

						stopDataLoading();
					};

					var loadGrid = function () {
						$scope.gridOptions = getGridOptions(
							$scope.columns,
							$scope.formattedData
						);
					};

					var stopCountLoading = function () {
						$scope.countLoading = false;
						delete $scope.countJobClientId;
						delete $scope.countJobId;
					};

					var stopDataLoading = function () {
						$scope.dataLoading = false;
						delete $scope.dataJobClientId;
						delete $scope.dataJobId;
					};

					$scope.showMarker = function (item) {
						$scope.vm.data.showMarkerAction(item);
					};

					$scope.showColumns = function () {
						$scope.selectedStep = $scope.dataview.grammar.steps[0];
						$scope.oldSteps = angular.copy($scope.dataview.grammar.steps);
						$scope.oldPartielStep = {};
						$scope.datablockData.updateaction = $scope.saveColumns;
						$scope.datablockData.widget = $scope.dataview;
						$scope.datablockData.saveColumnsMapping = $scope.saveColumnsMapping;
						$scope.datablockData.showAddColumn = true;
					};

					$scope.saveColumnsMapping = function (fromDataBlock, columns) {
						$scope.dataview.grammar.columns = columns;
					};

					$scope.saveColumns = function (columns) {
						checkDeletedColumns(
							undefined,
							{ in_columns: true },
							function () {
								let oldCols = angular.copy($scope.dataview.grammar.columns);
								$scope.dataview.grammar.columns = columns;
								let deletedItems = _.difference(_.pluck(oldCols, 'uuid'), _.pluck(columns, 'uuid'));
								// update input and outputs aif some cols deleted in init
								if (deletedItems.length > 0) {
									for (let i in $scope.dataview.grammar.steps) {
										let s = $scope.dataview.grammar.steps[i];
										if ($scope.dataview.grammar.steps[i].input) {
											$scope.dataview.grammar.steps[i].input.columns = _.filter($scope.dataview.grammar.steps[i].input.columns, function (item) {
												return !_.contains(deletedItems, item.column_uuid);
											});
										}
										if ($scope.dataview.grammar.steps[i].output) {
											$scope.dataview.grammar.steps[i].output.columns = _.filter($scope.dataview.grammar.steps[i].input.output, function (item) {
												return !_.contains(deletedItems, item.column_uuid);
											});
										}

									}
								}
								$scope.datablockData.showAddColumn = false;
							},
							columns
						);
					};

					$scope.showFormulas = function (i) {
						$scope.selectedStep = $scope.dataview.grammar.steps[i];
						$scope.oldSteps = angular.copy($scope.dataview.grammar.steps);
						$scope.datablockData.updateaction = $scope.saveFormulas;
						var step = angular.copy($scope.selectedStep);
						var steps = $scope.dataview.grammar.steps.slice(0, i);
						steps.push(step);
						step.formulas = [];
						step.agregation = {};
						step.sort = {};
						delete step.output;
						$scope.dbBuilder.step = step;
						$scope.oldPartielStep = angular.copy(step);
						$scope.oldPartielStep.formulas = angular.copy(
							$scope.selectedStep.formulas
						);
						getInputColumns(steps, openFormulas);
					};

					$scope.showAgregation = function (i) {
						$scope.selectedStep = $scope.dataview.grammar.steps[i];
						$scope.oldSteps = angular.copy($scope.dataview.grammar.steps);
						$scope.dbBuilder.saveAgregationMethod = saveAgregation;
						$scope.dbBuilder.datablock = $scope.dataview;
						$scope.dbBuilder.currentSteps = [];
						var step = angular.copy($scope.selectedStep);
						var steps = $scope.dataview.grammar.steps.slice(0, i);
						steps.push(step);
						step.agregation = {};
						step.sort = {};
						delete step.output;
						$scope.dbBuilder.step = $scope.selectedStep;
						$scope.oldPartielStep = angular.copy(step);
						$scope.oldPartielStep.agregation = angular.copy(
							$scope.selectedStep.agregation
						);
						getInputColumns(steps, openAgregation);
					};

					$scope.showSorts = function (i) {
						$scope.selectedStep = $scope.dataview.grammar.steps[i];
						$scope.dbBuilder.saveSortsMethod = saveSorts;
						var step = angular.copy($scope.selectedStep);
						var steps = $scope.dataview.grammar.steps.slice(0, i);
						steps.push(step);
						step.sort = {};
						delete step.output;
						$scope.dbBuilder.step = $scope.selectedStep;
						getInputColumns(steps, openSorts);
					};

					$scope.showOutput = function (i) {
						$scope.selectedStep = $scope.dataview.grammar.steps[i];
						$scope.oldSteps = angular.copy($scope.dataview.grammar.steps);
						$scope.dbBuilder.saveOutputMethod = saveOutput;
						var step = angular.copy($scope.selectedStep);
						var steps = $scope.dataview.grammar.steps.slice(0, i);
						$scope.oldPartielStep = angular.copy(step);
						$scope.oldPartielStep.output = angular.copy(
							$scope.selectedStep.output
						);
						steps.push(step);
						step.output = [];
						$scope.dbBuilder.step = $scope.selectedStep;
						getInputColumns(steps, openOutput);
					};

					$scope.saveFormulas = function (
						widget,
						saveAndCloseMethod,
						onDeleteFailure
					) {
						var formulas = [];
						for (var c in $scope.datablockData.widget.grammar.columns) {
							if ($scope.datablockData.widget.grammar.columns[c].formula) {
								setFormulaColumnUuid(
									$scope.datablockData.widget.grammar.columns[c].formula,
									$scope.datablockData.widget.grammar.columns
								);
								$scope.datablockData.widget.grammar.columns[c].formula.lib =
									$scope.datablockData.widget.grammar.columns[c].column_alias;
								$scope.datablockData.widget.grammar.columns[c].formula.is_list =
									$scope.datablockData.widget.grammar.columns[c].list;
								$scope.datablockData.widget.grammar.columns[c].formula.uuid =
									$scope.datablockData.widget.grammar.columns[c].uuid
										? $scope.datablockData.widget.grammar.columns[c].uuid
										: generateUuid('_');
								formulas.push(
									$scope.datablockData.widget.grammar.columns[c].formula
								);
							}
						}
						var s = angular.copy($scope.oldPartielStep);
						s.formulas = formulas;

						checkDeletedColumns(
							s,
							{ in_formulas: true },
							function () {
								$scope.selectedStep.formulas = formulas;
								if (saveAndCloseMethod) {
									saveAndCloseMethod();
								}
							},
							undefined,
							onDeleteFailure
						);
					};

					var openFormulas = function (columns) {
						$scope.dbBuilder.columns = columns;
						var widget = { grammar: {} };
						widget.grammar.columns = [];
						var j = 1;
						for (var c in columns) {
							var col = {
								id: j,
								column_alias: columns[c].lib,
								field: columns[c].lib,
								type: columns[c].type,
								uuid: columns[c].uuid,
								list: columns[c].is_list,
							};
							widget.grammar.columns.push(col);
							j++;
						}
						for (var f in $scope.selectedStep.formulas) {
							widget.grammar.columns.push({
								id: j,
								formula: angular.copy($scope.selectedStep.formulas[f]),
								column_alias: $scope.selectedStep.formulas[f].lib,
								field: $scope.selectedStep.formulas[f].lib,
								type: $scope.selectedStep.formulas[f].data_type,
								uuid: $scope.selectedStep.formulas[f].uuid,
								list: $scope.selectedStep.formulas[f].is_list,
							});
							j++;
						}

						for (var c in widget.grammar.columns) {
							if (widget.grammar.columns[c].formula) {
								setFormulaColumnIndex(
									widget.grammar.columns[c].formula,
									widget.grammar.columns
								);
							}
						}

						$scope.datablockData.widget = widget;
						$scope.datablock = widget;
						$scope.datablockData.checkMethodBeforeSaveFormulas = false;
						$scope.datablockData.showFormule = true;
					};

					var openSorts = function (columns) {
						if (!$scope.selectedStep.sort || !$scope.selectedStep.sort.sorts) {
							$scope.selectedStep.sort = { sorts: [] };
						}
						$scope.dbBuilder.columns = columns;
						$scope.dbBuilder.showBDTris = true;
					};

					var openOutput = function (columns) {
						if (!$scope.selectedStep.output || !$scope.selectedStep.output[0]) {
							$scope.selectedStep.output = [];
						}
						$scope.dbBuilder.columns = columns;
						$scope.dbBuilder.showOutput = true;
					};

					var getInputColumns = function (steps, method) {
						var grammar = {
							columns: $scope.dataview.grammar.columns,
							steps: steps,
						};
						DataBlocksService.getDBBuilderColumns(grammar).then(function (
							response
						) {
							method(response.data);
						});
					};

					$scope.showFilter = function (i) {
						$scope.selectedStep = $scope.dataview.grammar.steps[i];
						if (!$scope.selectedStep.filter) {
							$scope.selectedStep.filter = { rules: [], condition: 'AND' };
						}
						$scope.dbBuilder.step = $scope.selectedStep;
						$scope.datablock = $scope.dataview;
						$scope.dbBuilder.filterRules = $scope.selectedStep.filter;
						$scope.dbBuilder.saveFilterMethod = saveFilter;
						var step = angular.copy($scope.selectedStep);
						var steps = $scope.dataview.grammar.steps.slice(0, i);
						steps.push(step);
						step.agregation = {};
						step.sort = {};
						step.data_operation = {};
						step.output = [];
						getInputColumns(steps, openFilter);
					};

					var openFilter = function (columns) {
						$scope.dbBuilder.columns = columns;
						$scope.dbBuilder.disableLimit = false;
						$scope.dbBuilder.disableActivation = false;
						$scope.dbBuilder.showFilter = true;
					};

					var saveFilter = function (rules, limit) {
						$scope.selectedStep.filter = rules;
						$scope.selectedStep.limit = limit;
					};

					var openAgregation = function (columns) {
						$scope.dbBuilder.columns = columns;
						$scope.dbBuilder.showBDAgregation = true;
					};

					var saveAgregation = function (agregation, saveAndCloseMethod) {
						let s = angular.copy($scope.oldPartielStep);
						s.agregation = agregation;
						checkDeletedColumns(s, { in_agregation: true }, function () {
							$scope.selectedStep.agregation = agregation;
							saveAndCloseMethod();
						});
					};

					var saveSorts = function (sorts, inactive) {
						for (var s in sorts) {
							sorts[s].column_uuid = sorts[s].uuid;
						}
						$scope.selectedStep.sort = {sorts: sorts, inactive: inactive};
					};

					var saveOutput = function (columns, saveAndCloseMethod) {
						var s = angular.copy($scope.oldPartielStep);
						s.output = columns;
						checkDeletedColumns(s, { in_output: true }, function () {
							$scope.selectedStep.output = columns;
							for (var c in columns) {
								updateColumnLib(columns[c].column_uuid, columns[c].lib);
								if (columns[c].date_output_pattern) {
									updateDatePattern(
										columns[c].column_uuid,
										columns[c].date_output_pattern
									);
								}
							}
							saveAndCloseMethod();
						});
					};

					var updateDatePattern = function (uuid, pattern) {
						for (var s in $scope.steps) {
							if ($scope.steps[s].output) {
								let outputCol = _.find($scope.steps[s].output, function (item) {
									return item.column_uuid == uuid;
								});
								if (outputCol) {
									outputCol.date_output_pattern = pattern;
								}
							}
						}
					};

					$scope.getSourceGrammar = function () {
						return getGrammar();
					};

					$scope.filterOnRows = function (f) {
						var newDataSource = _.filter($scope.gridOptions.dataSource, f);
						$scope.gridInstance.option('dataSource', newDataSource);
					};

					$scope.clearRowFilter = function (key, value) {
						$scope.gridInstance.option(
							'dataSource',
							$scope.gridOptions.dataSource
						);
					};

					$scope.getTypeHtml = function (type, isList) {
						if (type != undefined) {
							type = type.toLowerCase();
							return $sce.trustAsHtml(getHtmlIconByType(type, isList, GrammarUtils.getTypeLabel(type)));
						} else {
							return '';
						}
					};

					$scope.showColumnOrigin = function (columnUuid) {
						$scope.dbBuilder.columnOperationsColumnId = columnUuid;
						$scope.dbBuilder.columnOperationsParentId =
							$scope.dataview.source_id;
						$scope.dbBuilder.columnOperationsParentType = 'DATAVIEW';
						$scope.dbBuilder.columnOperationsHdId = vm.data.hdId;
						$scope.dbBuilder.columnOperationsHdType = vm.data.hdType;
						$scope.dbBuilder.showColumnOperations = true;
					};

					var checkDeletedColumns = function (
						newPartialStep,
						type,
						saveAndClose,
						newColumns,
						onDeleteFailure
					) {
						let partialSteps;
						let oldPartialSteps;
						let stepPos = $scope.selectedStep
							? $scope.dataview.grammar.steps.indexOf($scope.selectedStep)
							: 0;
						if (stepPos > 0 && newPartialStep) {
							partialSteps = angular.copy(
								$scope.dataview.grammar.steps.slice(0, stepPos)
							);
							oldPartialSteps = angular.copy(
								$scope.dataview.grammar.steps.slice(0, stepPos)
							);
							partialSteps.push(newPartialStep);
							oldPartialSteps.push($scope.oldPartielStep);
						} else if (newPartialStep) {
							partialSteps = [newPartialStep];
							oldPartialSteps = [$scope.oldPartielStep];
						}

						let oldSteps = $scope.oldSteps;
						type.hd_source_id = $scope.dataview.id;
						type.all_steps = oldSteps;
						type.new_steps = partialSteps;
						type.old_steps = oldPartialSteps;
						type.step_index = stepPos;
						type.columns = $scope.dataview.grammar.columns;
						if (type.in_columns) {
							type.new_columns = newColumns;
						}

						DataBlocksService.getImpact(type).then(function (response) {
							let safeDeletedColumns = [];
							if (response.data[0]) {
								for (var i in response.data) {
									if (
										response.data[i].impacts[0] ||
										!_.isEmpty(response.data[i].linked_elements)
									) {
										response.data[i].impact = true;
										$scope.datablockData.showDbStepImpact = true;
										$scope.datablockData.dbImpacts = response.data;
										if (onDeleteFailure) {
											onDeleteFailure();
										}
										return;
									} else {
										safeDeletedColumns.push(response.data[i].column_uuid);
									}
								}
							}
							safeDeletedColumnImpact(safeDeletedColumns, stepPos);
							saveAndClose();
						});
					};

					var safeDeletedColumnImpact = function (deletedColumns, stepPos) {
						return safeDeletedColumnStepImpact(
							deletedColumns,
							$scope.dataview.grammar.steps,
							stepPos
						);
					};

					var updateColumnLib = function (uuid, lib) {
						updateColumnLibInSteps(
							0,
							$scope.dataview.grammar.steps,
							uuid,
							lib,
							lib
						);
					};

					$scope.checkColumnRemoval = function() {
						return $q.resolve({ data: [] }); // satisfies HTTP promise return
					}
				},
			];

			return {
				restrict: 'E',

				scope: {
					data: '=',
					jobDetailsActive: '=',
					reloadTableOn: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/handledata/dataview/dataview.html',
				link: function (scope, element, attrs) {
					if (scope.vm.jobDetailsActive) {
						scope.jobDetails = { rerunMethod: scope.execWithoutCache };
						scope.jobDetailsId = generateUuid('_');
					}

					scope.vm.data.getGrammar = scope.getSourceGrammar;
					scope.vm.data.reloadGridView = scope.reloadGridView;
					scope.vm.data.filterOnRows = scope.filterOnRows;
					scope.vm.data.clearRowFilter = scope.clearRowFilter;
					scope.init();
				},
			};
		},
	]);
})();
