(function () {
	'use strict';

	angular.module('dcApp').directive('addColumn', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'GenericService',
				'$stateParams',
				'DataBlocksService',
				'toaster',
				'$sce',
				'gettextCatalog',
				'GrammarUtils',
				function (
					$scope,
					GenericService,
					$stateParams,
					DataBlocksService,
					toaster,
					$sce,
					gettextCatalog,
					GrammarUtils
				) {
					var vm = this;

					vm.init = function () {
						$scope.data = {};
						$scope.selectedIndex = 0;
						$scope.currentId = $stateParams.id;
						$scope.search = { searchText: '' };
						$scope.loading = false;
						$scope.caracsSort = 2;
						$scope.columnsSort = 0;
						$scope.searchInColumns = undefined;
						$scope.flags = {};
						$scope.selectedColumns = {};
						$scope.isIndeterminate = false;
						$scope.allColumnSelected = false;
						$scope.allColumnsActive = true;
					};

					$scope.refreshCaracs = function () {
						if ($scope.pivot.id) {
							$scope.refreshConnectedCaracs();
						} else {
							$scope.refreshFullCaracs();
						}
					};

					$scope.showColumnsMapping = function () {
						vm.widgetData.showColumnsMapping = true;
						vm.widgetData.mappingColumns = $scope.tmpColumns;
						vm.widgetData.saveColumnsMapping = saveColumnsMapping;
					};

					$scope.showColumn = function (column) {
						$scope.selectedColumn = column;
						vm.widgetData.updateaction_l2 = $scope.saveColumn;
						vm.widgetData.column = column;
						vm.widgetData.showColumnInfo = true;
					};

					$scope.saveColumn = function (columnAlias) {
						$scope.selectedColumn.column_alias = columnAlias;
						$scope.selectedColumn.alias = columnAlias;
					};

					$scope.showCaraInfo = function (carac) {
						delete vm.widgetData.columnInfoId;
						delete vm.widgetData.columnInfoGrammar;
						vm.widgetData.caracInfoId = carac.id;
						vm.widgetData.caracInfoEntiteId = carac.parent_entite_type_id;
						vm.widgetData.showColumnStat = true;
					};

					$scope.deleteColumn = function (index) {
						let col = $scope.filteredTmpColumns[index];
						$scope.columnToDeleteIndex = $scope.tmpColumns.indexOf(col);
						if (!vm.widgetData.widget.source_id) {
							// a new datablock  => dont check dependencies
							$scope.showDeleteColumnModal(true);
							return;
						}
						if (!vm.widgetData.widget.grammar.columns[index]) {
							$scope.showDeleteColumnModal(true);
							return;
						}
						vm.removeColumnCheck(
							{
								currentElementId: vm.widgetData.widget.source_id,
								columnUUID: vm.widgetData.widget.grammar.columns[index].uuid
							}
						).then(function (response) {
							if (Object.keys(response.data).length > 0) {
								var cause =
									' ' +
									gettextCatalog.getString(
										'Car elle est utilisée dans les DataBlocks suivants :'
									) +
									' ';
								$scope.showDeleteColumnModal(
									false,
									cause,
									_.map(response.data, function (v, k) {
										return v;
									})
								);
							} else {
								$scope.showDeleteColumnModal(true);
							}
						});
					};

					$scope.getHtmlIconByType = function (type, isList) {
						if (type != undefined) {
							type = type.toLowerCase();
							return $sce.trustAsHtml(getHtmlIconByType(type, isList, GrammarUtils.getTypeLabel(type)));
						}
					};

					$scope.getHtmlIconByCaracType = function (type, isList) {
						if (type != undefined) {
							type = getTypeFromEntiteTypeCode(type);
							return $sce.trustAsHtml(getHtmlIconByType(type, isList,  GrammarUtils.getTypeLabel(type)));
						}
					};

					$scope.showDeleteColumnModal = function (
						deleteEnabled,
						cause,
						linkedElements
					) {
						vm.widgetData.confirmationDialogTitle = gettextCatalog.getString(
							'Supprimer la colonne'
						);
						vm.widgetData.confirmationDialogMessage = deleteEnabled
							? gettextCatalog.getString('Voulez-vous supprimer la colonne ?') +
							  ' '
							: gettextCatalog.getString('Impossible de supprimer la colonne.');
						if (cause && !deleteEnabled) {
							vm.widgetData.confirmationDialogMessage =
								vm.widgetData.confirmationDialogMessage + cause;
						}
						vm.widgetData.confirmationDialogMessageDetailsList = linkedElements;
						vm.widgetData.confirmationDialogMessageDetails = '';
						vm.widgetData.confirmationDialogActionName =
							gettextCatalog.getString('Supprimer');
						vm.widgetData.enableConfirmDialogAction = deleteEnabled;
						vm.widgetData.confirmDialogAction = $scope.confirmDeleteColumn;
						vm.widgetData.showConfirmationDialog = true;
					};

					$scope.confirmDeleteColumn = function () {
						var deletedCol = $scope.tmpColumns[$scope.columnToDeleteIndex];
						$scope.tmpColumns.splice($scope.columnToDeleteIndex, 1);
						if (
							vm.widgetData.widget.grammar.columns[$scope.columnToDeleteIndex]
						) {
							updateWidgetColumnDeleteImpact(
								vm.widgetData.widget,
								vm.widgetData.widget.grammar.columns[$scope.columnToDeleteIndex]
									.id,
								$scope.columnToDeleteIndex,
								$scope.tmpColumns
							);
						}
						if (deletedCol.pivot) {
							setNewPivot(vm.widgetData.widget, deletedCol.uca_id);
						}
						reinitConfirmationDialog();
						// re-filter
						$scope.filterTmpColumns();
					};

					$scope.addCaracToColumns = function (caracId) {
						$scope.selectedCaracId = caracId;
						$scope.selectedCaracType = 'c';
						$scope.pathes = [];
						if (
							!isEmpty($scope.pivot.id) &&
							($scope.selectedCaracId !== $scope.pivot.id ||
								!isEmpty($scope.selectedCaracParent))
						) {
							$scope.pathFor = 'c';
							$scope.checkAllPathes();
						} else {
							var carac = $scope.getCaracFromId(
								$scope.selectedCaracId,
								$scope.selectedCaracType
							);
							var column = getColumnFromCarac(carac);

							column.carac = carac;
							if (carac.type == 'c') {
								column.path = 'c' + carac.id;
							} else {
								column.path = carac.id;
							}
							column.pivot = true;
							column.column_alias = column.field;
							column.id = $scope.column_id++;
							column.uca_code = carac.code;
							column.active = true;
							$scope.tmpColumns.push(column);
							$scope.pivot.id = carac.id;
							$scope.pivot.type = 'c';
							column.uuid = generateUuid('_');
							$scope.refreshConnectedCaracs();
						}
					};

					$scope.addEntiteCaracToColumns = function (entiteId, entiteLib) {
						$scope.selectedCaracId = entiteId;
						$scope.selectedCaracLib = entiteLib;
						$scope.selectedCaracType = 't';
						$scope.pathes = [];
						let caracFromId = $scope.getCaracFromId(
							$scope.pivot.id,
							$scope.pivot.type
						);
						if (
							!isEmpty($scope.pivot.id) &&
							(!caracFromId ||
								(caracFromId &&
									$scope.selectedCaracId !== caracFromId.parent_entite_type_id))
						) {
							$scope.pathFor = 't';
							$scope.checkAllPathes();
						} else {
							$scope.showEntiteCaracs();
						}
					};

					$scope.showEntiteCaracs = function () {
						vm.widgetData.selectEntiteCaracsEntiteId = $scope.selectedCaracId;
						vm.widgetData.selectEntiteCaracsEntiteLib = $scope.selectedCaracLib;
						vm.widgetData.updateaction_l3 = $scope.addEntiteCaracs;
						vm.widgetData.showSelectEntiteCaracs = true;
						vm.widgetData.fromDataBlocks = $scope.fromDataBlocks;
					};

					$scope.checkAllPathes = function () {
						delete $scope.selectedPath;
						vm.widgetData.updateaction_l2 = $scope.saveSelectPath;
						vm.widgetData.source = $scope.pivot.id;
						vm.widgetData.sourceType = $scope.pivot.type;
						vm.widgetData.targetLib = $scope.getCaracFromId(
							$scope.selectedCaracId,
							$scope.selectedCaracType
						).lib_court;
						vm.widgetData.targetParentLib = $scope.getCaracFromId(
							$scope.selectedCaracId,
							$scope.selectedCaracType
						).parent_entite_type_lib;
						vm.widgetData.targetParentId = $scope.getCaracFromId(
							$scope.selectedCaracId,
							$scope.selectedCaracType
						).parent_entite_type_id;
						vm.widgetData.target = $scope.selectedCaracId;
						vm.widgetData.targetType = $scope.selectedCaracType;
						vm.widgetData.oldPathes = [];
						for (var c in $scope.tmpColumns) {
							if ($scope.tmpColumns[c].path && !$scope.tmpColumns[c].pivot) {
								vm.widgetData.oldPathes.push($scope.tmpColumns[c].path);
							}
						}
						vm.widgetData.showSelectPath = true;
					};

					$scope.saveSelectPath = function (path) {
						$scope.selectedPath = path;
						if ($scope.selectedCaracType == 'c') {
							var carac = $scope.getCaracFromId(
								$scope.selectedCaracId,
								$scope.selectedCaracType
							);
							var column = getColumnFromCarac(carac);
							column.id = $scope.column_id++;
							column.carac = carac;
							column.media = !carac.structured;
							column.path = path;
							column.active = true;
							column.column_alias = column.field;
							column.tmpPath = $scope.getPathFromPathes($scope.selectedPath);
							column.uca_code = carac.code;
							column.uuid = generateUuid('_');
							$scope.tmpColumns.push(column);
						} else {
							$scope.showEntiteCaracs();
						}
					};

					$scope.addEntiteCaracs = function (caracs) {
						for (var c in caracs) {
							var carac = caracs[c];
							var column = getColumnFromCarac(carac);
							column.id = $scope.column_id++;
							column.carac = carac;
							column.media = !carac.structured;
							column.column_alias = column.field;
							column.tmpPath = $scope.getPathFromPathes($scope.selectedPath);
							column.uca_code = carac.code;
							column.active = true;
							column.uuid = generateUuid('_');
							$scope.tmpColumns.push(column);
							if (isEmpty($scope.pivot.id)) {
								column.pivot = true;
								$scope.pivot.id = carac.id;
								$scope.pivot.type = 'c';
								column.path = 'c' + carac.id;
								$scope.refreshConnectedCaracs();
							} else {
								column.path =
									'c' +
									$scope.pivot.id +
									'-' +
									carac.parent_entite_type_id +
									'-c' +
									carac.id;
							}

							if ($scope.selectedPath) {
								column.path = $scope.selectedPath + '-c' + carac.id;
							}
						}
					};

					$scope.getPathFromPathes = function (path) {
						for (var p in $scope.pathes) {
							if ($scope.pathes[p].object == path) {
								return $scope.pathes[p];
							}
						}
					};

					$scope.getCaracsAndEntites = function () {
						if (!$scope.pivot) {
							return;
						}
						if (!$scope.pivot.id) {
							return $scope.caracs;
						}
						return _.filter($scope.caracs, function (item) {
							return !_.filter($scope.tmpColumns, function (col) {
								return col.uca_id == item.id && item.type == 'c';
							})[0];
						});
					};

					$scope.refreshConnectedCaracs = function () {
						if (
							$scope.search.searchText &&
							$scope.search.searchText.length == 1
						) {
							return;
						}

						var text;
						if ($scope.search.searchText) {
							text = $scope.search.searchText;
						} else {
							text = '';
						}

						GenericService.getConnectedCaracs(
							$scope.pivot.id,
							text,
							$scope.searchLibCourt,
							$scope.searchLibLong,
							$scope.searchDesc,
							$scope.searchTags,
							$scope.searchPoids,
							$scope.pivot.type,
							$scope.fromDataBlocks,
							$scope.filter.filterType
						).then(function (response) {
							$scope.connectedCaracs = [];
							for (var c in response.data) {
								if (!response.data[c].complex) {
									$scope.connectedCaracs.push(response.data[c]);
								}
							}
							$scope.caracs = $scope.connectedCaracs;
							for (var i = 0; i < $scope.tmpColumns.length; i++) {
								for (var j = 0; j < $scope.caracs.length; j++) {
									if (
										$scope.tmpColumns[i].parent_entite_type_id ==
										$scope.caracs[j].parent_entite_type_id
									) {
										$scope.tmpColumns[i].parent_entite_type_lib =
											$scope.caracs[j].parent_entite_type_lib;
									}
								}
							}

							$scope.sortCaracs();
							var pivotCara = $scope.getCaracFromId(
								$scope.pivot.id,
								$scope.pivot.type
							);
							if (pivotCara) {
								vm.widgetData.sourceLib = $scope.getCaracFromId(
									$scope.pivot.id,
									$scope.pivot.type
								).lib_court;
								vm.widgetData.sourceParentLib = $scope.getCaracFromId(
									$scope.pivot.id,
									$scope.pivot.type
								).parent_entite_type_lib;
								vm.widgetData.sourceParentId = $scope.getCaracFromId(
									$scope.pivot.id,
									$scope.pivot.type
								).parent_entite_type_id;
							}
						});
					};

					$scope.getFromDataBlocks = function () {
						if ($scope.pivot && $scope.pivot.id) {
							return;
						}
						$scope.fromDataBlocks = true;
						$scope.refreshFullCaracs();
					};

					$scope.getFromEntites = function () {
						if ($scope.pivot && $scope.pivot.id) {
							return;
						}
						$scope.fromDataBlocks = false;
						$scope.refreshFullCaracs();
					};

					$scope.refreshFullCaracs = function () {
						// Search Entite Types By Lib Court
						if (
							$scope.search.searchText &&
							$scope.search.searchText.length == 1
						) {
							return;
						}
						$scope.loading = true;
						if ($scope.search.searchText) {
							GenericService.getCaracsByFullText(
								$scope.search.searchText,
								$scope.searchLibCourt,
								$scope.searchLibLong,
								$scope.searchDesc,
								$scope.searchTags,
								$scope.searchPoids,
								$scope.fromDataBlocks,
								$scope.filter.filterType
							).then(function (response) {
								$scope.caracs = [];
								for (var c in response.data) {
									if (!response.data[c].complex) {
										$scope.caracs.push(response.data[c]);
									}
								}
								$scope.sortCaracs();
								$scope.loading = false;
							});
						} else {
							GenericService.getCaracsByFullText(
								'',
								$scope.searchLibCourt,
								$scope.searchLibLong,
								$scope.searchDesc,
								$scope.searchTags,
								$scope.searchPoids,
								$scope.fromDataBlocks,
								$scope.filter.filterType
							).then(function (response) {
								$scope.caracs = [];
								for (var c in response.data) {
									if (!response.data[c].complex) {
										$scope.caracs.push(response.data[c]);
									}
								}
								$scope.sortCaracs();
								$scope.loading = false;
							});
						}
					};

					$scope.sortCaracs = function (mode) {
						if (mode) {
							$scope.caracsSort = mode;
						}
						if ($scope.caracsSort == undefined || $scope.caracsSort == 2) {
							$scope.caracs = _.sortBy($scope.caracs, function (item) {
								return (
									(item.type == 'c' ? '2' : '1') + item.lib_court.toLowerCase()
								);
							});
						} else {
							$scope.caracs = _.sortBy($scope.caracs, function (item) {
								return (
									(item.type == 'c' ? '2' : '1') + item.lib_court.toLowerCase()
								);
							}).reverse();
						}
					};

					$scope.getCaracFromId = function (id, typeData) {
						for (var c in $scope.caracs) {
							if ($scope.caracs[c].id == id) {
								if (typeData !== undefined) {
									if ($scope.caracs[c].type == typeData) {
										return $scope.caracs[c];
									}
								} else {
									return $scope.caracs[c];
								}
							}
						}
					};

					vm.closeModal = function () {
						$(vm.element).modal('hide');
						vm.widgetData.showAddColumn = false;
					};

					$scope.save = function () {
						for (var c in $scope.selectedWidget.grammar.columns) {
							for (var c_ in $scope.tmpColumns) {
								if (
									$scope.tmpColumns[c_].id ==
									$scope.selectedWidget.grammar.columns[c].id
								) {
									if (
										$scope.selectedWidget.grammar.columns[c].active == undefined
									) {
										$scope.selectedWidget.grammar.columns[c].active = true;
									}
									if (
										$scope.tmpColumns[c_].active !==
										$scope.selectedWidget.grammar.columns[c].active
									) {
										$scope.tmpColumns[c].toggleVisible = true;
									}
								}
							}
						}
						$scope.selectedWidget.grammar.from_datablock =
							$scope.fromDataBlocks;
						if ($scope.selectedWidget.checkColumnsBeforeSave) {
							$scope.selectedWidget.checkColumnsBeforeSave(
								$scope.tmpColumns,
								$scope.saveAndClose
							);
						} else {
							$scope.saveAndClose();
						}
					};

					$scope.saveAndClose = function () {
						vm.widgetData.updateaction(angular.copy($scope.tmpColumns));
						reinitConfirmationDialog();
						toaster.pop(
							'success',
							gettextCatalog.getString('Succès'),
							gettextCatalog.getString('Enregistrement effectué')
						);
						vm.closeModal();
					};

					$scope.filterTmpColumns = function () {
						if (
							$scope.searchInColumns == undefined ||
							$scope.searchInColumns == ''
						) {
							$scope.filteredTmpColumns = $scope.tmpColumns;
						} else {
							$scope.filteredTmpColumns = _.filter(
								$scope.tmpColumns,
								function (el) {
									return el.column_alias && el.column_alias.length > 0
										? el.column_alias
												.toLowerCase()
												.indexOf($scope.searchInColumns.toLowerCase()) > -1
										: el.field
												.toLowerCase()
												.indexOf($scope.searchInColumns.toLowerCase()) > -1;
								}
							);
						}
					};

					$scope.sortFilteredTmpColumn = function () {
						if ($scope.columnsSort == undefined || $scope.columnsSort == -1) {
							$scope.columnsSort = 0;
							$scope.filteredTmpColumns = _.sortBy(
								$scope.filteredTmpColumns,
								function (item) {
									return item.column_alias && item.column_alias.length > 0
										? item.column_alias.trim().toUpperCase()
										: item.field.trim().toUpperCase();
								}
							);
						} else if ($scope.columnsSort == 1) {
							$scope.columnsSort = -1;
							$scope.filteredTmpColumns = _.sortBy(
								$scope.filteredTmpColumns,
								function (item) {
									return item.column_alias && item.column_alias.length > 0
										? item.column_alias.trim().toUpperCase()
										: item.field.trim().toUpperCase();
								}
							).reverse();
						} else {
							$scope.filteredTmpColumns = $scope.tmpColumns;
							$scope.columnsSort = 1;
						}
					};

					$scope.cancel = function () {
						vm.closeModal();
					};

					$scope.closeConfirmation = function () {
						$scope.flags.confirmationIsOpend = false;
						vm.closeModal();
					};

					$scope.initColumns = function () {
						$scope.fromDataBlocks =
							vm.widgetData.disableColumnSwitch ||
							vm.widgetData.widget.grammar.from_datablock;
						$scope.selectedWidget = vm.widgetData.widget;
						$scope.tmpColumns = angular.copy(
							vm.widgetData.widget.grammar.columns
						);
						$scope.filterTmpColumns();
						if (!$scope.tmpColumns) {
							$scope.tmpColumns = [];
						}
						for (let c in $scope.tmpColumns) {
							if ($scope.tmpColumns[c].active == undefined) {
								$scope.tmpColumns[c].active = true;
							}
						}
						$scope.column_id = 0;
						var pivotId;
						let pivotParentId;
						for (let c in $scope.tmpColumns) {
							if ($scope.tmpColumns[c].pivot) {
								pivotId = $scope.tmpColumns[c].uca_id;
								pivotParentId = $scope.tmpColumns[c].parent_entite_type_id;
							}
							if ($scope.tmpColumns[c].id > $scope.column_id) {
								$scope.column_id = $scope.tmpColumns[c].id;
							}
						}
						vm.widgetData.lastColumnId = $scope.column_id++;
						$scope.pivot = {};

						$scope.pivot.id = pivotId;
						$scope.pivot.type = 'c';
						$scope.searchLibCourt = true;
						$scope.searchLibLong = true;
						$scope.searchDesc = true;
						$scope.searchTags = true;
						$scope.pivot.id
							? ($scope.filter = { filterType: 'c' })
							: ($scope.filter = { filterType: 't' });
						$scope.searchPoids = 100;

						if (pivotParentId) {
							DataBlocksService.isDataBlock(pivotParentId).then(function (
								response
							) {
								$scope.fromDataBlocks = response.data;
								$scope.refreshCaracs();
							});
						} else {
							$scope.refreshCaracs();
						}
					};

					$scope.changeAllStatus = function () {
						let count = _.groupBy($scope.selectedColumns, function (item) {
							return item;
						});
						$scope.isIndeterminate =
							count.true &&
							count.true.length > 0 &&
							count.true.length < Object.keys($scope.selectedColumns).length;
						$scope.allColumnSelected =
							count.true &&
							count.true.length == Object.keys($scope.selectedColumns).length;
					};

					$scope.changeAllColumnsSelection = function () {
						for (let i in $scope.selectedColumns) {
							$scope.selectedColumns[i] = $scope.allColumnSelected;
						}
						$scope.changeAllStatus();
					};

					$scope.changeAllColumnsSelectionValue = function () {
						for (let i in $scope.filteredTmpColumns) {
							if ($scope.selectedColumns[$scope.filteredTmpColumns[i].uuid]) {
								$scope.filteredTmpColumns[i].active = $scope.allColumnsActive;
							}
						}
					};

					$scope.deleteAllSelectedColumns = function () {
						for (let i = $scope.filteredTmpColumns.length - 1; i >= 0; i--) {
							let uuid = $scope.filteredTmpColumns[i].uuid;
							if ($scope.selectedColumns[uuid]) {
								$scope.columnToDeleteIndex = i;
								$scope.confirmDeleteColumn();
								delete $scope.selectedColumns[uuid];
							}
						}
						$scope.changeAllStatus();
					};

					$scope.showDeleteAllConfirmation = function () {
						vm.widgetData.confirmationDialogTitle = gettextCatalog.getString(
							'Supprimer les colonnes'
						);
						vm.widgetData.confirmationDialogMessage = gettextCatalog.getString(
							'Voulez-vous supprimer les colonnes selectionnées ?'
						);
						vm.widgetData.confirmationDialogActionName =
							gettextCatalog.getString('Supprimer');
						vm.widgetData.enableConfirmDialogAction = true;
						vm.widgetData.confirmDialogAction = $scope.deleteAllSelectedColumns;
						vm.widgetData.showConfirmationDialog = true;
					};

					function saveColumnsMapping(fromDataBlock, columns) {
						let oldPivot = _.find($scope.tmpColumns, function (elm) {
							return elm.pivot;
						});
						let newPivot = _.find(columns, function (elm) {
							return elm.pivot;
						});
						$scope.fromDataBlocks = fromDataBlock;
						$scope.tmpColumns = columns;
						$scope.filterTmpColumns();
						if (oldPivot.uca_id != newPivot.uca_id) {
							setNewPivot(vm.widgetData.widget, newPivot.uca_id);
						}
					}

					function reinitConfirmationDialog() {
						delete vm.widgetData.confirmationDialogTitle;
						delete vm.widgetData.confirmationDialogMessage;
						delete vm.widgetData.confirmationDialogActionName;
						delete vm.widgetData.enableConfirmDialogAction;
						delete vm.widgetData.closeDialogAction;
						delete vm.widgetData.confirmDialogAction;
						delete vm.widgetData.showConfirmationDialog;
					}

					function setNewPivot(widget, pivotUcaId) {
						var firstColumn;
						for (let c in $scope.tmpColumns) {
							if ($scope.tmpColumns[c].path && !firstColumn) {
								firstColumn = $scope.tmpColumns[c];
							}
							if ($scope.tmpColumns[c].uca_id == pivotUcaId) {
								$scope.tmpColumns[c].pivot = true;
								return;
							}
						}
						if (firstColumn) {
							firstColumn.path = 'c' + firstColumn.uca_id;
							firstColumn.pivot = true;
						} else {
							return;
						}

						for (let c in $scope.tmpColumns) {
							if (
								$scope.tmpColumns[c].uca_id &&
								$scope.tmpColumns[c].uca_id !== firstColumn.uca_id
							) {
								$scope.tmpColumns[c].path =
									firstColumn.path +
									'-' +
									firstColumn.parent_entite_type_id +
									'-c' +
									$scope.tmpColumns[c].uca_id;
							}
						}
					}
				},
			];

			return buildDirectiveReturnObject(
				{ widgetData: '=', saveAction: '&',  removeColumnCheck: '&'},
				controller,
				'./src/components/directives/dataOperationTools/addColumn/addColumn.html',
				function postLink(scope, element, attrs, $ctrl) {
					$ctrl.init();
					initElementForDirective(element, attrs, function () {
						$ctrl.closeModal();
					});
					watchElementForDirective(
						element,
						scope,
						function () {
							return $ctrl.widgetData.showAddColumn;
						},
						function () {
							scope.initColumns();
						}
					);
				}
			);
		},
	]);
})();
