(function () {
	'use strict';

	angular.module('dcApp').factory('ExpositionService', [
		'$http',
		'UrlService',
		function ($http, UrlService) {
			var ExpositionService = {};

			ExpositionService.getExpositionMetadata = function (expositionId) {
				const url = UrlService.generateUrl('EXPOSITIONS_METADATA', {id: expositionId}, {});
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			return ExpositionService;
		},
	]);
})();
