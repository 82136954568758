(function () {
	angular.module('dcApp').directive('permissionTemplate', [
		'$parse',
		function ($parse) {
			let controller = [
				'$scope',
				'$rootScope',
				'PermissionService',
				'toaster',
				'gettextCatalog',
				function (
					$scope,
					$rootScope,
					PermissionService,
					toaster,
					gettextCatalog
				) {
					$scope.allMember = {};
					$scope.allCreator = {};
					var vm = this;

					$scope.element_types = [
						{
							code: 'connector',
							label: gettextCatalog.getString('Connecteurs'),
							gd: true,
						},
						{
							code: 'data_source',
							label: gettextCatalog.getString('Dépôts'),
							gd: true,
						},
						{
							code: 'entity',
							label: gettextCatalog.getString('Entités Métier'),
							gd: true,
						},
						{
							code: 'datablock',
							label: gettextCatalog.getString('DataBlock'),
							gd: true,
						},
						{
							code: 'export_template',
							label: gettextCatalog.getString('export.template'),
							gd: true,
						},
						{
							code: 'dashboard',
							label: gettextCatalog.getString('Tableaux de Bord'),
						},
						{ code: 'timeline', label: gettextCatalog.getString('Timeline') },
						{ code: 'chart', label: gettextCatalog.getString('Graphiques') },
						{ code: 'media', label: gettextCatalog.getString('Médias') },
						{ code: 'grid', label: gettextCatalog.getString('Tableaux') },
						{ code: 'map', label: gettextCatalog.getString('Cartes') },
						{code: 'exposition', label: gettextCatalog.getString('exposition.name')},
					];

					var element_actions = [
						{
							code: 'view',
							label: gettextCatalog.getString('Lecture'),
							basic: true,
						},
						{
							code: 'edit',
							label: gettextCatalog.getString('Modification'),
							basic: true,
						},
						{
							code: 'delete',
							label: gettextCatalog.getString('Suppression'),
							basic: true,
						},
						{
							code: 'permissions',
							label: gettextCatalog.getString('Permissions'),
							basic: false,
						},
						{
							code: 'data',
							label: gettextCatalog.getString('Données'),
							basic: true,
						},
						{
							code: 'export',
							label: gettextCatalog.getString('Export'),
							basic: true,
						},
						{
							code: 'export_dc',
							label: gettextCatalog.getString('Export DC'),
							basic: false,
						},
						{
							code: 'share',
							label: gettextCatalog.getString('share'),
							basic: false,
						},
						{
							code: 'persist',
							label: gettextCatalog.getString('Persistance/Cache'),
							basic: false,
						},
						{
							code: 'carac',
							label: gettextCatalog.getString('Caractéristiques'),
							basic: false,
						},
						{
							code: 'data_catalog',
							label: gettextCatalog.getString('DataCatalogue'),
							basic: false,
						},
						{
							code: 'extract',
							label: gettextCatalog.getString('Extraction'),
							basic: false,
						},
						{
							code: 'link',
							label: gettextCatalog.getString('Lien'),
							basic: false,
						},
					];

					$scope.toggleAllCreator = function (action) {
						for (let ele in vm.data.creator) {
							vm.data.creator[ele][action] = $scope.allCreator[action];
						}
					};

					$scope.toggleAllMember = function (action) {
						for (let ele in vm.data.member) {
							vm.data.member[ele][action] = $scope.allMember[action];
						}
					};

					$scope.init = function () {
						PermissionService.getElementPermissionRules().then(function (
							response
						) {
							$scope.elementPermissions = response.data;
							initPermissionTemplate();
						});
					};

					var initPermissionTemplate = function () {
						$scope.element_actions = !vm.basic
							? element_actions
							: _.filter(element_actions, function (item) {
									return item.basic == true;
							  });
						for (let ac in element_actions) {
							$scope.allMember[element_actions[ac].code] = true;
							$scope.allCreator[element_actions[ac].code] = true;
						}
						for (let ele in $scope.element_types) {
							if (!vm.data.creator[$scope.element_types[ele].code]) {
								vm.data.creator[$scope.element_types[ele].code] = {};
								for (let ac in element_actions) {
									vm.data.creator[$scope.element_types[ele].code][
										element_actions[ac].code
									] = !(
										($scope.element_types[ele].code == 'connector' ||
											element_actions[ele].code == 'data_source') &&
										element_actions[ac].code == 'export'
									);
								}
							}
							if (!vm.data.member[$scope.element_types[ele].code]) {
								vm.data.member[$scope.element_types[ele].code] = {};
								for (let ac in $scope.element_actions) {
									vm.data.member[$scope.element_types[ele].code][
										$scope.element_actions[ac].code
									] =
										$scope.element_actions[ac].code != 'delete' &&
										!(
											($scope.element_types[ele].code == 'connector' ||
												$scope.element_types[ele].code == 'data_source') &&
											$scope.element_actions[ac].code == 'export'
										);
								}
							}
						}

						for (let ac in element_actions) {
							for (let ele in $scope.element_types) {
								if (
									$scope.elementPermissions[
										$scope.element_types[ele].code
									].indexOf(element_actions[ac].code) < 0
								) {
									continue;
								}
								if (
									!vm.data.creator[$scope.element_types[ele].code][
										element_actions[ac].code
									]
								) {
									$scope.allCreator[element_actions[ac].code] = false;
								}
								if (
									!vm.data.member[$scope.element_types[ele].code][
										element_actions[ac].code
									]
								) {
									$scope.allMember[element_actions[ac].code] = false;
								}
							}
						}
					};
				},
			];

			return {
				restrict: 'E',
				scope: {
					data: '=',
					basic: '=',
				},
				controller: controller,
				bindToController: true,
				transclude: true,
				replace: true,
				controllerAs: 'vm',
				templateUrl:
					'./src/components/directives/permission/template/permissionTemplate.html',
				link: function (scope, element, attrs) {
					scope.init();
				},
			};
		},
	]);
})();
